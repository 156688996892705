const dictionaryWords = [
  "ก.ก.",
  "ก.ก.น.",
  "ก.ข.ค.",
  "ก.ค.",
  "ก.จ.",
  "ก.ช.น.",
  "ก.ฌ.",
  "ก.ต.",
  "ก.ต.ง.",
  "ก.ต.ช.",
  "ก.ตร.",
  "ก.ท.",
  "ก.น.ช.",
  "ก.บช.",
  "ก.บถ.",
  "ก.ป.ส.",
  "ก.พ.",
  "ก.ม.",
  "ก.ย.",
  "ก.ร.",
  "ก.ล.ต.",
  "ก.ว.",
  "ก.ศ.ว.",
  "ก.ส.ท.",
  "ก.ส.ธ.",
  "ก.ส.อ.",
  "ก.อ.",
  "กก",
  "กก.ตชด.",
  "กก.ตร.น.",
  "กก.ภ.จว.",
  "กก.รสช.",
  "กกบ.ขส.ทบ.",
  "กกล.รพน.",
  "กกหู",
  "กกุธภัณฑ์",
  "กง",
  "กง.กห.",
  "กง.ทบ.",
  "กง.ทร.",
  "กงกอน",
  "กงการ",
  "กงจักร",
  "กงสี",
  "กงสุล",
  "กงเต็ก",
  "กงเต๊ก",
  "กงไฉ่",
  "กช",
  "กซข.ป.",
  "กซม.ป.",
  "กฎ",
  "กฎบัตร",
  "กฎหมาย",
  "กฎหมู่",
  "กฎุมพี",
  "กฎเกณฑ์",
  "กฏ",
  "กฏหมาย",
  "กฏุก",
  "กฐิน",
  "กณิกนันต์",
  "กณิการ์",
  "กด",
  "กดขี่",
  "กดดัน",
  "กตัญชลี",
  "กตัญญุตา",
  "กตัญญู",
  "กตาธิการ",
  "กตาภินิหาร",
  "กติกา",
  "กตเวทิตา",
  "กตเวที",
  "กถา",
  "กถิกาจารย์",
  "กทม.กรุงเทพมหานคร",
  "กทลี",
  "กน",
  "กนก",
  "กนิษฐภคินี",
  "กนิษฐภาดา",
  "กนิษฐา",
  "กนิษฐ์",
  "กบ",
  "กบ.ทบ.",
  "กบฎ",
  "กบฏ",
  "กบดาน",
  "กบทู",
  "กบาล",
  "กบินทร์",
  "กบิล",
  "กบิลพัสดุ์",
  "กบี่",
  "กบูร",
  "กป.สป.",
  "กพ.ทบ.",
  "กพ.ทร.",
  "กพ.ทหาร",
  "กม",
  "กมณฑลาภิเษก",
  "กมณฑโลทก",
  "กมล",
  "กมลา",
  "กมลาศ",
  "กมลาสน์",
  "กมัณฑลุ",
  "กมุท",
  "กมเลศ",
  "กร",
  "กร.ทบ.",
  "กรกฎ",
  "กรกฎา",
  "กรกฎาคม",
  "กรกฏ",
  "กรง",
  "กรงเล็บ",
  "กรชกาย",
  "กรณฑ์",
  "กรณิการ์",
  "กรณี",
  "กรณียกิจ",
  "กรณียะ",
  "กรณีย์",
  "กรด",
  "กรน",
  "กรบ",
  "กรบูร",
  "กรป.กลาง",
  "กรพินธุ์",
  "กรม",
  "กรมการ",
  "กรมท่า",
  "กรมธรรม์",
  "กรมนา",
  "กรมวัง",
  "กรรกฎ",
  "กรรกศ",
  "กรรชิง",
  "กรรฐา",
  "กรรฐ์",
  "กรรณ",
  "กรรณา",
  "กรรณิกา",
  "กรรณิการ์",
  "กรรดิ",
  "กรรดิก",
  "กรรดึก",
  "กรรตุ",
  "กรรทบ",
  "กรรบิด",
  "กรรบูร",
  "กรรภิรมย์",
  "กรรม",
  "กรรมกร",
  "กรรมการ",
  "กรรมชวาต",
  "กรรมฐาน",
  "กรรมบถ",
  "กรรมพันธุ์",
  "กรรมวิธี",
  "กรรมสิทธิ์",
  "กรรมัชวาต",
  "กรรมาชน",
  "กรรมาชีพ",
  "กรรมาธิการ",
  "กรรมาร",
  "กรรม์",
  "กรรม์ภิรมย์",
  "กรรษก",
  "กรรสะ",
  "กรรเกด",
  "กรรเจียก",
  "กรรเชียง",
  "กรรแทก",
  "กรรแสง",
  "กรรโชก",
  "กรรไกร",
  "กรรไตร",
  "กรวด",
  "กรวบ",
  "กรวม",
  "กรวย",
  "กรวิก",
  "กรสาปน์",
  "กรสุทธิ์",
  "กรอ",
  "กรอ.พอ.",
  "กรอก",
  "กรอกแกรก",
  "กรอง",
  "กรองกรอย",
  "กรอด",
  "กรอบ",
  "กรอบรูป",
  "กรอม",
  "กระ",
  "กระกร",
  "กระกรุ่น",
  "กระกลับกลอก",
  "กระกี้",
  "กระคน",
  "กระคาย",
  "กระงกกระเงิ่น",
  "กระง่องกระแง่ง",
  "กระง่อนกระแง่น",
  "กระจก",
  "กระจกตา",
  "กระจกนูน",
  "กระจกเงา",
  "กระจกเว้า",
  "กระจง",
  "กระจร",
  "กระจอก",
  "กระจอกชวา",
  "กระจอกเทศ",
  "กระจองหง่อง",
  "กระจองอแง",
  "กระจอนหู",
  "กระจะ",
  "กระจัก",
  "กระจัง",
  "กระจัด",
  "กระจับ",
  "กระจับบก",
  "กระจับปิ้ง",
  "กระจับปี่",
  "กระจาด",
  "กระจาน",
  "กระจาบ",
  "กระจาม",
  "กระจาย",
  "กระจาว",
  "กระจิก",
  "กระจิด",
  "กระจิบ",
  "กระจิริด",
  "กระจิ๋ง",
  "กระจิ๋ม",
  "กระจิ๋ว",
  "กระจี้",
  "กระจี๋",
  "กระจุก",
  "กระจุบ",
  "กระจุย",
  "กระจุ๊บ",
  "กระจุ๋งกระจิ๋ง",
  "กระจุ๋มกระจิ๋ม",
  "กระจูด",
  "กระจู้",
  "กระจู๋กระจี๋",
  "กระจ่า",
  "กระจ่าง",
  "กระจ้อน",
  "กระจ้อย",
  "กระจ้อยร่อย",
  "กระจ๋องหง่อง",
  "กระจ๋อหวอ",
  "กระฉอก",
  "กระฉับกระเฉง",
  "กระฉิ่ง",
  "กระฉีก",
  "กระฉูด",
  "กระฉ่อน",
  "กระชดกระช้อย",
  "กระชอน",
  "กระชอมดอก",
  "กระชัง",
  "กระชับ",
  "กระชั้น",
  "กระชาก",
  "กระชาย",
  "กระชิง",
  "กระชิด",
  "กระชุ",
  "กระชุก",
  "กระชุ่มกระชวย",
  "กระช้อย",
  "กระซับ",
  "กระซาบ",
  "กระซิก",
  "กระซิบ",
  "กระซี้",
  "กระซุง",
  "กระซุบกระซิบ",
  "กระซุ้ม",
  "กระซู่",
  "กระซ่องกระแซ่ง",
  "กระฎี",
  "กระฎุมพี",
  "กระดก",
  "กระดนโด่",
  "กระดวง",
  "กระดวน",
  "กระดอ",
  "กระดอง",
  "กระดองหาย",
  "กระดอน",
  "กระดอม",
  "กระดักกระเดี้ย",
  "กระดังงัว",
  "กระดังงา",
  "กระดาก",
  "กระดางลาง",
  "กระดาด",
  "กระดาดขาว",
  "กระดาน",
  "กระดานดำ",
  "กระดานพน",
  "กระดานหก",
  "กระดาษ",
  "กระดาษทราย",
  "กระดาษสา",
  "กระดาษแก้ว",
  "กระดาษไข",
  "กระดำกระด่าง",
  "กระดิก",
  "กระดิบ",
  "กระดิ่ง",
  "กระดิ้ง",
  "กระดี่",
  "กระดี้กระเดียม",
  "กระดี๊กระด๊า",
  "กระดึง",
  "กระดืบ",
  "กระดุ",
  "กระดุกกระดิก",
  "กระดุบ",
  "กระดุบกระดิบ",
  "กระดุม",
  "กระดุ้งกระดิ้ง",
  "กระดูก",
  "กระดูกงู",
  "กระด้ง",
  "กระด้วมกระเดี้ยม",
  "กระด้าง",
  "กระตรับ",
  "กระตราก",
  "กระตรุด",
  "กระตรุม",
  "กระตัก",
  "กระตั้ว",
  "กระติก",
  "กระติบ",
  "กระติ๊ด",
  "กระตือรือร้น",
  "กระตุก",
  "กระตุ่น",
  "กระตุ้งกระติ้ง",
  "กระตุ้น",
  "กระตูบ",
  "กระต่องกระแต่ง",
  "กระต่าย",
  "กระต้วมกระเตี้ยม",
  "กระต้อ",
  "กระต้อยตีวิด",
  "กระต๊อบ",
  "กระถด",
  "กระถอบ",
  "กระถั่ว",
  "กระถาง",
  "กระถิก",
  "กระถิน",
  "กระทก",
  "กระทง",
  "กระทบ",
  "กระทรวง",
  "กระทอก",
  "กระทะ",
  "กระทั่ง",
  "กระทั้น",
  "กระทา",
  "กระทาชาย",
  "กระทาย",
  "กระทาสี",
  "กระทาหอง",
  "กระทำ",
  "กระทิกกระทวย",
  "กระทิง",
  "กระทึง",
  "กระทืบ",
  "กระทุ",
  "กระทุง",
  "กระทุ่ม",
  "กระทุ้ง",
  "กระทู้",
  "กระท่อนกระแท่น",
  "กระท่อม",
  "กระท้อน",
  "กระท้อมกระแท้ม",
  "กระนั้น",
  "กระนี้",
  "กระน่อง",
  "กระบก",
  "กระบม",
  "กระบวน",
  "กระบวนการ",
  "กระบวย",
  "กระบวร",
  "กระบอก",
  "กระบอง",
  "กระบองเพชร",
  "กระบะ",
  "กระบัด",
  "กระบับ",
  "กระบั้วกระเบี้ย",
  "กระบาก",
  "กระบาย",
  "กระบาล",
  "กระบิ",
  "กระบิด",
  "กระบิล",
  "กระบิ้ง",
  "กระบี่",
  "กระบี่",
  "กระบือ",
  "กระบุง",
  "กระบุ่มกระบ่าม",
  "กระบูน",
  "กระบูร",
  "กระบู้กระบี้",
  "กระบ่า",
  "กระบ้า",
  "กระปมกระปำ",
  "กระปมกระเปา",
  "กระปรอก",
  "กระปรอกว่าว",
  "กระปรี้กระเปร่า",
  "กระปอก",
  "กระปอดกระแปด",
  "กระปั้วกระเปี้ย",
  "กระปุก",
  "กระปุ่ม",
  "กระปุ่มกระปิ่ม",
  "กระปุ่มกระป่ำ",
  "กระปุ๊กลุก",
  "กระป่อง",
  "กระป่ำ",
  "กระป้อกระแป้",
  "กระป๋อง",
  "กระป๋อหลอ",
  "กระผม",
  "กระผลีกระผลาม",
  "กระผาน",
  "กระผีก",
  "กระผีกริ้น",
  "กระพรวน",
  "กระพริ้ม",
  "กระพอก",
  "กระพอง",
  "กระพัก",
  "กระพัง",
  "กระพังเหิร",
  "กระพังโหม",
  "กระพัด",
  "กระพัตร",
  "กระพัน",
  "กระพั่น",
  "กระพา",
  "กระพาก",
  "กระพี้",
  "กระพือ",
  "กระพุ่ม",
  "กระพุ้ง",
  "กระพ้อม",
  "กระฟัดกระเฟียด",
  "กระฟูมกระฟาย",
  "กระมล",
  "กระมอบ",
  "กระมอมกระแมม",
  "กระมัง",
  "กระมัน",
  "กระมิดกระเมี้ยน",
  "กระมุท",
  "กระยา",
  "กระยาง",
  "กระยาจก",
  "กระยาทิพย์",
  "กระยาสารท",
  "กระยาหงัน",
  "กระยาหาร",
  "กระยิก",
  "กระยิ้มกระย่อง",
  "กระยึกกระยือ",
  "กระยืดกระยาด",
  "กระย่อง",
  "กระย่องกระแย่ง",
  "กระย่อน",
  "กระย่อม",
  "กระรอก",
  "กระลด",
  "กระลบ",
  "กระลอก",
  "กระลอม",
  "กระละหล่ำ",
  "กระลัด",
  "กระลับ",
  "กระลัมพร",
  "กระลา",
  "กระลำ",
  "กระลำพัก",
  "กระลำพุก",
  "กระลิง",
  "กระลี",
  "กระลุมพาง",
  "กระลุมพุก",
  "กระลุมพู",
  "กระลูน",
  "กระลู่น์",
  "กระวน",
  "กระวัด",
  "กระวาด",
  "กระวาน",
  "กระวาย",
  "กระวิน",
  "กระวี",
  "กระวีกระวาด",
  "กระวูดกระวาด",
  "กระษัย",
  "กระษาปณ์",
  "กระสง",
  "กระสบ",
  "กระสม",
  "กระสรวล",
  "กระสร้อย",
  "กระสวน",
  "กระสวย",
  "กระสอบ",
  "กระสอบทราย",
  "กระสะ",
  "กระสัง",
  "กระสัน",
  "กระสับกระส่าย",
  "กระสา",
  "กระสานติ์",
  "กระสาบ",
  "กระสาย",
  "กระสือ",
  "กระสุงกระสิง",
  "กระสุน",
  "กระสุนปืน",
  "กระสูทธิ์",
  "กระสูบ",
  "กระหนก",
  "กระหนาก",
  "กระหนาบ",
  "กระหน่ำ",
  "กระหมวด",
  "กระหมอบ",
  "กระหมั่ง",
  "กระหมิบ",
  "กระหมุดกระหมิด",
  "กระหมุบ",
  "กระหม่อม",
  "กระหยัง",
  "กระหยับ",
  "กระหยิ่ม",
  "กระหย่ง",
  "กระหย่อม",
  "กระหรอด",
  "กระหริ่ง",
  "กระหวน",
  "กระหวัด",
  "กระหอง",
  "กระหัง",
  "กระหัด",
  "กระหาง",
  "กระหาย",
  "กระหึม",
  "กระหึ่ม",
  "กระหืดกระหอบ",
  "กระอวล",
  "กระออดกระแอด",
  "กระออบ",
  "กระออม",
  "กระอัก",
  "กระอักกระอ่วน",
  "กระอั้วแทงควาย",
  "กระอาน",
  "กระอิด",
  "กระอิดกระเอื้อน",
  "กระอึก",
  "กระอืด",
  "กระอุ",
  "กระอุก",
  "กระอ้อกระแอ้",
  "กระอ้อมกระแอ้ม",
  "กระอ้า",
  "กระเกรอก",
  "กระเกริก",
  "กระเกริ่น",
  "กระเง้ากระงอด",
  "กระเจอะกระเจิง",
  "กระเจา",
  "กระเจาะ",
  "กระเจิง",
  "กระเจิดกระเจิง",
  "กระเจียว",
  "กระเจี้ยง",
  "กระเจี๊ยบ",
  "กระเจี๊ยว",
  "กระเจ่า",
  "กระเจ้า",
  "กระเฉก",
  "กระเฉด",
  "กระเชอ",
  "กระเชา",
  "กระเชียง",
  "กระเช้า",
  "กระเซอ",
  "กระเซอะกระเซอ",
  "กระเซอะกระเซิง",
  "กระเซิง",
  "กระเซ็น",
  "กระเซ้า",
  "กระเดก",
  "กระเดาะ",
  "กระเดิด",
  "กระเดียด",
  "กระเดี้ย",
  "กระเดือก",
  "กระเดื่อง",
  "กระเด็น",
  "กระเด้ง",
  "กระเด้า",
  "กระเตง",
  "กระเตอะ",
  "กระเตาะ",
  "กระเตาะกระแตะ",
  "กระเตื้อง",
  "กระเต็น",
  "กระเถิบ",
  "กระเทียบ",
  "กระเทียม",
  "กระเทือน",
  "กระเทื้อม",
  "กระเท่",
  "กระเท่เร่",
  "กระเบง",
  "กระเบญ",
  "กระเบน",
  "กระเบา",
  "กระเบิก",
  "กระเบียด",
  "กระเบียน",
  "กระเบื้อง",
  "กระเบ็ดกระบวน",
  "กระเปาะ",
  "กระเป๋า",
  "กระเพาะ",
  "กระเพิง",
  "กระเพื่อม",
  "กระเมาะ",
  "กระเย้อกระแหย่ง",
  "กระเรียน",
  "กระเลียด",
  "กระเลือก",
  "กระเล็น",
  "กระเวน",
  "กระเวยกระวาย",
  "กระเสด",
  "กระเสาะกระแสะ",
  "กระเสียน",
  "กระเสียร",
  "กระเสือกกระสน",
  "กระเส็นกระสาย",
  "กระเส่า",
  "กระเหนียด",
  "กระเหม็ดกระเหมียด",
  "กระเหม็ดกระแหม่",
  "กระเหม่น",
  "กระเหม่า",
  "กระเหว่า",
  "กระเหิม",
  "กระเหี้ยนกระหือรือ",
  "กระเห็น",
  "กระเห่อ",
  "กระเอา",
  "กระเอิก",
  "กระเอิบ",
  "กระแจะ",
  "กระแฉก",
  "กระแชง",
  "กระแชะ",
  "กระแซ",
  "กระแซะ",
  "กระแดะ",
  "กระแด็ก",
  "กระแด่ว",
  "กระแด้ง",
  "กระแด้แร่",
  "กระแต",
  "กระแตแต้แว้ด",
  "กระแทก",
  "กระแทะ",
  "กระแท่น",
  "กระแนะกระแหน",
  "กระแบก",
  "กระแบะ",
  "กระแพ้ง",
  "กระแวน",
  "กระแส",
  "กระแสง",
  "กระแสจิต",
  "กระแสน้ำ",
  "กระแสลม",
  "กระแสะ",
  "กระแห",
  "กระแหทอง",
  "กระแหนบ",
  "กระแหนะ",
  "กระแหมบ",
  "กระแหม่ว",
  "กระแหย่ง",
  "กระแหร่ม",
  "กระแหล่ง",
  "กระแอก",
  "กระแอบ",
  "กระแอม",
  "กระโงก",
  "กระโจน",
  "กระโจม",
  "กระโฉกกระเฉก",
  "กระโฉม",
  "กระโชก",
  "กระโชกโฮกฮาก",
  "กระโซกระเซ",
  "กระโดก",
  "กระโดง",
  "กระโดด",
  "กระโดน",
  "กระโตกกระตาก",
  "กระโตน",
  "กระโถน",
  "กระโน้น",
  "กระโบม",
  "กระโปก",
  "กระโปรง",
  "กระโรกน้ำข้าว",
  "กระโรกใหญ่",
  "กระโลง",
  "กระโวยกระวาย",
  "กระโสง",
  "กระโหนด",
  "กระโหม",
  "กระโหย",
  "กระโหย่ง",
  "กระโห้",
  "กระได",
  "กระไทชาย",
  "กระไน",
  "กระไร",
  "กระไส",
  "กระไอ",
  "กรัก",
  "กรักขี",
  "กรัง",
  "กรัชกาย",
  "กรัณฑ์",
  "กรัณย์",
  "กรัน",
  "กรับ",
  "กรับคู่",
  "กรับพวง",
  "กรับเสภา",
  "กรัม",
  "กราก",
  "กราง",
  "กราด",
  "กราดวง",
  "กราดเกรี้ยว",
  "กราน",
  "กราบ",
  "กราฟ",
  "กราฟิก",
  "กราฟิกส์",
  "กราฟิค",
  "กราม",
  "กราย",
  "กราว",
  "กราวนด์",
  "กราวรูด",
  "กราไฟต์",
  "กรำ",
  "กริก",
  "กริกกริว",
  "กริกกรี",
  "กริงกริว",
  "กริช",
  "กริณี",
  "กริด",
  "กริน",
  "กรินทร์",
  "กรินี",
  "กริบ",
  "กริม",
  "กริยา",
  "กริยานุเคราะห์",
  "กริว",
  "กริ่ง",
  "กริ่งเกรง",
  "กริ่ม",
  "กริ้งกริ้ว",
  "กริ้ว",
  "กริ๊ก",
  "กริ๊ง",
  "กรี",
  "กรีก",
  "กรีซ",
  "กรีฑา",
  "กรีฑาสถาน",
  "กรีด",
  "กรีดกราย",
  "กรีธา",
  "กรีน",
  "กรีนพีซ",
  "กรีนิช",
  "กรีษ",
  "กรีส",
  "กรี๊ด",
  "กรี๊ดกร๊าด",
  "กรึ๊บ",
  "กรือเซะ",
  "กรุ",
  "กรุง",
  "กรุงเทพ",
  "กรุณ",
  "กรุณา",
  "กรุณาธิคุณ",
  "กรุบ",
  "กรุย",
  "กรุยเกรียว",
  "กรุ่น",
  "กรุ่ม",
  "กรุ้งกริ่ง",
  "กรุ้มกริ่ม",
  "กรุ๊ป",
  "กรู",
  "กรูด",
  "กรูม",
  "กร็อกกร๋อย",
  "กร่อน",
  "กร่อย",
  "กร่าง",
  "กร่าย",
  "กร่ำ",
  "กร้วม",
  "กร้อ",
  "กร้าน",
  "กร้าว",
  "กฤช",
  "กฤดาภินิหาร",
  "กฤตติกา",
  "กฤษฎา",
  "กฤษฎาธาร",
  "กฤษฎาภินิหาร",
  "กฤษฎีกา",
  "กฤษณา",
  "กฤษณ์",
  "กล",
  "กลด",
  "กลบ",
  "กลบท",
  "กลบเกลื่อน",
  "กลม",
  "กลมกลืน",
  "กลมกล่อม",
  "กลมเกลียว",
  "กลยุทธ์",
  "กลละ",
  "กลวง",
  "กลวิธี",
  "กลศ",
  "กลศาสตร์",
  "กลอก",
  "กลอง",
  "กลอน",
  "กลอย",
  "กลอักษร",
  "กลัก",
  "กลัด",
  "กลัดกลุ้ม",
  "กลัดมัน",
  "กลันตัน",
  "กลันทก์",
  "กลันทะ",
  "กลับ",
  "กลับกลอก",
  "กลัมพร",
  "กลัมพัก",
  "กลัว",
  "กลั่น",
  "กลั่นกรอง",
  "กลั่นแกล้ง",
  "กลั้น",
  "กลั้ว",
  "กลา",
  "กลาก",
  "กลากลาด",
  "กลาง",
  "กลางคน",
  "กลางคัน",
  "กลางคืน",
  "กลางค่ำ",
  "กลางวัน",
  "กลางแจ้ง",
  "กลางแปลง",
  "กลาด",
  "กลาดเกลื่อน",
  "กลาบาต",
  "กลาป",
  "กลาย",
  "กลายกลอก",
  "กลาส",
  "กลาโหม",
  "กลิงค์",
  "กลิ่น",
  "กลิ่นอาย",
  "กลิ้ง",
  "กลิ้งกลอก",
  "กลิ้งเกลือก",
  "กลี",
  "กลีบ",
  "กลียุค",
  "กลีเซอรีน",
  "กลึง",
  "กลึงค์",
  "กลืน",
  "กลุ่ม",
  "กลุ้ม",
  "กลูโคส",
  "กลเม็ด",
  "กลไก",
  "กล่น",
  "กล่อง",
  "กล่องเสียง",
  "กล่อน",
  "กล่อม",
  "กล่อมเกลา",
  "กล่อมเกลี้ยง",
  "กล่าว",
  "กล่าวขวัญ",
  "กล่าวหา",
  "กล่าวโทษ",
  "กล่ำ",
  "กล้วย",
  "กล้วยแขก",
  "กล้วยไม้",
  "กล้อ",
  "กล้อง",
  "กล้องแกล้ง",
  "กล้อน",
  "กล้อมแกล้ม",
  "กล้า",
  "กล้าม",
  "กล้าย",
  "กล้ำ",
  "กล้ำกราย",
  "กล้ำกลืน",
  "กวด",
  "กวดขัน",
  "กวน",
  "กวนอิม",
  "กวนอู",
  "กวม",
  "กวย",
  "กวยจั๊บ",
  "กวยจี๊",
  "กวัก",
  "กวัด",
  "กวัดแกว่ง",
  "กวัดไกว",
  "กวา",
  "กวาง",
  "กวางตุ้ง",
  "กวาด",
  "กวาวเครือ",
  "กวี",
  "กวีนิพนธ์",
  "กว่า",
  "กว่าง",
  "กว่างโซ้ง",
  "กว้าง",
  "กว้าน",
  "กว้าว",
  "กว๊าน",
  "กศ.ด.",
  "กศ.บ.",
  "กศ.บป.",
  "กศ.ม.",
  "กษ.ด.",
  "กษ.บ.",
  "กษ.ม.",
  "กษณะ",
  "กษมา",
  "กษัตร",
  "กษัตรา",
  "กษัตริยา",
  "กษัตริยาธิราช",
  "กษัตริย์",
  "กษัตรี",
  "กษัตรีย์",
  "กษัย",
  "กษาปณ์",
  "กษิดิ",
  "กษีร",
  "กษีรธารา",
  "กษีระ",
  "กส.ด.",
  "กส.ทบ.",
  "กส.บ.",
  "กส.ม.",
  "กสานติ์",
  "กสิกร",
  "กสิกรรม",
  "กสิณ",
  "กหังปายา",
  "กหาปณะ",
  "กอ",
  "กอ.ปค.",
  "กอ.รพน.",
  "กอ.รมน.",
  "กอ.รสต.",
  "กอก",
  "กอง",
  "กองกลาง",
  "กองกอย",
  "กองทัพ",
  "กองทุน",
  "กองพล",
  "กองพัน",
  "กองฟอน",
  "กองร้อย",
  "กองหนุน",
  "กองเกิน",
  "กองโจร",
  "กอด",
  "กอบ",
  "กอบนาง",
  "กอบโกย",
  "กอปร",
  "กอมก้อ",
  "กอย",
  "กอริลลา",
  "กอล์ฟ",
  "กอเอี๊ยะ",
  "กอแก",
  "กอและ",
  "กะ",
  "กะกัง",
  "กะง้องกะแง้ง",
  "กะจัง",
  "กะชะ",
  "กะชัง",
  "กะชามาศ",
  "กะชิง",
  "กะชึ่กกะชั่ก",
  "กะซวก",
  "กะซี่",
  "กะซ้าหอย",
  "กะดก",
  "กะดง",
  "กะดวน",
  "กะดอก",
  "กะดะ",
  "กะดังบาย",
  "กะดัด",
  "กะดำกะด่าง",
  "กะดี",
  "กะดี่",
  "กะดุ้ง",
  "กะด้าง",
  "กะตรุด",
  "กะตอก",
  "กะตัก",
  "กะตัง",
  "กะตังกะติ้ว",
  "กะตั้ก",
  "กะติงกะแตง",
  "กะติ๊กริก",
  "กะตีบ",
  "กะตึงกะแตง",
  "กะตุด",
  "กะตุ๊ก",
  "กะตูก",
  "กะต่อย",
  "กะต้ำ",
  "กะต๊าก",
  "กะถัว",
  "กะทกรก",
  "กะทอ",
  "กะทะ",
  "กะทัง",
  "กะทังหัน",
  "กะทัดรัด",
  "กะทันหัน",
  "กะทับ",
  "กะทิ",
  "กะทือ",
  "กะทุน",
  "กะนวล",
  "กะนัด",
  "กะบัง",
  "กะบังลม",
  "กะบับ",
  "กะบั้ง",
  "กะบิล",
  "กะบิ้ง",
  "กะบึงกะบอน",
  "กะบุด",
  "กะบ่อนกะแบ่น",
  "กะปริดกะปรอย",
  "กะปริบ",
  "กะปริบกะปรอย",
  "กะปลกกะเปลี้ย",
  "กะปวกกะเปียก",
  "กะปอม",
  "กะปอมขาง",
  "กะปะ",
  "กะปิ",
  "กะปู",
  "กะปูด",
  "กะปูดหลูด",
  "กะป้ำกะเป๋อ",
  "กะผลุบกะโผล่",
  "กะพง",
  "กะพรวดกะพราด",
  "กะพริบ",
  "กะพรุน",
  "กะพรูดกะพราด",
  "กะพร่องกะแพร่ง",
  "กะพล้อ",
  "กะพ้อ",
  "กะระตะ",
  "กะระหนะ",
  "กะรัง",
  "กะรัต",
  "กะราง",
  "กะริง",
  "กะรุงกะรัง",
  "กะรุน",
  "กะรุ่งกะริ่ง",
  "กะร่องกะแร่ง",
  "กะลวย",
  "กะลอ",
  "กะลอจี๊",
  "กะละปังหา",
  "กะละมัง",
  "กะละออม",
  "กะละแม",
  "กะลังตังไก่",
  "กะลัน",
  "กะลันทา",
  "กะลา",
  "กะลาง",
  "กะลาสี",
  "กะลำพอ",
  "กะลิง",
  "กะลิงปลิง",
  "กะลิอ่อง",
  "กะลิ้มกะเหลี่ย",
  "กะลุมพี",
  "กะล่อกะแล่",
  "กะล่อน",
  "กะล่อมกะแล่ม",
  "กะล่อยกะหลิบ",
  "กะวอกกะแวก",
  "กะวะ",
  "กะสัง",
  "กะส้มชื่น",
  "กะส้าหอย",
  "กะหนอกะแหน",
  "กะหนะ",
  "กะหนุงกะหนิง",
  "กะหน็องกะแหน็ง",
  "กะหรอด",
  "กะหราน",
  "กะหรี่",
  "กะหรี่ปั๊บ",
  "กะหร็อมกะแหร็ม",
  "กะหร่อง",
  "กะหลาป๋า",
  "กะหลีกะหลอ",
  "กะหลี่",
  "กะหลุกกะหลิก",
  "กะหล่ำ",
  "กะหำ",
  "กะหำแพะ",
  "กะหือ",
  "กะหูด",
  "กะอวม",
  "กะออม",
  "กะอาน",
  "กะอาม",
  "กะอูบ",
  "กะเกณฑ์",
  "กะเดก",
  "กะเดี๋ยว",
  "กะเด้",
  "กะเตง",
  "กะเทย",
  "กะเทาะ",
  "กะเบียน",
  "กะเบือ",
  "กะเบ้อ",
  "กะเปะ",
  "กะเปิ๊บกะป๊าบ",
  "กะเปียด",
  "กะเผลก",
  "กะเผ่น",
  "กะเพรา",
  "กะเพียด",
  "กะเม็ง",
  "กะเร",
  "กะเรกะร่อน",
  "กะเรี่ยกะราด",
  "กะเร่กะร่อน",
  "กะเร่อ",
  "กะเลิด",
  "กะเลียว",
  "กะเล็ง",
  "กะเล่อกะล่า",
  "กะเหรี่ยง",
  "กะเหลาะเปาะ",
  "กะแจะ",
  "กะแช่",
  "กะแทน",
  "กะแท่ง",
  "กะแท้",
  "กะแปะ",
  "กะแป้น",
  "กะแล",
  "กะแหยก",
  "กะแหะ",
  "กะโซ่",
  "กะโซ้",
  "กะโตงกะเตง",
  "กะโต้งโห่ง",
  "กะโต๊ก",
  "กะโปรง",
  "กะโปโล",
  "กะโผลกกะเผลก",
  "กะโรกะเร",
  "กะโลง",
  "กะโล่",
  "กะโหลก",
  "กะโหล้ง",
  "กะไหล่",
  "กัก",
  "กักกรา",
  "กักกัน",
  "กักการุ",
  "กักขฬะ",
  "กักขัง",
  "กักตัว",
  "กักตุน",
  "กัง",
  "กังก้า",
  "กังขา",
  "กังฉิน",
  "กังฟู",
  "กังวล",
  "กังวาน",
  "กังวาล",
  "กังสดาล",
  "กังหัน",
  "กังเกียง",
  "กังไส",
  "กัจฉปะ",
  "กัจฉะ",
  "กัจฉา",
  "กัญ",
  "กัญจุก",
  "กัญจุการา",
  "กัญชา",
  "กัญญา",
  "กัฐ",
  "กัณฏกะ",
  "กัณฐกะ",
  "กัณฐชะ",
  "กัณฐัศ",
  "กัณฐัศว์",
  "กัณฐา",
  "กัณฐี",
  "กัณฑ์",
  "กัณฑ์เทศน์",
  "กัณณาฑ",
  "กัณณ์",
  "กัณหา",
  "กัด",
  "กัดดาฟี",
  "กัดฟัน",
  "กัตติกมาส",
  "กัตติกา",
  "กัตติเกยา",
  "กัตรา",
  "กัทลี",
  "กัน",
  "กันชน",
  "กันชิง",
  "กันดาร",
  "กันดาล",
  "กันตัง",
  "กันต์",
  "กันทร",
  "กันทรลักษ์",
  "กันทรากร",
  "กันท่า",
  "กันภิรมย์",
  "กันยา",
  "กันยา",
  "กันยายน",
  "กันย์",
  "กันลง",
  "กันลอง",
  "กันสาด",
  "กันเกรา",
  "กันเชอ",
  "กันเมียง",
  "กันเอง",
  "กันแสง",
  "กันไกร",
  "กันได",
  "กันไตร",
  "กับ",
  "กับข้าว",
  "กับดัก",
  "กับระเบิด",
  "กับแกล้ม",
  "กับแก้",
  "กัป",
  "กัปตัน",
  "กัปปาสิก",
  "กัปปิยภัณฑ์",
  "กัปปิยะ",
  "กัมปนาท",
  "กัมประโด",
  "กัมปี",
  "กัมพล",
  "กัมพุช",
  "กัมพู",
  "กัมพูชา",
  "กัมพูชา",
  "กัมมัชวาต",
  "กัมมัฏฐาน",
  "กัมมันตภาพรังสี",
  "กัมมันตรังสี",
  "กัมมันตะ",
  "กัมมาร",
  "กัมลาศ",
  "กัมโพช",
  "กัยวิกัย",
  "กัลกัตตา",
  "กัลชาญ",
  "กัลบก",
  "กัลปนา",
  "กัลปพฤกษ์",
  "กัลปังหา",
  "กัลปาวสาน",
  "กัลปิต",
  "กัลป์",
  "กัลยา",
  "กัลยาณมิตร",
  "กัลยาณวัตร",
  "กัลยาณี",
  "กัลออม",
  "กัลเม็ด",
  "กัวลาลัมเปอร์",
  "กัวเตมาลา",
  "กัศมีรี",
  "กัศมีร์",
  "กัศยป",
  "กัษณ",
  "กัสสปะ",
  "กั่น",
  "กั้ง",
  "กั้น",
  "กั้นหยั่น",
  "กั๊ก",
  "กา",
  "กาก",
  "กากบาท",
  "กากหมู",
  "กากะทิง",
  "กากะเยีย",
  "กากี",
  "กากเพชร",
  "กาง",
  "กางเกง",
  "กางเขน",
  "กาชาด",
  "กาซะลอง",
  "กาญจนบุรี",
  "กาญจนา",
  "กาญจนาภิเษก",
  "กาญจนาภิเษก",
  "กาญจน์",
  "กาฐ",
  "กาฐมาณฑุ",
  "กาด",
  "กาตาร์",
  "กาน",
  "กานดา",
  "กานต์",
  "กานน",
  "กานพลู",
  "กานา",
  "กาน้า",
  "กาบ",
  "กาบอง",
  "กาบู",
  "กาฝาก",
  "กาพย์",
  "กาม",
  "กามกิจ",
  "กามคุณ",
  "กามารมณ์",
  "กามิกาเซ่",
  "กามินี",
  "กามเทพ",
  "กามโรค",
  "กาย",
  "กายกรรม",
  "กายบริหาร",
  "กายภาพ",
  "กายวิภาค",
  "กายสิทธิ์",
  "กายอานา",
  "การ",
  "การคลัง",
  "การณ์",
  "การบูร",
  "การบ้าน",
  "การย์",
  "การละเล่น",
  "การวิก",
  "การะบุหนิง",
  "การะเกด",
  "การัณย์",
  "การันตี",
  "การันต์",
  "การางหัวขวาน",
  "การิตการก",
  "การิตวาจก",
  "การุญ",
  "การุณย์",
  "การเงิน",
  "การเปรียญ",
  "การเมือง",
  "การเรือน",
  "การเวก",
  "การ์ด",
  "การ์ตูน",
  "กาล",
  "กาลกรรณี",
  "กาลกิณี",
  "กาลกิริยา",
  "กาลจักร",
  "กาลัญญุตา",
  "กาลัญญู",
  "กาลัด",
  "กาลานุกาล",
  "กาลิก",
  "กาลิเลโอ",
  "กาลี",
  "กาลเทศะ",
  "กาว",
  "กาววาว",
  "กาวาง",
  "กาศิก",
  "กาษฐะ",
  "กาษา",
  "กาสร",
  "กาสะ",
  "กาสา",
  "กาสาร",
  "กาสาวพัสตร์",
  "กาสาวะ",
  "กาสิโน",
  "กาหล",
  "กาหลง",
  "กาหลา",
  "กาหลิบ",
  "กาฬ",
  "กาฬพฤกษ์",
  "กาฬสินธุ์",
  "กาฬาวก",
  "กาฬโรค",
  "กาฮัง",
  "กาเฟอีน",
  "กาเมสุมิจฉาจาร",
  "กาเยน",
  "กาเรการ่อน",
  "กาเหว่า",
  "กาแฟ",
  "กาแล",
  "กาแล็กซี",
  "กาแล็กโทส",
  "กาแวน",
  "กาไหล่",
  "กำ",
  "กำกวม",
  "กำกัด",
  "กำกับ",
  "กำกูน",
  "กำคูน",
  "กำจร",
  "กำจัด",
  "กำจาย",
  "กำชับ",
  "กำชำ",
  "กำซาบ",
  "กำซำ",
  "กำดัด",
  "กำดาล",
  "กำด้น",
  "กำทอน",
  "กำธร",
  "กำนล",
  "กำนัน",
  "กำนัล",
  "กำบัง",
  "กำปอ",
  "กำปั่น",
  "กำปั้น",
  "กำผลา",
  "กำพง",
  "กำพด",
  "กำพต",
  "กำพราก",
  "กำพร้า",
  "กำพวด",
  "กำพอง",
  "กำพืด",
  "กำพุด",
  "กำพู",
  "กำภู",
  "กำมลาศน์",
  "กำมะถัน",
  "กำมะลอ",
  "กำมะหยี่",
  "กำมะหริด",
  "กำมังละการ",
  "กำมังวิลิต",
  "กำมัชพล",
  "กำมือ",
  "กำมเลศ",
  "กำยาน",
  "กำยำ",
  "กำรอ",
  "กำราบ",
  "กำราล",
  "กำลัง",
  "กำลังม้า",
  "กำลังใจ",
  "กำลุง",
  "กำสรด",
  "กำสรวล",
  "กำหนด",
  "กำหนัด",
  "กำเกียง",
  "กำเดา",
  "กำเนิด",
  "กำเบ้อ",
  "กำเพลิง",
  "กำเริบ",
  "กำเลา",
  "กำเหน็จ",
  "กำแพง",
  "กำแพงขาว",
  "กำแพงเพชร",
  "กำแหง",
  "กำไร",
  "กำไล",
  "กิก",
  "กิกะ",
  "กิกะไบต์",
  "กิจ",
  "กิจกรรม",
  "กิจการ",
  "กิจจะ",
  "กิจจะลักษณะ",
  "กิจจา",
  "กิจวัตร",
  "กิดาการ",
  "กิดาหยัน",
  "กิตติ",
  "กิตติคุณ",
  "กิตติมศักดิ์",
  "กิตติศัพท์",
  "กิน",
  "กินขาด",
  "กินดอง",
  "กินนร",
  "กินปลี",
  "กินริน",
  "กินรี",
  "กินี",
  "กินเนส",
  "กินเปล่า",
  "กินเปี้ยว",
  "กินเลี้ยง",
  "กินเส้น",
  "กินแบ่ง",
  "กินแหนง",
  "กินโต๊ะ",
  "กินใจ",
  "กิฟท์",
  "กิมจิ",
  "กิมตึ๋ง",
  "กิมิชาติ",
  "กิมิวิทยา",
  "กิระ",
  "กิริณี",
  "กิรินท",
  "กิริยา",
  "กิริเนศวร",
  "กิเลน",
  "กิเลส",
  "กิโมโน",
  "กิโยตีน",
  "กิโล",
  "กิโลกรัม",
  "กิโลมกะ",
  "กิโลลิตร",
  "กิโลเมตร",
  "กิโลเฮิรตซ์",
  "กิ่ง",
  "กิ่ว",
  "กิ้งกือ",
  "กิ้งก่า",
  "กิ้งโครง",
  "กิ๊ก",
  "กิ๊บ",
  "กิ๋ว",
  "กี",
  "กีฏวิทยา",
  "กีด",
  "กีดกัน",
  "กีดกั้น",
  "กีดขวาง",
  "กีตาร์",
  "กีบ",
  "กีรติ",
  "กีวี",
  "กีฬา",
  "กี่",
  "กี่งอำเภอ",
  "กี้",
  "กี๊",
  "กี๋",
  "กึก",
  "กึกกัก",
  "กึกกือ",
  "กึกก้อง",
  "กึง",
  "กึ่ง",
  "กึ่งกลาง",
  "กึ๊ก",
  "กึ๋น",
  "กึ๋ย",
  "กุ",
  "กุก",
  "กุกกัก",
  "กุกกุฏ",
  "กุกกุร",
  "กุกรรม",
  "กุงอน",
  "กุงาน",
  "กุจี",
  "กุญชร",
  "กุญแจ",
  "กุญแจผี",
  "กุญแจมือ",
  "กุญแจเลื่อน",
  "กุญแจเสียง",
  "กุฎ",
  "กุฎฐัง",
  "กุฎา",
  "กุฎี",
  "กุฎุมพี",
  "กุฏฐัง",
  "กุฏิ",
  "กุฏุมพี",
  "กุณฑล",
  "กุณฑี",
  "กุณฑ์",
  "กุณาล",
  "กุณี",
  "กุณโฑ",
  "กุด",
  "กุดัง",
  "กุดั่น",
  "กุดา",
  "กุทัณฑ์",
  "กุน",
  "กุนซือ",
  "กุนที",
  "กุนเชียง",
  "กุบ",
  "กุบกับ",
  "กุม",
  "กุมฝอย",
  "กุมภกรรณ",
  "กุมภนิยา",
  "กุมภัณฑ์",
  "กุมภา",
  "กุมภา",
  "กุมภาพันธ์",
  "กุมภิล",
  "กุมภีล์",
  "กุมภ์",
  "กุมาร",
  "กุมารา",
  "กุมารี",
  "กุมุท",
  "กุมเหง",
  "กุย",
  "กุยช่าย",
  "กุยเฮง",
  "กุรระ",
  "กุรุพินท์",
  "กุล",
  "กุลธิดา",
  "กุลบุตร",
  "กุลสตรี",
  "กุลา",
  "กุลาหล",
  "กุลี",
  "กุลีกุจอ",
  "กุศราช",
  "กุศล",
  "กุศโลบาย",
  "กุสินารา",
  "กุสุม",
  "กุสุมภ์",
  "กุสุมา",
  "กุสุมาลย์",
  "กุสุมิตลดาเวลลิตา",
  "กุหนี",
  "กุหนุง",
  "กุหร่า",
  "กุหลาบ",
  "กุหล่า",
  "กุเรา",
  "กุเลา",
  "กุเวร",
  "กุแล",
  "กุแหละ",
  "กุโงก",
  "กุโนกามอ",
  "กุ่ม",
  "กุ้ง",
  "กุ้งฝอย",
  "กุ้งมังกร",
  "กุ้งยิง",
  "กุ้งเต้น",
  "กุ้งแห้ง",
  "กุ๊ก",
  "กุ๊กกิ๊ก",
  "กุ๊น",
  "กุ๊ย",
  "กุ๋ย",
  "กู",
  "กูฏ",
  "กูฏา",
  "กูณฑ์",
  "กูด",
  "กูบ",
  "กูปรี",
  "กูรมะ",
  "กูรมาวตาร",
  "กูเกิล",
  "กูเกิ้ล",
  "กูเตนเบิร์ก",
  "กู่",
  "กู้",
  "กู้ยืม",
  "กู๊ก",
  "กู๋",
  "กเบนทร์",
  "กเรณุ",
  "กเรนทร",
  "กเรนทร์",
  "กเลวระ",
  "กเฬวราก",
  "ก็",
  "ก่ง",
  "ก่น",
  "ก่อ",
  "ก่อกวน",
  "ก่อการ",
  "ก่อง",
  "ก่อตั้ง",
  "ก่อน",
  "ก่อนหน้า",
  "ก่อมก้อ",
  "ก่อสร้าง",
  "ก่อหวอด",
  "ก่าน",
  "ก่าย",
  "ก่ายกอง",
  "ก่ำ",
  "ก่ำบึ้ง",
  "ก้ง",
  "ก้น",
  "ก้นกบ",
  "ก้นบึ้ง",
  "ก้นปล่อง",
  "ก้ม",
  "ก้อ",
  "ก้อง",
  "ก้อน",
  "ก้อม",
  "ก้อย",
  "ก้อร่อก้อติก",
  "ก้าง",
  "ก้าน",
  "ก้านคอ",
  "ก้านพร้าว",
  "ก้าบ",
  "ก้าม",
  "ก้ามกราม",
  "ก้ามปู",
  "ก้าว",
  "ก้าวก่าย",
  "ก้าวร้าว",
  "ก้าวหน้า",
  "ก้ำกึ่ง",
  "ก้ำเกิน",
  "ก๊ก",
  "ก๊ง",
  "ก๊วน",
  "ก๊อ",
  "ก๊อก",
  "ก๊อซ",
  "ก๊อบปี้",
  "ก๊อปปี้",
  "ก๊าก",
  "ก๊าซ",
  "ก๊าด",
  "ก๊าน",
  "ก๋ง",
  "ก๋วยเตี๋ยว",
  "ก๋อย",
  "ก๋า",
  "ก๋ากั่น",
  "ข.ต.ว.",
  "ขงจื๊อ",
  "ขงเบ้ง",
  "ขจร",
  "ขจรจบ",
  "ขจัด",
  "ขจัดขจาย",
  "ขจาย",
  "ขจาว",
  "ขจิต",
  "ขจี",
  "ขจุย",
  "ขจ่าง",
  "ขณะ",
  "ขด",
  "ขน",
  "ขนง",
  "ขนด",
  "ขนบ",
  "ขนม",
  "ขนมจีน",
  "ขนสัตว์",
  "ขนส่ง",
  "ขนหนู",
  "ขนอง",
  "ขนอน",
  "ขนอบ",
  "ขนัด",
  "ขนัน",
  "ขนาก",
  "ขนาง",
  "ขนาด",
  "ขนาน",
  "ขนาบ",
  "ขนาย",
  "ขนำ",
  "ขนิษฐ",
  "ขนิษฐา",
  "ขนุน",
  "ขนุนนก",
  "ขนเพชร",
  "ขบ",
  "ขบขัน",
  "ขบถ",
  "ขบวน",
  "ขบวนการ",
  "ขบวร",
  "ขม",
  "ขมงโกรย",
  "ขมวด",
  "ขมวน",
  "ขมหิน",
  "ขมอง",
  "ขมัง",
  "ขมับ",
  "ขมา",
  "ขมิบ",
  "ขมิ้น",
  "ขมิ้นชัน",
  "ขมีขมัน",
  "ขมึง",
  "ขมึงทึง",
  "ขมุ",
  "ขมุกขมัว",
  "ขมุบ",
  "ขมุบขมิบ",
  "ขม่อม",
  "ขม้ำ",
  "ขยด",
  "ขยม",
  "ขยอก",
  "ขยอง",
  "ขยะ",
  "ขยะแขยง",
  "ขยัก",
  "ขยักขย่อน",
  "ขยักขย้อน",
  "ขยัน",
  "ขยับ",
  "ขยับขยาย",
  "ขยับเขยื้อน",
  "ขยั้น",
  "ขยาด",
  "ขยาย",
  "ขยำ",
  "ขยิก",
  "ขยิบ",
  "ขยิ่ม",
  "ขยี้",
  "ขยุกขยิก",
  "ขยุกขยุย",
  "ขยุบ",
  "ขยุบขยิบ",
  "ขยุม",
  "ขยุย",
  "ขยุ้ม",
  "ขย่ม",
  "ขย่อน",
  "ขย้อน",
  "ขย้ำ",
  "ขรม",
  "ขรรค์",
  "ขรัว",
  "ขริบ",
  "ขรี",
  "ขรึม",
  "ขรุขระ",
  "ขลบ",
  "ขลัง",
  "ขลับ",
  "ขลาด",
  "ขลาย",
  "ขลิบ",
  "ขลุก",
  "ขลุกขลัก",
  "ขลุกขลิก",
  "ขลุบ",
  "ขลุม",
  "ขลุ่ย",
  "ขลู",
  "ขลู่",
  "ขล้อ",
  "ขว.ทบ.",
  "ขว.ทร.",
  "ขว.ทหาร",
  "ขวง",
  "ขวด",
  "ขวดโหล",
  "ขวนขวาย",
  "ขวบ",
  "ขวย",
  "ขวยเขิน",
  "ขวักไขว่",
  "ขวัญ",
  "ขวัญตา",
  "ขวัญอ่อน",
  "ขวัญใจ",
  "ขวับ",
  "ขวับเขวียว",
  "ขวั้น",
  "ขวา",
  "ขวาก",
  "ขวากหนาม",
  "ขวาง",
  "ขวางโลก",
  "ขวาด",
  "ขวาน",
  "ขวายขวน",
  "ขวาว",
  "ขวิด",
  "ขว้าง",
  "ขว้าว",
  "ขส.ทบ.",
  "ขส.ทร.",
  "ขส.ทอ.",
  "ขอ",
  "ขอขมา",
  "ของ",
  "ของกลาง",
  "ของขวัญ",
  "ของชำ",
  "ของลับ",
  "ของว่าง",
  "ของเล่น",
  "ของเหลว",
  "ของแข็ง",
  "ของไหล",
  "ของไหว้",
  "ของ้าว",
  "ขอด",
  "ขอทาน",
  "ขอน",
  "ขอนแก่น",
  "ขอบ",
  "ขอบข่าย",
  "ขอบคุณ",
  "ขอบพระคุณ",
  "ขอบเขต",
  "ขอบใจ",
  "ขอม",
  "ขอร้อง",
  "ขอสับ",
  "ขออภัย",
  "ขอโทษ",
  "ขะมอมขะแมม",
  "ขะมักเขม้น",
  "ขะมุกขะมอม",
  "ขะยิก",
  "ขะยุก",
  "ขะเน็ด",
  "ขะเย้อแขย่ง",
  "ขะแจะ",
  "ขัค",
  "ขัง",
  "ขังขอก",
  "ขัช",
  "ขัณฑสกร",
  "ขัณฑสีมา",
  "ขัด",
  "ขัดขืน",
  "ขัดข้อง",
  "ขัดจังหวะ",
  "ขัดดอก",
  "ขัดมอน",
  "ขัดยอก",
  "ขัดสมาธิ",
  "ขัดเขิน",
  "ขัดแตะ",
  "ขัดแย้ง",
  "ขัตติยมานะ",
  "ขัน",
  "ขันติ",
  "ขันตี",
  "ขันที",
  "ขันธาวาร",
  "ขันธ์",
  "ขันหมาก",
  "ขันอาสา",
  "ขันโตก",
  "ขับ",
  "ขับขี่",
  "ขับเคี่ยว",
  "ขัว",
  "ขั้น",
  "ขั้นตอน",
  "ขั้ว",
  "ขั้วโลก",
  "ขา",
  "ขาก",
  "ขาก๊วย",
  "ขาง",
  "ขาจร",
  "ขาณุ",
  "ขาด",
  "ขาดตอน",
  "ขาดตัว",
  "ขาดทุน",
  "ขาดเหลือ",
  "ขาดแคลน",
  "ขาดใจ",
  "ขาทนียะ",
  "ขาน",
  "ขาบ",
  "ขาประจำ",
  "ขาม",
  "ขาย",
  "ขายหน้า",
  "ขาล",
  "ขาว",
  "ขำ",
  "ขิก",
  "ขิง",
  "ขิงแกลง",
  "ขิงแครง",
  "ขิด",
  "ขิปสัทโท",
  "ขิม",
  "ขีณาสพ",
  "ขีด",
  "ขีดขั้น",
  "ขีดคร่อม",
  "ขีดคั่น",
  "ขีดฆ่า",
  "ขีปนาวุธ",
  "ขีระ",
  "ขี่",
  "ขี้",
  "ขี้ข้า",
  "ขี้ครอก",
  "ขี้คร้าน",
  "ขี้คุก",
  "ขี้ตังนี",
  "ขี้ตา",
  "ขี้ตืด",
  "ขี้ทูด",
  "ขี้ปะติ๋ว",
  "ขี้ผึ้ง",
  "ขี้มูก",
  "ขี้ยอก",
  "ขี้ยา",
  "ขี้ริ้ว",
  "ขี้หน้า",
  "ขี้หู",
  "ขี้เกียจ",
  "ขี้เข็บ",
  "ขี้เซา",
  "ขี้เถ้า",
  "ขี้เรื้อน",
  "ขี้เล็บ",
  "ขี้เหนียว",
  "ขี้เหร่",
  "ขี้เหล็ก",
  "ขี้แย",
  "ขี้ไคล",
  "ขึง",
  "ขึงขัง",
  "ขึงพืด",
  "ขึ้ง",
  "ขึ้งโกรธ",
  "ขึ้น",
  "ขึ้นฉ่าย",
  "ขึ้นชื่อ",
  "ขึ้นมือ",
  "ขึ้นใจ",
  "ขืน",
  "ขืนใจ",
  "ขื่น",
  "ขื่นขม",
  "ขื่อ",
  "ขุก",
  "ขุด",
  "ขุดคุ้ย",
  "ขุน",
  "ขุนทอง",
  "ขุนนาง",
  "ขุนพล",
  "ขุนศึก",
  "ขุนหลวง",
  "ขุนเพ็ด",
  "ขุม",
  "ขุย",
  "ขุ่น",
  "ขูด",
  "ขูดรีด",
  "ขู่",
  "ขเจา",
  "ขโมย",
  "ข่ม",
  "ข่มขี่",
  "ข่มขืน",
  "ข่มขู่",
  "ข่มเหง",
  "ข่วง",
  "ข่วน",
  "ข่อ",
  "ข่อย",
  "ข่อยหยอง",
  "ข่า",
  "ข่าง",
  "ข่าม",
  "ข่าย",
  "ข่าว",
  "ข่าวกรอง",
  "ข่าวคราว",
  "ข่าวลือ",
  "ข่าวล่า",
  "ข่าวสาร",
  "ข้น",
  "ข้อ",
  "ข้อความ",
  "ข้อง",
  "ข้องแวะ",
  "ข้องใจ",
  "ข้อน",
  "ข้อมูล",
  "ข้อย",
  "ข้อสังเกต",
  "ข้อหา",
  "ข้อเขียน",
  "ข้อเท็จจริง",
  "ข้อแม้",
  "ข้า",
  "ข้าง",
  "ข้าพเจ้า",
  "ข้าม",
  "ข้าราชการ",
  "ข้าว",
  "ข้าวของ",
  "ข้าวจี่",
  "ข้าวซอย",
  "ข้าวตอก",
  "ข้าวตัง",
  "ข้าวต้ม",
  "ข้าวทิพย์",
  "ข้าวบิณฑ์",
  "ข้าวฟ่าง",
  "ข้าวสวย",
  "ข้าวสาร",
  "ข้าวหมาก",
  "ข้าวหลาม",
  "ข้าวอังกุลี",
  "ข้าวเกรียบ",
  "ข้าวเจ้า",
  "ข้าวเปลือก",
  "ข้าวเม่า",
  "ข้าวเหนียว",
  "ข้าวแกง",
  "ข้าวแตน",
  "ข้าวโพด",
  "ข้าศึก",
  "ข้าหลวง",
  "ฃวด",
  "ค.ด.",
  "ค.บ.",
  "ค.พ.ศ.",
  "ค.ม.",
  "ค.ร.น.",
  "ค.ร.ฟ.",
  "ค.ร.ม.",
  "ค.ศ.",
  "ค.อ.ด.",
  "ค.อ.บ.",
  "ค.อ.ม.",
  "คคนะ",
  "คคนัมพร",
  "คคนางค์",
  "คคนานต์",
  "คง",
  "คงกระพัน",
  "คงคา",
  "คงตัว",
  "คงทน",
  "คงที่",
  "คงไคย",
  "คช",
  "คชราช",
  "คชลักษณ์",
  "คชสาร",
  "คชสีห์",
  "คชาชาติ",
  "คชาชีพ",
  "คชาธาร",
  "คชาภรณ์",
  "คฑา",
  "คณนา",
  "คณบดี",
  "คณะ",
  "คณาจารย์",
  "คณาญาติ",
  "คณาธิการ",
  "คณาธิปไตย",
  "คณานับ",
  "คณิกา",
  "คณิต",
  "คณิตศาสตร์",
  "คด",
  "คดี",
  "คดเคี้ยว",
  "คติ",
  "คติธรรม",
  "คติพจน์",
  "คทา",
  "คน",
  "คนกลาง",
  "คนทรง",
  "คนทา",
  "คนทิสอ",
  "คนที",
  "คนธรรพ์",
  "คนธ์",
  "คนโท",
  "คนใช้",
  "คนไข้",
  "คบ",
  "คบคิด",
  "คบค้า",
  "คบหา",
  "คบเพลิง",
  "คบไฟ",
  "คม",
  "คมคาย",
  "คมนาการ",
  "คมนาคม",
  "คมน์",
  "คมิกภัต",
  "ครก",
  "ครบ",
  "ครบครัน",
  "ครบถ้วน",
  "ครรชิต",
  "ครรภ",
  "ครรภ์",
  "ครรลอง",
  "ครรโลง",
  "ครรไล",
  "ครวญ",
  "ครวี",
  "ครหา",
  "ครอก",
  "ครอง",
  "ครองแครง",
  "ครอบ",
  "ครอบครอง",
  "ครอบครัว",
  "ครอบคลุม",
  "ครอบงำ",
  "ครอบจักรวาล",
  "ครอส",
  "คระหน",
  "คระหวน",
  "คระหาย",
  "คระเมิม",
  "คระแลง",
  "คระแวง",
  "คระโหย",
  "คระไล",
  "ครัดเคร่ง",
  "ครัน",
  "ครับ",
  "ครัว",
  "ครัวซอง",
  "ครัวซองต์",
  "ครั่ง",
  "ครั่น",
  "ครั้ง",
  "ครั้น",
  "ครา",
  "คราก",
  "คราง",
  "คราญ",
  "คราด",
  "คราบ",
  "คราม",
  "ครามครัน",
  "คราว",
  "คราส",
  "ครำ",
  "คริปทอน",
  "คริสตกาล",
  "คริสตจักร",
  "คริสตัง",
  "คริสตัล",
  "คริสต์",
  "คริสต์มาส",
  "คริสต์ศตวรรษ",
  "คริสต์ศักราช",
  "คริสเตียน",
  "คริสโตเฟอร์",
  "ครีบ",
  "ครีม",
  "ครีษมายัน",
  "ครีเอทีฟ",
  "ครึ",
  "ครึกครื้น",
  "ครึกโครม",
  "ครึน",
  "ครึ่ง",
  "ครึ่ด",
  "ครึ้ม",
  "ครืด",
  "ครืน",
  "ครืนครั่น",
  "ครือ",
  "ครื้น",
  "ครื้นครั่น",
  "ครื้นครึก",
  "ครื้นเครง",
  "ครุ",
  "ครุกรรม",
  "ครุคระ",
  "ครุฑ",
  "ครุฑพ่าห์",
  "ครุภัณฑ์",
  "ครุมเครือ",
  "ครุย",
  "ครุวนา",
  "ครุศาสตร์",
  "ครุ่น",
  "ครุ่นคิด",
  "ครู",
  "ครูด",
  "ครูเสด",
  "ครู่",
  "คร่อม",
  "คร่อเงาะ",
  "คร่อเทียน",
  "คร่า",
  "คร่าว",
  "คร่ำ",
  "คร่ำครวญ",
  "คร่ำเคร่ง",
  "คร้าน",
  "คร้าม",
  "คฤนถ์",
  "คฤหบดี",
  "คฤหัสถ์",
  "คฤหาสน์",
  "คฤโฆษ",
  "คลวง",
  "คลอ",
  "คลอก",
  "คลอง",
  "คลอด",
  "คลอน",
  "คลอรีน",
  "คลอเคลีย",
  "คลอโรพลาสต์",
  "คลอโรฟอร์ม",
  "คลอโรฟีลล์",
  "คลอไรด์",
  "คละ",
  "คละคลุ้ง",
  "คลัก",
  "คลัง",
  "คลัตช์",
  "คลับ",
  "คลับคลา",
  "คลับคล้าย",
  "คลัสเตอร์",
  "คลั่ก",
  "คลั่ง",
  "คลั่งไคล้",
  "คลา",
  "คลาคล่ำ",
  "คลางแคลง",
  "คลาด",
  "คลาดเคลื่อน",
  "คลาดแคล้ว",
  "คลาน",
  "คลาย",
  "คลาส",
  "คลาสสิก",
  "คลาไคล",
  "คลำ",
  "คลิก",
  "คลิด",
  "คลิตอริส",
  "คลินิก",
  "คลินิค",
  "คลิป",
  "คลิปอาร์ต",
  "คลิ้งโคลง",
  "คลี",
  "คลีนิก",
  "คลีนิค",
  "คลี่",
  "คลี่คลาย",
  "คลึง",
  "คลึงเคล้น",
  "คลึงเคล้า",
  "คลื่น",
  "คลื่นเหียน",
  "คลื่นไส้",
  "คลุก",
  "คลุกคลาน",
  "คลุกคลี",
  "คลุบ",
  "คลุม",
  "คลุมเครือ",
  "คลุมโปง",
  "คลุ่ม",
  "คลุ้ง",
  "คลุ้ม",
  "คลุ้มคลั่ง",
  "คล่อง",
  "คล่องตัว",
  "คล่องมือ",
  "คล่องแคล่ว",
  "คล่าว",
  "คล่ำ",
  "คล้อ",
  "คล้อง",
  "คล้อย",
  "คล้า",
  "คล้าย",
  "คล้ายคลึง",
  "คล้ำ",
  "ควง",
  "ควงสว่าน",
  "ควณ",
  "ควน",
  "ควบ",
  "ควบคุม",
  "ควบคู่",
  "ควบแน่น",
  "ควย",
  "ควร",
  "ควอนตัม",
  "ควัก",
  "ควัน",
  "ควันหลง",
  "ควั่ก",
  "ควั่งคว้าง",
  "ควั่น",
  "ควาก",
  "ควาญ",
  "ควาน",
  "ความ",
  "ความคิด",
  "ความหมาย",
  "ความหลัง",
  "ความเห็น",
  "ควาย",
  "ควิก",
  "ควินิน",
  "ควิวคว่าง",
  "ควิเบก",
  "ควีน",
  "คว่าว",
  "คว่ำ",
  "คว้า",
  "คว้าง",
  "คว้าน",
  "คศ.ด.",
  "คศ.บ.",
  "คศ.ม.",
  "คหกรรม",
  "คหกรรมศาสตร์",
  "คหบดี",
  "คหัฐ",
  "คอ",
  "คอก",
  "คองคอร์ด",
  "คองเกรส",
  "คองโก",
  "คอด",
  "คอน",
  "คอนกรีต",
  "คอนซูเมอร์",
  "คอนดักเตอร์",
  "คอนเซปต์",
  "คอนเซ็ปต์",
  "คอนเดนเซอร์",
  "คอนเทนต์",
  "คอนเทนเนอร์",
  "คอนเนตทิคัต",
  "คอนเฟิร์ม",
  "คอนเสิร์ต",
  "คอนแทค",
  "คอนแท็ค",
  "คอนแวนต์",
  "คอนโซล",
  "คอนโด",
  "คอนโดมิเนียม",
  "คอนโทรล",
  "คอปเตอร์",
  "คอปเปอร์",
  "คอม",
  "คอมพิวติ้ง",
  "คอมพิวเตอร์",
  "คอมพ์",
  "คอมมอนส์",
  "คอมมานโด",
  "คอมมิวนิสต์",
  "คอมมูนิเคชั่น",
  "คอมเพล็กซ์",
  "คอมเมนต์",
  "คอมเมนท์",
  "คอมไพล์",
  "คอมไพเลอร์",
  "คอย",
  "คอยล์",
  "คอรัปชัน",
  "คอรัปชั่น",
  "คอร์",
  "คอร์ด",
  "คอร์ป",
  "คอร์ปอเรชั่น",
  "คอร์รัปชัน",
  "คอร์รัปชั่น",
  "คอร์ส",
  "คอลลาเจน",
  "คอลัมนิสต์",
  "คอลัมน์",
  "คอลเกต",
  "คอลเล็กชั่น",
  "คอสตาริกา",
  "คอสติกโซดา",
  "คอสมิก",
  "คอหอย",
  "คอห่าน",
  "คอเคซอยด์",
  "คอเลสเตอรอล",
  "คอแดง",
  "คอแลน",
  "คอแห้ง",
  "คอโมโรส",
  "คะ",
  "คะคาน",
  "คะค้อย",
  "คะนน",
  "คะนอง",
  "คะนึง",
  "คะน้า",
  "คะมำ",
  "คะยั้นคะยอ",
  "คะเน",
  "คะเนงร้าย",
  "คะเน็ด",
  "คะเยอ",
  "คะแนน",
  "คะไขว่",
  "คัก",
  "คัคนะ",
  "คัคนัมพร",
  "คัคนางค์",
  "คัคนานต์",
  "คังไคย",
  "คัจฉ",
  "คัณฑมาลา",
  "คัณฑสูตร",
  "คัด",
  "คัดค้าน",
  "คัดง้าง",
  "คัดท้าย",
  "คัดมอน",
  "คัดเค้า",
  "คัดเม็ง",
  "คัดเลือก",
  "คัตเอาต์",
  "คัทลียา",
  "คัน",
  "คันคาก",
  "คันจาม",
  "คันจิ",
  "คันฉาย",
  "คันฉ่อง",
  "คันชัก",
  "คันชั่ง",
  "คันถธุระ",
  "คันถรจนาจารย์",
  "คันธกุฎี",
  "คันธมาทน์",
  "คันธารราษฎร์",
  "คันธาระ",
  "คันธ์",
  "คันนา",
  "คันยิ",
  "คันเร่ง",
  "คันไถ",
  "คับ",
  "คับขัน",
  "คับคั่ง",
  "คับคา",
  "คับค้อน",
  "คับแค",
  "คับแคบ",
  "คับแค้น",
  "คัพภ์",
  "คัมภีรภาพ",
  "คัมภีร์",
  "คัล",
  "คัสตาร์ด",
  "คั่ก",
  "คั่ง",
  "คั่งค้าง",
  "คั่งแค้น",
  "คั่น",
  "คั่ว",
  "คั้น",
  "คา",
  "คาง",
  "คางคก",
  "คางทูม",
  "คางหมู",
  "คาซัคสถาน",
  "คาด",
  "คาดคั้น",
  "คาดหมาย",
  "คาดเชือก",
  "คาดโทษ",
  "คาตาคานะ",
  "คาถา",
  "คาทอลิก",
  "คาทอลิค",
  "คาน",
  "คานธี",
  "คานหาม",
  "คาบ",
  "คาบศิลา",
  "คาบสมุทร",
  "คาบูล",
  "คาบเกี่ยว",
  "คาปูชิโน",
  "คาพยุต",
  "คาม",
  "คามณีย์",
  "คามภีร์",
  "คามวาสี",
  "คามิคาเซ่",
  "คาย",
  "คารม",
  "คารวะ",
  "คาราบาว",
  "คาราวาน",
  "คาราเต้",
  "คาราเมล",
  "คาราโอเกะ",
  "คาร์",
  "คาร์บอน",
  "คาร์บอลิก",
  "คาร์บอเนต",
  "คาร์บูเรเตอร์",
  "คาร์ฟูร์",
  "คาร์สัน",
  "คาร์โก้",
  "คาร์โบรันดัม",
  "คาร์โบไฮเดรต",
  "คาว",
  "คาวตอง",
  "คาวบอย",
  "คาวี",
  "คาวุต",
  "คาส",
  "คาสิโน",
  "คาสิโอ",
  "คาเบรียล",
  "คาเฟ่",
  "คาแรกเตอร์",
  "คาแรคเตอร์",
  "คาแร็กเตอร์",
  "คาแร็คเตอร์",
  "คำ",
  "คำขาด",
  "คำนวณ",
  "คำนวร",
  "คำนับ",
  "คำนัล",
  "คำนำ",
  "คำนึง",
  "คำนูณ",
  "คำฝอย",
  "คำรน",
  "คำรบ",
  "คำราม",
  "คำเพลิง",
  "คำแสด",
  "คำแหง",
  "คำโอง",
  "คิก",
  "คิกขุ",
  "คิง",
  "คิด",
  "คิดค้น",
  "คิตส์",
  "คิมหันต์",
  "คิริน",
  "คิริบาตี",
  "คิริบาส",
  "คิรี",
  "คิลาน",
  "คิลานะ",
  "คิว",
  "คิว",
  "คิวชู",
  "คิวบา",
  "คิวบิก",
  "คิ้ว",
  "คีต",
  "คีตกวี",
  "คีตปฏิภาณ",
  "คีตราชัน",
  "คีบ",
  "คีม",
  "คีย์",
  "คีย์บอร์ด",
  "คีรี",
  "คีรีบูน",
  "คีร์กีซสถาน",
  "คี่",
  "คึก",
  "คึกคัก",
  "คึ่ก",
  "คึ่นฉ่าย",
  "คืน",
  "คืนดี",
  "คืนตัว",
  "คืบ",
  "คือ",
  "คุ",
  "คุก",
  "คุกกี้",
  "คุกคาม",
  "คุกพาทย์",
  "คุกเข่า",
  "คุชราต",
  "คุชราตี",
  "คุณ",
  "คุณค่า",
  "คุณชาย",
  "คุณธรรม",
  "คุณนาย",
  "คุณภาพ",
  "คุณลักษณะ",
  "คุณวุฒิ",
  "คุณศัพท์",
  "คุณสมบัติ",
  "คุณหญิง",
  "คุณากร",
  "คุณูปการ",
  "คุด",
  "คุดทะราด",
  "คุต",
  "คุตติ",
  "คุนลุ้น",
  "คุนหมิง",
  "คุป",
  "คุปติ",
  "คุปต์",
  "คุม",
  "คุมเชิง",
  "คุย",
  "คุยหฐาน",
  "คุยหประเทศ",
  "คุยเขื่อง",
  "คุยโต",
  "คุรุ",
  "คุรุมุขี",
  "คุลา",
  "คุลิก่า",
  "คุลีการ",
  "คุหา",
  "คุโณปการ",
  "คุ่น",
  "คุ่ม",
  "คุ้ง",
  "คุ้น",
  "คุ้ม",
  "คุ้มกัน",
  "คุ้มครอง",
  "คุ้ย",
  "คุ้ยเขี่ย",
  "คู",
  "คูณ",
  "คูถ",
  "คูน",
  "คูปอง",
  "คูลอมบ์",
  "คูลเลอร์",
  "คูหา",
  "คูเรียม",
  "คูเวต",
  "คูโบต้า",
  "คู่",
  "คู่กรณี",
  "คู่กัด",
  "คู่ขา",
  "คู่ครอง",
  "คู่ควร",
  "คู่คิด",
  "คู่คี่",
  "คู่ชีพ",
  "คู่ชีวิต",
  "คู่บารมี",
  "คู่บุญ",
  "คู่ปรปักษ์",
  "คู่ปรับ",
  "คู่ผสม",
  "คู่มือ",
  "คู่รัก",
  "คู่ลำดับ",
  "คู่สาย",
  "คู่หมั้น",
  "คู่หู",
  "คู่อริ",
  "คู่อาฆาต",
  "คู่แข่ง",
  "คู่ใจ",
  "คู้",
  "คเณศ",
  "คเนจร",
  "ค็อกคัส",
  "ค็อกเทล",
  "ค่องอ้อย",
  "ค่อน",
  "ค่อนขอด",
  "ค่อนแคะ",
  "ค่อม",
  "ค่อย",
  "ค่ะ",
  "ค่า",
  "ค่าคบ",
  "ค่าง",
  "ค่าย",
  "ค่าว",
  "ค่ำ",
  "ค้น",
  "ค้อ",
  "ค้อน",
  "ค้อนควัก",
  "ค้อม",
  "ค้อย",
  "ค้า",
  "ค้าง",
  "ค้างคาว",
  "ค้างคืน",
  "ค้างปี",
  "ค้าน",
  "ค้าว",
  "ค้ำ",
  "ฅน",
  "ฆนะ",
  "ฆราวาส",
  "ฆาต",
  "ฆาตกร",
  "ฆาตกรรม",
  "ฆาน",
  "ฆานประสาท",
  "ฆานินทรีย์",
  "ฆ่า",
  "ฆ้อง",
  "ฆ้องกระแต",
  "ฆ้องชัย",
  "ฆ้องวง",
  "ฆ้องหุ่ย",
  "ฆ้องเหม่ง",
  "ฆ้องโหม่ง",
  "ง.ด.",
  "งก",
  "งง",
  "งงงวย",
  "งงงัน",
  "งด",
  "งดงาม",
  "งดเว้น",
  "งบ",
  "งบดุล",
  "งบประมาณ",
  "งม",
  "งมงาย",
  "งมโข่ง",
  "งวง",
  "งวด",
  "งวยงง",
  "งอ",
  "งอก",
  "งอกแงก",
  "งอด",
  "งอดแงด",
  "งอน",
  "งอนง้อ",
  "งอนหง่อ",
  "งอบ",
  "งอม",
  "งอมแงม",
  "งอย",
  "งอหาย",
  "งอแง",
  "งัก",
  "งัด",
  "งัน",
  "งันงก",
  "งับ",
  "งัว",
  "งัวเงีย",
  "งั่ก",
  "งั่ง",
  "งั่ว",
  "งั้น",
  "งา",
  "งาช้าง",
  "งาน",
  "งานการ",
  "งาบ",
  "งาม",
  "งาย",
  "งำ",
  "งิ้ว",
  "งีบ",
  "งี่เง่า",
  "งี้",
  "งึก",
  "งึน",
  "งึม",
  "งึมงำ",
  "งุด",
  "งุนงง",
  "งุบ",
  "งุบงิบ",
  "งุย",
  "งุ่นง่าน",
  "งุ่มง่าม",
  "งุ้ม",
  "งู",
  "งูบ",
  "งูสวัด",
  "ง่วง",
  "ง่วงงุน",
  "ง่วงเหงา",
  "ง่วน",
  "ง่อกแง่ก",
  "ง่อง",
  "ง่องแง่ง",
  "ง่อน",
  "ง่อนแง่น",
  "ง่อย",
  "ง่า",
  "ง่าน",
  "ง่าม",
  "ง่าย",
  "ง่ายดาย",
  "ง่าว",
  "ง่าเงย",
  "ง่ำ",
  "ง้วน",
  "ง้อ",
  "ง้องอน",
  "ง้อม",
  "ง้อไบ๊",
  "ง้าง",
  "ง้าว",
  "ง้ำ",
  "จ.จ.",
  "จ.จ.จ.",
  "จ.ช.",
  "จ.ต.",
  "จ.ท.",
  "จ.ป.ร.",
  "จ.ม.",
  "จ.ศ.",
  "จ.ส.ต.",
  "จ.ส.ท.",
  "จ.ส.อ.",
  "จ.อ.",
  "จ.อ.ร.",
  "จ.๑๘",
  "จก",
  "จก.ธน.",
  "จก.สน.",
  "จง",
  "จงกรม",
  "จงกล",
  "จงกลนี",
  "จงรัก",
  "จงอร",
  "จงอาง",
  "จงโคร่ง",
  "จงใจ",
  "จด",
  "จดจำ",
  "จดจ่อ",
  "จดหมาย",
  "จดหมายเหตุ",
  "จดุรงค์",
  "จตุคาม",
  "จตุจักร",
  "จตุตถ",
  "จตุตถี",
  "จตุปัจจัย",
  "จตุพร",
  "จตุร",
  "จตุรงค์",
  "จตุรพักตร์",
  "จตุรพิธ",
  "จตุรพิธพร",
  "จตุลังคบาท",
  "จตุสดมภ์",
  "จตุโลกบาล",
  "จน",
  "จบ",
  "จม",
  "จมร",
  "จมรี",
  "จมูก",
  "จยุติ",
  "จร",
  "จรจัด",
  "จรณะ",
  "จรด",
  "จรรจา",
  "จรรม",
  "จรรยา",
  "จรรยาบรรณ",
  "จรรโจษ",
  "จรรโลง",
  "จรลี",
  "จรวจ",
  "จรวด",
  "จรส",
  "จรอก",
  "จระนำ",
  "จระบี",
  "จระเข้",
  "จรัล",
  "จรัส",
  "จราจร",
  "จราญ",
  "จริก",
  "จริง",
  "จริงจัง",
  "จริงใจ",
  "จริต",
  "จริม",
  "จริยธรรม",
  "จริยวัตร",
  "จริยศาสตร์",
  "จริยศึกษา",
  "จริยา",
  "จริยาวัตร",
  "จรุง",
  "จรูง",
  "จรูญ",
  "จล",
  "จลนพลศาสตร์",
  "จลนศาสตร์",
  "จลนี",
  "จลาจล",
  "จวก",
  "จวง",
  "จวด",
  "จวน",
  "จวบ",
  "จวัก",
  "จอ",
  "จอก",
  "จอง",
  "จองหอง",
  "จองเปรียง",
  "จอด",
  "จอน",
  "จอนจ่อ",
  "จอบ",
  "จอม",
  "จอมขวัญ",
  "จอมทัพ",
  "จอมปลวก",
  "จอมพล",
  "จอมสุรางค์",
  "จอมใจ",
  "จอร์เจีย",
  "จอร์แดน",
  "จอหงวน",
  "จอห์น",
  "จอแจ",
  "จอแส",
  "จะ",
  "จะกละ",
  "จะกลาม",
  "จะกูด",
  "จะขาบ",
  "จะงอย",
  "จะจะ",
  "จะละหวั่น",
  "จะละเม็ด",
  "จะเข็บ",
  "จะเข้",
  "จะแจ่ม",
  "จะแจ้ง",
  "จัก",
  "จักกาย",
  "จักขุ",
  "จักจั่น",
  "จักจี้",
  "จักร",
  "จักรพรรดิ",
  "จักรพาก",
  "จักรภพ",
  "จักรยาน",
  "จักรยานยนต์",
  "จักรราศี",
  "จักรวรรดิ",
  "จักรวรรดินิยม",
  "จักรวาก",
  "จักรวาล",
  "จักริน",
  "จักรี",
  "จักษุ",
  "จักสาน",
  "จักแหล่น",
  "จัง",
  "จังกวด",
  "จังกอบ",
  "จังกูด",
  "จังก้า",
  "จังงัง",
  "จังหนับ",
  "จังหนับ",
  "จังหรีด",
  "จังหวะ",
  "จังหวัด",
  "จังหัน",
  "จังโกฏก์",
  "จังโก้",
  "จังไร",
  "จัญไร",
  "จัณฑาล",
  "จัณฑ์",
  "จัด",
  "จัดการ",
  "จัดจ้าน",
  "จัดตั้ง",
  "จัดสรร",
  "จัดเจน",
  "จัดแจง",
  "จัตตาฬีสะ",
  "จัตวา",
  "จัตุ",
  "จัตุรงค์",
  "จัตุรัส",
  "จัตุลังคบาท",
  "จัตุสดมภ์",
  "จัตุโลกบาล",
  "จัน",
  "จันทน์",
  "จันทบุรี",
  "จันทรคติ",
  "จันทรคราส",
  "จันทรุปราคา",
  "จันทรเม็ด",
  "จันทร์",
  "จันทวาร",
  "จันทัน",
  "จันท์",
  "จันอับ",
  "จันโจษ",
  "จับ",
  "จับกัง",
  "จับกุม",
  "จับจด",
  "จับฉ่าย",
  "จับปิ้ง",
  "จับยี่กี",
  "จับเจ่า",
  "จับเดิม",
  "จัมบก",
  "จัมปกะ",
  "จัมปา",
  "จัมมะ",
  "จัมโบ้",
  "จัว",
  "จั่น",
  "จั่นดิน",
  "จั่ว",
  "จั้ก",
  "จั้ง",
  "จั้งมั่ง",
  "จั๊กกิ้ม",
  "จั๊กจี้",
  "จั๊กเดียม",
  "จั๊กเล้อ",
  "จั๊วะ",
  "จั๋ง",
  "จา",
  "จาก",
  "จากพาก",
  "จาการ์ตา",
  "จาคะ",
  "จาคี",
  "จาง",
  "จางปาง",
  "จางวาง",
  "จาด",
  "จาตุทสี",
  "จาตุมหาราช",
  "จาตุมหาราชิก",
  "จาตุมหาราชิกา",
  "จาตุรงคสันนิบาต",
  "จาตุรงค์",
  "จาตุรนต์",
  "จาตุรราชการ",
  "จาตุรันต์",
  "จาน",
  "จานบิน",
  "จานผี",
  "จานเชิง",
  "จานเสียง",
  "จาบ",
  "จาบจ้วง",
  "จาบัล",
  "จาบัลย์",
  "จาป",
  "จาม",
  "จามจุรี",
  "จามร",
  "จามรี",
  "จามีกร",
  "จาร",
  "จารกรรม",
  "จารชน",
  "จารวาก",
  "จาระบี",
  "จาระไน",
  "จาริก",
  "จารี",
  "จารีต",
  "จารึก",
  "จารุ",
  "จาว",
  "จาวา",
  "จาเมกา",
  "จำ",
  "จำกัด",
  "จำงาย",
  "จำทวย",
  "จำนง",
  "จำนน",
  "จำนรรจา",
  "จำนรรจ์",
  "จำนวน",
  "จำนอง",
  "จำนัล",
  "จำนำ",
  "จำบัง",
  "จำบ่ม",
  "จำปา",
  "จำปาดะ",
  "จำปาศักดิ์",
  "จำปี",
  "จำปูน",
  "จำพรรษา",
  "จำพวก",
  "จำรัส",
  "จำราญ",
  "จำรูญ",
  "จำลอง",
  "จำวัด",
  "จำหนับ",
  "จำหน่าย",
  "จำหระ",
  "จำหลัก",
  "จำหล่อ",
  "จำอวด",
  "จำเจ",
  "จำเดิม",
  "จำเนียน",
  "จำเนียม",
  "จำเนียร",
  "จำเป็น",
  "จำเพาะ",
  "จำเริญ",
  "จำเรียง",
  "จำเลย",
  "จำเลาะ",
  "จำเลาะตา",
  "จำเหียง",
  "จำแนก",
  "จำแลง",
  "จำแล่น",
  "จิ",
  "จิก",
  "จิงจัง",
  "จิงจ้อ",
  "จิงโจ้",
  "จิงโจ้น้ำ",
  "จิต",
  "จิตกาธาน",
  "จิตตภาวนา",
  "จิตตัง",
  "จิตตานุปัสสนา",
  "จิตต์",
  "จิตนิยม",
  "จิตบำบัด",
  "จิตพิสัย",
  "จิตร",
  "จิตรกร",
  "จิตรกรรม",
  "จิตรจุล",
  "จิตรลดา",
  "จิตระ",
  "จิตรา",
  "จิตวิทยา",
  "จิตวิสัย",
  "จิตเภท",
  "จิตเวช",
  "จิตเวชศาสตร์",
  "จิตแพทย์",
  "จิตใจ",
  "จินดา",
  "จินดาหนา",
  "จินดาหรา",
  "จินตกวี",
  "จินตนา",
  "จินตนาการ",
  "จินตภาพ",
  "จินต์",
  "จินเจา",
  "จิบ",
  "จิบูตี",
  "จิปาถะ",
  "จิม",
  "จิรกาล",
  "จิว",
  "จิ่ม",
  "จิ้งจก",
  "จิ้งจอก",
  "จิ้งจัง",
  "จิ้งหรีด",
  "จิ้งเหลน",
  "จิ้งโกร่ง",
  "จิ้น",
  "จิ้ม",
  "จิ้มก้อง",
  "จิ้มลิ้ม",
  "จิ๊ก",
  "จิ๊กซอว์",
  "จิ๊กโก๋",
  "จิ๋ม",
  "จิ๋ว",
  "จี",
  "จี",
  "จีดีพี",
  "จีน",
  "จีน",
  "จีนแส",
  "จีบ",
  "จีพีเอส",
  "จีม",
  "จีวร",
  "จีแจ๊บ",
  "จีโนม",
  "จีโบ",
  "จี่",
  "จี้",
  "จี๊ด",
  "จี๋",
  "จี๋จ้อ",
  "จึง",
  "จึ่ง",
  "จึ้ง",
  "จึ๊ก",
  "จืด",
  "จุ",
  "จุก",
  "จุกจิก",
  "จุกชี",
  "จุกผาม",
  "จุกโรหินี",
  "จุฑา",
  "จุฑามณี",
  "จุฑามาศ",
  "จุฑารัตน์",
  "จุณ",
  "จุณณียบท",
  "จุด",
  "จุติ",
  "จุตูปปาตญาณ",
  "จุทส",
  "จุน",
  "จุนจู๋",
  "จุนทการ",
  "จุนสี",
  "จุนเจือ",
  "จุบ",
  "จุบจิบ",
  "จุมพฎ",
  "จุมพฏ",
  "จุมพรวด",
  "จุมพล",
  "จุมพิต",
  "จุมโพล่",
  "จุรณ",
  "จุรี",
  "จุล",
  "จุลจอมเกล้า",
  "จุลชีพ",
  "จุลชีววิทยา",
  "จุลชีวัน",
  "จุลชีวิน",
  "จุลทรรศน์",
  "จุลภาค",
  "จุลวงศ์",
  "จุลวรรค",
  "จุลศักราช",
  "จุลสาร",
  "จุลินทรีย์",
  "จุฬา",
  "จุฬาภรณ์",
  "จุฬามณี",
  "จุฬาราชมนตรี",
  "จุฬาลงกรณ์",
  "จุฬาลักษณ์",
  "จุฬาลัมพา",
  "จุฬาลำพา",
  "จุไร",
  "จุ่ง",
  "จุ่น",
  "จุ่ม",
  "จุ้น",
  "จุ้นจู๊",
  "จุ้นจ้าน",
  "จุ้ม",
  "จุ้ย",
  "จุ๊กกรู๊",
  "จุ๊บ",
  "จุ๊บแจง",
  "จุ๊ย",
  "จุ๋งจิ๋ง",
  "จุ๋มจิ๋ม",
  "จู",
  "จูง",
  "จูน",
  "จูบ",
  "จูรณ",
  "จูเนียร์",
  "จู่",
  "จู้",
  "จู้จี้",
  "จู๊ด",
  "จู๋",
  "จู๋จี๋",
  "จเร",
  "จ็อบส์",
  "จ่ง",
  "จ่ม",
  "จ่อ",
  "จ่อม",
  "จ่อย",
  "จ่า",
  "จ่าง",
  "จ่ามงกุฎ",
  "จ่าย",
  "จ่ารง",
  "จ่าหน้า",
  "จ่าหวัก",
  "จ้วง",
  "จ้อ",
  "จ้อก",
  "จ้อกแจ้ก",
  "จ้อง",
  "จ้องหน่อง",
  "จ้องเต",
  "จ้อน",
  "จ้อย",
  "จ้ะ",
  "จ้า",
  "จ้าง",
  "จ้าน",
  "จ้าละหวั่น",
  "จ้าว",
  "จ้ำ",
  "จ้ำจี้",
  "จ้ำจี้จ้ำไช",
  "จ้ำม่ำ",
  "จ้ำเบ้า",
  "จ๊วก",
  "จ๊อก",
  "จ๊อกกี้",
  "จ๊ะ",
  "จ๊ะจ๋า",
  "จ๊ะเอ๋",
  "จ๊าบ",
  "จ๋วง",
  "จ๋อ",
  "จ๋อง",
  "จ๋อย",
  "จ๋อแจ๋",
  "จ๋ะ",
  "จ๋า",
  "จ๋ำหนับ",
  "ฉก",
  "ฉกฉวย",
  "ฉกชิง",
  "ฉกรรจ์",
  "ฉกษัตริย์",
  "ฉกาจ",
  "ฉกามาพจร",
  "ฉกามาวจร",
  "ฉง",
  "ฉงชิ่ง",
  "ฉงน",
  "ฉงาย",
  "ฉทึง",
  "ฉนวน",
  "ฉนัง",
  "ฉนาก",
  "ฉนำ",
  "ฉบัง",
  "ฉบัด",
  "ฉบับ",
  "ฉบำ",
  "ฉม",
  "ฉมบ",
  "ฉมวก",
  "ฉมวย",
  "ฉมัง",
  "ฉมัน",
  "ฉมา",
  "ฉมำ",
  "ฉม่อง",
  "ฉล",
  "ฉลวย",
  "ฉลอง",
  "ฉลองได",
  "ฉลอม",
  "ฉลัก",
  "ฉลับ",
  "ฉลาก",
  "ฉลาง",
  "ฉลาด",
  "ฉลาม",
  "ฉลาย",
  "ฉลิว",
  "ฉลีก",
  "ฉลุ",
  "ฉลุย",
  "ฉลู",
  "ฉวย",
  "ฉวะ",
  "ฉวัดเฉวียน",
  "ฉวาง",
  "ฉวี",
  "ฉศก",
  "ฉอก",
  "ฉอด",
  "ฉอเลาะ",
  "ฉะ",
  "ฉะฉาด",
  "ฉะฉาน",
  "ฉะฉี่",
  "ฉะฉ่ำ",
  "ฉะนั้น",
  "ฉะนี้",
  "ฉะอ้อน",
  "ฉะเฉื่อย",
  "ฉะเชิงเทรา",
  "ฉักกะ",
  "ฉัฐ",
  "ฉัด",
  "ฉัตร",
  "ฉัตรมงคล",
  "ฉัททันต์",
  "ฉัน",
  "ฉันท",
  "ฉันทลักษณ์",
  "ฉันทะ",
  "ฉันทา",
  "ฉันทาคติ",
  "ฉันทานุมัติ",
  "ฉันท์",
  "ฉับ",
  "ฉัพพรรณรังสี",
  "ฉัยยา",
  "ฉาก",
  "ฉาง",
  "ฉาด",
  "ฉาดฉาน",
  "ฉาตกภัย",
  "ฉาน",
  "ฉาบ",
  "ฉาบฉวย",
  "ฉาย",
  "ฉายา",
  "ฉายาลักษณ์",
  "ฉาว",
  "ฉำฉา",
  "ฉำแฉะ",
  "ฉิน",
  "ฉินทฤกษ์",
  "ฉินท์",
  "ฉิบ",
  "ฉิบหาย",
  "ฉิมพลี",
  "ฉิว",
  "ฉิ่ง",
  "ฉีก",
  "ฉีด",
  "ฉี่",
  "ฉุ",
  "ฉุก",
  "ฉุกละหุก",
  "ฉุกเฉิน",
  "ฉุด",
  "ฉุน",
  "ฉุนเฉียว",
  "ฉุป",
  "ฉุป",
  "ฉุปศาสตร์",
  "ฉุย",
  "ฉุยฉาย",
  "ฉูด",
  "ฉูดฉาด",
  "ฉู่",
  "ฉู่ฉี่",
  "ฉ่อง",
  "ฉ่อย",
  "ฉ่า",
  "ฉ่าง",
  "ฉ่าฉาว",
  "ฉ่ำ",
  "ฉ้อ",
  "ฉ้อฉล",
  "ช.ค.",
  "ช.ค.บ.",
  "ช.พ.ค.",
  "ช.ส.",
  "ช.ส.ค.",
  "ชก",
  "ชคัตตรัย",
  "ชง",
  "ชงฆา",
  "ชงฆ์",
  "ชงโค",
  "ชงโลง",
  "ชฎา",
  "ชฎามังษี",
  "ชฎามังสี",
  "ชฎิล",
  "ชด",
  "ชดช้อย",
  "ชดเชย",
  "ชดใช้",
  "ชน",
  "ชนก",
  "ชนกกรรม",
  "ชนนี",
  "ชนบท",
  "ชนมพรรษา",
  "ชนมายุ",
  "ชนม์",
  "ชนวน",
  "ชนะ",
  "ชนะเลิศ",
  "ชนัก",
  "ชนา",
  "ชนาง",
  "ชนิด",
  "ชนินทร์",
  "ชบา",
  "ชม",
  "ชมดชม้อย",
  "ชมนาด",
  "ชมพู",
  "ชมพูทวีป",
  "ชมพูนท",
  "ชมพูนุท",
  "ชมพู่",
  "ชมรม",
  "ชมัยมรุเชฐ",
  "ชมัยมรุเชษฐ์",
  "ชมเชย",
  "ชม้อย",
  "ชม้าย",
  "ชยา",
  "ชรทึง",
  "ชรริน",
  "ชรอุ่ม",
  "ชระลุ",
  "ชระล้ำ",
  "ชระอาบ",
  "ชระเอม",
  "ชรัด",
  "ชรา",
  "ชราธรรม",
  "ชราภาพ",
  "ชล",
  "ชลจร",
  "ชลธาร",
  "ชลธี",
  "ชลนัยน์",
  "ชลนา",
  "ชลบุรี",
  "ชลประทาน",
  "ชลมารค",
  "ชลาธาร",
  "ชลาลัย",
  "ชลาศัย",
  "ชลาสินธุ์",
  "ชลเนตร",
  "ชวด",
  "ชวน",
  "ชวนชม",
  "ชวนหัว",
  "ชวย",
  "ชวร",
  "ชวลิต",
  "ชวา",
  "ชวาล",
  "ชวาลา",
  "ชอก",
  "ชอกช้ำ",
  "ชอง",
  "ชองระอา",
  "ชอน",
  "ชอบ",
  "ชอบกล",
  "ชอบธรรม",
  "ชอบพอ",
  "ชอบใจ",
  "ชอม",
  "ชอล์ก",
  "ชอุ่ม",
  "ชอ่ำ",
  "ชะ",
  "ชะคราม",
  "ชะงอก",
  "ชะงัก",
  "ชะงัด",
  "ชะงุ้ม",
  "ชะง่อน",
  "ชะง้ำ",
  "ชะฉ่า",
  "ชะชะ",
  "ชะช่อง",
  "ชะช้า",
  "ชะตา",
  "ชะต้า",
  "ชะนี",
  "ชะพลู",
  "ชะมด",
  "ชะมบ",
  "ชะมวง",
  "ชะมัง",
  "ชะมัด",
  "ชะรอย",
  "ชะลอ",
  "ชะลอม",
  "ชะลาน",
  "ชะลิน",
  "ชะลูด",
  "ชะล่า",
  "ชะวาก",
  "ชะวาด",
  "ชะอม",
  "ชะอ้อน",
  "ชะเงื้อม",
  "ชะเง้อ",
  "ชะเนาะ",
  "ชะเนียง",
  "ชะเพลิง",
  "ชะเลง",
  "ชะเลย",
  "ชะเวิกชะวาก",
  "ชะเอม",
  "ชะแง้",
  "ชะแม่",
  "ชะแลง",
  "ชะแล็ก",
  "ชะแวง",
  "ชะโงก",
  "ชะโด",
  "ชะโนด",
  "ชะโอน",
  "ชัก",
  "ชักคราม",
  "ชักจูง",
  "ชักชวน",
  "ชักช้า",
  "ชักนำ",
  "ชักพระ",
  "ชักเงา",
  "ชักเนื้อ",
  "ชักเย่อ",
  "ชักโครก",
  "ชักใย",
  "ชัง",
  "ชังคา",
  "ชังฆ",
  "ชังฆวิหาร",
  "ชัชวาล",
  "ชัฏ",
  "ชัด",
  "ชัดช้า",
  "ชัดเจน",
  "ชัตเตอร์",
  "ชัน",
  "ชันกาด",
  "ชันชี",
  "ชันตุ",
  "ชันนะตุ",
  "ชันนุ",
  "ชันษา",
  "ชันสูตร",
  "ชันโรง",
  "ชัปนะ",
  "ชัพ",
  "ชัมพูนท",
  "ชัย",
  "ชัยนาท",
  "ชัยพฤกษ์",
  "ชัยภูมิ",
  "ชัยภูมิ",
  "ชัลลุกา",
  "ชัวชม",
  "ชัวร์",
  "ชั่ง",
  "ชั่งใจ",
  "ชั่ว",
  "ชั่วคน",
  "ชั่วคราว",
  "ชั่วช้า",
  "ชั่วแล่น",
  "ชั่วโมง",
  "ชั้น",
  "ชั้นเชิง",
  "ชั้ว",
  "ชา",
  "ชาคระ",
  "ชาคริต",
  "ชาคริยานุโยค",
  "ชาญ",
  "ชาด",
  "ชาด",
  "ชาดก",
  "ชาต",
  "ชาตบุษย์",
  "ชาตรี",
  "ชาตะ",
  "ชาตา",
  "ชาติ",
  "ชาติธรรม",
  "ชาตินิยม",
  "ชาติพันธุ์",
  "ชาติพันธุ์วิทยา",
  "ชาติภูมิ",
  "ชาน",
  "ชานชาลา",
  "ชานตง",
  "ชานุ",
  "ชาปีไหน",
  "ชาม",
  "ชามพูนท",
  "ชามาดร",
  "ชามาดา",
  "ชามาตุ",
  "ชาย",
  "ชายคา",
  "ชายชาตรี",
  "ชายทะเล",
  "ชายฝั่ง",
  "ชายา",
  "ชาร์จ",
  "ชาร์ต",
  "ชาร์ป",
  "ชาร์ลส์",
  "ชาล",
  "ชาลา",
  "ชาลินี",
  "ชาว",
  "ชาววัง",
  "ชาวี",
  "ชาวเล",
  "ชาเย็น",
  "ชำ",
  "ชำงัด",
  "ชำงาย",
  "ชำนะ",
  "ชำนัญ",
  "ชำนัน",
  "ชำนาญ",
  "ชำนิ",
  "ชำมะนาด",
  "ชำมะเลียง",
  "ชำระ",
  "ชำรุด",
  "ชำร่วย",
  "ชำเนียร",
  "ชำเรา",
  "ชำเราะ",
  "ชำเลือง",
  "ชำแรก",
  "ชำแระ",
  "ชำแหละ",
  "ชิ",
  "ชิคาโก",
  "ชิคุนกุนยา",
  "ชิง",
  "ชิงชัง",
  "ชิงชัน",
  "ชิงชี่",
  "ชิงช้า",
  "ชิงช้าชาลี",
  "ชิงพลบ",
  "ชิงฮื้อ",
  "ชิชะ",
  "ชิชิ",
  "ชิณณะ",
  "ชิด",
  "ชิต",
  "ชิตินทรีย์",
  "ชิน",
  "ชินชา",
  "ชินบัญชร",
  "ชินบุตร",
  "ชินราช",
  "ชินวัตร",
  "ชินโต",
  "ชิป",
  "ชิพ",
  "ชิฟฟอน",
  "ชิม",
  "ชิมลาง",
  "ชิมแปนซี",
  "ชิยา",
  "ชิรณะ",
  "ชิระ",
  "ชิลี",
  "ชิวหา",
  "ชิสา",
  "ชิเดนทรีย์",
  "ชิโนรส",
  "ชิ่ง",
  "ชิ้น",
  "ชิ้นเอก",
  "ชี",
  "ชีปะขาว",
  "ชีผะขาว",
  "ชีผ้าขาว",
  "ชีพ",
  "ชีพจร",
  "ชีพิตักษัย",
  "ชีฟอง",
  "ชีรณะ",
  "ชีระ",
  "ชีวประวัติ",
  "ชีวภาพ",
  "ชีววิทยา",
  "ชีวะ",
  "ชีวัน",
  "ชีวา",
  "ชีวาตม์",
  "ชีวาลัย",
  "ชีวิต",
  "ชีวิตักษัย",
  "ชีวิน",
  "ชีวี",
  "ชีวเคมี",
  "ชีส",
  "ชีอะห์",
  "ชีเปลือย",
  "ชี่",
  "ชี้",
  "ชี้ขาด",
  "ชี้นำ",
  "ชี้ฟ้า",
  "ชี้แจง",
  "ชี้แนะ",
  "ชืด",
  "ชื่น",
  "ชื่นชม",
  "ชื่นบาน",
  "ชื่นมื่น",
  "ชื่อ",
  "ชื่อย่อ",
  "ชื่อรอง",
  "ชื่อเล่น",
  "ชื่อเสียง",
  "ชื้น",
  "ชุก",
  "ชุกชี",
  "ชุกชุม",
  "ชุณห",
  "ชุด",
  "ชุติ",
  "ชุติมา",
  "ชุน",
  "ชุนชิว",
  "ชุบ",
  "ชุบตัว",
  "ชุบเลี้ยง",
  "ชุม",
  "ชุมชน",
  "ชุมทาง",
  "ชุมนุม",
  "ชุมนุมชน",
  "ชุมพร",
  "ชุมพร",
  "ชุมพา",
  "ชุมรุม",
  "ชุมสาย",
  "ชุมเห็ด",
  "ชุมแพรก",
  "ชุมแสง",
  "ชุลมุน",
  "ชุลี",
  "ชุษณะ",
  "ชุ่ม",
  "ชุ่มชื่น",
  "ชุ่มชื้น",
  "ชุ่มใจ",
  "ชุ่ย",
  "ชุ้ง",
  "ชู",
  "ชูชีพ",
  "ชูโรง",
  "ชู้",
  "ชู้สาว",
  "ชเนตตี",
  "ชเวดากอง",
  "ชโย",
  "ชโลง",
  "ชโลทร",
  "ชโลม",
  "ชไม",
  "ช็อก",
  "ช็อกโกเลต",
  "ช็อกโกแลต",
  "ช็อค",
  "ช็อคโกแลต",
  "ช็อต",
  "ช็อป",
  "ช็อปปิ้ง",
  "ช็อปเปอร์",
  "ช่วง",
  "ช่วงชิง",
  "ช่วงใช้",
  "ช่วย",
  "ช่วยเหลือ",
  "ช่อ",
  "ช่อง",
  "ช่องว่าง",
  "ช่องเขา",
  "ช่องแคบ",
  "ช่องไฟ",
  "ช่อน",
  "ช่อฟ้า",
  "ช่อม่วง",
  "ช่าง",
  "ช่างฝีมือ",
  "ช่างฟิต",
  "ช่างเครื่อง",
  "ช่างไฟ",
  "ช่ำ",
  "ช่ำชอง",
  "ช้อง",
  "ช้องนาง",
  "ช้อน",
  "ช้อย",
  "ช้า",
  "ช้าง",
  "ช้างน้ำ",
  "ช้างพลาย",
  "ช้างพัง",
  "ช้างสาร",
  "ช้างสีดอ",
  "ช้างเผือก",
  "ช้าช่อน",
  "ช้านาน",
  "ช้าปี่",
  "ช้าพลู",
  "ช้าเลือด",
  "ช้าแป้น",
  "ช้ำ",
  "ช้ำชอก",
  "ช้ำรั่ว",
  "ช้ำใจ",
  "ซก",
  "ซงดำ",
  "ซด",
  "ซน",
  "ซบ",
  "ซบเซา",
  "ซม",
  "ซมซาน",
  "ซวดเซ",
  "ซวน",
  "ซวนเซ",
  "ซวย",
  "ซอ",
  "ซอก",
  "ซอกซอน",
  "ซอกแซก",
  "ซอง",
  "ซองแมว",
  "ซอน",
  "ซอฟต์แวร์",
  "ซอฟท์แวร์",
  "ซอม",
  "ซอมซ่อ",
  "ซอมบี้",
  "ซอย",
  "ซอร์ส",
  "ซอส",
  "ซะ",
  "ซัก",
  "ซักค้าน",
  "ซักซ้อม",
  "ซักฟอก",
  "ซักส้าว",
  "ซักแห้ง",
  "ซักไซ้",
  "ซัง",
  "ซังกะตาย",
  "ซังตาย",
  "ซังฮี้",
  "ซังเต",
  "ซัด",
  "ซัดดัม",
  "ซัดทอด",
  "ซัดเซ",
  "ซันซิล",
  "ซันตาคลอส",
  "ซับ",
  "ซับซ้อน",
  "ซับพระพักตร์",
  "ซับใน",
  "ซัปโปโร",
  "ซัพพลาย",
  "ซัพพลายเออร์",
  "ซัพพอร์ต",
  "ซัพพอร์ท",
  "ซัมซุง",
  "ซัมเมอร์",
  "ซัลฟา",
  "ซัลฟิวริก",
  "ซัลเฟต",
  "ซัลไฟด์",
  "ซัวเจ๋ง",
  "ซั้ง",
  "ซั้ว",
  "ซา",
  "ซาก",
  "ซากศพ",
  "ซากุระ",
  "ซาง",
  "ซาด",
  "ซาดิสต์",
  "ซาดิสม์",
  "ซาตาน",
  "ซาน",
  "ซานตาคลอส",
  "ซานมารีโน",
  "ซาบซึ้ง",
  "ซาบซ่าน",
  "ซาบะ",
  "ซาบาห์",
  "ซาฟารี",
  "ซามัว",
  "ซามูไร",
  "ซาราวัก",
  "ซาราเยโว",
  "ซาร์",
  "ซาร์ดีน",
  "ซาลาเปา",
  "ซาว",
  "ซาอุ",
  "ซาเล้ง",
  "ซาแมเรียม",
  "ซำ",
  "ซิ",
  "ซิก",
  "ซิกข์",
  "ซิกซี้",
  "ซิการ์",
  "ซิกแซ็ก",
  "ซิง",
  "ซิงค์",
  "ซิงเกิล",
  "ซิงโคนา",
  "ซิดนีย์",
  "ซิตี",
  "ซิตี้",
  "ซินนามิก",
  "ซินหัว",
  "ซินแส",
  "ซินโดรม",
  "ซิบ",
  "ซิป",
  "ซิฟิลิส",
  "ซิม",
  "ซิมบับเว",
  "ซิมโฟนี",
  "ซิมโฟนี่",
  "ซิริอุส",
  "ซิลิกา",
  "ซิลิคอน",
  "ซิลิเกต",
  "ซิลิโคน",
  "ซิลเวอร์",
  "ซิว",
  "ซิ่ง",
  "ซิ่น",
  "ซิ้ม",
  "ซี",
  "ซี",
  "ซีก",
  "ซีคอน",
  "ซีซาร์",
  "ซีด",
  "ซีดาน",
  "ซีดี",
  "ซีดีรอม",
  "ซีดเซียว",
  "ซีน",
  "ซีนอน",
  "ซีนีเพล็กซ์",
  "ซีป่าย",
  "ซีริลลิก",
  "ซีรีส์",
  "ซีร็อกซ์",
  "ซีลีเนียม",
  "ซีอาน",
  "ซีอิ๊ว",
  "ซีอีโอ",
  "ซีเซียม",
  "ซีเทน",
  "ซีเนอร์",
  "ซีเนียร์",
  "ซีเมนต์",
  "ซีเรีย",
  "ซีเรียม",
  "ซีเรียส",
  "ซีเวิร์ต",
  "ซีแพค",
  "ซีแอตเทิล",
  "ซี่",
  "ซี่โครง",
  "ซี้",
  "ซี้ซั้ว",
  "ซี้ด",
  "ซึก",
  "ซึง",
  "ซึม",
  "ซึมกะทือ",
  "ซึมซาบ",
  "ซึมทราบ",
  "ซึมเซา",
  "ซึมเศร้า",
  "ซึ่ง",
  "ซึ้ง",
  "ซื่อ",
  "ซื่อตรง",
  "ซื่อบื้อ",
  "ซื่อสัตย์",
  "ซื้อ",
  "ซื้อขาย",
  "ซุก",
  "ซุกซน",
  "ซุกซ่อน",
  "ซุง",
  "ซุน",
  "ซุนหนี่",
  "ซุบ",
  "ซุบซิบ",
  "ซุป",
  "ซุปเปอร์",
  "ซุย",
  "ซุ่ม",
  "ซุ่มซ่าม",
  "ซุ้ม",
  "ซูชิ",
  "ซูซาน",
  "ซูซูกิ",
  "ซูด",
  "ซูดซาด",
  "ซูดาน",
  "ซูบ",
  "ซูบารุ",
  "ซูม",
  "ซูริก",
  "ซูริค",
  "ซูรินาเม",
  "ซูลู",
  "ซูฮก",
  "ซูเปอร์",
  "ซูเอี๋ย",
  "ซูโครส",
  "ซูโม่",
  "ซู่",
  "ซู่ซ่า",
  "ซู้ด",
  "ซ็อกเก็ต",
  "ซ่ก",
  "ซ่ง",
  "ซ่งฮื้อ",
  "ซ่อง",
  "ซ่องสุม",
  "ซ่องเสพ",
  "ซ่องแซ่ง",
  "ซ่อน",
  "ซ่อนกลิ่น",
  "ซ่อนทราย",
  "ซ่อนรูป",
  "ซ่อนหา",
  "ซ่อนเร้น",
  "ซ่อม",
  "ซ่อมแซม",
  "ซ่า",
  "ซ่าง",
  "ซ่าน",
  "ซ่านเซ็น",
  "ซ่าหริ่ม",
  "ซ่าโบะ",
  "ซ้น",
  "ซ้อ",
  "ซ้อง",
  "ซ้องแมว",
  "ซ้อน",
  "ซ้อม",
  "ซ้าย",
  "ซ้ำ",
  "ซ้ำซาก",
  "ซ้ำซ้อน",
  "ซ้ำร้าย",
  "ซ้ำเติม",
  "ฌ.ป.ค.",
  "ฌ.ศ.ร.",
  "ฌ.ส.อ.",
  "ฌาน",
  "ฌาปน",
  "ฌาปนกิจ",
  "ฌาปนสถาน",
  "ญวน",
  "ญัตติ",
  "ญาณ",
  "ญาณทัสนะ",
  "ญาณทัสสนะ",
  "ญาณวิทยา",
  "ญาณศาสตร์",
  "ญาติ",
  "ญาติกา",
  "ญานาซะฮ์",
  "ญิบ",
  "ญี่ปุ่น",
  "ญี่ปุ่น",
  "ฎีกา",
  "ฐกัด",
  "ฐท.สห.",
  "ฐากูร",
  "ฐาน",
  "ฐานราก",
  "ฐานะ",
  "ฐานันดร",
  "ฐานานุกรม",
  "ฐานานุรูป",
  "ฐานานุศักดิ์",
  "ฐานียะ",
  "ฐาปน",
  "ฐาปนา",
  "ฐายี",
  "ฐิต",
  "ฐิติ",
  "ฑาหก",
  "ฑาหะ",
  "ณรงค์",
  "ณัฐวุฒิ",
  "ด.ช.",
  "ด.ญ.",
  "ด.ต.",
  "ด.ศ.ค.",
  "ด.ศ.ร.",
  "ดก",
  "ดกดื่น",
  "ดง",
  "ดงดิบ",
  "ดนตรี",
  "ดนัย",
  "ดนุ",
  "ดนู",
  "ดนโด่",
  "ดบัสวิน",
  "ดบัสวี",
  "ดม",
  "ดย.ทร.",
  "ดยุก",
  "ดยุค",
  "ดรงค์",
  "ดรณี",
  "ดรรชนี",
  "ดรัมเมเยอร์",
  "ดราฟต์",
  "ดรามา",
  "ดราม่า",
  "ดรุณ",
  "ดรุณี",
  "ดร็อป",
  "ดล",
  "ดลบันดาล",
  "ดลใจ",
  "ดวง",
  "ดวงตรา",
  "ดวงตา",
  "ดวงสมร",
  "ดวงเดือน",
  "ดวงแก้ว",
  "ดวงใจ",
  "ดวด",
  "ดอก",
  "ดอกจัน",
  "ดอกจิก",
  "ดอกทอง",
  "ดอกบัว",
  "ดอกฟ้า",
  "ดอกยาง",
  "ดอกสร้อย",
  "ดอกเตอร์",
  "ดอกเบี้ย",
  "ดอกเล็บ",
  "ดอกไม้",
  "ดอง",
  "ดองฉาย",
  "ดองดึง",
  "ดองยา",
  "ดอด",
  "ดอน",
  "ดอนเมือง",
  "ดอปเปลอร์",
  "ดอปเพลอร์",
  "ดอม",
  "ดอย",
  "ดอลลาร์",
  "ดอส",
  "ดะ",
  "ดะหมัง",
  "ดะโต๊ะ",
  "ดัก",
  "ดักคอ",
  "ดักดาน",
  "ดักฟัง",
  "ดักแด้",
  "ดัง",
  "ดังนั้น",
  "ดังนี้",
  "ดังหนึ่ง",
  "ดัชนี",
  "ดัด",
  "ดัดจริต",
  "ดัดแปลง",
  "ดัตช์",
  "ดัทช์",
  "ดัน",
  "ดันทุรัง",
  "ดับ",
  "ดับขันธ์",
  "ดับจิต",
  "ดับชีพ",
  "ดับบลิว",
  "ดับเบิล",
  "ดัมพ์",
  "ดัมพ์",
  "ดัลลัส",
  "ดัสกร",
  "ดั่ง",
  "ดั้ง",
  "ดั้งเดิม",
  "ดั้น",
  "ดั้วเดี้ย",
  "ดั๊มพ์",
  "ดา",
  "ดาก",
  "ดาด",
  "ดาดฟ้า",
  "ดาน",
  "ดานัง",
  "ดานูบ",
  "ดาบ",
  "ดาบส",
  "ดาม",
  "ดามพ์",
  "ดาย",
  "ดารกะ",
  "ดารณี",
  "ดารดาษ",
  "ดาระ",
  "ดารา",
  "ดาราศาสตร์",
  "ดารุสซาลาม",
  "ดาร์ลี่",
  "ดาล",
  "ดาลัด",
  "ดาลเดือด",
  "ดาว",
  "ดาวกระจาย",
  "ดาวดึงส์",
  "ดาวตก",
  "ดาวน์",
  "ดาวน์เกรด",
  "ดาวน์โหลด",
  "ดาวบส",
  "ดาวรุ่ง",
  "ดาวฤกษ์",
  "ดาวหาง",
  "ดาวเคราะห์",
  "ดาวเทียม",
  "ดาวเรือง",
  "ดาวเหนือ",
  "ดาวโจนส์",
  "ดาษ",
  "ดาษดา",
  "ดาษดื่น",
  "ดาโคตา",
  "ดำ",
  "ดำกล",
  "ดำดง",
  "ดำนาณ",
  "ดำบล",
  "ดำรง",
  "ดำรวจ",
  "ดำรัส",
  "ดำริ",
  "ดำรี",
  "ดำรู",
  "ดำฤษณา",
  "ดำเกิง",
  "ดำเนิน",
  "ดำเลิง",
  "ดำแคง",
  "ดิก",
  "ดิกชันนารี",
  "ดิจิตอล",
  "ดิจิทัล",
  "ดิฉัน",
  "ดิฐ",
  "ดิตถ์",
  "ดิถี",
  "ดิน",
  "ดินขาว",
  "ดินดาน",
  "ดินดำ",
  "ดินประสิว",
  "ดินปืน",
  "ดินระเบิด",
  "ดินสอ",
  "ดินสอพอง",
  "ดิบ",
  "ดิบดี",
  "ดิรัจฉาน",
  "ดิลก",
  "ดิษฐ์",
  "ดิสนีย์",
  "ดิสเครดิต",
  "ดิสโพรเซียม",
  "ดิ่ง",
  "ดิ่ว",
  "ดิ้น",
  "ดิ้นรน",
  "ดิ้ว",
  "ดิ้วเดี้ยว",
  "ดี",
  "ดี",
  "ดีกรี",
  "ดีซ่าน",
  "ดีด",
  "ดีดดิ้น",
  "ดีดัก",
  "ดีดีที",
  "ดีทรอยต์",
  "ดีบั๊ก",
  "ดีบุก",
  "ดีปลี",
  "ดีฝ่อ",
  "ดีพาร์ตเมนต์",
  "ดีพาร์ตเมนท์",
  "ดีพาร์ทเมนต์",
  "ดีพาร์ทเมนท์",
  "ดีมานด์",
  "ดีล",
  "ดีลเลอร์",
  "ดีวีดี",
  "ดีหมี",
  "ดีหลี",
  "ดีเกลือ",
  "ดีเจ",
  "ดีเซล",
  "ดีเดือด",
  "ดีเบต",
  "ดีเปรสชัน",
  "ดีเลย์",
  "ดีแทค",
  "ดีใจ",
  "ดีไซน์",
  "ดีไซน์เนอร์",
  "ดีไซเนอร์",
  "ดีไวซ์",
  "ดึก",
  "ดึกดำบรรพ์",
  "ดึกดื่น",
  "ดึง",
  "ดึงดัน",
  "ดึงดูด",
  "ดึ่ง",
  "ดึ่ม",
  "ดือ",
  "ดื่น",
  "ดื่ม",
  "ดื่มด่ำ",
  "ดื้อ",
  "ดื้อดึง",
  "ดื้อด้าน",
  "ดื้อยา",
  "ดื้อรั้น",
  "ดื้อแพ่ง",
  "ดุ",
  "ดุก",
  "ดุกดิก",
  "ดุกทะเล",
  "ดุจ",
  "ดุด",
  "ดุดัน",
  "ดุน",
  "ดุบ",
  "ดุม",
  "ดุรงค์",
  "ดุริยะ",
  "ดุริยางคศาสตร์",
  "ดุริยางคศิลป์",
  "ดุริยางค์",
  "ดุร้าย",
  "ดุล",
  "ดุลการค้า",
  "ดุลพินิจ",
  "ดุลภาค",
  "ดุลยพินิจ",
  "ดุลยภาพ",
  "ดุษฎี",
  "ดุษฎีนิพนธ์",
  "ดุษฎีบัณฑิต",
  "ดุษณี",
  "ดุษณีภาพ",
  "ดุษณีภาพ",
  "ดุษิต",
  "ดุสิต",
  "ดุเดือด",
  "ดุเหว่า",
  "ดุ่ม",
  "ดุ่ย",
  "ดุ้ง",
  "ดุ้งดิ้ง",
  "ดุ้น",
  "ดู",
  "ดูกค่าง",
  "ดูกร",
  "ดูด",
  "ดูดดื่ม",
  "ดูดาย",
  "ดูถูก",
  "ดูปองท์",
  "ดูรา",
  "ดูหมิ่น",
  "ดูเบา",
  "ดูเหมือน",
  "ดูแคลน",
  "ดูแล",
  "ดูแล",
  "ดูไบ",
  "ด็อกเตอร์",
  "ด่วน",
  "ด่อง",
  "ด่อน",
  "ด่า",
  "ด่าง",
  "ด่างทับทิม",
  "ด่างพร้อย",
  "ด่าทอ",
  "ด่าน",
  "ด่าว",
  "ด่ำ",
  "ด้ง",
  "ด้น",
  "ด้วง",
  "ด้วน",
  "ด้วย",
  "ด้อง",
  "ด้อม",
  "ด้อย",
  "ด้าง",
  "ด้าน",
  "ด้าม",
  "ด้ามจิ้ว",
  "ด้าย",
  "ด้าว",
  "ด้ำ",
  "ต.ก.",
  "ต.ค.",
  "ต.จ.",
  "ต.จ.ว.",
  "ต.ช.",
  "ต.ต.",
  "ต.บ.",
  "ต.ม.",
  "ต.ร.",
  "ต.ศ.ร.",
  "ต.ห.",
  "ต.อ.",
  "ต.อ.จ.",
  "ตก",
  "ตกค้าง",
  "ตกต่ำ",
  "ตกทอด",
  "ตกฟาก",
  "ตกมัน",
  "ตกยาก",
  "ตกลง",
  "ตกหล่น",
  "ตกเขียว",
  "ตกแต่ง",
  "ตกใจ",
  "ตง",
  "ตงฉิน",
  "ตงิด",
  "ตงุ่น",
  "ตด",
  "ตติย",
  "ตถตา",
  "ตถาคต",
  "ตน",
  "ตนัย",
  "ตนุ",
  "ตนเอง",
  "ตบ",
  "ตบตา",
  "ตบมือ",
  "ตบะ",
  "ตบแต่ง",
  "ตบแผละ",
  "ตปนียะ",
  "ตม",
  "ตมูก",
  "ตยาคี",
  "ตร.กม.",
  "ตร.ซม.",
  "ตร.ต.",
  "ตร.ทล.",
  "ตร.น.",
  "ตร.ปม.",
  "ตร.ภ.",
  "ตร.ม.",
  "ตร.รฟ.",
  "ตร.ว.",
  "ตร.ส.",
  "ตร.สข.",
  "ตรง",
  "ตรณี",
  "ตรม",
  "ตรมตรอม",
  "ตรรก",
  "ตรรกวิทยา",
  "ตรรกศาสตร์",
  "ตรรกะ",
  "ตรลบ",
  "ตรลอด",
  "ตรลาด",
  "ตรวจ",
  "ตรวจการ",
  "ตรวจการณ์",
  "ตรวจตรา",
  "ตรวจทาน",
  "ตรวจสอบ",
  "ตรวน",
  "ตรอก",
  "ตรอง",
  "ตรอมตรม",
  "ตรอมใจ",
  "ตระ",
  "ตระกล",
  "ตระกวน",
  "ตระกอง",
  "ตระการ",
  "ตระกูล",
  "ตระคัร",
  "ตระนาว",
  "ตระบก",
  "ตระบอก",
  "ตระบอง",
  "ตระบัด",
  "ตระบัดสัตย์",
  "ตระบัน",
  "ตระพอง",
  "ตระพัง",
  "ตระลาการ",
  "ตระวัน",
  "ตระสัก",
  "ตระหง่าน",
  "ตระหนก",
  "ตระหนัก",
  "ตระหนี่",
  "ตระหน่ำ",
  "ตระเตรียม",
  "ตระเบ็ง",
  "ตระเวน",
  "ตระเว็ด",
  "ตระแบก",
  "ตระแบง",
  "ตระโบม",
  "ตรัง",
  "ตรัง",
  "ตรังกานู",
  "ตรังค์",
  "ตรับ",
  "ตรับฟัง",
  "ตรัย",
  "ตรัยตรึงศ์",
  "ตรัส",
  "ตรัสรู้",
  "ตรัสสา",
  "ตรา",
  "ตรากตรำ",
  "ตราชู",
  "ตราด",
  "ตราตั้ง",
  "ตราบ",
  "ตราบาป",
  "ตรายาง",
  "ตราสัง",
  "ตราสาร",
  "ตรำ",
  "ตริ",
  "ตริตรอง",
  "ตรินิแดด",
  "ตริว",
  "ตริโปลี",
  "ตรี",
  "ตรีกฏุก",
  "ตรีกาย",
  "ตรีคูณ",
  "ตรีทูต",
  "ตรีปวาย",
  "ตรีปิฎก",
  "ตรีพิธพรรณ",
  "ตรีภพ",
  "ตรีมูรติ",
  "ตรียัมปวาย",
  "ตรีโกณ",
  "ตรีโกณมิติ",
  "ตรึก",
  "ตรึกตรอง",
  "ตรึง",
  "ตรึงตรา",
  "ตรุ",
  "ตรุณ",
  "ตรุณะ",
  "ตรุษ",
  "ตรุษจีน",
  "ตรู",
  "ตรู่",
  "ตฤณ",
  "ตฤณชาติ",
  "ตฤณมัย",
  "ตฤตีย",
  "ตฤษณา",
  "ตลก",
  "ตลกบาตร",
  "ตลบ",
  "ตลบตะแลง",
  "ตลบหลัง",
  "ตลอด",
  "ตลับ",
  "ตลาด",
  "ตลาดนัด",
  "ตลาดน้ำ",
  "ตลาดมืด",
  "ตลาดสด",
  "ตลิ่ง",
  "ตลึง",
  "ตวง",
  "ตวัก",
  "ตวัด",
  "ตวาด",
  "ตอ",
  "ตอก",
  "ตอกย้ำ",
  "ตอง",
  "ตองกราย",
  "ตองกา",
  "ตองอู",
  "ตองเหลือง",
  "ตองแตก",
  "ตอด",
  "ตอน",
  "ตอบ",
  "ตอบแทน",
  "ตอบโต้",
  "ตอม",
  "ตอม่อ",
  "ตอร์ปิโด",
  "ตอเบา",
  "ตอแย",
  "ตอแหล",
  "ตะ",
  "ตะกรน",
  "ตะกรัน",
  "ตะกรับ",
  "ตะกราม",
  "ตะกรุด",
  "ตะกรุม",
  "ตะกรุมตะกราม",
  "ตะกร้อ",
  "ตะกร้า",
  "ตะกละ",
  "ตะกลาม",
  "ตะกวด",
  "ตะกอ",
  "ตะกอน",
  "ตะกัง",
  "ตะกั่ว",
  "ตะกาง",
  "ตะกาด",
  "ตะกาย",
  "ตะกาว",
  "ตะกุกตะกัก",
  "ตะกุย",
  "ตะกู",
  "ตะกูด",
  "ตะขบ",
  "ตะขอ",
  "ตะขาบ",
  "ตะขิดตะขวง",
  "ตะครอง",
  "ตะครั่นตะครอ",
  "ตะคริว",
  "ตะครุบ",
  "ตะคร้อ",
  "ตะคร้ำ",
  "ตะคอก",
  "ตะคัน",
  "ตะคาก",
  "ตะคิว",
  "ตะคุ่ม",
  "ตะค้า",
  "ตะค้าน",
  "ตะนอย",
  "ตะนาว",
  "ตะนาวศรี",
  "ตะบม",
  "ตะบอง",
  "ตะบอย",
  "ตะบัน",
  "ตะบิด",
  "ตะบิดตะบอย",
  "ตะบิ้ง",
  "ตะบี้ตะบัน",
  "ตะบึง",
  "ตะบูน",
  "ตะปบ",
  "ตะปลิง",
  "ตะปิ้ง",
  "ตะปุ่มตะป่ำ",
  "ตะปู",
  "ตะพง",
  "ตะพด",
  "ตะพอง",
  "ตะพัก",
  "ตะพัง",
  "ตะพัด",
  "ตะพั้น",
  "ตะพาก",
  "ตะพาน",
  "ตะพาบ",
  "ตะพาบน้ำ",
  "ตะพาย",
  "ตะพึด",
  "ตะพึดตะพือ",
  "ตะพุ่น",
  "ตะมอย",
  "ตะม่อ",
  "ตะรังกะนู",
  "ตะรังตังกวาง",
  "ตะรังตังช้าง",
  "ตะราง",
  "ตะลอง",
  "ตะลอน",
  "ตะละ",
  "ตะลาน",
  "ตะลิงปลิง",
  "ตะลิบ",
  "ตะลีตะลาน",
  "ตะลึง",
  "ตะลึงพรึงเพริด",
  "ตะลุง",
  "ตะลุมบอน",
  "ตะลุมพอ",
  "ตะลุมพุก",
  "ตะลุย",
  "ตะลุ่ม",
  "ตะลุ่มนก",
  "ตะลุ่มโปง",
  "ตะล่อม",
  "ตะวัน",
  "ตะหงิด",
  "ตะหลิว",
  "ตะหลุก",
  "ตะหลุง",
  "ตะเกียกตะกาย",
  "ตะเกียง",
  "ตะเกียบ",
  "ตะเข็บ",
  "ตะเข้",
  "ตะเครียว",
  "ตะเคียน",
  "ตะเคียว",
  "ตะเฆ่",
  "ตะเบ็ง",
  "ตะเบ็งมาน",
  "ตะเบ๊ะ",
  "ตะเพรา",
  "ตะเพิง",
  "ตะเพิด",
  "ตะเพียน",
  "ตะเภา",
  "ตะเลง",
  "ตะเวน",
  "ตะแก",
  "ตะแกรง",
  "ตะแก่",
  "ตะแคง",
  "ตะแบก",
  "ตะแบง",
  "ตะแลงแกง",
  "ตะแหง่ว",
  "ตะแหมะแขะ",
  "ตะโก",
  "ตะโกก",
  "ตะโกน",
  "ตะโกรง",
  "ตะโกรม",
  "ตะโก้",
  "ตะโขง",
  "ตะโบม",
  "ตะโพก",
  "ตะโพง",
  "ตะโพน",
  "ตะโหงก",
  "ตะใภ้",
  "ตะไกร",
  "ตะไคร่",
  "ตะไคร้",
  "ตะไบ",
  "ตะไล",
  "ตัก",
  "ตักกะ",
  "ตักตวง",
  "ตักบาตร",
  "ตักษัย",
  "ตักเตือน",
  "ตัง",
  "ตังค์",
  "ตังฉ่าย",
  "ตังวาย",
  "ตังเก",
  "ตังเม",
  "ตังโอ๋",
  "ตัจฉก",
  "ตัจฉนี",
  "ตัณฑุล",
  "ตัณหา",
  "ตัด",
  "ตัดขาด",
  "ตัดตอน",
  "ตัดทอน",
  "ตัดบท",
  "ตัดพ้อ",
  "ตัดรอน",
  "ตัดสิน",
  "ตัดเชือก",
  "ตัดใจ",
  "ตัน",
  "ตันตระ",
  "ตันติ",
  "ตันหยง",
  "ตันเถียน",
  "ตับ",
  "ตับปิ้ง",
  "ตับอ่อน",
  "ตับเต่า",
  "ตับแลบ",
  "ตัว",
  "ตัวกลาง",
  "ตัวการ",
  "ตัวจี๊ด",
  "ตัวดี",
  "ตัวตน",
  "ตัวตั้ง",
  "ตัวตืด",
  "ตัวถัง",
  "ตัวประกอบ",
  "ตัวประกัน",
  "ตัวผู้",
  "ตัวพิมพ์",
  "ตัวยืน",
  "ตัวอย่าง",
  "ตัวเก็ง",
  "ตัวเต็ง",
  "ตัวเมีย",
  "ตัวเลข",
  "ตัวเอง",
  "ตัวแทน",
  "ตัวแปร",
  "ตั่ง",
  "ตั้ง",
  "ตั้งต้น",
  "ตั้งท้อง",
  "ตั้งเข็ม",
  "ตั้งเค้า",
  "ตั้งแง่",
  "ตั้งแต่",
  "ตั้งใจ",
  "ตั้งไข่",
  "ตั้วเหี่ย",
  "ตั้วโผ",
  "ตั๊กม้อ",
  "ตั๊กแตน",
  "ตั๋ว",
  "ตั๋วเงิน",
  "ตั๋วแลกเงิน",
  "ตา",
  "ตาก",
  "ตาก",
  "ตากล้อง",
  "ตากวาง",
  "ตากสิน",
  "ตากาล็อก",
  "ตาข่าย",
  "ตาชั่ง",
  "ตาด",
  "ตาตุ่ม",
  "ตาทวด",
  "ตาน",
  "ตานนกกด",
  "ตานี",
  "ตาบ",
  "ตาปรือ",
  "ตาปลา",
  "ตาม",
  "ตามะแน",
  "ตามิน",
  "ตามใจ",
  "ตาย",
  "ตายซาก",
  "ตายด้าน",
  "ตายตัว",
  "ตายทั้งกลม",
  "ตายห่า",
  "ตายโหง",
  "ตายใจ",
  "ตาราง",
  "ตาราไต",
  "ตาล",
  "ตาลปัตร",
  "ตาลีบัน",
  "ตาลุ",
  "ตาหนู",
  "ตาฬ",
  "ตาเมือน",
  "ตาเสือ",
  "ตาไก่",
  "ตำ",
  "ตำนาน",
  "ตำบล",
  "ตำรวจ",
  "ตำรับ",
  "ตำรา",
  "ตำรุ",
  "ตำลึง",
  "ตำส้ม",
  "ตำหนัก",
  "ตำหนิ",
  "ตำเสา",
  "ตำแบ",
  "ตำแย",
  "ตำแหน่ง",
  "ติ",
  "ติก",
  "ติกะ",
  "ติกาหรัง",
  "ติง",
  "ติงต๊อง",
  "ติงส",
  "ติงสติ",
  "ติณ",
  "ติณชาติ",
  "ติด",
  "ติดขัด",
  "ติดตั้ง",
  "ติดตาม",
  "ติดตื้น",
  "ติดต่อ",
  "ติดพัน",
  "ติดลม",
  "ติดอ่าง",
  "ติดใจ",
  "ติตติกะ",
  "ติตติร",
  "ติตถ",
  "ติตถะ",
  "ติถี",
  "ติมอร์",
  "ติมิงคละ",
  "ติรัจฉาน",
  "ติลก",
  "ติละ",
  "ติว",
  "ติวเตอร์",
  "ติเตียน",
  "ติ่ง",
  "ติ่งตั่ง",
  "ติ่มซำ",
  "ติ้ว",
  "ติ๊ก",
  "ติ๋ง",
  "ติ๋งต่าง",
  "ติ๋ม",
  "ตี",
  "ตีขลุม",
  "ตีความ",
  "ตีคู่",
  "ตีจาก",
  "ตีตื้น",
  "ตีน",
  "ตีนกา",
  "ตีนคู้",
  "ตีนจก",
  "ตีนตะขาบ",
  "ตีนผี",
  "ตีนเหยียด",
  "ตีบ",
  "ตีรถะ",
  "ตีรวน",
  "ตีระ",
  "ตีลังกา",
  "ตีวง",
  "ตีเกลียว",
  "ตีเสมอ",
  "ตีแผ่",
  "ตี่",
  "ตี๋",
  "ตึ",
  "ตึก",
  "ตึกระฟ้า",
  "ตึกแถว",
  "ตึง",
  "ตึงตัง",
  "ตึงเครียด",
  "ตึดตื๋อ",
  "ตึ้ก",
  "ตึ้กตั้ก",
  "ตึ๊ดตื๋อ",
  "ตืด",
  "ตื่น",
  "ตื่นตัว",
  "ตื่นตูม",
  "ตื่นเต้น",
  "ตื้น",
  "ตื้นตัน",
  "ตื้บ",
  "ตื้อ",
  "ตื๊อ",
  "ตื๋อ",
  "ตุ",
  "ตุกติก",
  "ตุง",
  "ตุน",
  "ตุนาหงัน",
  "ตุบ",
  "ตุปัดตุป่อง",
  "ตุปัดตุเป๋",
  "ตุมกา",
  "ตุมตัง",
  "ตุมพะ",
  "ตุรกี",
  "ตุรคะ",
  "ตุรงค์",
  "ตุล",
  "ตุลา",
  "ตุลา",
  "ตุลาการ",
  "ตุลาคม",
  "ตุหรัดตุเหร่",
  "ตุ่น",
  "ตุ่ม",
  "ตุ่ย",
  "ตุ้งก่า",
  "ตุ้งติ้ง",
  "ตุ้บ",
  "ตุ้บตั้บ",
  "ตุ้ม",
  "ตุ้มกว้าว",
  "ตุ้มปี่",
  "ตุ้มหู",
  "ตุ้มเต๋น",
  "ตุ้มแซะ",
  "ตุ้ย",
  "ตุ๊",
  "ตุ๊ก",
  "ตุ๊กตา",
  "ตุ๊กติ๊ก",
  "ตุ๊กตุ่น",
  "ตุ๊กตุ๊ก",
  "ตุ๊กตุ๋ย",
  "ตุ๊กต่ำ",
  "ตุ๊กแก",
  "ตุ๊ด",
  "ตุ๊ดตู่",
  "ตุ๊ต๊ะ",
  "ตุ๊บป่อง",
  "ตุ๊ย",
  "ตุ๋น",
  "ตุ๋ม",
  "ตุ๋ย",
  "ตุ๋ยตุ่ย",
  "ตู",
  "ตูก",
  "ตูด",
  "ตูน",
  "ตูนิเซีย",
  "ตูบ",
  "ตูม",
  "ตูมตาม",
  "ตูวาลู",
  "ตู่",
  "ตู้",
  "ตู้นิรภัย",
  "ตู้เซฟ",
  "ตู้เสบียง",
  "ต่วน",
  "ต่อ",
  "ต่อกร",
  "ต่องแต่ง",
  "ต่อตี",
  "ต่อต้าน",
  "ต่อม",
  "ต่อย",
  "ต่อยหอย",
  "ต่อยอด",
  "ต่อรอง",
  "ต่อว่า",
  "ต่อสู้",
  "ต่อเติม",
  "ต่อแย้ง",
  "ต่อไส้",
  "ต่าง",
  "ต่างหาก",
  "ต่าน",
  "ต่ำ",
  "ต่ำช้า",
  "ต่ำต้อย",
  "ต้น",
  "ต้นขั้ว",
  "ต้นคิด",
  "ต้นฉบับ",
  "ต้นตอ",
  "ต้นตำรับ",
  "ต้นทุน",
  "ต้นมือ",
  "ต้นร่าง",
  "ต้นสังกัด",
  "ต้นหน",
  "ต้นเพลิง",
  "ต้นเรื่อง",
  "ต้นเหตุ",
  "ต้นแบบ",
  "ต้นไม้",
  "ต้ม",
  "ต้มข่า",
  "ต้มยำ",
  "ต้มส้ม",
  "ต้มโคล้ง",
  "ต้วมเตี้ยม",
  "ต้อ",
  "ต้อง",
  "ต้องการ",
  "ต้องหา",
  "ต้องเต",
  "ต้องโทษ",
  "ต้อน",
  "ต้อนรับ",
  "ต้อย",
  "ต้อยตริ่ง",
  "ต้อยติ่ง",
  "ต้อยตีวิด",
  "ต้าน",
  "ต้านทาน",
  "ต้าอ่วย",
  "ต๊กโต",
  "ต๊อก",
  "ต๊อกต๋อย",
  "ต๊อง",
  "ต๋ง",
  "ต๋อง",
  "ต๋อม",
  "ต๋าว",
  "ถก",
  "ถกล",
  "ถกเถียง",
  "ถงาด",
  "ถด",
  "ถดถอย",
  "ถนน",
  "ถนอม",
  "ถนัด",
  "ถนัดถนี่",
  "ถนัน",
  "ถนำ",
  "ถนิม",
  "ถนิมสร้อย",
  "ถม",
  "ถมถืด",
  "ถมอ",
  "ถมึงทึง",
  "ถมเถ",
  "ถมไป",
  "ถลก",
  "ถลกบาตร",
  "ถลน",
  "ถลอก",
  "ถลัน",
  "ถลา",
  "ถลาก",
  "ถลากไถล",
  "ถลาง",
  "ถลาย",
  "ถลำ",
  "ถลึงตา",
  "ถลุง",
  "ถล่ม",
  "ถวัล",
  "ถวัลย์",
  "ถวาย",
  "ถวิน",
  "ถวิล",
  "ถอก",
  "ถอง",
  "ถอด",
  "ถอดถอน",
  "ถอน",
  "ถอบ",
  "ถอบแถบ",
  "ถอย",
  "ถะ",
  "ถะถั่น",
  "ถะมัดถะแมง",
  "ถัก",
  "ถัง",
  "ถังซัมจั๋ง",
  "ถังซำจั๋ง",
  "ถัด",
  "ถัทธ",
  "ถัน",
  "ถับ",
  "ถัมภ์",
  "ถัว",
  "ถั่ง",
  "ถั่น",
  "ถั่ว",
  "ถา",
  "ถาก",
  "ถากถาง",
  "ถาง",
  "ถาด",
  "ถาน",
  "ถาบ",
  "ถาม",
  "ถามะ",
  "ถามไถ่",
  "ถาวร",
  "ถาวรธิรา",
  "ถาวรวัตถุ",
  "ถิ่น",
  "ถีบ",
  "ถี่",
  "ถี่ถ้วน",
  "ถึก",
  "ถึง",
  "ถึงใจ",
  "ถือ",
  "ถุง",
  "ถุน",
  "ถุย",
  "ถู",
  "ถูก",
  "ถูกชะตา",
  "ถูกต้อง",
  "ถูกใจ",
  "ถ่ม",
  "ถ่วง",
  "ถ่อ",
  "ถ่อง",
  "ถ่องแท้",
  "ถ่อม",
  "ถ่อย",
  "ถ่าง",
  "ถ่าน",
  "ถ่านหิน",
  "ถ่านไฟฉาย",
  "ถ่าย",
  "ถ่ายทอด",
  "ถ่ายทำ",
  "ถ่ายทุกข์",
  "ถ่ายเท",
  "ถ่าว",
  "ถ้วน",
  "ถ้วนถี่",
  "ถ้วย",
  "ถ้วยฟู",
  "ถ้อ",
  "ถ้อง",
  "ถ้อย",
  "ถ้อยคำ",
  "ถ้อยแถลง",
  "ถ้า",
  "ถ้ำ",
  "ถ้ำมอง",
  "ท.จ.",
  "ท.จ.ว.",
  "ท.ช.",
  "ท.ญ.",
  "ท.ด.",
  "ท.ท.ท.",
  "ท.ทบ.",
  "ท.บ.",
  "ท.พ.",
  "ท.ม.",
  "ท.ศ.",
  "ทก.ด.",
  "ทก.บ.",
  "ทก.ม.",
  "ทกล้า",
  "ทงคัตสึ",
  "ทด",
  "ทดรอง",
  "ทดลอง",
  "ทดสอบ",
  "ทดแทน",
  "ทน",
  "ทนง",
  "ทนต์",
  "ทนทาน",
  "ทนาย",
  "ทนายความ",
  "ทนโท่",
  "ทบ",
  "ทบทวน",
  "ทบวง",
  "ทมอ",
  "ทมะ",
  "ทมิฬ",
  "ทมิฬนาฑู",
  "ทยอย",
  "ทรกรรม",
  "ทรง",
  "ทรงกลด",
  "ทรงเครื่อง",
  "ทรงเจ้า",
  "ทรชน",
  "ทรชาติ",
  "ทรพา",
  "ทรพิษ",
  "ทรพี",
  "ทรมาณ",
  "ทรมาทรกรรม",
  "ทรมาน",
  "ทรยศ",
  "ทรรทึง",
  "ทรรป",
  "ทรรปณะ",
  "ทรรปณ์",
  "ทรรศนะ",
  "ทรรศนาการ",
  "ทรรศนีย์",
  "ทรราช",
  "ทรลักษณ์",
  "ทรวง",
  "ทรวดทรง",
  "ทรวาร",
  "ทรหด",
  "ทรหวล",
  "ทรหึง",
  "ทรอมโบน",
  "ทรอย",
  "ทระนง",
  "ทรัพยากร",
  "ทรัพย์",
  "ทรัพย์สิน",
  "ทรัมเป็ต",
  "ทราก",
  "ทรานซิสเตอร์",
  "ทรานส์",
  "ทรานแซกชัน",
  "ทรานแซกชั่น",
  "ทรานแซคชัน",
  "ทรานแซคชั่น",
  "ทรานแซ็กชัน",
  "ทรานแซ็กชั่น",
  "ทรานแซ็คชัน",
  "ทรานแซ็คชั่น",
  "ทราบ",
  "ทราม",
  "ทรามชม",
  "ทรามวัย",
  "ทรามสงวน",
  "ทรามสวาท",
  "ทรามเชย",
  "ทราย",
  "ทราวิฑ",
  "ทราเวล",
  "ทริป",
  "ทริโปลี",
  "ทรุด",
  "ทรุดโทรม",
  "ทรู",
  "ทรูมูฟ",
  "ทฤษฎี",
  "ทฤษฎีบท",
  "ทลาย",
  "ทลิท",
  "ทลิททก",
  "ทวง",
  "ทวด",
  "ทวน",
  "ทวย",
  "ทวอย",
  "ทวัตดึงส์",
  "ทวัย",
  "ทวา",
  "ทวาทศ",
  "ทวาทศมาส",
  "ทวาบร",
  "ทวาบรยุค",
  "ทวาย",
  "ทวาร",
  "ทวารบาล",
  "ทวารวดี",
  "ทวาราวดี",
  "ทวิ",
  "ทวิช",
  "ทวิตียา",
  "ทวิตีย์",
  "ทวิตเตอร์",
  "ทวิบถ",
  "ทวิบท",
  "ทวิบาท",
  "ทวิภาค",
  "ทวิภาคี",
  "ทวี",
  "ทวีคูณ",
  "ทวีต",
  "ทวีธาภิเษก",
  "ทวีป",
  "ทว่า",
  "ทศ",
  "ทศกัณฐ์",
  "ทศชาติ",
  "ทศทิศ",
  "ทศนิยม",
  "ทศพร",
  "ทศพล",
  "ทศพิธราชธรรม",
  "ทศมาส",
  "ทศมี",
  "ทศวรรษ",
  "ทศางค์",
  "ทส.ปช.",
  "ทส.รมว.กห.",
  "ทหระ",
  "ทหาร",
  "ทอ",
  "ทอก",
  "ทอง",
  "ทองกวาว",
  "ทองขาว",
  "ทองคำ",
  "ทองคำขาว",
  "ทองคำเปลว",
  "ทองบรอนซ์",
  "ทองภู",
  "ทองม้วน",
  "ทองย้อย",
  "ทองลิน",
  "ทองสัมฤทธิ์",
  "ทองหยอด",
  "ทองหยิบ",
  "ทองหลาง",
  "ทองอุไร",
  "ทองเค",
  "ทองเหลือง",
  "ทองเอก",
  "ทองแดง",
  "ทองโหลง",
  "ทอด",
  "ทอดทิ้ง",
  "ทอดน่อง",
  "ทอดมัน",
  "ทอดยอด",
  "ทอดหุ่ย",
  "ทอน",
  "ทอนซิล",
  "ทอฟฟี่",
  "ทอม",
  "ทอย",
  "ทอยกอง",
  "ทอร์นาโด",
  "ทอล์ค",
  "ทอเรียม",
  "ทะ",
  "ทะงัน",
  "ทะนง",
  "ทะนน",
  "ทะนาน",
  "ทะนุ",
  "ทะมัดทะแมง",
  "ทะมึน",
  "ทะมื่น",
  "ทะยาน",
  "ทะร่อทะแร่",
  "ทะลวง",
  "ทะลอก",
  "ทะลัก",
  "ทะลาย",
  "ทะลึ่ง",
  "ทะลุ",
  "ทะลุดทะลาด",
  "ทะวาย",
  "ทะเบียน",
  "ทะเบียนบ้าน",
  "ทะเยอทะยาน",
  "ทะเล",
  "ทะเลทราย",
  "ทะเลสาบ",
  "ทะเลหลวง",
  "ทะเลาะ",
  "ทะเลิ่กทะลั่ก",
  "ทะเล่อทะล่า",
  "ทะเล้น",
  "ทะแม่ง",
  "ทะแย",
  "ทะแยง",
  "ทัก",
  "ทักขิญ",
  "ทักขิณ",
  "ทักขิณา",
  "ทักขิณาวัฏ",
  "ทักขิณาวัฏ",
  "ทักขิโณทก",
  "ทักขิไณยบุคคล",
  "ทักข์",
  "ทักซิโด",
  "ทักทาย",
  "ทักทิน",
  "ทักท้วง",
  "ทักษะ",
  "ทักษา",
  "ทักษิณ",
  "ทักษิณา",
  "ทักษิณาทาน",
  "ทักษิณานุประทาน",
  "ทักษิณาวรรต",
  "ทักษิโณทก",
  "ทัง",
  "ทังวล",
  "ทังวี้ทังวล",
  "ทังสเตน",
  "ทัชแพด",
  "ทัณฑกรรม",
  "ทัณฑฆาต",
  "ทัณฑสถาน",
  "ทัณฑะ",
  "ทัณฑิกา",
  "ทัณฑิมา",
  "ทัณฑีบท",
  "ทัณฑ์",
  "ทัณฑ์บน",
  "ทัด",
  "ทัดทา",
  "ทัดทาน",
  "ทัดเทียม",
  "ทัต",
  "ทัน",
  "ทันควัน",
  "ทันตกรรม",
  "ทันตชะ",
  "ทันตา",
  "ทันติน",
  "ทันตี",
  "ทันตแพทยศาสตร์",
  "ทันตแพทย์",
  "ทันต์",
  "ทันที",
  "ทันธ์",
  "ทันสมัย",
  "ทันใจ",
  "ทันใด",
  "ทับ",
  "ทับซ้อน",
  "ทับถม",
  "ทับทรวง",
  "ทับทิม",
  "ทับศัพท์",
  "ทับสมิงคลา",
  "ทัพ",
  "ทัพพะ",
  "ทัพพี",
  "ทัวริสต์",
  "ทัวร์",
  "ทัวร์นาเมนต์",
  "ทัวร์นาเมนท์",
  "ทัศ",
  "ทัศนคติ",
  "ทัศนวิสัย",
  "ทัศนศาสตร์",
  "ทัศนศิลป์",
  "ทัศนศึกษา",
  "ทัศนะ",
  "ทัศนา",
  "ทัศนาการ",
  "ทัศนาจร",
  "ทัศนียภาพ",
  "ทัศนีย์",
  "ทัศนูปกรณ์",
  "ทัศน์",
  "ทัศไนย",
  "ทัสนานุตริยะ",
  "ทัฬหะ",
  "ทัฬหิ",
  "ทัฬหี",
  "ทั่ง",
  "ทั่ว",
  "ทั่วถึง",
  "ทั่วไป",
  "ทั้ง",
  "ทั้งกลม",
  "ทั้งคน",
  "ทั้งดุ้น",
  "ทั้งที",
  "ทั้งนั้น",
  "ทั้งนี้",
  "ทั้งปวง",
  "ทั้งผอง",
  "ทั้งมวล",
  "ทั้งสิ้น",
  "ทั้งหมด",
  "ทั้งหลาย",
  "ทั้งเพ",
  "ทา",
  "ทาก",
  "ทาง",
  "ทางการ",
  "ทางข้าม",
  "ทางด่วน",
  "ทางผ่าน",
  "ทางม้าลาย",
  "ทางหลวง",
  "ทางออก",
  "ทางเท้า",
  "ทางเอก",
  "ทางโท",
  "ทางใน",
  "ทาจิกิสถาน",
  "ทาฐะ",
  "ทาฐิกะ",
  "ทาฒะ",
  "ทาฒิกะ",
  "ทาน",
  "ทานกัณฑ์",
  "ทานตะวัน",
  "ทานต์",
  "ทานบารมี",
  "ทานพ",
  "ทาบ",
  "ทาบทาม",
  "ทาม",
  "ทามิฟลู",
  "ทาย",
  "ทายก",
  "ทายัช",
  "ทายาด",
  "ทายาท",
  "ทายิกา",
  "ทารก",
  "ทารพี",
  "ทาริกา",
  "ทารุณ",
  "ทารุณกรรม",
  "ทาว",
  "ทาวน์",
  "ทาวน์เฮาส์",
  "ทาส",
  "ทาสี",
  "ทาเลนต์",
  "ทำ",
  "ทำคลอด",
  "ทำงาน",
  "ทำซ้ำ",
  "ทำที",
  "ทำท่า",
  "ทำนบ",
  "ทำนอง",
  "ทำนาย",
  "ทำนุ",
  "ทำนูล",
  "ทำบาป",
  "ทำบุญ",
  "ทำพิษ",
  "ทำฟัน",
  "ทำร้าย",
  "ทำลาย",
  "ทำวัตร",
  "ทำสาว",
  "ทำหมัน",
  "ทำเนา",
  "ทำเนียบ",
  "ทำเล",
  "ทำเสน่ห์",
  "ทำแท้ง",
  "ทำโทษ",
  "ทำใจ",
  "ทำให้",
  "ทำไม",
  "ทิคัมพร",
  "ทิฆัมพร",
  "ทิงเจอร์",
  "ทิชะ",
  "ทิชากร",
  "ทิชาชาติ",
  "ทิฏฐะ",
  "ทิฏฐานุคติ",
  "ทิฏฐิ",
  "ทิฏฐุชุกรรม",
  "ทิฐธรรม",
  "ทิฐิ",
  "ทิด",
  "ทิต",
  "ทิน",
  "ทินกร",
  "ทิป",
  "ทิพ",
  "ทิพจักขุ",
  "ทิพยจักษุ",
  "ทิพยญาณ",
  "ทิพยรส",
  "ทิพยสมบัติ",
  "ทิพยเนตร",
  "ทิพย์",
  "ทิพา",
  "ทิพากร",
  "ทิพโสต",
  "ทิม",
  "ทิมทอง",
  "ทิว",
  "ทิวงคต",
  "ทิวทัศน์",
  "ทิวลิป",
  "ทิวา",
  "ทิวากร",
  "ทิวากาล",
  "ทิศ",
  "ทิศทาง",
  "ทิศา",
  "ทิศานุทิศ",
  "ทิเบต",
  "ทิ่ม",
  "ทิ่มตำ",
  "ทิ่มแทง",
  "ทิ้ง",
  "ทิ้งขว้าง",
  "ทิ้งถ่อน",
  "ทิ้งทวน",
  "ทิ้งทูด",
  "ทิ้งท้าย",
  "ที",
  "ที",
  "ทีฆชาติ",
  "ทีฆนิกาย",
  "ทีฆนิกาย",
  "ทีฆสระ",
  "ทีฆสระ",
  "ทีฆายุ",
  "ทีท่า",
  "ทีนี้",
  "ทีป",
  "ทีปังกร",
  "ทีม",
  "ทีรามิสุ",
  "ทีวี",
  "ทีหลัง",
  "ทีเด็ด",
  "ทีเอ็นที",
  "ที่",
  "ที่ดิน",
  "ที่นอน",
  "ที่นั่ง",
  "ที่ปรึกษา",
  "ที่พึ่ง",
  "ที่มั่น",
  "ที่ราบ",
  "ที่ว่าการ",
  "ที่สุด",
  "ที่หมาย",
  "ที่ไหน",
  "ทึก",
  "ทึกทัก",
  "ทึดทือ",
  "ทึนทึก",
  "ทึบ",
  "ทึม",
  "ทึ่ง",
  "ทึ่ม",
  "ทึ้ง",
  "ทื่อ",
  "ทุ",
  "ทุ.ส.นิ.ม.",
  "ทุก",
  "ทุกขลาภ",
  "ทุกขารมณ์",
  "ทุกขเวทนา",
  "ทุกข์",
  "ทุกข์สุข",
  "ทุกฏ",
  "ทุกที",
  "ทุกรกิริยา",
  "ทุกะ",
  "ทุกัง",
  "ทุกูล",
  "ทุกเมื่อ",
  "ทุคตะ",
  "ทุคติ",
  "ทุงงะ",
  "ทุจริต",
  "ทุด",
  "ทุทรรศนนิยม",
  "ทุน",
  "ทุนทรัพย์",
  "ทุนนิมิต",
  "ทุนนิยม",
  "ทุนรอน",
  "ทุนสำรอง",
  "ทุนิยม",
  "ทุบ",
  "ทุบทู",
  "ทุปปัญญา",
  "ทุพพรรณ",
  "ทุพพล",
  "ทุพพลภาพ",
  "ทุพภิกขภัย",
  "ทุม",
  "ทุย",
  "ทุรกันดาร",
  "ทุรชน",
  "ทุรชาติ",
  "ทุรน",
  "ทุรนทุราย",
  "ทุรพล",
  "ทุรลักษณ์",
  "ทุรัศ",
  "ทุราคม",
  "ทุราจาร",
  "ทุลักทุเล",
  "ทุศีล",
  "ทุสสะ",
  "ทุสสีล",
  "ทุเรศ",
  "ทุเรียน",
  "ทุเลา",
  "ทุ่ง",
  "ทุ่น",
  "ทุ่ม",
  "ทุ่มเถียง",
  "ทุ่มเท",
  "ทุ้ง",
  "ทุ้ม",
  "ทุ้ย",
  "ทู",
  "ทูกัง",
  "ทูต",
  "ทูตานุทูต",
  "ทูน",
  "ทูนหัว",
  "ทูน่า",
  "ทูบ",
  "ทูม",
  "ทูล",
  "ทูลกระหม่อม",
  "ทูเลียม",
  "ทูโม่ง",
  "ทู่",
  "ทู่ซี้",
  "ทู้",
  "ทแกล้ว",
  "ทแยง",
  "ทแยงมุม",
  "ทโมน",
  "ท็อป",
  "ท่ง",
  "ท่วงทำนอง",
  "ท่วงที",
  "ท่วงท่า",
  "ท่วม",
  "ท่วมท้น",
  "ท่วย",
  "ท่อ",
  "ท่อง",
  "ท่องเที่ยว",
  "ท่อน",
  "ท่อม",
  "ท่อไอเสีย",
  "ท่า",
  "ท่าทาง",
  "ท่าที",
  "ท่าน",
  "ท่านชาย",
  "ท่านผู้หญิง",
  "ท่านหญิง",
  "ท่ามกลาง",
  "ท่าว",
  "ท้น",
  "ท้วง",
  "ท้วงติง",
  "ท้วน",
  "ท้วม",
  "ท้วย",
  "ท้อ",
  "ท้อง",
  "ท้องตรา",
  "ท้องถิ่น",
  "ท้องที่",
  "ท้องน้อย",
  "ท้องร่อง",
  "ท้องเรื่อง",
  "ท้อถอย",
  "ท้อแท้",
  "ท้า",
  "ท้าง",
  "ท้าทาย",
  "ท้าย",
  "ท้ายทอย",
  "ท้าว",
  "ธ.ก.ส.",
  "ธ.ค.",
  "ธ.ญ",
  "ธ.บ.",
  "ธง",
  "ธงก์",
  "ธงชัย",
  "ธงชาติ",
  "ธงทิว",
  "ธชะ",
  "ธชี",
  "ธตรฐ",
  "ธนบัตร",
  "ธนบุรี",
  "ธนสมบัติ",
  "ธนสาร",
  "ธนะ",
  "ธนา",
  "ธนาคม",
  "ธนาคาร",
  "ธนาณัติ",
  "ธนิต",
  "ธนิษฐะ",
  "ธนิษฐา",
  "ธนุ",
  "ธนุรวิทยา",
  "ธนุรเวท",
  "ธนู",
  "ธม",
  "ธมกรก",
  "ธรณะ",
  "ธรณิน",
  "ธรณินทร์",
  "ธรณิศ",
  "ธรณิศร",
  "ธรณิศวร์",
  "ธรณี",
  "ธรณีวิทยา",
  "ธรณีสงฆ์",
  "ธรมาน",
  "ธรรม",
  "ธรรมกาย",
  "ธรรมการ",
  "ธรรมขันธ์",
  "ธรรมคุณ",
  "ธรรมจรรยา",
  "ธรรมจริยา",
  "ธรรมจักร",
  "ธรรมจักษุ",
  "ธรรมจาคะ",
  "ธรรมจารี",
  "ธรรมชาติ",
  "ธรรมดา",
  "ธรรมนูญ",
  "ธรรมยุต",
  "ธรรมยุติกนิกาย",
  "ธรรมราชา",
  "ธรรมศาสตร์",
  "ธรรมสภา",
  "ธรรมสังเวช",
  "ธรรมะ",
  "ธรรมา",
  "ธรรมาทิตย์",
  "ธรรมาธรรม",
  "ธรรมาธิปไตย",
  "ธรรมาธิษฐาน",
  "ธรรมานุสาร",
  "ธรรมาภิบาล",
  "ธรรมาภิมุข",
  "ธรรมาภิสมัย",
  "ธรรมายตนะ",
  "ธรรมารมณ์",
  "ธรรมาสน์",
  "ธรรมิก",
  "ธรรมเกษตร",
  "ธรรมเนียม",
  "ธรา",
  "ธราดล",
  "ธราธร",
  "ธราธาร",
  "ธราธิบดี",
  "ธราธิป",
  "ธริษตรี",
  "ธวัช",
  "ธัช",
  "ธัญ",
  "ธัญญาหาร",
  "ธัญบุรี",
  "ธัญพืช",
  "ธันยา",
  "ธันยาวาท",
  "ธันวา",
  "ธันวาคม",
  "ธัมมะ",
  "ธัมโม",
  "ธากา",
  "ธาดา",
  "ธาตรี",
  "ธาตวากร",
  "ธาตุ",
  "ธาตุมมิสสา",
  "ธาตุโขภ",
  "ธานิน",
  "ธานินทร์",
  "ธานี",
  "ธาร",
  "ธารกำนัล",
  "ธารคำนัล",
  "ธารณะ",
  "ธารณา",
  "ธารพระกร",
  "ธารา",
  "ธาลัสซีเมีย",
  "ธาษตรี",
  "ธำมรงค์",
  "ธำรง",
  "ธิดา",
  "ธิติ",
  "ธีม",
  "ธีรภาพ",
  "ธีรราช",
  "ธีระ",
  "ธีออส",
  "ธุช",
  "ธุดงควัตร",
  "ธุดงค์",
  "ธุต",
  "ธุตตะ",
  "ธุมา",
  "ธุมเกตุ",
  "ธุรกรรม",
  "ธุรการ",
  "ธุรกิจ",
  "ธุระ",
  "ธุรำ",
  "ธุลี",
  "ธุวดารา",
  "ธุวภาค",
  "ธุวมณฑล",
  "ธุหร่ำ",
  "ธูป",
  "ธเนศ",
  "ธเรศ",
  "ธเรษตรี",
  "ธโนปจัย",
  "ธไนศวรรย์",
  "น.ช.",
  "น.ญ.",
  "น.ด.",
  "น.ต.",
  "น.ท.",
  "น.น.",
  "น.บ.",
  "น.บ.ท.",
  "น.ป.ท.",
  "น.พ.",
  "น.ม.",
  "น.ร.",
  "น.ว.",
  "น.ศ.",
  "น.ส.",
  "น.ส.พ.",
  "น.ส.๓",
  "น.สพ.",
  "น.อ.",
  "นก",
  "นกต่อ",
  "นกยูง",
  "นกรู้",
  "นกหวีด",
  "นกุล",
  "นกเขา",
  "นขลิขิต",
  "นขะ",
  "นขา",
  "นคร",
  "นครนายก",
  "นครบาล",
  "นครปฐม",
  "นครพนม",
  "นครรัฐ",
  "นครราชสีมา",
  "นครศรีธรรมราช",
  "นครสวรรค์",
  "นครินทรา",
  "นครินทร์",
  "นคเรศ",
  "นง",
  "นงคราญ",
  "นงคุฐ",
  "นงนุช",
  "นงพะงา",
  "นงราม",
  "นงลักษณ์",
  "นงเยาว์",
  "นที",
  "นนตรา",
  "นนทบุรี",
  "นนทรี",
  "นนทลี",
  "นนทิ",
  "นนท์",
  "นบ",
  "นบนอบ",
  "นปพ.ภ.",
  "นปุงสกลิงค์",
  "นปุงสกลึงค์",
  "นพ",
  "นพคุณ",
  "นพดล",
  "นพนิต",
  "นพปฎล",
  "นพพล",
  "นพมาศ",
  "นพรัตน์",
  "นพศก",
  "นพศูล",
  "นพเก้า",
  "นพเคราะห์",
  "นภจร",
  "นภดล",
  "นภศูล",
  "นภา",
  "นภาลัย",
  "นม",
  "นมข้น",
  "นมตำเรีย",
  "นมตำเลีย",
  "นมนาน",
  "นมผง",
  "นมหนู",
  "นมะ",
  "นมัสการ",
  "นมาซ",
  "นมแมว",
  "นมไม้",
  "นยนะ",
  "นยนา",
  "นรก",
  "นรกานต์",
  "นรการ",
  "นรชาติ",
  "นรนาถ",
  "นรบดี",
  "นรบาล",
  "นรสิงห์",
  "นรสีห์",
  "นรา",
  "นรากร",
  "นราดูร",
  "นราธิป",
  "นราธิวาส",
  "นรินทร์",
  "นริศ",
  "นริศร",
  "นริศวร",
  "นรี",
  "นรีเวช",
  "นรีเวชวิทยา",
  "นรีแพทย์",
  "นรเทพ",
  "นฤคหิต",
  "นฤตยสถาน",
  "นฤตย์",
  "นฤนาท",
  "นฤบดี",
  "นฤบาล",
  "นฤปัตนี",
  "นฤปเวศม์",
  "นฤพาน",
  "นฤมล",
  "นฤมาณ",
  "นฤมิต",
  "นฤเทพ",
  "นฤเบศ",
  "นลาฏ",
  "นลิน",
  "นลินี",
  "นวกรรม",
  "นวกะ",
  "นวการ",
  "นวกิจ",
  "นวด",
  "นวดฟั้น",
  "นวนิยาย",
  "นวปฎล",
  "นวม",
  "นวมี",
  "นวย",
  "นวยนาด",
  "นวรัตน์",
  "นวล",
  "นวลจันทร์",
  "นวลระหง",
  "นวลลออ",
  "นวลละออง",
  "นวัตกรรม",
  "นวาระ",
  "นวโกวาท",
  "นวโลหะ",
  "นศ.ด.",
  "นศ.บ.",
  "นศ.ม.",
  "นหารุ",
  "นหุต",
  "นฬการ",
  "นอ",
  "นอก",
  "นอกครู",
  "นอกคอก",
  "นอกจาก",
  "นอกชาน",
  "นอกรีต",
  "นอกเหนือ",
  "นอกใจ",
  "นอง",
  "นองเนือง",
  "นองเลือด",
  "นอต",
  "นอน",
  "นอนก้น",
  "นอนเล่น",
  "นอนใจ",
  "นอบ",
  "นอบนบ",
  "นอบน้อม",
  "นอมินี",
  "นอร์ท",
  "นอร์วีเจียน",
  "นอร์เวย์",
  "นอสตราดามุส",
  "นะ",
  "นะแน่ง",
  "นัก",
  "นักการ",
  "นักการเมือง",
  "นักกีฬา",
  "นักขัต",
  "นักขัตฤกษ์",
  "นักข่าว",
  "นักท่องเที่ยว",
  "นักธรรม",
  "นักบวช",
  "นักบิน",
  "นักบุญ",
  "นักปราชญ์",
  "นักพรต",
  "นักรบ",
  "นักวิชาการ",
  "นักศึกษา",
  "นักษัตร",
  "นักสราช",
  "นักสิทธิ์",
  "นักสืบ",
  "นักหนา",
  "นักเทศน์",
  "นักเรียน",
  "นักเลง",
  "นักโทษ",
  "นัข",
  "นังคัล",
  "นัจ",
  "นัฑ",
  "นัด",
  "นัดดา",
  "นัดหมาย",
  "นัดแนะ",
  "นัตถุ์",
  "นันททายี",
  "นันทนาการ",
  "นันทน์",
  "นันทวัน",
  "นันทิ",
  "นับ",
  "นับถือ",
  "นับประสา",
  "นัย",
  "นัยนา",
  "นัยน์",
  "นัยน์ตา",
  "นัว",
  "นัวเนีย",
  "นั่ง",
  "นั่งร้าน",
  "นั่งเทียน",
  "นั่น",
  "นั่นเอง",
  "นั่นแหละ",
  "นั้น",
  "นา",
  "นาก",
  "นากบุด",
  "นากาสาหรี",
  "นาค",
  "นาคบาศ",
  "นาคปรก",
  "นาคร",
  "นาคราช",
  "นาคา",
  "นาคาวโลก",
  "นาคินทร์",
  "นาคี",
  "นาง",
  "นางกวัก",
  "นางกำนัล",
  "นางงาม",
  "นางนวล",
  "นางนูน",
  "นางบำเรอ",
  "นางพญา",
  "นางฟ้า",
  "นางรม",
  "นางรำ",
  "นางล้อม",
  "นางสาว",
  "นางหงส์",
  "นางห้าม",
  "นางอาย",
  "นางาซากิ",
  "นางเกล็ด",
  "นางเลิ้ง",
  "นางเล็ด",
  "นางเอก",
  "นางแบบ",
  "นางแบบ",
  "นางแอ่น",
  "นางโลม",
  "นางใน",
  "นางไม้",
  "นาซา",
  "นาซี",
  "นาซ่า",
  "นาฏ",
  "นาฏกรรม",
  "นาฏกะ",
  "นาฏดนตรี",
  "นาฏยศาลา",
  "นาฏศิลป์",
  "นาด",
  "นาดำ",
  "นาถ",
  "นาท",
  "นาที",
  "นาน",
  "นานนม",
  "นานัครส",
  "นานัปการ",
  "นานา",
  "นาบ",
  "นาปรัง",
  "นาปี",
  "นาภี",
  "นาม",
  "นามกร",
  "นามธรรม",
  "นามบัตร",
  "นามปากกา",
  "นามสกุล",
  "นามสงเคราะห์",
  "นามสมญา",
  "นามานุกรม",
  "นามาภิไธย",
  "นามิเบีย",
  "นามแฝง",
  "นามไธย",
  "นาย",
  "นายก",
  "นายทะเบียน",
  "นายทุน",
  "นายท่า",
  "นายท้าย",
  "นายประกัน",
  "นายพราน",
  "นายหน้า",
  "นายอำเภอ",
  "นายิกา",
  "นายแบบ",
  "นารา",
  "นารายณ์",
  "นารายณ์หัตถ์",
  "นาริตะ",
  "นารี",
  "นารีผล",
  "นาลันทา",
  "นาลิวัน",
  "นาลแก",
  "นาว",
  "นาวา",
  "นาวิก",
  "นาวิกโยธิน",
  "นาวิน",
  "นาวี",
  "นาศ",
  "นาสวน",
  "นาสา",
  "นาสิก",
  "นาหว่าน",
  "นาฬิกา",
  "นาฬิเก",
  "นาฬี",
  "นาอูรู",
  "นาเคนทร์",
  "นาเคศวร",
  "นาเนก",
  "นาเรศ",
  "นาเวศ",
  "นาโต",
  "นาโต้",
  "นาโน",
  "นำ",
  "นำจับ",
  "นำทาง",
  "นำพา",
  "นำร่อง",
  "นำสืบ",
  "นำแสดง",
  "นิ",
  "นิกขะ",
  "นิกร",
  "นิกรอยด์",
  "นิกาย",
  "นิการากัว",
  "นิกเกิล",
  "นิคม",
  "นิครนถ์",
  "นิคหกรรม",
  "นิคหะ",
  "นิคหิต",
  "นิคอน",
  "นิคาลัย",
  "นิจ",
  "นิจศีล",
  "นิด",
  "นิดหน่อย",
  "นิดเดียว",
  "นิด้า",
  "นิตยทาน",
  "นิตยภัต",
  "นิตยสาร",
  "นิตย์",
  "นิติ",
  "นิติกร",
  "นิติกรรม",
  "นิติธรรม",
  "นิตินัย",
  "นิติบัญญัติ",
  "นิติบุคคล",
  "นิติภาวะ",
  "นิติวิทยาศาสตร์",
  "นิติศาสตร์",
  "นิติเวช",
  "นิติเวชศาสตร์",
  "นิทร",
  "นิทรรศการ",
  "นิทรา",
  "นิทรารมณ์",
  "นิทัศน์",
  "นิทาน",
  "นิธาน",
  "นิธิ",
  "นินจา",
  "นินทา",
  "นินนาท",
  "นินาท",
  "นินเทนโด",
  "นิบาต",
  "นิปปอน",
  "นิปริยาย",
  "นิปัจการ",
  "นิพจน์",
  "นิพนธ์",
  "นิพพาน",
  "นิพพิทา",
  "นิพัทธ์",
  "นิพันธ์",
  "นิพิท",
  "นิภา",
  "นิมนต์",
  "นิมมาน",
  "นิมมานรดี",
  "นิมิต",
  "นิยต",
  "นิยม",
  "นิยัตินิยม",
  "นิยาม",
  "นิยาย",
  "นิยุต",
  "นิรคุณ",
  "นิรชร",
  "นิรชรา",
  "นิรทุกข์",
  "นิรนัย",
  "นิรนาม",
  "นิรภัย",
  "นิรมล",
  "นิรมาณ",
  "นิรมาน",
  "นิรมิต",
  "นิรยบาล",
  "นิรัตศัย",
  "นิรันดร",
  "นิรันดร์",
  "นิรัพพุท",
  "นิรา",
  "นิราพาธ",
  "นิรามัย",
  "นิรามิษ",
  "นิราศ",
  "นิราศรัย",
  "นิรินธน์",
  "นิรุกติ",
  "นิรุกติศาสตร์",
  "นิรุตติ",
  "นิรุทธ์",
  "นิรเทศ",
  "นิรโทษ",
  "นิรโทษกรรม",
  "นิล",
  "นิลุบล",
  "นิว",
  "นิวคลิอิก",
  "นิวซีแลนด์",
  "นิวตรอน",
  "นิวตัน",
  "นิวยอร์ก",
  "นิวยอร์ค",
  "นิวรณ์",
  "นิวส์",
  "นิวัต",
  "นิวัตน์",
  "นิวาต",
  "นิวาส",
  "นิวเคลียร์",
  "นิวเคลียส",
  "นิวเจอร์ซีย์",
  "นิวเดลี",
  "นิวแฮมป์เชียร์",
  "นิศา",
  "นิศากร",
  "นิศากาล",
  "นิศาชล",
  "นิศารัตน์",
  "นิษาท",
  "นิสสัน",
  "นิสัช",
  "นิสัชชาการ",
  "นิสัย",
  "นิสาท",
  "นิสิต",
  "นิสีทนสันถัต",
  "นิสีทนะ",
  "นิสีทนาการ",
  "นิเคราะห์",
  "นิเทศ",
  "นิเทศศาสตร์",
  "นิเพท",
  "นิเวศ",
  "นิเวศน์",
  "นิเวศวิทยา",
  "นิเสธ",
  "นิโคติน",
  "นิโคบาร์",
  "นิโครธ",
  "นิโครม",
  "นิโคลัส",
  "นิโรธ",
  "นิโลบล",
  "นิ่ง",
  "นิ่งเฉย",
  "นิ่ม",
  "นิ่มนวล",
  "นิ่ว",
  "นิ้ม",
  "นิ้ว",
  "นีรนาท",
  "นีออน",
  "นีโอดิเมียม",
  "นี่",
  "นี่นัน",
  "นี่เอง",
  "นี่แน่ะ",
  "นี่แหละ",
  "นี้",
  "นึก",
  "นึกคิด",
  "นึง",
  "นึ่ง",
  "นุง",
  "นุงถุง",
  "นุงนัง",
  "นุช",
  "นุต",
  "นุ่ง",
  "นุ่งห่ม",
  "นุ่น",
  "นุ่ม",
  "นุ่มนวล",
  "นุ่มนิ่ม",
  "นุ้ย",
  "นูน",
  "นู่น",
  "นู้ด",
  "นู้น",
  "นเคนทร์",
  "นเรนทร",
  "นเรศ",
  "นเรศวร",
  "นเรศวร์",
  "นโคทร",
  "นโปเลียน",
  "นโยบาย",
  "นโรดม",
  "น็อก",
  "น็อค",
  "น่วม",
  "น่อง",
  "น่องแน่ง",
  "น่ะ",
  "น่า",
  "น่าน",
  "น่าน",
  "น่านน้ำ",
  "น่านฟ้า",
  "น่าย",
  "น้อง",
  "น้องใหม่",
  "น้อม",
  "น้อมนำ",
  "น้อย",
  "น้อยหน่า",
  "น้อยหน้า",
  "น้อยโหน่ง",
  "น้อยใจ",
  "น้า",
  "น้าว",
  "น้ำ",
  "น้ำกรด",
  "น้ำกาม",
  "น้ำข้าว",
  "น้ำครำ",
  "น้ำคร่ำ",
  "น้ำคาวปลา",
  "น้ำคำ",
  "น้ำค้าง",
  "น้ำค้างแข็ง",
  "น้ำจัณฑ์",
  "น้ำจิ้ม",
  "น้ำซาวข้าว",
  "น้ำดอกไม้",
  "น้ำดี",
  "น้ำตก",
  "น้ำตา",
  "น้ำตาล",
  "น้ำท่า",
  "น้ำนม",
  "น้ำนวล",
  "น้ำบาดาล",
  "น้ำประปา",
  "น้ำประสานทอง",
  "น้ำปลา",
  "น้ำป่า",
  "น้ำผึ้ง",
  "น้ำพริก",
  "น้ำพริกเผา",
  "น้ำพี้",
  "น้ำพุ",
  "น้ำมนตร์",
  "น้ำมนต์",
  "น้ำมัน",
  "น้ำมือ",
  "น้ำมูก",
  "น้ำยา",
  "น้ำย่อย",
  "น้ำรัก",
  "น้ำละว้า",
  "น้ำลาย",
  "น้ำว้า",
  "น้ำสต๊อก",
  "น้ำสังข์",
  "น้ำสาบาน",
  "น้ำส้ม",
  "น้ำส้มสายชู",
  "น้ำหนวก",
  "น้ำหนอง",
  "น้ำหนัก",
  "น้ำหนึ่ง",
  "น้ำหน้า",
  "น้ำหมึก",
  "น้ำหอม",
  "น้ำอบ",
  "น้ำอัดลม",
  "น้ำอ้อย",
  "น้ำเกลือ",
  "น้ำเคย",
  "น้ำเค็ม",
  "น้ำเงิน",
  "น้ำเงี้ยว",
  "น้ำเชื่อม",
  "น้ำเชื้อ",
  "น้ำเมา",
  "น้ำเลี้ยง",
  "น้ำเสียง",
  "น้ำเหลือง",
  "น้ำแข็ง",
  "น้ำแข็งแห้ง",
  "น้ำแข็งไส",
  "น้ำแร่",
  "น้ำใจ",
  "บ.ก.",
  "บ.ข.ส.",
  "บ.ช.",
  "บ.ด.ท.",
  "บ.ตร.",
  "บ.ภ.",
  "บ.ม.",
  "บก",
  "บก.จร.",
  "บก.ตชด.",
  "บก.ตม.",
  "บก.ทล.",
  "บก.น.",
  "บก.ป.",
  "บก.ปค.",
  "บก.ปม.",
  "บก.ภ.เขต",
  "บก.รน.",
  "บก.รฟ.",
  "บก.ร้อย.ตชด.",
  "บก.ส.",
  "บกข.ป.",
  "บกพร่อง",
  "บง",
  "บงกช",
  "บงการ",
  "บงก์",
  "บงสุ์",
  "บจพ.ป.",
  "บช.ก.",
  "บช.ด.",
  "บช.ตชด.",
  "บช.น.",
  "บช.บ.",
  "บช.ปส.",
  "บช.ภ.",
  "บช.ม.",
  "บชท.ป.",
  "บชน.ป.",
  "บชส.ป.",
  "บด",
  "บดบัง",
  "บดินทร์",
  "บดี",
  "บถ",
  "บท",
  "บทกลอน",
  "บทกวี",
  "บทความ",
  "บทคัดย่อ",
  "บทจร",
  "บทนำ",
  "บทบงสุ์",
  "บทบัญญัติ",
  "บทบาท",
  "บทประพันธ์",
  "บทมาลย์",
  "บทรัช",
  "บทร้อง",
  "บทลงโทษ",
  "บทวลัญช์",
  "บทสนทนา",
  "บทอัศจรรย์",
  "บทเฉพาะกาล",
  "บทเพลง",
  "บทเรศ",
  "บทเรียน",
  "บธ.ด.",
  "บธ.บ.",
  "บธ.ม.",
  "บน",
  "บนท.ป.",
  "บนบาน",
  "บนอ.ป.",
  "บปช.ป.",
  "บพิตร",
  "บพิธ",
  "บรม",
  "บรมครู",
  "บรมธาตุ",
  "บรมบพิตร",
  "บรมวงศานุวงศ์",
  "บรมอัฐิ",
  "บรมัตถ์",
  "บรรจง",
  "บรรจถรณ์",
  "บรรจบ",
  "บรรจวบ",
  "บรรจุ",
  "บรรณ",
  "บรรณพิภพ",
  "บรรณศาลา",
  "บรรณาการ",
  "บรรณาธิการ",
  "บรรณานุกรม",
  "บรรณารักษศาสตร์",
  "บรรณารักษ์",
  "บรรดา",
  "บรรดามี",
  "บรรดาศักดิ์",
  "บรรตานึก",
  "บรรถร",
  "บรรทม",
  "บรรทัด",
  "บรรทัดฐาน",
  "บรรทาน",
  "บรรทุก",
  "บรรพ",
  "บรรพชน",
  "บรรพชา",
  "บรรพชิต",
  "บรรพต",
  "บรรพบุรุษ",
  "บรรพ์",
  "บรรยง",
  "บรรยงก์",
  "บรรยากาศ",
  "บรรยาย",
  "บรรยเวกษก์",
  "บรรลัย",
  "บรรลัยกัลป์",
  "บรรลัยจักร",
  "บรรลาย",
  "บรรลุ",
  "บรรษัท",
  "บรรสบ",
  "บรรสพ",
  "บรรสม",
  "บรรสาน",
  "บรรสาร",
  "บรรหาน",
  "บรรหาร",
  "บรรเจิด",
  "บรรเทา",
  "บรรเทือง",
  "บรรเลง",
  "บรรโลม",
  "บรอดเวย์",
  "บรอดแบนด์",
  "บรอนซ์",
  "บรัดเลย์",
  "บรัศว์",
  "บรัสเซลส์",
  "บรั่นดี",
  "บรา",
  "บราซิล",
  "บราซิเลีย",
  "บราลี",
  "บราวเซอร์",
  "บริกร",
  "บริกรรม",
  "บริการ",
  "บริขาร",
  "บริขารโจล",
  "บริคณห์",
  "บริคณห์สนธิ",
  "บริจาค",
  "บริจาริกา",
  "บริชน",
  "บริดจ์",
  "บริติช",
  "บริบท",
  "บริบาล",
  "บริบูรณ์",
  "บริพนธ์",
  "บริพัตร",
  "บริพันธ์",
  "บริพาชก",
  "บริพาร",
  "บริภัณฑ์",
  "บริภาษ",
  "บริมาส",
  "บริยาย",
  "บริรม",
  "บริรักษ์",
  "บริราช",
  "บริวรรต",
  "บริวาร",
  "บริวาส",
  "บริวเวอรี่ส์",
  "บริษัท",
  "บริสชน",
  "บริสุทธิ์",
  "บริหาร",
  "บริเฉท",
  "บริเตน",
  "บริเวณ",
  "บริโภค",
  "บรูไน",
  "บรู๊ซ",
  "บรเมนทร์",
  "บร็อกโคลี",
  "บร็อคโคลี",
  "บลอนด์",
  "บลูทูท",
  "บลูทูธ",
  "บลูเบอร์รี",
  "บลูเบอร์รี่",
  "บลูเรย์",
  "บล็อก",
  "บล็อกเกอร์",
  "บล็อค",
  "บวก",
  "บวง",
  "บวงสรวง",
  "บวช",
  "บวน",
  "บวบ",
  "บวม",
  "บวร",
  "บหลิ่ม",
  "บอ",
  "บอก",
  "บอกกล่าว",
  "บอกซ์",
  "บอกบท",
  "บอกบุญ",
  "บอกปัด",
  "บอกใบ้",
  "บอง",
  "บองหลา",
  "บอด",
  "บอดสี",
  "บอดี้",
  "บอตสวานา",
  "บอน",
  "บอนด์",
  "บอบ",
  "บอบช้ำ",
  "บอบบาง",
  "บอบแบบ",
  "บอมบ์",
  "บอมเบย์",
  "บอยคอต",
  "บอยคอตต์",
  "บอระเพ็ด",
  "บอร์ด",
  "บอร์เนียว",
  "บอล",
  "บอลติก",
  "บอลลูน",
  "บอสตัน",
  "บอสเนีย",
  "บอแรกซ์",
  "บะหมี่",
  "บัก",
  "บักโกรก",
  "บัคเตรี",
  "บัง",
  "บังกลาเทศ",
  "บังกะโล",
  "บังกาลอร์",
  "บังคน",
  "บังคม",
  "บังคล",
  "บังคลาเทศ",
  "บังควร",
  "บังคับ",
  "บังคับการ",
  "บังคับบัญชา",
  "บังคัล",
  "บังคาลอร์",
  "บังวาย",
  "บังสุกุล",
  "บังสุกูลิก",
  "บังสูรย์",
  "บังหวน",
  "บังอร",
  "บังอวจ",
  "บังอาจ",
  "บังเกอร์",
  "บังเกิด",
  "บังเวียน",
  "บังเหตุ",
  "บังเหียน",
  "บังเอิญ",
  "บังแทรก",
  "บังโกลน",
  "บังโคลน",
  "บังใบ",
  "บัญจก",
  "บัญชร",
  "บัญชา",
  "บัญชาการ",
  "บัญชี",
  "บัญญัติ",
  "บัญหา",
  "บัฏ",
  "บัณฑร",
  "บัณฑิต",
  "บัณฑิตย์",
  "บัณฑุ",
  "บัณฑุกัมพล",
  "บัณฑูร",
  "บัณณาส",
  "บัณรส",
  "บัณรสี",
  "บัณเฑาะก์",
  "บัณเฑาะว์",
  "บัด",
  "บัดกรี",
  "บัดซบ",
  "บัดดล",
  "บัดนั้น",
  "บัดนี้",
  "บัดสี",
  "บัดสีบัดเถลิง",
  "บัดเดี๋ยว",
  "บัตร",
  "บัตรพลี",
  "บัตรสนเท่ห์",
  "บัตรสินเชื่อ",
  "บัตรเครดิต",
  "บัตเตอร์",
  "บัทม์",
  "บัน",
  "บันจวบ",
  "บันดล",
  "บันดาล",
  "บันทึก",
  "บันทึง",
  "บันยะบันยัง",
  "บันลือ",
  "บันเทิง",
  "บันเทิงคดี",
  "บันได",
  "บันไดลิง",
  "บันไดเลื่อน",
  "บัปผาสะ",
  "บัพ",
  "บัพชา",
  "บัพพาชนียกรรม",
  "บัฟเฟอร์",
  "บัล",
  "บัลลพ",
  "บัลลังก์",
  "บัลลาสต์",
  "บัลลูน",
  "บัลเมอร์",
  "บัลเลต์",
  "บัลเล่ต์",
  "บัลแกเรีย",
  "บัว",
  "บัวบก",
  "บัวลอย",
  "บัส",
  "บั่น",
  "บั่นทอน",
  "บั้ง",
  "บั้งไฟ",
  "บั้น",
  "บั้นท้าย",
  "บั้นปลาย",
  "บั้นพระองค์",
  "บั้นเอว",
  "บั๊ก",
  "บา",
  "บาก",
  "บากบั่น",
  "บากหน้า",
  "บาง",
  "บางกอก",
  "บางตา",
  "บางที",
  "บางปะกง",
  "บางระจัน",
  "บางเบา",
  "บาจรีย์",
  "บาซิลลัส",
  "บาด",
  "บาดทะจิต",
  "บาดทะพิษ",
  "บาดทะยัก",
  "บาดหมาง",
  "บาดาล",
  "บาดเจ็บ",
  "บาดแผล",
  "บาตร",
  "บาตรใหญ่",
  "บาท",
  "บาทบงกช",
  "บาทบงสุ์",
  "บาทบริจาริกา",
  "บาทวิถี",
  "บาทสกุณี",
  "บาทหลวง",
  "บาทาธึก",
  "บาทุกา",
  "บาน",
  "บานชื่น",
  "บานตะเกียง",
  "บานตะไท",
  "บานบุรี",
  "บานปลาย",
  "บานพับ",
  "บานเกล็ด",
  "บานเช้า",
  "บานเบอะ",
  "บานเย็น",
  "บานแผละ",
  "บานไม่รู้โรย",
  "บาบูน",
  "บาป",
  "บาปกรรม",
  "บาย",
  "บายศรี",
  "บารนี",
  "บารมี",
  "บารอมิเตอร์",
  "บารัก",
  "บารัค",
  "บาร์",
  "บาร์บีคิว",
  "บาร์บี้",
  "บาร์บูดา",
  "บาร์เบโดส",
  "บาร์เรล",
  "บาร์เลย์",
  "บาล",
  "บาลานซ์",
  "บาลี",
  "บาศ",
  "บาศก์",
  "บาสเกตบอล",
  "บาหลี",
  "บาห์เรน",
  "บาฮามาส",
  "บาเรียน",
  "บาเรียม",
  "บำนาญ",
  "บำบวง",
  "บำบัด",
  "บำราบ",
  "บำราศ",
  "บำรุง",
  "บำรู",
  "บำหยัด",
  "บำเทิง",
  "บำเพ็ญ",
  "บำเรอ",
  "บำเหน็จ",
  "บิ",
  "บิฐ",
  "บิณฑบาต",
  "บิด",
  "บิดพลิ้ว",
  "บิดร",
  "บิดหล่า",
  "บิดา",
  "บิดเบี้ยว",
  "บิดเบือน",
  "บิต",
  "บิตุ",
  "บิตุจฉา",
  "บิตุรงค์",
  "บิตุลา",
  "บิตุเรศ",
  "บิท",
  "บิน",
  "บินยา",
  "บิล",
  "บิลเลียด",
  "บิวเรตต์",
  "บิสมัท",
  "บิสเซา",
  "บิ่น",
  "บิ๊ก",
  "บี",
  "บีฑา",
  "บีตา",
  "บีบ",
  "บีบคั้น",
  "บีบรัด",
  "บีเยศ",
  "บี้",
  "บี้แบน",
  "บึก",
  "บึกบึน",
  "บึง",
  "บึงกาฬ",
  "บึม",
  "บึ่ง",
  "บึ้ง",
  "บึ้งตึง",
  "บึ้งบูด",
  "บึ้ม",
  "บุ",
  "บุก",
  "บุกบั่น",
  "บุกรุก",
  "บุกเบิก",
  "บุคคล",
  "บุคลากร",
  "บุคลาธิษฐาน",
  "บุคลิก",
  "บุคลิกภาพ",
  "บุคลิกลักษณะ",
  "บุง",
  "บุญ",
  "บุญคุณ",
  "บุญญาธิการ",
  "บุญญานุภาพ",
  "บุญญาภินิหาร",
  "บุญญาภิสังขาร",
  "บุญธรรม",
  "บุญนิธิ",
  "บุญฤทธิ์",
  "บุณฑริก",
  "บุณมี",
  "บุณย์",
  "บุตร",
  "บุตรี",
  "บุถุชน",
  "บุทคล",
  "บุนนาค",
  "บุบ",
  "บุบบิบ",
  "บุบสลาย",
  "บุปผชาติ",
  "บุปผา",
  "บุพกรรม",
  "บุพการี",
  "บุพกิจ",
  "บุพชาติ",
  "บุพทักษิณ",
  "บุพนิมิต",
  "บุพบท",
  "บุพพาจารย์",
  "บุพเพสันนิวาส",
  "บุพโพ",
  "บุรณะ",
  "บุรพทิศ",
  "บุรพบท",
  "บุรพาจารย์",
  "บุระ",
  "บุราณ",
  "บุรินทร์",
  "บุริมทิศ",
  "บุริมพรรษา",
  "บุริมสิทธิ",
  "บุรี",
  "บุรีรัมย์",
  "บุรุนดี",
  "บุรุษ",
  "บุรุษเพศ",
  "บุษกร",
  "บุษบก",
  "บุษบง",
  "บุษบัน",
  "บุษบา",
  "บุษบามินตรา",
  "บุษปราค",
  "บุษปะ",
  "บุษยมาส",
  "บุษยะ",
  "บุษย์",
  "บุษย์น้ำทอง",
  "บุษราคัม",
  "บุหงัน",
  "บุหงา",
  "บุหงารำไป",
  "บุหรง",
  "บุหรี่",
  "บุหลัน",
  "บุโรทั่ง",
  "บุ่ง",
  "บุ่มบ่าม",
  "บุ้ง",
  "บุ้งกี๋",
  "บุ้ย",
  "บุ้ยใบ้",
  "บุ๋น",
  "บุ๋ม",
  "บูคาเรสต์",
  "บูชนียสถาน",
  "บูชา",
  "บูชายัญ",
  "บูชิต",
  "บูชิโด",
  "บูด",
  "บูดบึ้ง",
  "บูดาเปสต์",
  "บูดู",
  "บูดเบี้ยว",
  "บูต",
  "บูติก",
  "บูติค",
  "บูม",
  "บูร",
  "บูรณภาพ",
  "บูรณมี",
  "บูรณะ",
  "บูรณาการ",
  "บูรณ์",
  "บูรพะ",
  "บูรพา",
  "บูรพาจารย์",
  "บูรพ์",
  "บูร์กินาฟาโซ",
  "บู่",
  "บู้",
  "บู้บี้",
  "บู๊",
  "บู๊ตึ๊ง",
  "บ็อกซ์",
  "บ่",
  "บ่ง",
  "บ่น",
  "บ่ม",
  "บ่วง",
  "บ่วงบาศ",
  "บ่อ",
  "บ่อง",
  "บ่อน",
  "บ่อนทำลาย",
  "บ่อย",
  "บ่อเกิด",
  "บ่า",
  "บ่าง",
  "บ่าย",
  "บ่ายหน้า",
  "บ่ายเบี่ยง",
  "บ่าว",
  "บ่าวขุน",
  "บ่าวไพร่",
  "บ้วน",
  "บ้วนพระโอษฐ์",
  "บ้อ",
  "บ้อง",
  "บ้องกัญชา",
  "บ้องตื้น",
  "บ้องหู",
  "บ้องแบ๊ว",
  "บ้องไฟ",
  "บ้อม",
  "บ้อหุ้น",
  "บ้า",
  "บ้าง",
  "บ้าจี้",
  "บ้าดีเดือด",
  "บ้าน",
  "บ้านจัดสรร",
  "บ้านช่อง",
  "บ้านนอก",
  "บ้านพัก",
  "บ้านรับรอง",
  "บ้านเมือง",
  "บ้านเรือน",
  "บ้าน้ำลาย",
  "บ้าบิ่น",
  "บ้าบ๋า",
  "บ้าย",
  "บ้าระบุ่น",
  "บ้าระห่ำ",
  "บ้าหอบฟาง",
  "บ้าเลือด",
  "บ๊งเบ๊ง",
  "บ๊วย",
  "บ๊วย",
  "บ๊อกซ์",
  "บ๊อง",
  "บ๊องแบ๊ว",
  "บ๊อบ",
  "บ๊ะ",
  "บ๊ะจ่าง",
  "บ๋อม",
  "บ๋อย",
  "บ๋ำ",
  "ป.กท.",
  "ป.กศ.",
  "ป.กศ.สูง",
  "ป.จ.",
  "ป.จ.ว.",
  "ป.ช.",
  "ป.ธ.",
  "ป.ป.",
  "ป.ป.ก.",
  "ป.ป.ช.",
  "ป.ป.ป.",
  "ป.ป.ร.",
  "ป.ป.ส.",
  "ป.พ.",
  "ป.พ.พ.",
  "ป.พย.",
  "ป.ม.",
  "ป.ม.ก.",
  "ป.ม.ช.",
  "ป.ม.ธ.",
  "ป.ม.ศ.",
  "ป.ม.อ.",
  "ป.ร.ร.๔",
  "ป.ร.ร.๕",
  "ป.ร.ร.๖",
  "ป.ล.",
  "ป.ว.พ.",
  "ป.วิ.อ.",
  "ป.ส.ส.",
  "ป.อ.",
  "ป.อ.ร.ส.",
  "ป.๑",
  "ปก",
  "ปกครอง",
  "ปกคลุม",
  "ปกติ",
  "ปกปิด",
  "ปกป้อง",
  "ปกรณัม",
  "ปกรณ์",
  "ปกิณกะ",
  "ปกีรณัม",
  "ปง",
  "ปงช้าง",
  "ปฎล",
  "ปฏัก",
  "ปฏิกรณ์",
  "ปฏิกรรม",
  "ปฏิการะ",
  "ปฏิกิริยา",
  "ปฏิกูล",
  "ปฏิคม",
  "ปฏิคหิต",
  "ปฏิคาหก",
  "ปฏิฆะ",
  "ปฏิชีวนะ",
  "ปฏิญญา",
  "ปฏิญาณ",
  "ปฏิทิน",
  "ปฏิบถ",
  "ปฏิบัติ",
  "ปฏิบัติการ",
  "ปฏิบัติบูชา",
  "ปฏิปทา",
  "ปฏิปักษ์",
  "ปฏิปัน",
  "ปฏิปุจฉาพยากรณ์",
  "ปฏิปุจฉาวาที",
  "ปฏิพัทธ์",
  "ปฏิพากย์",
  "ปฏิภาค",
  "ปฏิภาณ",
  "ปฏิมา",
  "ปฏิมากร",
  "ปฏิยานุพันธ์",
  "ปฏิยุทธ์",
  "ปฏิรพ",
  "ปฏิรูป",
  "ปฏิวัติ",
  "ปฏิวาต",
  "ปฏิวาท",
  "ปฏิสนธิ",
  "ปฏิสวะ",
  "ปฏิสังขรณ์",
  "ปฏิสันถาร",
  "ปฏิสัมพันธ์",
  "ปฏิสัมภิทา",
  "ปฏิเวธ",
  "ปฏิเสธ",
  "ปฏิโลม",
  "ปฐพี",
  "ปฐพีวิทยา",
  "ปฐม",
  "ปฐมฌาน",
  "ปฐมทัศน์",
  "ปฐมนิเทศ",
  "ปฐมพยาบาล",
  "ปฐมยาม",
  "ปฐมฤกษ์",
  "ปฐมวัย",
  "ปฐมสมโพธิ",
  "ปฐมเทศนา",
  "ปฐวี",
  "ปณต",
  "ปณาม",
  "ปณิธาน",
  "ปณิธิ",
  "ปณีต",
  "ปด",
  "ปดิวรัดา",
  "ปติ",
  "ปถพี",
  "ปถมัง",
  "ปถวี",
  "ปทัฏฐาน",
  "ปทัสถาน",
  "ปทานุกรม",
  "ปทีป",
  "ปทุม",
  "ปทุมธานี",
  "ปน",
  "ปนัดดา",
  "ปนเป",
  "ปปัญจะ",
  "ปม",
  "ปม.วส.",
  "ปมด้อย",
  "ปมเขื่อง",
  "ปมเด่น",
  "ปรก",
  "ปรกติ",
  "ปรง",
  "ปรตยักษ์",
  "ปรน",
  "ปรนนิบัติ",
  "ปรนัย",
  "ปรนิมมิตวสวัตดี",
  "ปรนเปรอ",
  "ปรบ",
  "ปรบมือ",
  "ปรบไก่",
  "ปรปักษ์",
  "ปรปักษ์",
  "ปรมัตถ์",
  "ปรมาจารย์",
  "ปรมาณู",
  "ปรมาภิเษก",
  "ปรมาภิไธย",
  "ปรมินทร์",
  "ปรวด",
  "ปรวนแปร",
  "ปรวาที",
  "ปรศุ",
  "ปรสิต",
  "ปรสิตวิทยา",
  "ปรองดอง",
  "ปรอด",
  "ปรอท",
  "ปรอย",
  "ประ",
  "ประกบ",
  "ประกฤต",
  "ประกฤติ",
  "ประกล",
  "ประกวด",
  "ประกวดประขัน",
  "ประกอบ",
  "ประกัน",
  "ประกันชีวิต",
  "ประกันภัย",
  "ประกับ",
  "ประกาย",
  "ประกายพรึก",
  "ประการ",
  "ประกาศ",
  "ประกาศนียบัตร",
  "ประกาศิต",
  "ประกำ",
  "ประกิด",
  "ประกิต",
  "ประคด",
  "ประคนธรรพ",
  "ประคนธรรพ์",
  "ประคบ",
  "ประคบประหงม",
  "ประคอง",
  "ประคับประคอง",
  "ประคัลภ์",
  "ประคำ",
  "ประคิ่น",
  "ประคุณ",
  "ประจง",
  "ประจญ",
  "ประจบ",
  "ประจบประแจง",
  "ประจวบ",
  "ประจวบคีรีขันธ์",
  "ประจักษนิยม",
  "ประจักษ์",
  "ประจักษ์พยาน",
  "ประจัญ",
  "ประจัญบาน",
  "ประจัน",
  "ประจันหน้า",
  "ประจาก",
  "ประจาค",
  "ประจาน",
  "ประจำ",
  "ประจำการ",
  "ประจำยาม",
  "ประจำเดือน",
  "ประจำเมือง",
  "ประจิม",
  "ประจิ้มประเจ๋อ",
  "ประจุ",
  "ประจุคมน์",
  "ประจุบัน",
  "ประจ๋อประแจ๋",
  "ประชด",
  "ประชดประชัน",
  "ประชน",
  "ประชวม",
  "ประชวร",
  "ประชัน",
  "ประชา",
  "ประชากร",
  "ประชากรศาสตร์",
  "ประชาคม",
  "ประชาชน",
  "ประชาชาติ",
  "ประชาชี",
  "ประชาทัณฑ์",
  "ประชาธิปัตย์",
  "ประชาธิปไตย",
  "ประชาบาล",
  "ประชาพิจารณ์",
  "ประชาภิบาล",
  "ประชามติ",
  "ประชาราษฎร์",
  "ประชาสงเคราะห์",
  "ประชาสัมพันธ์",
  "ประชิด",
  "ประชี",
  "ประชุม",
  "ประณต",
  "ประณม",
  "ประณาม",
  "ประณิธาน",
  "ประณิธิ",
  "ประณีต",
  "ประณุท",
  "ประดง",
  "ประดน",
  "ประดวน",
  "ประดอน",
  "ประดอย",
  "ประดัก",
  "ประดักประเดิด",
  "ประดัง",
  "ประดับ",
  "ประดับประดา",
  "ประดา",
  "ประดาก",
  "ประดาน้ำ",
  "ประดาป",
  "ประดามี",
  "ประดาษ",
  "ประดิชญา",
  "ประดิดประดอย",
  "ประดิทิน",
  "ประดิษฐกรรม",
  "ประดิษฐาน",
  "ประดิษฐ์",
  "ประดุง",
  "ประดุจ",
  "ประดู่",
  "ประตง",
  "ประตัก",
  "ประตาป",
  "ประติชญา",
  "ประติญาณ",
  "ประติทิน",
  "ประติมากร",
  "ประติมากรรม",
  "ประติรพ",
  "ประตู",
  "ประถม",
  "ประถมจินดา",
  "ประทม",
  "ประทวน",
  "ประทักษิณ",
  "ประทักษ์",
  "ประทัง",
  "ประทัด",
  "ประทับ",
  "ประทับใจ",
  "ประทาน",
  "ประทาย",
  "ประทาศี",
  "ประทิน",
  "ประทิ่น",
  "ประทีป",
  "ประทุฐ",
  "ประทุน",
  "ประทุษ",
  "ประทุษฐ์",
  "ประทุษร้าย",
  "ประท้วง",
  "ประธาน",
  "ประธานาธิบดี",
  "ประนม",
  "ประนอ",
  "ประนอม",
  "ประนัง",
  "ประนัปดา",
  "ประนีประนอม",
  "ประปราน",
  "ประปราย",
  "ประปา",
  "ประพจน์",
  "ประพนธ์",
  "ประพรม",
  "ประพรม",
  "ประพฤติ",
  "ประพฤทธิ์",
  "ประพัด",
  "ประพัทธ์",
  "ประพันธ์",
  "ประพาต",
  "ประพาส",
  "ประพาสต้น",
  "ประพาสมหรณพ",
  "ประพาฬ",
  "ประพิณ",
  "ประพิมพ์ประพาย",
  "ประพุทธ์",
  "ประภพ",
  "ประภัสสร",
  "ประภา",
  "ประภาคาร",
  "ประภาพ",
  "ประภาษ",
  "ประภาส",
  "ประมง",
  "ประมวล",
  "ประมาณ",
  "ประมาท",
  "ประมุข",
  "ประมุท",
  "ประมูล",
  "ประยงค์",
  "ประยุกต์",
  "ประยุทธ์",
  "ประยุร",
  "ประยูร",
  "ประลมพ์",
  "ประลอง",
  "ประลัย",
  "ประลัยกัลป์",
  "ประลาต",
  "ประลาย",
  "ประลุ",
  "ประวรรต",
  "ประวรรตน์",
  "ประวัติ",
  "ประวัติการณ์",
  "ประวัติศาสตร์",
  "ประวาล",
  "ประวาลปัทม์",
  "ประวาส",
  "ประวิง",
  "ประวิช",
  "ประวิตร",
  "ประวิน",
  "ประวีณ",
  "ประศม",
  "ประศาสน์",
  "ประศุ",
  "ประสก",
  "ประสงค์",
  "ประสบ",
  "ประสบการณ์",
  "ประสบการณ์นิยม",
  "ประสพ",
  "ประสม",
  "ประสะ",
  "ประสัก",
  "ประสันนาการ",
  "ประสัยห์",
  "ประสา",
  "ประสาท",
  "ประสาทการ",
  "ประสาธน์",
  "ประสาน",
  "ประสาร",
  "ประสิทธิผล",
  "ประสิทธิภาพ",
  "ประสิทธิ์",
  "ประสีประสา",
  "ประสูต",
  "ประสูติ",
  "ประสูติการ",
  "ประสูติกาล",
  "ประหนึ่ง",
  "ประหม่า",
  "ประหยัด",
  "ประหลาด",
  "ประหล่ำ",
  "ประหวัด",
  "ประหวั่น",
  "ประหัตประหาร",
  "ประหาณ",
  "ประหาร",
  "ประอบ",
  "ประอร",
  "ประเคน",
  "ประเคราะห์",
  "ประเจก",
  "ประเจิด",
  "ประเจิดประเจ้อ",
  "ประเจียด",
  "ประเชิญ",
  "ประเด",
  "ประเดิม",
  "ประเดียง",
  "ประเดี๋ยว",
  "ประเดี๋ยวนี้",
  "ประเดี๋ยวประด๋าว",
  "ประเดี๋ยวเดียว",
  "ประเด็น",
  "ประเทศ",
  "ประเทศราช",
  "ประเทา",
  "ประเทียด",
  "ประเทียบ",
  "ประเทือง",
  "ประเปรียว",
  "ประเปรี้ยง",
  "ประเพณี",
  "ประเพณีนิยม",
  "ประเภท",
  "ประเมิน",
  "ประเล่ห์",
  "ประเล้าประโลม",
  "ประเวณี",
  "ประเวศ",
  "ประเวศน์",
  "ประเส",
  "ประเสบัน",
  "ประเสบันอากง",
  "ประเสริฐ",
  "ประเหล",
  "ประเหส",
  "ประแจ",
  "ประแดง",
  "ประแดะ",
  "ประโคน",
  "ประโคนธรรพ",
  "ประโคนธรรพ์",
  "ประโคม",
  "ประโดง",
  "ประโดย",
  "ประโปรย",
  "ประโพธ",
  "ประโมง",
  "ประโมทย์",
  "ประโยค",
  "ประโยชน์",
  "ประโรหิต",
  "ประโลม",
  "ประไพ",
  "ประไพร",
  "ประไหมสุหรี",
  "ปรัก",
  "ปรักปรำ",
  "ปรักมะ",
  "ปรัง",
  "ปรัชญา",
  "ปรัตถจริยา",
  "ปรัตยุบัน",
  "ปรัน",
  "ปรับ",
  "ปรับทุกข์",
  "ปรับปรุง",
  "ปรับโทษ",
  "ปรัมปรา",
  "ปรัยัติธรรม",
  "ปรัศจิม",
  "ปรัศนา",
  "ปรัศนี",
  "ปรัศว์",
  "ปรัสสบท",
  "ปรัสเซีย",
  "ปรากฎ",
  "ปรากฏ",
  "ปรากฏการณ์",
  "ปรากรม",
  "ปรากฤต",
  "ปราการ",
  "ปราง",
  "ปรางค์",
  "ปราจีน",
  "ปราจีนบุรี",
  "ปราชญา",
  "ปราชญ์",
  "ปราชัย",
  "ปราณ",
  "ปราณี",
  "ปราด",
  "ปราดเปรียว",
  "ปราดเปรื่อง",
  "ปราน",
  "ปรานี",
  "ปราบ",
  "ปราบดาภิเษก",
  "ปราบปราม",
  "ปราปต์",
  "ปราม",
  "ปรามาส",
  "ปราย",
  "ปรารถนา",
  "ปรารภ",
  "ปรารมภ์",
  "ปราศ",
  "ปราศจาก",
  "ปราศรัย",
  "ปราษณี",
  "ปราษาณ",
  "ปราสัย",
  "ปราสาท",
  "ปราโมช",
  "ปราโมทย์",
  "ปรำ",
  "ปริ",
  "ปริก",
  "ปริกขาร",
  "ปริกรรม",
  "ปริกัป",
  "ปริคณห์",
  "ปริจาค",
  "ปริจาริกา",
  "ปริชน",
  "ปริซึม",
  "ปริญญา",
  "ปริญญาบัตร",
  "ปริณาม",
  "ปริณายก",
  "ปริต",
  "ปริตตะ",
  "ปริตร",
  "ปริตโตทก",
  "ปริทรรศน์",
  "ปริทัยหัคคี",
  "ปริทัศน์",
  "ปรินซิปี",
  "ปรินิพพาน",
  "ปริบ",
  "ปริบท",
  "ปริปันถ์",
  "ปริพนธ์",
  "ปริพัตร",
  "ปริพันธ์",
  "ปริพาชก",
  "ปริภัณฑ์",
  "ปริภาษ",
  "ปริภูมิ",
  "ปริมณฑล",
  "ปริมาณ",
  "ปริมาตร",
  "ปริยัติ",
  "ปริยานุช",
  "ปริยาย",
  "ปริวรรต",
  "ปริวัตร",
  "ปริวาร",
  "ปริวาส",
  "ปริวิตก",
  "ปริศนา",
  "ปริษัท",
  "ปริสัญญู",
  "ปริสุทธิ",
  "ปริหาน",
  "ปริหาร",
  "ปริเฉท",
  "ปริเทพ",
  "ปริเทวะ",
  "ปริเยศ",
  "ปริเวณ",
  "ปริโภค",
  "ปริโยสาน",
  "ปริ่ม",
  "ปรีชญา",
  "ปรีชา",
  "ปรีดา",
  "ปรีดิ",
  "ปรีดิ์",
  "ปรีดี",
  "ปรีติ",
  "ปรียะ",
  "ปรียา",
  "ปรี่",
  "ปรี๊ด",
  "ปรึก",
  "ปรึกษา",
  "ปรึง",
  "ปรือ",
  "ปรื๋อ",
  "ปรุ",
  "ปรุง",
  "ปรุโปร่ง",
  "ปรู",
  "ปรูด",
  "ปรู๊ด",
  "ปรู๊ดปร๊าด",
  "ปรู๊ฟ",
  "ปรู๋",
  "ปรเมศวร์",
  "ปรเมษฐ์",
  "ปรโลก",
  "ปร่า",
  "ปร๋อ",
  "ปฤงคพ",
  "ปฤจฉา",
  "ปฤษฎางค์",
  "ปฤษฐ",
  "ปลก",
  "ปลกเปลี้ย",
  "ปลง",
  "ปลงตก",
  "ปลงใจ",
  "ปลด",
  "ปลดทุกข์",
  "ปลดปลง",
  "ปลดปล่อย",
  "ปลดระวาง",
  "ปลดเปลื้อง",
  "ปลดแอก",
  "ปลวก",
  "ปลอก",
  "ปลอกกระสุน",
  "ปลอกคอ",
  "ปลอด",
  "ปลอดภัย",
  "ปลอดโปร่ง",
  "ปลอบ",
  "ปลอบโยน",
  "ปลอม",
  "ปลอมปน",
  "ปลอมแปลง",
  "ปละ",
  "ปลัก",
  "ปลัง",
  "ปลัด",
  "ปลัดขิก",
  "ปลั่ง",
  "ปลั๊ก",
  "ปลั๊กไฟ",
  "ปลา",
  "ปลากริม",
  "ปลาจ่อม",
  "ปลาดาว",
  "ปลาต",
  "ปลาตู้",
  "ปลาทอง",
  "ปลาบ",
  "ปลาบปลื้ม",
  "ปลาบิน",
  "ปลาฝา",
  "ปลาย",
  "ปลายข้าว",
  "ปลายทาง",
  "ปลายแถว",
  "ปลาร้า",
  "ปลาวาฬ",
  "ปลาสนาการ",
  "ปลาสเตอร์",
  "ปลาส้ม",
  "ปลาหมึก",
  "ปลาเค็ม",
  "ปลาเจ่า",
  "ปลาแดก",
  "ปลิง",
  "ปลิด",
  "ปลิม",
  "ปลิว",
  "ปลิโพธ",
  "ปลิ่ม",
  "ปลิ้น",
  "ปลิ้นปลอก",
  "ปลิ้นปล้อน",
  "ปลี",
  "ปลีก",
  "ปลีกตัว",
  "ปลีกย่อย",
  "ปลื้ม",
  "ปลุก",
  "ปลุกปล้ำ",
  "ปลุกปั่น",
  "ปลุกระดม",
  "ปลุกเสก",
  "ปลุกใจ",
  "ปลูก",
  "ปลูกฝัง",
  "ปลูกสร้าง",
  "ปล่ง",
  "ปล่อง",
  "ปล่อย",
  "ปล่อยตัว",
  "ปล่อยปละ",
  "ปล่อยใจ",
  "ปล้น",
  "ปล้นสะดม",
  "ปล้อง",
  "ปล้อน",
  "ปล้ำ",
  "ปวกเปียก",
  "ปวง",
  "ปวด",
  "ปวดถ่วง",
  "ปวดมวน",
  "ปวดร้าว",
  "ปวดหลัง",
  "ปวัตน์",
  "ปวารณา",
  "ปวาล",
  "ปวาส",
  "ปวาฬ",
  "ปวิช",
  "ปวิตร",
  "ปวิธ",
  "ปวิเวก",
  "ปวีณ",
  "ปวีณา",
  "ปวุติ",
  "ปศุ",
  "ปสันนะ",
  "ปสันนาการ",
  "ปสาท",
  "ปสาน",
  "ปสาสน์",
  "ปสุ",
  "ปสุต",
  "ปสูติ",
  "ปหังสนะ",
  "ปหาน",
  "ปหาร",
  "ปหาส",
  "ปอ",
  "ปอ.พ.",
  "ปอก",
  "ปอกลอก",
  "ปอง",
  "ปอด",
  "ปอดแหก",
  "ปอน",
  "ปอนด์",
  "ปอบ",
  "ปอม",
  "ปอย",
  "ปอยเปต",
  "ปอเต็กตึ๊ง",
  "ปอเต๊กตึ๊ง",
  "ปอเนาะ",
  "ปะ",
  "ปะกน",
  "ปะกัง",
  "ปะการัง",
  "ปะกำ",
  "ปะขาว",
  "ปะงาบ",
  "ปะตาปา",
  "ปะตาระกาหลา",
  "ปะติดปะต่อ",
  "ปะติยาน",
  "ปะทะ",
  "ปะทะปะทัง",
  "ปะทิว",
  "ปะทุ",
  "ปะทุน",
  "ปะปน",
  "ปะมง",
  "ปะราลี",
  "ปะรำ",
  "ปะลอม",
  "ปะลิส",
  "ปะวะหล่ำ",
  "ปะหงับ",
  "ปะหนัน",
  "ปะหัง",
  "ปะหัง",
  "ปะเลง",
  "ปะเหลาะ",
  "ปะแล่ม",
  "ปะโลง",
  "ปะไร",
  "ปัก",
  "ปักกิ่ง",
  "ปักขคณนา",
  "ปักข์",
  "ปักดำ",
  "ปักษา",
  "ปักษิน",
  "ปักษี",
  "ปักษ์",
  "ปักหลัก",
  "ปักเป้า",
  "ปักใจ",
  "ปัคคหะ",
  "ปัง",
  "ปังสุกุล",
  "ปังสุ์",
  "ปัจจัตตะ",
  "ปัจจันตคาม",
  "ปัจจันตชนบท",
  "ปัจจันตประเทศ",
  "ปัจจันต์",
  "ปัจจัย",
  "ปัจจามิตร",
  "ปัจจุคมน์",
  "ปัจจุทธรณ์",
  "ปัจจุบัน",
  "ปัจจุสมัย",
  "ปัจฉา",
  "ปัจฉิม",
  "ปัจฉิมชน",
  "ปัจฉิมทิศ",
  "ปัจฉิมนิเทศ",
  "ปัจฉิมภาค",
  "ปัจฉิมยาม",
  "ปัจฉิมลิขิต",
  "ปัจฉิมวัย",
  "ปัจฉิมวาจา",
  "ปัจถรณ์",
  "ปัจนึก",
  "ปัจยาการ",
  "ปัจเจก",
  "ปัจเจกชน",
  "ปัจเจกบุคคล",
  "ปัจเจกพุทธะ",
  "ปัจเจกโพธิ",
  "ปัจเวกขณ์",
  "ปัจโจปการกิจ",
  "ปัชชุน",
  "ปัญจก",
  "ปัญจกะ",
  "ปัญจนที",
  "ปัญจมี",
  "ปัญจวัคคีย์",
  "ปัญจวีสติ",
  "ปัญจาบ",
  "ปัญจาบี",
  "ปัญญัติ",
  "ปัญญา",
  "ปัญญาชน",
  "ปัญญาวิมุติ",
  "ปัญญาอ่อน",
  "ปัญหา",
  "ปัฏ",
  "ปัฏนะ",
  "ปัฏนา",
  "ปัฐยาวัต",
  "ปัณฑรหัตถี",
  "ปัณณะ",
  "ปัณณาส",
  "ปัณณาสก์",
  "ปัณรสี",
  "ปัณหิ",
  "ปัด",
  "ปัดเป่า",
  "ปัตคาด",
  "ปัตตะ",
  "ปัตตะโชติ",
  "ปัตตานี",
  "ปัตตานึก",
  "ปัตตานุโมทนา",
  "ปัตตาเวีย",
  "ปัตติ",
  "ปัตติก",
  "ปัตถร",
  "ปัตถะ",
  "ปัตนิ",
  "ปัตนี",
  "ปัตหล่า",
  "ปัถพี",
  "ปัถวี",
  "ปัทมะ",
  "ปัทมาสน์",
  "ปัทม์",
  "ปัน",
  "ปันจุเหร็จ",
  "ปันส่วน",
  "ปับ",
  "ปัปผาสะ",
  "ปัพพาชนียกรรม",
  "ปัพภาระ",
  "ปัยกา",
  "ปัยยิกา",
  "ปัลลวะ",
  "ปัวเปีย",
  "ปัศจิม",
  "ปัศตัน",
  "ปัสสาวะ",
  "ปัสสาสะ",
  "ปั่น",
  "ปั่นป่วน",
  "ปั่นหัว",
  "ปั่นแปะ",
  "ปั้น",
  "ปั้นจั่น",
  "ปั้นจิ้ม",
  "ปั้นปึ่ง",
  "ปั้นลม",
  "ปั้นสิบ",
  "ปั้นหยา",
  "ปั้นเจ๋อ",
  "ปั้นเหน่ง",
  "ปั๊บ",
  "ปั๊ม",
  "ปั๊มน้ำมัน",
  "ปั๋ง",
  "ปา",
  "ปาก",
  "ปากกา",
  "ปากขอ",
  "ปากคอ",
  "ปากคำ",
  "ปากคีบ",
  "ปากจัด",
  "ปากน้ำ",
  "ปากีสถาน",
  "ปากเปล่า",
  "ปากเสียง",
  "ปากแข็ง",
  "ปาง",
  "ปาจรีย์",
  "ปาจิตตีย์",
  "ปาจีน",
  "ปาฏลิ",
  "ปาฏลีบุตร",
  "ปาฏิบท",
  "ปาฏิบุคลิก",
  "ปาฏิหาริย์",
  "ปาฏิโภค",
  "ปาฏิโมกข์",
  "ปาฐก",
  "ปาฐกถา",
  "ปาฐะ",
  "ปาณกชาติ",
  "ปาณะ",
  "ปาณาติบาต",
  "ปาณิ",
  "ปาณี",
  "ปาด",
  "ปาติโมกข์",
  "ปาตี",
  "ปาทังกา",
  "ปาทาน",
  "ปาทุกา",
  "ปาท่องโก๋",
  "ปาน",
  "ปานกลาง",
  "ปานะ",
  "ปานามา",
  "ปานียะ",
  "ปาปัวนิวกินี",
  "ปาพจน์",
  "ปาม",
  "ปาย",
  "ปายาส",
  "ปารมี",
  "ปารากวัย",
  "ปาราชิก",
  "ปาริฉัตร",
  "ปาริชาต",
  "ปารีส",
  "ปารุสกวัน",
  "ปารุสก์",
  "ปารเมศ",
  "ปาร์ตี้",
  "ปาร์เกต์",
  "ปาล",
  "ปาลิไลยก์",
  "ปาลี",
  "ปาล์ม",
  "ปาว",
  "ปาวาร",
  "ปาษาณ",
  "ปาส",
  "ปาสกาล",
  "ปาสคาล",
  "ปาสาณ",
  "ปาสาทิกะ",
  "ปาสเตอร์",
  "ปาหนัน",
  "ปาหี่",
  "ปาเต๊ะ",
  "ปาเลสไตน์",
  "ปาเลา",
  "ปาโมกข์",
  "ปำ",
  "ปิกนิก",
  "ปิกอัพ",
  "ปิง",
  "ปิงคละ",
  "ปิงปอง",
  "ปิฎก",
  "ปิฏฐะ",
  "ปิฐิ",
  "ปิณฑะ",
  "ปิด",
  "ปิดฉาก",
  "ปิดบัง",
  "ปิตตะ",
  "ปิตา",
  "ปิตามหัยกา",
  "ปิตามหัยยิกา",
  "ปิติ",
  "ปิตุ",
  "ปิตุฆาต",
  "ปิตุจฉา",
  "ปิตุภูมิ",
  "ปิตุภูมิ",
  "ปิตุลา",
  "ปิปผลี",
  "ปิยมิตร",
  "ปิยะ",
  "ปิยังคุ",
  "ปิรามิด",
  "ปิลันธน์",
  "ปิศาจ",
  "ปิหกะ",
  "ปิโตรเคมี",
  "ปิโตรเลียม",
  "ปิโยรส",
  "ปิ่น",
  "ปิ่นแก้ว",
  "ปิ่นโต",
  "ปิ่ม",
  "ปิ้ง",
  "ปิ้ม",
  "ปิ๊ง",
  "ปิ๋ว",
  "ปี",
  "ปีก",
  "ปีกกา",
  "ปีฐะ",
  "ปีติ",
  "ปีน",
  "ปีนป่าย",
  "ปีนัง",
  "ปีนเกลียว",
  "ปีบ",
  "ปีมะโว้",
  "ปีศาจ",
  "ปีฬกะ",
  "ปีเตอร์",
  "ปีแสง",
  "ปี่",
  "ปี่กลาง",
  "ปี่ชวา",
  "ปี่นอก",
  "ปี่พาทย์",
  "ปี่อ้อ",
  "ปี่ใน",
  "ปี่ไฉน",
  "ปี้",
  "ปี๊ด",
  "ปี๊บ",
  "ปี๋",
  "ปึก",
  "ปึกแผ่น",
  "ปึง",
  "ปึงปัง",
  "ปึมปื้อ",
  "ปึ่ง",
  "ปึ้ก",
  "ปึ้ด",
  "ปึ๋ง",
  "ปืน",
  "ปืนกล",
  "ปืนครก",
  "ปืนพก",
  "ปืนยา",
  "ปืนยาว",
  "ปืนลม",
  "ปืนสั้น",
  "ปืนเล็ก",
  "ปืนเล็กยาว",
  "ปืนใหญ่",
  "ปือ",
  "ปื้น",
  "ปื้อ",
  "ปื๋อ",
  "ปุ",
  "ปุก",
  "ปุกปุย",
  "ปุคละ",
  "ปุงควะ",
  "ปุงลิงค์",
  "ปุงลึงค์",
  "ปุจฉา",
  "ปุฏะ",
  "ปุณฑริก",
  "ปุด",
  "ปุตตะ",
  "ปุถุชน",
  "ปุนนาค",
  "ปุนภพ",
  "ปุนัพสุ",
  "ปุบ",
  "ปุบปับ",
  "ปุปผะ",
  "ปุปะ",
  "ปุพพะ",
  "ปุย",
  "ปุยฝ้าย",
  "ปุรณะ",
  "ปุระ",
  "ปุราณ",
  "ปุราณะ",
  "ปุริมพรรษา",
  "ปุลลิงค์",
  "ปุลลึงค์",
  "ปุลินท์",
  "ปุษยะ",
  "ปุสสะ",
  "ปุเรจาริก",
  "ปุโรหิต",
  "ปุโลปุเล",
  "ปุ่ม",
  "ปุ่มป่ำ",
  "ปุ่มเปือก",
  "ปุ้งกี๋",
  "ปุ้ม",
  "ปุ้ย",
  "ปุ๊",
  "ปุ๊บ",
  "ปุ๊บปั๊บ",
  "ปุ๋ม",
  "ปุ๋ย",
  "ปุ๋ยคอก",
  "ปุ๋ยวิทยาศาสตร์",
  "ปุ๋ยหมัก",
  "ปุ๋ยอินทรีย์",
  "ปุ๋ยเคมี",
  "ปู",
  "ปูจ๋า",
  "ปูชกะ",
  "ปูชนียบุคคล",
  "ปูชนียวัตถุ",
  "ปูชนียะ",
  "ปูชา",
  "ปูชิต",
  "ปูด",
  "ปูติน",
  "ปูน",
  "ปูนขาว",
  "ปูนซีเมนต์",
  "ปูนดิบ",
  "ปูนปลาสเตอร์",
  "ปูนปั้น",
  "ปูนแดง",
  "ปูม",
  "ปูระ",
  "ปูลู",
  "ปูอัด",
  "ปูเสฉวน",
  "ปู่",
  "ปู่ทวด",
  "ปู่เจ้า",
  "ปู้ยี่ปู้ยำ",
  "ปเวณี",
  "ปเวส",
  "ปเวสน์",
  "ปโกฏิ",
  "ป่ง",
  "ป่น",
  "ป่นปี้",
  "ป่วง",
  "ป่วน",
  "ป่วนปั่น",
  "ป่วย",
  "ป่วยการ",
  "ป่อง",
  "ป่อเต็กตึ๊ง",
  "ป่า",
  "ป่าชายเลน",
  "ป่าช้า",
  "ป่าดง",
  "ป่าดงดิบ",
  "ป่าดิบ",
  "ป่าน",
  "ป่านนี้",
  "ป่าย",
  "ป่าละเมาะ",
  "ป่าว",
  "ป่าวร้อง",
  "ป่าเถื่อน",
  "ป่าเบญจพรรณ",
  "ป่าไม้",
  "ป้วน",
  "ป้วนเปี้ยน",
  "ป้อ",
  "ป้อง",
  "ป้องกัน",
  "ป้อน",
  "ป้อม",
  "ป้อย",
  "ป้อยอ",
  "ป้อแป้",
  "ป้า",
  "ป้าง",
  "ป้าน",
  "ป้านลม",
  "ป้าบ",
  "ป้าย",
  "ป้ายสี",
  "ป้ำ",
  "ป้ำเป๋อ",
  "ป๊อก",
  "ป๊อป",
  "ป๊า",
  "ป๊าบ",
  "ป๊าว",
  "ป๋อ",
  "ป๋อม",
  "ป๋อหลอ",
  "ป๋า",
  "ผก",
  "ผกก.ภ.",
  "ผกผัน",
  "ผกา",
  "ผกากรอง",
  "ผกาย",
  "ผคม",
  "ผง",
  "ผงก",
  "ผงขาว",
  "ผงชูรส",
  "ผงซักฟอก",
  "ผงฟู",
  "ผงม",
  "ผงะ",
  "ผงาด",
  "ผง่าน",
  "ผจง",
  "ผจญ",
  "ผจัญ",
  "ผจาน",
  "ผช.ผอ.",
  "ผชุม",
  "ผณิน",
  "ผณินทร",
  "ผณิศวร",
  "ผด",
  "ผดุง",
  "ผดุงครรภ์",
  "ผต.มท.",
  "ผทม",
  "ผนวก",
  "ผนวช",
  "ผนัง",
  "ผนิด",
  "ผนึก",
  "ผบ.ตร.",
  "ผบ.ทบ.",
  "ผบ.ทร.",
  "ผบ.ทสส.",
  "ผบ.ทอ.",
  "ผบก.น.",
  "ผบก.ป.",
  "ผบก.ปค.",
  "ผบก.ปม.",
  "ผบก.ภ.",
  "ผบช.ก.",
  "ผบช.ตชด.",
  "ผบช.น.",
  "ผบช.ภ.",
  "ผม",
  "ผมไฟ",
  "ผยอง",
  "ผรณาปีติ",
  "ผรสุ",
  "ผริต",
  "ผรุสวาท",
  "ผล",
  "ผลคุน",
  "ผลคุนี",
  "ผลพลอยได้",
  "ผลลัพธ์",
  "ผละ",
  "ผลัก",
  "ผลักดัน",
  "ผลัด",
  "ผลัดเปลี่ยน",
  "ผลับ",
  "ผลัวะ",
  "ผลา",
  "ผลาญ",
  "ผลานิสงส์",
  "ผลาผล",
  "ผลาหาร",
  "ผลิ",
  "ผลิกะ",
  "ผลิต",
  "ผลิตผล",
  "ผลิตภัณฑ์",
  "ผลิน",
  "ผลี",
  "ผลีผลาม",
  "ผลึก",
  "ผลึ่ง",
  "ผลือ",
  "ผลุ",
  "ผลุง",
  "ผลุด",
  "ผลุน",
  "ผลุนผลัน",
  "ผลุบ",
  "ผลุบผลับ",
  "ผลุบโผล่",
  "ผลุย",
  "ผลู",
  "ผลไม้",
  "ผล็อย",
  "ผว.กทม.",
  "ผวน",
  "ผวย",
  "ผวา",
  "ผสม",
  "ผสมผสาน",
  "ผสมผเส",
  "ผสมพันธุ์",
  "ผสมเทียม",
  "ผสมเสร็จ",
  "ผสมโรง",
  "ผสาน",
  "ผอ.ปจ.",
  "ผอง",
  "ผอบ",
  "ผอม",
  "ผอมแห้ง",
  "ผอมโซ",
  "ผอูน",
  "ผะ",
  "ผะดา",
  "ผะสา",
  "ผัก",
  "ผักชี",
  "ผักตบชวา",
  "ผักบุ้ง",
  "ผัคคุณ",
  "ผัคคุณี",
  "ผัง",
  "ผังเมือง",
  "ผัด",
  "ผัดผ่อน",
  "ผัน",
  "ผันผวน",
  "ผันแปร",
  "ผับ",
  "ผัว",
  "ผัวะ",
  "ผัสสะ",
  "ผัสสาหาร",
  "ผา",
  "ผาก",
  "ผาง",
  "ผาณิต",
  "ผาด",
  "ผาดโผน",
  "ผาติ",
  "ผาติกรรม",
  "ผาม",
  "ผาย",
  "ผาล",
  "ผาลคุน",
  "ผาลา",
  "ผาสุก",
  "ผำ",
  "ผิ",
  "ผิง",
  "ผิด",
  "ผิดพลาด",
  "ผิดหวัง",
  "ผิตะ",
  "ผิน",
  "ผิว",
  "ผิวพรรณ",
  "ผิวหนัง",
  "ผิวเผิน",
  "ผี",
  "ผีกระสือ",
  "ผีกระหัง",
  "ผีกองกอย",
  "ผีดิบ",
  "ผีตองเหลือง",
  "ผีถ้วยแก้ว",
  "ผีทะเล",
  "ผีบุญ",
  "ผีปอบ",
  "ผีพุ่งไต้",
  "ผีฟ้า",
  "ผีสาง",
  "ผีห่า",
  "ผีเรือน",
  "ผีเสื้อ",
  "ผีแถน",
  "ผีโขมด",
  "ผี้ว์",
  "ผึง",
  "ผึ่ง",
  "ผึ่งผาย",
  "ผึ้ง",
  "ผึ้งรวง",
  "ผืน",
  "ผื่น",
  "ผุ",
  "ผุด",
  "ผุดผาด",
  "ผุดผ่อง",
  "ผุยผง",
  "ผุสราคา",
  "ผูก",
  "ผูกขวัญ",
  "ผูกขาด",
  "ผูกพัน",
  "ผูกมัด",
  "ผู้",
  "ผู้คน",
  "ผู้คุม",
  "ผู้จัดการ",
  "ผู้ชาย",
  "ผู้ดี",
  "ผู้ต้องขัง",
  "ผู้ต้องหา",
  "ผู้นำ",
  "ผู้น้อย",
  "ผู้บริโภค",
  "ผู้บังคับบัญชา",
  "ผู้ปกครอง",
  "ผู้ประกอบการ",
  "ผู้ป่วย",
  "ผู้พิพากษา",
  "ผู้ร้าย",
  "ผู้วิเศษ",
  "ผู้สื่อข่าว",
  "ผู้หญิง",
  "ผู้เชี่ยวชาญ",
  "ผู้เยาว์",
  "ผู้เสียหาย",
  "ผู้แทน",
  "ผู้โดยสาร",
  "ผู้ใหญ่",
  "ผู้ใหญ่บ้าน",
  "ผเดิน",
  "ผ็อย",
  "ผ่อง",
  "ผ่องแผ้ว",
  "ผ่องใส",
  "ผ่อน",
  "ผ่อนคลาย",
  "ผ่อนชำระ",
  "ผ่อนปรน",
  "ผ่อนผัน",
  "ผ่อนส่ง",
  "ผ่า",
  "ผ่าง",
  "ผ่าตัด",
  "ผ่าน",
  "ผ่ายผอม",
  "ผ่าว",
  "ผ่าหมาก",
  "ผ่าเผย",
  "ผ่าเหล่า",
  "ผ้า",
  "ผ้าขนหนู",
  "ผ้าขาวม้า",
  "ผ้าขี้ริ้ว",
  "ผ้าดิบ",
  "ผ้าต่วน",
  "ผ้าถุง",
  "ผ้านวม",
  "ผ้านุ่ง",
  "ผ้าป่า",
  "ผ้าป่าน",
  "ผ้าผ่อน",
  "ผ้าพันคอ",
  "ผ้าพันแผล",
  "ผ้ามัดหมี่",
  "ผ้ายาง",
  "ผ้าลูกไม้",
  "ผ้าห่ม",
  "ผ้าอนามัย",
  "ผ้าอ้อม",
  "ผ้าฮาด",
  "ผ้าเช็ดตัว",
  "ผ้าเช็ดปาก",
  "ผ้าเช็ดมือ",
  "ผ้าเช็ดหน้า",
  "ผ้าเหลือง",
  "ผ้าแถบ",
  "ผ้าแพร",
  "ผ้าโพกหัว",
  "ผ้าใบ",
  "ผ้าไตร",
  "ผ้ำ",
  "ฝน",
  "ฝนทอง",
  "ฝรั่ง",
  "ฝรั่งเศส",
  "ฝรั่งเศส",
  "ฝอย",
  "ฝอยทอง",
  "ฝัก",
  "ฝักบัว",
  "ฝักฝ่าย",
  "ฝักแค",
  "ฝักใฝ่",
  "ฝัง",
  "ฝังหัว",
  "ฝังใจ",
  "ฝัด",
  "ฝัน",
  "ฝั่ง",
  "ฝา",
  "ฝาก",
  "ฝากตัว",
  "ฝากฝัง",
  "ฝาง",
  "ฝาชี",
  "ฝาด",
  "ฝาน",
  "ฝาย",
  "ฝาละมี",
  "ฝาแฝด",
  "ฝิ่น",
  "ฝี",
  "ฝีก",
  "ฝีจักร",
  "ฝีดาษ",
  "ฝีปาก",
  "ฝีพาย",
  "ฝีมะม่วง",
  "ฝีมือ",
  "ฝีเท้า",
  "ฝีเย็บ",
  "ฝี่",
  "ฝึก",
  "ฝึกงาน",
  "ฝึกปรือ",
  "ฝึกฝน",
  "ฝึกสอน",
  "ฝึกหัด",
  "ฝืด",
  "ฝืดเคือง",
  "ฝืน",
  "ฝุ่น",
  "ฝูง",
  "ฝ่อ",
  "ฝ่า",
  "ฝ่าฝืน",
  "ฝ่าพระบาท",
  "ฝ่าฟัน",
  "ฝ่าย",
  "ฝ้า",
  "ฝ้าฟาง",
  "ฝ้าย",
  "พ.ก.ง.",
  "พ.กศ.",
  "พ.ข.ต.",
  "พ.ค.",
  "พ.ค.ช.",
  "พ.ค.ว.",
  "พ.ค.ศ.",
  "พ.จ.ต.",
  "พ.จ.ท.",
  "พ.จ.อ.",
  "พ.ช.",
  "พ.ช.ค.",
  "พ.ด.",
  "พ.ต.",
  "พ.ต.ต.",
  "พ.ต.ท.",
  "พ.ต.อ.",
  "พ.ต.อ.พิเศษ",
  "พ.ท.",
  "พ.บ.",
  "พ.ป.",
  "พ.ภ.ม.",
  "พ.ม.",
  "พ.ม.ช.",
  "พ.ย.",
  "พ.ร.ก.",
  "พ.ร.ฎ.",
  "พ.ร.ต.",
  "พ.ร.ธ.",
  "พ.ร.บ.",
  "พ.ศ.",
  "พ.ศ.บ.",
  "พ.ส.ร.",
  "พ.ส.ล.",
  "พ.อ.",
  "พ.อ.ต.",
  "พ.อ.ท.",
  "พ.อ.พิเศษ",
  "พ.อ.อ.",
  "พก",
  "พกุล",
  "พง",
  "พงพี",
  "พงศกร",
  "พงศธร",
  "พงศา",
  "พงศาวดาร",
  "พงศ์",
  "พงศ์พันธุ์",
  "พงษ์",
  "พจนา",
  "พจนานุกรม",
  "พจนารถ",
  "พจนีย์",
  "พจน์",
  "พจมาน",
  "พจี",
  "พชระ",
  "พญา",
  "พญาลอ",
  "พญาโศก",
  "พญาไท",
  "พญาไฟ",
  "พณ.ด.",
  "พณ.บ.",
  "พณ.ม.",
  "พณิช",
  "พณิชย์",
  "พดด้วง",
  "พธ.ด.",
  "พธ.บ.",
  "พธ.ม.",
  "พธู",
  "พนม",
  "พนมเปญ",
  "พนอง",
  "พนอม",
  "พนัก",
  "พนักงาน",
  "พนัง",
  "พนัน",
  "พนันดร",
  "พนัส",
  "พนา",
  "พนาดร",
  "พนาดอน",
  "พนาย",
  "พนาราม",
  "พนาลัย",
  "พนาลี",
  "พนาวาส",
  "พนาศรม",
  "พนาสณฑ์",
  "พนาสัณฑ์",
  "พนาเวศ",
  "พนิช",
  "พนิดา",
  "พนิต",
  "พบ",
  "พบ.ด.",
  "พบ.บ.",
  "พบ.ม.",
  "พบปะ",
  "พบพาน",
  "พม่า",
  "พม่า",
  "พย.ด.",
  "พย.บ.",
  "พย.ม.",
  "พยนต์",
  "พยศ",
  "พยัก",
  "พยักพเยิด",
  "พยักเพยิด",
  "พยัคฆา",
  "พยัคฆิน",
  "พยัคฆี",
  "พยัคฆ์",
  "พยัชน์",
  "พยัญชนะ",
  "พยัต",
  "พยับ",
  "พยากรณ์",
  "พยาฆร์",
  "พยางค์",
  "พยาธิ",
  "พยาน",
  "พยาบาท",
  "พยาบาล",
  "พยาม",
  "พยามะ",
  "พยายาม",
  "พยุ",
  "พยุง",
  "พยุหยาตรา",
  "พยุหะ",
  "พยุหเสนา",
  "พยุหโยธา",
  "พยู่ห์",
  "พร",
  "พรต",
  "พรม",
  "พรมคด",
  "พรมมิ",
  "พรมแดน",
  "พรรค",
  "พรรคพวก",
  "พรรคานต์",
  "พรรค์",
  "พรรณ",
  "พรรณนา",
  "พรรณราย",
  "พรรดึก",
  "พรรลาย",
  "พรรษ",
  "พรรษา",
  "พรรเอิญ",
  "พรวด",
  "พรวดพราด",
  "พรวน",
  "พรสวรรค์",
  "พรหม",
  "พรหมจรรย์",
  "พรหมจาริณี",
  "พรหมจารี",
  "พรหมชาติ",
  "พรหมทัต",
  "พรหมลิขิต",
  "พรหมวิหาร",
  "พรหมา",
  "พรหมาสตร์",
  "พรหมินทร์",
  "พรหมโลก",
  "พรอก",
  "พรอด",
  "พระ",
  "พระครู",
  "พระคุณ",
  "พระชายา",
  "พระตะบอง",
  "พระทัย",
  "พระนอม",
  "พระนาง",
  "พระนางเจ้า",
  "พระนาด",
  "พระผู้เป็นเจ้า",
  "พระพิมพ์",
  "พระพุทธองค์",
  "พระพุทธเจ้า",
  "พระภูมิ",
  "พระยา",
  "พระรอง",
  "พระสนม",
  "พระสนมเอก",
  "พระองค์",
  "พระองค์เจ้า",
  "พระฮาม",
  "พระเคราะห์",
  "พระเครื่อง",
  "พระเจ้า",
  "พระเจ้าอยู่หัว",
  "พระเป็นเจ้า",
  "พระเอก",
  "พรักพร้อม",
  "พรัด",
  "พรับ",
  "พรั่ง",
  "พรั่งพรู",
  "พรั่งพร้อม",
  "พรั่น",
  "พรั่นพรึง",
  "พราก",
  "พราง",
  "พราด",
  "พราน",
  "พราย",
  "พรายน้ำ",
  "พรายแพรว",
  "พราว",
  "พราวแพรว",
  "พราหมณะ",
  "พราหมณี",
  "พราหมณ์",
  "พราหมี",
  "พรำ",
  "พริก",
  "พริกไทย",
  "พริตตี้",
  "พริบ",
  "พริบตา",
  "พริ้ง",
  "พริ้งพราย",
  "พริ้งเพรา",
  "พริ้งเพริศ",
  "พริ้ม",
  "พริ้มพราย",
  "พริ้มเพรา",
  "พริ้ว",
  "พรีเซนต์",
  "พรีเซ็นเตอร์",
  "พรีเมียม",
  "พรีเมียร์",
  "พรึง",
  "พรึน",
  "พรึบ",
  "พรึ่บ",
  "พรืด",
  "พรุ",
  "พรุน",
  "พรุ่ง",
  "พรุ่งนี้",
  "พรู",
  "พรูด",
  "พร็อกซี",
  "พร่อง",
  "พร่อย",
  "พร่า",
  "พร่าง",
  "พร่างพราว",
  "พร่ำ",
  "พร่ำพลอด",
  "พร่ำเพรื่อ",
  "พร่ำเพ้อ",
  "พร้อง",
  "พร้อม",
  "พร้อมพรัก",
  "พร้อมพรั่ง",
  "พร้อมมูล",
  "พร้อมสรรพ",
  "พร้อมหน้า",
  "พร้อมเพรียง",
  "พร้อมใจ",
  "พร้อย",
  "พร้า",
  "พฤกษชาติ",
  "พฤกษราช",
  "พฤกษศาสตร์",
  "พฤกษา",
  "พฤกษเทวดา",
  "พฤกษ์",
  "พฤฒ",
  "พฤฒา",
  "พฤฒาจารย์",
  "พฤฒิ",
  "พฤต",
  "พฤติ",
  "พฤติกรรม",
  "พฤติการณ์",
  "พฤตินัย",
  "พฤทธิ์",
  "พฤทธ์",
  "พฤนต์",
  "พฤนท์",
  "พฤศจิก",
  "พฤศจิกา",
  "พฤศจิกายน",
  "พฤษภ",
  "พฤษภา",
  "พฤษภาคม",
  "พฤหัส",
  "พฤหัสบดี",
  "พล",
  "พล.จ.",
  "พล.ต.",
  "พล.ต.จ.",
  "พล.ต.ต.",
  "พล.ต.ท.",
  "พล.ต.อ.",
  "พล.ท.",
  "พล.ปตอ.",
  "พล.ม.",
  "พล.ม.๒",
  "พล.ร.จ.",
  "พล.ร.ต.",
  "พล.ร.ท.",
  "พล.ร.อ.",
  "พล.อ.",
  "พล.อ.จ.",
  "พล.อ.ต.",
  "พล.อ.ท.",
  "พล.อ.อ.",
  "พลการ",
  "พลขับ",
  "พลความ",
  "พลบ",
  "พลบค่ำ",
  "พลรบ",
  "พลร่ม",
  "พลวก",
  "พลวง",
  "พลวัต",
  "พลศาสตร์",
  "พลศึกษา",
  "พลอ",
  "พลอง",
  "พลอด",
  "พลอดรัก",
  "พลอน",
  "พลอมแพลม",
  "พลอย",
  "พละ",
  "พลัง",
  "พลังงาน",
  "พลังจิต",
  "พลังเงียบ",
  "พลัด",
  "พลัดถิ่น",
  "พลัดพราก",
  "พลัน",
  "พลับ",
  "พลับพลา",
  "พลับพลึง",
  "พลั่ก",
  "พลั่ง",
  "พลั่ว",
  "พลั้ง",
  "พลั้งปาก",
  "พลั้งพลาด",
  "พลั้งเผลอ",
  "พลา.ทร.",
  "พลากร",
  "พลาง",
  "พลาซ่า",
  "พลาญ",
  "พลาด",
  "พลาดท่า",
  "พลาดพลั้ง",
  "พลาดิศัย",
  "พลาธิการ",
  "พลานามัย",
  "พลานุภาพ",
  "พลาม",
  "พลาย",
  "พลาสติก",
  "พลาสมา",
  "พลาหก",
  "พลาโต",
  "พลำ",
  "พลำภัง",
  "พลิก",
  "พลิกแพลง",
  "พลิพัท",
  "พลิ้ว",
  "พลี",
  "พลีกรรม",
  "พลุ",
  "พลุก",
  "พลุกพล่าน",
  "พลุ่ก",
  "พลุ่ง",
  "พลุ่งพล่าน",
  "พลุ้ย",
  "พลู",
  "พลูโต",
  "พลูโตเนียม",
  "พลูโทเนียม",
  "พลเมือง",
  "พลเรือน",
  "พลโลก",
  "พล็อต",
  "พล่อย",
  "พล่า",
  "พล่าน",
  "พล่าม",
  "พล้อ",
  "พล้ำ",
  "พวก",
  "พวกพ้อง",
  "พวง",
  "พวงคราม",
  "พวงชมพู",
  "พวงมาลัย",
  "พวงมาลา",
  "พวงหรีด",
  "พวงแสด",
  "พวน",
  "พวย",
  "พวยน้ำ",
  "พวยพุ่ง",
  "พศ.ด.",
  "พศ.บ.",
  "พศ.ม.",
  "พสก",
  "พสกนิกร",
  "พสุ",
  "พสุธา",
  "พสุสงกรานต์",
  "พหล",
  "พหุ",
  "พหุคูณ",
  "พหุภาคี",
  "พหุล",
  "พหู",
  "พหูพจน์",
  "พหูสูต",
  "พอ",
  "พอ.สว.",
  "พอก",
  "พอกพูน",
  "พอควร",
  "พอง",
  "พอดี",
  "พอตัว",
  "พอทำเนา",
  "พอน",
  "พอประมาณ",
  "พอร์ต",
  "พอร์ท",
  "พอลล่า",
  "พอลิเมอร์",
  "พอสมควร",
  "พอเพียง",
  "พอเพียง",
  "พอเหมาะ",
  "พอแรง",
  "พอโลเนียม",
  "พอใจ",
  "พอใช้",
  "พอใช้ได้",
  "พะ",
  "พะงา",
  "พะงาบ",
  "พะจง",
  "พะทำมะรง",
  "พะนอ",
  "พะพาน",
  "พะพิง",
  "พะยอม",
  "พะยูง",
  "พะยูน",
  "พะรุงพะรัง",
  "พะวง",
  "พะวักพะวน",
  "พะวา",
  "พะว้าพะวัง",
  "พะสิม",
  "พะอง",
  "พะอากพะอำ",
  "พะอืดพะอม",
  "พะเนิน",
  "พะเนียง",
  "พะเน้าพะนอ",
  "พะเพิง",
  "พะเยา",
  "พะเยิบ",
  "พะเยิบพะยาบ",
  "พะเรอ",
  "พะแนง",
  "พะโค",
  "พะโล้",
  "พะไล",
  "พัก",
  "พักตรา",
  "พักตร์",
  "พักผ่อน",
  "พักพิง",
  "พักฟื้น",
  "พักร",
  "พักร้อน",
  "พักแรม",
  "พัง",
  "พังกา",
  "พังงา",
  "พังงา",
  "พังผืด",
  "พังพวย",
  "พังพอน",
  "พังพาน",
  "พังพาบ",
  "พังเพย",
  "พัช",
  "พัชนี",
  "พัชระ",
  "พัญจน์",
  "พัฒนะ",
  "พัฒนา",
  "พัฒนากร",
  "พัฒนาการ",
  "พัฒน์พงษ์",
  "พัด",
  "พัดชา",
  "พัดดึงส์",
  "พัดยศ",
  "พัดลม",
  "พัตร",
  "พัทธยา",
  "พัทธสีมา",
  "พัทธ์",
  "พัทยา",
  "พัทร",
  "พัทลุง",
  "พัน",
  "พันตู",
  "พันทาง",
  "พันธกรณี",
  "พันธกิจ",
  "พันธนะ",
  "พันธนาการ",
  "พันธนาคาร",
  "พันธบัตร",
  "พันธมิตร",
  "พันธะ",
  "พันธุกรรม",
  "พันธุวิศวกรรม",
  "พันธุศาสตร์",
  "พันธุ์",
  "พันธ์",
  "พันพัว",
  "พันลึก",
  "พันลือ",
  "พันเลิศ",
  "พันเอิญ",
  "พับ",
  "พับฐาน",
  "พับเพียบ",
  "พัลซาร์",
  "พัลลภ",
  "พัลวัน",
  "พัว",
  "พัวพัน",
  "พัวะ",
  "พัศดี",
  "พัสดุ",
  "พัสตร์",
  "พัสถาน",
  "พา",
  "พาก",
  "พากย์",
  "พากษ์",
  "พากเพียร",
  "พาง",
  "พาชี",
  "พาซื่อ",
  "พาณิช",
  "พาณิชยกรรม",
  "พาณิชยการ",
  "พาณิชยศาสตร์",
  "พาณิชยศิลป์",
  "พาณิชย์",
  "พาณินี",
  "พาณี",
  "พาณีนี",
  "พาด",
  "พาดพิง",
  "พาท",
  "พาทย์",
  "พาธ",
  "พาธา",
  "พาน",
  "พานร",
  "พานรินทร์",
  "พานาโซนิก",
  "พานาโซนิค",
  "พาม",
  "พาย",
  "พายม้า",
  "พายัพ",
  "พายุ",
  "พารณ",
  "พารา",
  "พารากอน",
  "พาราณสี",
  "พาราฟิน",
  "พารามิเตอร์",
  "พาราเซตามอล",
  "พาราโบลา",
  "พาร์",
  "พาร์กินสัน",
  "พาร์ตเนอร์",
  "พาร์ทิชัน",
  "พาร์ทเนอร์",
  "พาร์เซก",
  "พาล",
  "พาลา",
  "พาลี",
  "พาลุก",
  "พาวเวอร์",
  "พาส",
  "พาสตา",
  "พาสต้า",
  "พาสนา",
  "พาสน์",
  "พาสปอร์ต",
  "พาสุกรี",
  "พาสเจอร์ไรส์",
  "พาสเวิร์ด",
  "พาหนะ",
  "พาหะ",
  "พาหา",
  "พาหิรกะ",
  "พาหิระ",
  "พาหุ",
  "พาหุรัด",
  "พาหุสัจจะ",
  "พาฬ",
  "พาเนล",
  "พาเหรด",
  "พาเหียร",
  "พาโล",
  "พาไล",
  "พำ",
  "พำนัก",
  "พำพึม",
  "พำลา",
  "พิกล",
  "พิกสิต",
  "พิกัด",
  "พิกัติ",
  "พิกัน",
  "พิการ",
  "พิกุล",
  "พิกเซล",
  "พิฆน์",
  "พิฆาต",
  "พิฆเนศ",
  "พิฆเนศวร",
  "พิง",
  "พิจัย",
  "พิจาร",
  "พิจารณา",
  "พิจารณ์",
  "พิจิก",
  "พิจิต",
  "พิจิตร",
  "พิจิตร",
  "พิชญ์",
  "พิชัย",
  "พิชาน",
  "พิชิต",
  "พิซซ่า",
  "พิฑูรย์",
  "พิณ",
  "พิณพาทย์",
  "พิดทูล",
  "พิดาน",
  "พิตร",
  "พิถี",
  "พิถีพิถัน",
  "พิทย",
  "พิทยา",
  "พิทยาคม",
  "พิทยาคาร",
  "พิทยาธร",
  "พิทยานุกูล",
  "พิทยาลัย",
  "พิทย์",
  "พิทักษ์",
  "พิทูร",
  "พิธาน",
  "พิธี",
  "พิธีกร",
  "พิธีกรรม",
  "พิธีการ",
  "พิธีรีตอง",
  "พิธีสาร",
  "พิธุ",
  "พินทุ",
  "พินอบพิเทา",
  "พินอิน",
  "พินัย",
  "พินัยกรรม",
  "พินาศ",
  "พินิจ",
  "พินิต",
  "พินิศ",
  "พินิศจัย",
  "พิบัติ",
  "พิบุล",
  "พิบูล",
  "พิปริต",
  "พิปลาส",
  "พิพรรธ",
  "พิพรรธน์",
  "พิพักพิพ่วน",
  "พิพัฒ",
  "พิพัฒน์",
  "พิพากษา",
  "พิพากษ์",
  "พิพาท",
  "พิพิธ",
  "พิพิธภัณฑสถาน",
  "พิพิธภัณฑ์",
  "พิภพ",
  "พิภัช",
  "พิภาค",
  "พิภูษณะ",
  "พิมปะการัง",
  "พิมพการัง",
  "พิมพา",
  "พิมพาภรณ์",
  "พิมพิสาร",
  "พิมพ์",
  "พิมพ์ดีด",
  "พิมพ์เขียว",
  "พิมพ์ใจ",
  "พิมล",
  "พิมาน",
  "พิมุข",
  "พิมเสน",
  "พิรอด",
  "พิระ",
  "พิรากล",
  "พิราบ",
  "พิราม",
  "พิราลัย",
  "พิริยะ",
  "พิรี้พิไร",
  "พิรุณ",
  "พิรุธ",
  "พิรุฬห์",
  "พิลังกาสา",
  "พิลาป",
  "พิลาส",
  "พิลิปดา",
  "พิลึก",
  "พิลึกกึกกือ",
  "พิลึกพิลั่น",
  "พิศ",
  "พิศวง",
  "พิศวาส",
  "พิศาล",
  "พิศุทธิ์",
  "พิศุทธ์",
  "พิษ",
  "พิษฐาน",
  "พิษณุโลก",
  "พิษนาศน์",
  "พิษสง",
  "พิสดาร",
  "พิสมร",
  "พิสมัย",
  "พิสัง",
  "พิสัช",
  "พิสัย",
  "พิสิฐ",
  "พิสุทธิ์",
  "พิสูจน์",
  "พิหค",
  "พิหาร",
  "พิหารี",
  "พิฬาร",
  "พิเคราะห์",
  "พิเชฐ",
  "พิเชียร",
  "พิเทศ",
  "พิเนต",
  "พิเภก",
  "พิเรนทร์",
  "พิเราะ",
  "พิเศษ",
  "พิโดร",
  "พิโมกข์",
  "พิโมกษ์",
  "พิโยกพิเกน",
  "พิโยค",
  "พิโรธ",
  "พิโลน",
  "พิไร",
  "พิไล",
  "พี",
  "พี",
  "พีชคณิต",
  "พีระมิด",
  "พีเรียด",
  "พี่",
  "พี่น้อง",
  "พี่เบิ้ม",
  "พี่เลี้ยง",
  "พี้",
  "พึง",
  "พึงพอใจ",
  "พึงใจ",
  "พึม",
  "พึมพำ",
  "พึ่ง",
  "พึ่งพา",
  "พึ่งพิง",
  "พึ่บ",
  "พึ่บพั่บ",
  "พืช",
  "พืชพันธุ์",
  "พืชมงคล",
  "พืด",
  "พื้น",
  "พื้นฐาน",
  "พื้นที่",
  "พื้นบ้าน",
  "พื้นเพ",
  "พื้นเมือง",
  "พื้นเสีย",
  "พุ",
  "พุก",
  "พุกาม",
  "พุง",
  "พุงดอ",
  "พุฒ",
  "พุฒิ",
  "พุด",
  "พุดดิ้ง",
  "พุดตาน",
  "พุทธ",
  "พุทธกาล",
  "พุทธคยา",
  "พุทธคุณ",
  "พุทธจักร",
  "พุทธชาด",
  "พุทธฎีกา",
  "พุทธปฏิมา",
  "พุทธปฏิมากร",
  "พุทธภูมิ",
  "พุทธมามกะ",
  "พุทธรักษา",
  "พุทธศตวรรษ",
  "พุทธศักราช",
  "พุทธศาสนิกชน",
  "พุทธองค์",
  "พุทธะ",
  "พุทธังกูร",
  "พุทธันดร",
  "พุทธางกูร",
  "พุทธาภิเษก",
  "พุทธาวาส",
  "พุทธิ",
  "พุทธเจดีย์",
  "พุทรา",
  "พุทโธ",
  "พุทโธ่",
  "พุธ",
  "พุพอง",
  "พุมเรียง",
  "พุ่ง",
  "พุ่ม",
  "พุ่มพวง",
  "พุ่มไม้",
  "พุ้ย",
  "พู",
  "พูด",
  "พูดจา",
  "พูน",
  "พูพอน",
  "พูล",
  "พู่",
  "พู่กัน",
  "พู่ระหง",
  "พู้น",
  "พเนจร",
  "พเยีย",
  "พ่น",
  "พ่วง",
  "พ่วงพี",
  "พ่อ",
  "พ่อขุน",
  "พ่อครัว",
  "พ่อค้า",
  "พ่อตา",
  "พ่อบ้าน",
  "พ่อพันธุ์",
  "พ่อม่าย",
  "พ่อสื่อ",
  "พ่อเมือง",
  "พ่อเลี้ยง",
  "พ่ะย่ะค่ะ",
  "พ่าง",
  "พ่าน",
  "พ่าย",
  "พ่าห์",
  "พ้น",
  "พ้อ",
  "พ้อง",
  "พ้องพาน",
  "พ้อม",
  "ฟก",
  "ฟกช้ำ",
  "ฟยอร์ด",
  "ฟรอยด์",
  "ฟรักโทส",
  "ฟรังก์",
  "ฟรังซิส",
  "ฟรานซิส",
  "ฟรานซ์",
  "ฟรี",
  "ฟรุต",
  "ฟลอริดา",
  "ฟลอร์",
  "ฟลอเรนซ์",
  "ฟลาโวนอยด์",
  "ฟลุก",
  "ฟลุค",
  "ฟลุต",
  "ฟลุท",
  "ฟลูออรีน",
  "ฟลูออเรสเซนซ์",
  "ฟลูออไรด์",
  "ฟล็อปส์",
  "ฟอก",
  "ฟอง",
  "ฟองน้ำ",
  "ฟองมัน",
  "ฟองเต้าหู้",
  "ฟอด",
  "ฟอน",
  "ฟอนต์",
  "ฟอนเฟะ",
  "ฟอยล์",
  "ฟอรัม",
  "ฟอร์ด",
  "ฟอร์ม",
  "ฟอร์มาลดีไฮด์",
  "ฟอร์มาลิน",
  "ฟอร์เวิร์ด",
  "ฟอร์แมต",
  "ฟอสซิล",
  "ฟอสฟอรัส",
  "ฟอสเฟต",
  "ฟัก",
  "ฟักทอง",
  "ฟักฟุ้น",
  "ฟัง",
  "ฟังก์ชัน",
  "ฟัด",
  "ฟัดเฟียด",
  "ฟัน",
  "ฟันดาบ",
  "ฟันด์",
  "ฟันน้ำนม",
  "ฟันปลา",
  "ฟันฝ่า",
  "ฟันฟาง",
  "ฟันม้า",
  "ฟันหนู",
  "ฟันเฟือง",
  "ฟันเลื่อย",
  "ฟันแท้",
  "ฟั่น",
  "ฟั่นเฝือ",
  "ฟั่นเฟือน",
  "ฟั้น",
  "ฟาก",
  "ฟาง",
  "ฟาด",
  "ฟาทอม",
  "ฟาน",
  "ฟาย",
  "ฟาร์ม",
  "ฟาวล์",
  "ฟาสซิสต์",
  "ฟาสต์ฟู้ด",
  "ฟิจิ",
  "ฟิชชัน",
  "ฟิด",
  "ฟิต",
  "ฟินิกซ์",
  "ฟินแลนด์",
  "ฟิบ",
  "ฟิลาเดลเฟีย",
  "ฟิลิปปินส์",
  "ฟิลิปส์",
  "ฟิล์ม",
  "ฟิวชัน",
  "ฟิวส์",
  "ฟิวเจอร์",
  "ฟิสิกส์",
  "ฟีด",
  "ฟีเจอร์",
  "ฟีเวอร์",
  "ฟีโรโมน",
  "ฟี่",
  "ฟี้",
  "ฟืดฟาด",
  "ฟืน",
  "ฟืม",
  "ฟื้น",
  "ฟื้นตัว",
  "ฟื้นฝอย",
  "ฟื้นฟู",
  "ฟุ",
  "ฟุกุชิมะ",
  "ฟุกุชิมา",
  "ฟุกุโอกะ",
  "ฟุต",
  "ฟุตบอล",
  "ฟุตบาท",
  "ฟุน",
  "ฟุบ",
  "ฟุลสแก๊ป",
  "ฟุ่บ",
  "ฟุ่มเฟือย",
  "ฟุ้ง",
  "ฟุ้งซ่าน",
  "ฟุ้งเฟื่อง",
  "ฟุ้งเฟ้อ",
  "ฟู",
  "ฟูก",
  "ฟูกูโอกะ",
  "ฟูจิ",
  "ฟูด",
  "ฟูฟ่อง",
  "ฟูม",
  "ฟูมฟัก",
  "ฟูมฟาย",
  "ฟูเฟื่อง",
  "ฟู่",
  "ฟ่อ",
  "ฟ่อง",
  "ฟ่อน",
  "ฟ่าง",
  "ฟ่าม",
  "ฟ้ง",
  "ฟ้อ",
  "ฟ้อง",
  "ฟ้องกลับ",
  "ฟ้องร้อง",
  "ฟ้อน",
  "ฟ้อแฟ้",
  "ฟ้า",
  "ภ.ง.ด.",
  "ภ.ง.ด.๙",
  "ภ.ด.",
  "ภ.บ.",
  "ภ.บ.ท.๕",
  "ภ.ป.ร.",
  "ภ.พ.",
  "ภ.ม.",
  "ภ.สถ.บ.",
  "ภควดี",
  "ภควัต",
  "ภควัทคีตา",
  "ภควันต์",
  "ภควัม",
  "ภควัมบดี",
  "ภควัมปติ",
  "ภควา",
  "ภควาน",
  "ภคะ",
  "ภคันทลา",
  "ภคันทลาพาธ",
  "ภคินี",
  "ภณะ",
  "ภณิดา",
  "ภพ",
  "ภมร",
  "ภมริน",
  "ภมรี",
  "ภมุกา",
  "ภยันตราย",
  "ภยาคติ",
  "ภรณี",
  "ภรต",
  "ภรรดร",
  "ภรรดา",
  "ภรรยา",
  "ภระ",
  "ภระมร",
  "ภระมรี",
  "ภราดร",
  "ภราดรภาพ",
  "ภราดา",
  "ภริยา",
  "ภฤศ",
  "ภวตัณหา",
  "ภวนะ",
  "ภววิสัย",
  "ภวะ",
  "ภวังคจิต",
  "ภวังค์",
  "ภักดี",
  "ภักตะ",
  "ภักติ",
  "ภักษา",
  "ภักษาหาร",
  "ภักษ์",
  "ภัค",
  "ภัคน์",
  "ภังคะ",
  "ภังคี",
  "ภัจ",
  "ภัณฑนะ",
  "ภัณฑาคาร",
  "ภัณฑาคาริก",
  "ภัณฑารักษ์",
  "ภัณฑู",
  "ภัณฑ์",
  "ภัต",
  "ภัตกิจ",
  "ภัตตาคาร",
  "ภัตตาหาร",
  "ภัตร",
  "ภัทรกัป",
  "ภัทระ",
  "ภัพ",
  "ภัย",
  "ภัสดา",
  "ภัสตรา",
  "ภัสมะ",
  "ภัสสร",
  "ภา",
  "ภาค",
  "ภาคทัณฑ์",
  "ภาคพื้น",
  "ภาคภูมิ",
  "ภาคยานุวัติ",
  "ภาคย์",
  "ภาคินี",
  "ภาคิไนย",
  "ภาคี",
  "ภาคียะ",
  "ภาคเรียน",
  "ภาชนะ",
  "ภาชี",
  "ภาณ",
  "ภาณกะ",
  "ภาณวาร",
  "ภาณี",
  "ภาณุ",
  "ภาดร",
  "ภาดา",
  "ภาตระ",
  "ภาตา",
  "ภาติกะ",
  "ภาติยะ",
  "ภาตุ",
  "ภาพ",
  "ภาพถ่าย",
  "ภาพนิ่ง",
  "ภาพประกอบ",
  "ภาพพจน์",
  "ภาพยนตร์",
  "ภาพย์",
  "ภาพลวงตา",
  "ภาพลักษณ์",
  "ภาม",
  "ภาย",
  "ภายนอก",
  "ภายหน้า",
  "ภายหลัง",
  "ภายใน",
  "ภาร",
  "ภารกิจ",
  "ภารดี",
  "ภารต",
  "ภารตวิทยา",
  "ภารตะ",
  "ภารตี",
  "ภารธุระ",
  "ภารยทรัพย์",
  "ภารยา",
  "ภาระ",
  "ภารา",
  "ภารโรง",
  "ภาวนา",
  "ภาวะ",
  "ภาษ",
  "ภาษณ์",
  "ภาษา",
  "ภาษาศาสตร์",
  "ภาษิต",
  "ภาษี",
  "ภาส",
  "ภาสกร",
  "ภาสน์",
  "ภาสวร",
  "ภาสา",
  "ภาสุระ",
  "ภิกขา",
  "ภิกขาจาร",
  "ภิกขุ",
  "ภิกขุนี",
  "ภิกษา",
  "ภิกษาจาร",
  "ภิกษาหาร",
  "ภิกษุ",
  "ภิกษุณี",
  "ภิงคาร",
  "ภิญโญ",
  "ภิญโญภาพ",
  "ภิตติ",
  "ภินชาติ",
  "ภินทนาการ",
  "ภินท์",
  "ภิยโย",
  "ภิรมย์",
  "ภิรมย์สุรางค์",
  "ภิษัช",
  "ภิสัก",
  "ภีตะ",
  "ภีมะ",
  "ภีรุ",
  "ภุกต์",
  "ภุขัน",
  "ภุช",
  "ภุชงค์",
  "ภุต",
  "ภุมมะ",
  "ภุมรัตน์",
  "ภุมรา",
  "ภุมริน",
  "ภุมรี",
  "ภุมวาร",
  "ภุมเรศ",
  "ภู",
  "ภูฏาน",
  "ภูฐาน",
  "ภูต",
  "ภูตคาม",
  "ภูตบดี",
  "ภูตรูป",
  "ภูติ",
  "ภูธร",
  "ภูธเรศ",
  "ภูบาล",
  "ภูผา",
  "ภูม",
  "ภูมิ",
  "ภูมิคุ้มกัน",
  "ภูมิซรอล",
  "ภูมิฐาน",
  "ภูมิทัศน์",
  "ภูมิธรรม",
  "ภูมินทร์",
  "ภูมิบาล",
  "ภูมิประเทศ",
  "ภูมิปัญญา",
  "ภูมิพล",
  "ภูมิภาค",
  "ภูมิรัฐศาสตร์",
  "ภูมิรู้",
  "ภูมิลำเนา",
  "ภูมิศาสตร์",
  "ภูมิอากาศ",
  "ภูมิแพ้",
  "ภูมิใจ",
  "ภูมี",
  "ภูริ",
  "ภูริทัต",
  "ภูรี",
  "ภูวดล",
  "ภูวนาถ",
  "ภูวเนตร",
  "ภูวไนย",
  "ภูษา",
  "ภูษาโยง",
  "ภูษิต",
  "ภูเก็ต",
  "ภูเขา",
  "ภูเขาไฟ",
  "ภูเตศวร",
  "ภูเบศ",
  "ภูเบศวร์",
  "ภู่",
  "ม.ค.",
  "ม.จ.",
  "ม.ป.ท.",
  "ม.ป.ป.",
  "ม.ป.พ.",
  "ม.ร.ว.",
  "ม.ศ.",
  "ม.อ.",
  "ม.อ.ปัตตานี",
  "มกร",
  "มกรา",
  "มกราคม",
  "มกุฎ",
  "มกุฎราชกุมาร",
  "มคธ",
  "มฆวัน",
  "มฆะ",
  "มฆา",
  "มงกุฎ",
  "มงคล",
  "มงคลวาร",
  "มงคลสูตร",
  "มงคลหัตถี",
  "มงคลแฝด",
  "มงฟอร์ต",
  "มงโกรย",
  "มณฑก",
  "มณฑนะ",
  "มณฑป",
  "มณฑล",
  "มณฑา",
  "มณฑารพ",
  "มณฑิระ",
  "มณฑ์",
  "มณี",
  "มณเฑียร",
  "มณเฑียรบาล",
  "มณโฑ",
  "มด",
  "มดดำ",
  "มดยอบ",
  "มดลูก",
  "มดเท็จ",
  "มดแดง",
  "มตกภัต",
  "มตะ",
  "มติ",
  "มติชน",
  "มถุรา",
  "มทนะ",
  "มทะ",
  "มธุ",
  "มธุกร",
  "มธุการี",
  "มธุปายาส",
  "มธุรพจน์",
  "มธุรส",
  "มธุระ",
  "มธุลีห์",
  "มน",
  "มนตรี",
  "มนตร์",
  "มนต์",
  "มนทิราลัย",
  "มนท์",
  "มนสิการ",
  "มนัส",
  "มนัสวี",
  "มนินทรีย์",
  "มนินทรีย์",
  "มนิมนา",
  "มนิลา",
  "มนุญ",
  "มนุษยชาติ",
  "มนุษยธรรม",
  "มนุษยศาสตร์",
  "มนุษยสัมพันธ์",
  "มนุษยโลก",
  "มนุษย์",
  "มนุษย์กบ",
  "มนุษย์มนา",
  "มนุสาร",
  "มนู",
  "มนูสาร",
  "มนเทียร",
  "มนเทียรบาล",
  "มมังการ",
  "มยุรฉัตร",
  "มยุระ",
  "มยุรา",
  "มยุราภิรมย์",
  "มยุรี",
  "มยุเรศ",
  "มยูร",
  "มรกต",
  "มรคา",
  "มรฑป",
  "มรณกรรม",
  "มรณบัตร",
  "มรณภัย",
  "มรณภาพ",
  "มรณะ",
  "มรณ์",
  "มรดก",
  "มรรค",
  "มรรคนายก",
  "มรรคผล",
  "มรรคา",
  "มรรตัย",
  "มรรยาท",
  "มรรษ",
  "มรสุม",
  "มริจ",
  "มริยาท",
  "มรีจิ",
  "มรุต",
  "มฤค",
  "มฤคชาติ",
  "มฤคทายวัน",
  "มฤคย์",
  "มฤคราช",
  "มฤคศิรมาส",
  "มฤคศิระ",
  "มฤคินทร์",
  "มฤคเศียร",
  "มฤดก",
  "มฤต",
  "มฤตยู",
  "มฤทุ",
  "มฤเคนทร์",
  "มล",
  "มลทิน",
  "มลพิษ",
  "มลภาวะ",
  "มลยาฬัม",
  "มลสาร",
  "มละ",
  "มลัก",
  "มลังเมลือง",
  "มลาย",
  "มลายู",
  "มล้าง",
  "มวก",
  "มวกเหล็ก",
  "มวน",
  "มวย",
  "มวยปล้ำ",
  "มวยล้ม",
  "มวยวัด",
  "มวยสากล",
  "มวยหมู่",
  "มวยไทย",
  "มวล",
  "มวลชน",
  "มวลสาร",
  "มหกรรม",
  "มหภาค",
  "มหรณพ",
  "มหรรณพ",
  "มหรสพ",
  "มหัจฉริยะ",
  "มหัต",
  "มหัทธนะ",
  "มหันตโทษ",
  "มหันต์",
  "มหัพภาค",
  "มหัศจรรย์",
  "มหา",
  "มหากฐิน",
  "มหากาฬ",
  "มหาขันธกะ",
  "มหาจักร",
  "มหาชน",
  "มหาชัย",
  "มหาชาติ",
  "มหาดเล็ก",
  "มหาดไทย",
  "มหาตมะ",
  "มหาธาตุ",
  "มหานิกาย",
  "มหานิล",
  "มหาบพิตร",
  "มหาบัณฑิต",
  "มหาพน",
  "มหาพรหม",
  "มหาภารตะ",
  "มหาภิเนษกรมณ์",
  "มหาภูต",
  "มหายาน",
  "มหายุค",
  "มหาราช",
  "มหาฤกษ์",
  "มหาละลวย",
  "มหาละลาย",
  "มหาวงศ์",
  "มหาวรรค",
  "มหาวิทยาลัย",
  "มหาศักราช",
  "มหาศาล",
  "มหาสงกรานต์",
  "มหาสดมภ์",
  "มหาสดำ",
  "มหาสมุทร",
  "มหาสารคาม",
  "มหาสาวก",
  "มหาหงส์",
  "มหาหิงคุ์",
  "มหาอำนาจ",
  "มหาอุจ",
  "มหาอุด",
  "มหาอุปรากร",
  "มหาอุปราช",
  "มหาอุปราชา",
  "มหาเทพ",
  "มหาเทพี",
  "มหาเทวี",
  "มหาเมฆ",
  "มหาโชตรัต",
  "มหาไถ่",
  "มหิ",
  "มหิงส์",
  "มหิดล",
  "มหิตลาธิเบศร",
  "มหิทธิ",
  "มหิธร",
  "มหินท์",
  "มหิป",
  "มหิมา",
  "มหิศร",
  "มหิศวร",
  "มหิษ",
  "มหิษี",
  "มหึมา",
  "มอ",
  "มอคคา",
  "มอคค่า",
  "มอคราม",
  "มอง",
  "มองคร่อ",
  "มองเมียง",
  "มองโกล",
  "มองโกลอยด์",
  "มองโกเลีย",
  "มองโกเลีย",
  "มอซอ",
  "มอญ",
  "มอด",
  "มอดูล",
  "มอน",
  "มอนสเตอร์",
  "มอนอกไซด์",
  "มอนิเตอร์",
  "มอนเตเนโกร",
  "มอนแทนา",
  "มอบ",
  "มอบตัว",
  "มอบตัว",
  "มอบหมาย",
  "มอม",
  "มอมเมา",
  "มอมแมม",
  "มอยส์เจอไรเซอร์",
  "มอระกู่",
  "มอริเชียส",
  "มอริเตเนีย",
  "มอร์ฟีน",
  "มอลตา",
  "มอลล์",
  "มอลโดวา",
  "มอลโทส",
  "มอสโก",
  "มอหมึก",
  "มอเตอร์",
  "มอเตอร์ไซค์",
  "มะ",
  "มะกรูด",
  "มะกล่ำ",
  "มะกอก",
  "มะกะโรนี",
  "มะกัน",
  "มะกา",
  "มะก่อง",
  "มะขวิด",
  "มะขาม",
  "มะขามป้อม",
  "มะขามเทศ",
  "มะขามเปียก",
  "มะข่วง",
  "มะคังแดง",
  "มะคำดีควาย",
  "มะคำไก่",
  "มะค่า",
  "มะงั่ว",
  "มะงุมมะงาหรา",
  "มะซัก",
  "มะซาง",
  "มะดัน",
  "มะดีหวี",
  "มะดูก",
  "มะตะบะ",
  "มะตาด",
  "มะตาหะรี",
  "มะตึ่ง",
  "มะตื๋น",
  "มะตูม",
  "มะต้อง",
  "มะนาว",
  "มะนิลา",
  "มะปราง",
  "มะปริง",
  "มะฝ่อ",
  "มะพร้าว",
  "มะพร้าวแก้ว",
  "มะพลับ",
  "มะพูด",
  "มะมี่",
  "มะมื่น",
  "มะมุด",
  "มะม่วง",
  "มะม่าว",
  "มะยง",
  "มะยม",
  "มะระ",
  "มะริด",
  "มะรืน",
  "มะรุม",
  "มะรุมมะตุ้ม",
  "มะลอกมะแลก",
  "มะละกอ",
  "มะละกา",
  "มะละแหม่ง",
  "มะลิ",
  "มะลืมดำ",
  "มะลื่น",
  "มะลุลี",
  "มะสัง",
  "มะหวด",
  "มะหะหมัด",
  "มะหาด",
  "มะหิ่ง",
  "มะอึก",
  "มะฮอกกานี",
  "มะเกลือ",
  "มะเกี๋ยง",
  "มะเขือ",
  "มะเขือพวง",
  "มะเขือเทศ",
  "มะเดหวี",
  "มะเดื่อ",
  "มะเฟือง",
  "มะเมอ",
  "มะเมีย",
  "มะเมื่อย",
  "มะเรื่อง",
  "มะเร็ง",
  "มะเส็ง",
  "มะเหงก",
  "มะแข่น",
  "มะแตก",
  "มะแพน",
  "มะแพร้ว",
  "มะแฟน",
  "มะแม",
  "มะแว้ง",
  "มะโต",
  "มะโรง",
  "มะไฟ",
  "มัก",
  "มักกะลีผล",
  "มักกะสัน",
  "มักกะโรนี",
  "มักขะ",
  "มักขิกา",
  "มักคุ้น",
  "มักง่าย",
  "มักจี่",
  "มักน้อย",
  "มักมาก",
  "มักใหญ่",
  "มัค",
  "มัคคะ",
  "มัคคุเทศก์",
  "มัคนายก",
  "มัฆวาน",
  "มังกง",
  "มังกร",
  "มังกุ",
  "มังคละ",
  "มังคุด",
  "มังค่า",
  "มังตาน",
  "มังสวิรัติ",
  "มังสะ",
  "มังสี",
  "มัจจะ",
  "มัจจุ",
  "มัจจุราช",
  "มัจฉริยะ",
  "มัจฉรี",
  "มัจฉะ",
  "มัจฉา",
  "มัจฉานุ",
  "มัชชะ",
  "มัชชาระ",
  "มัชฌันติกสมัย",
  "มัชฌิม",
  "มัชฌิมนิกาย",
  "มัชฌิมประเทศ",
  "มัชฌิมยาม",
  "มัชฌิมวัย",
  "มัชฌิมา",
  "มัชวิรัติ",
  "มัญจา",
  "มัญชิษฐา",
  "มัญชุ",
  "มัญชุสา",
  "มัญชูสา",
  "มัญเชฏฐะ",
  "มัฏฐะ",
  "มัณฑนศิลป์",
  "มัณฑนา",
  "มัณฑะเลย์",
  "มัด",
  "มัดจำ",
  "มัดหมี่",
  "มัตตะ",
  "มัตตัญญู",
  "มัตตา",
  "มัตติกา",
  "มัตถกะ",
  "มัตถลุงค์",
  "มัตสยะ",
  "มัตสยา",
  "มัตสระ",
  "มัตสริน",
  "มัททวะ",
  "มัทนะ",
  "มัทยะ",
  "มัทราส",
  "มัธยฐาน",
  "มัธยม",
  "มัธยมกาล",
  "มัธยมศึกษา",
  "มัธยันห์",
  "มัธยัสถ์",
  "มัน",
  "มันตา",
  "มันถะ",
  "มันทิระ",
  "มันทิราลัย",
  "มันฝรั่ง",
  "มันสมอง",
  "มันเทศ",
  "มันเปลว",
  "มันแกว",
  "มับ",
  "มัฟฟิน",
  "มัมมี่",
  "มัย",
  "มัลดีฟส์",
  "มัลติ",
  "มัลติทัช",
  "มัลติเพล็กซ์",
  "มัลละ",
  "มัลลิกา",
  "มัลแวร์",
  "มัว",
  "มัวซัว",
  "มัวหมอง",
  "มัวเมา",
  "มัศยา",
  "มัสตาร์ด",
  "มัสตุ",
  "มัสมั่น",
  "มัสยิด",
  "มัสรู่",
  "มัสลิน",
  "มัสสุ",
  "มั่กขั้ก",
  "มั่ง",
  "มั่งคั่ง",
  "มั่งมี",
  "มั่น",
  "มั่นคง",
  "มั่นหมาย",
  "มั่นเหมาะ",
  "มั่นใจ",
  "มั่ว",
  "มั่วสุม",
  "มั้ง",
  "มั้ม",
  "มั้ย",
  "มา",
  "มาก",
  "มากมาย",
  "มาคสิระ",
  "มาฆบูชา",
  "มาฆะ",
  "มางสะ",
  "มาซิโดเนีย",
  "มาณพ",
  "มาณวิกา",
  "มาด",
  "มาดริด",
  "มาดา",
  "มาดากัสการ์",
  "มาตงค์",
  "มาตร",
  "มาตรการ",
  "มาตรฐาน",
  "มาตรา",
  "มาตราส่วน",
  "มาตฤ",
  "มาตังคะ",
  "มาตา",
  "มาตามหัยกะ",
  "มาตามหัยกา",
  "มาตามหัยยิกา",
  "มาติกะ",
  "มาติกา",
  "มาตุ",
  "มาตุคาม",
  "มาตุฆาต",
  "มาตุจฉา",
  "มาตุภูมิ",
  "มาตุรงค์",
  "มาตุละ",
  "มาตุลา",
  "มาตุลานี",
  "มาตุเรศ",
  "มาทะ",
  "มาธยมิก",
  "มาธยมิกะ",
  "มาธุระ",
  "มาธุสร",
  "มาธูระ",
  "มาน",
  "มานพ",
  "มานะ",
  "มานัต",
  "มานัส",
  "มานิต",
  "มานี",
  "มานุษ",
  "มานุษยวิทยา",
  "มาบ",
  "มาภา",
  "มาม่า",
  "มายองเนส",
  "มายัน",
  "มายา",
  "มายากร",
  "มายากล",
  "มายาการ",
  "มายาคติ",
  "มายาวี",
  "มาร",
  "มารค",
  "มารดร",
  "มารดา",
  "มารผจญ",
  "มารยา",
  "มารยาท",
  "มารวิชัย",
  "มารศรี",
  "มารษา",
  "มารสังคม",
  "มารหัวขน",
  "มาราฐี",
  "มาราธอน",
  "มาราธิราช",
  "มาริต",
  "มารุต",
  "มาร์ก",
  "มาร์กาเร็ต",
  "มาร์ค",
  "มาร์จิน",
  "มาร์ช",
  "มาร์ต",
  "มาร์ติน",
  "มาร์ท",
  "มาร์เก็ต",
  "มาร์เก็ตติ้ง",
  "มาร์แชลล์",
  "มาลย์",
  "มาลัย",
  "มาลา",
  "มาลาการ",
  "มาลาตี",
  "มาลาวี",
  "มาลาเรีย",
  "มาลินี",
  "มาลี",
  "มาลี",
  "มาลุต",
  "มาศ",
  "มาส",
  "มาสก",
  "มาสด้า",
  "มาสเตอร์",
  "มาหิส",
  "มาห์",
  "มาฬก",
  "มาเก๊า",
  "มาเฟีย",
  "มาเลย์",
  "มาเลเซีย",
  "มาโนชญ์",
  "มิ",
  "มิ.ย.",
  "มิค",
  "มิคสัญญี",
  "มิจฉา",
  "มิจฉาจาร",
  "มิจฉาชีพ",
  "มิชิแกน",
  "มิด",
  "มิดชิด",
  "มิดหมี",
  "มิดเมี้ยน",
  "มิตซูบิชิ",
  "มิตร",
  "มิตรจิต",
  "มิตรภาพ",
  "มิตรสหาย",
  "มิติ",
  "มิถยา",
  "มิถิลา",
  "มิถุน",
  "มิถุนา",
  "มิถุนายน",
  "มิทธะ",
  "มินดาเนา",
  "มินตรา",
  "มินตา",
  "มินต์",
  "มินท์",
  "มินนิโซตา",
  "มินหม้อ",
  "มินิ",
  "มิน่า",
  "มิยางิ",
  "มิรันตี",
  "มิราจ",
  "มิราเคิล",
  "มิลค์",
  "มิลลิกรัม",
  "มิลลิบาร์",
  "มิลลิลิตร",
  "มิลลิเมตร",
  "มิลักขะ",
  "มิลักขู",
  "มิลาน",
  "มิวนิก",
  "มิวสิค",
  "มิส",
  "มิสกรี",
  "มิสกวัน",
  "มิสซัง",
  "มิสซา",
  "มิสซิสซิปปี",
  "มิสซูรี",
  "มิสเตอร์",
  "มิสไซล์",
  "มิเตอร์",
  "มิไย",
  "มิ่ง",
  "มิ่งขวัญ",
  "มิ่งมิตร",
  "มิ่ม",
  "มิ้ม",
  "มี",
  "มี.ค.",
  "มีด",
  "มีดดาบ",
  "มีดพก",
  "มีดพับ",
  "มีดสั้น",
  "มีดโกน",
  "มีดโต้",
  "มีน",
  "มีนา",
  "มีนาคม",
  "มีหน้า",
  "มีเดีย",
  "มีเทน",
  "มี่",
  "มี่สั้ว",
  "มึง",
  "มึน",
  "มึนงง",
  "มึนชา",
  "มึนตึง",
  "มึนเมา",
  "มืด",
  "มืดครึ้ม",
  "มืดมน",
  "มืดมัว",
  "มืน",
  "มือ",
  "มือจับ",
  "มือดี",
  "มือถือ",
  "มือปืน",
  "มือมืด",
  "มือสอง",
  "มือหนึ่ง",
  "มือเติบ",
  "มือเปล่า",
  "มือเสือ",
  "มือใหม่",
  "มื่น",
  "มื้อ",
  "มุ",
  "มุก",
  "มุกดา",
  "มุกดาหาร",
  "มุกดาหาร",
  "มุกตลก",
  "มุกุระ",
  "มุข",
  "มุขปาฐะ",
  "มุขมนตรี",
  "มุขยประโยค",
  "มุขเด็จ",
  "มุคคะ",
  "มุง",
  "มุจฉา",
  "มุจนะ",
  "มุจลินท์",
  "มุญจนะ",
  "มุญชะ",
  "มุฐิ",
  "มุณฑกะ",
  "มุณฑะ",
  "มุด",
  "มุตกิด",
  "มุตฆาต",
  "มุตตะ",
  "มุตตา",
  "มุตติ",
  "มุตะ",
  "มุติ",
  "มุททา",
  "มุทธชะ",
  "มุทธา",
  "มุทธาภิเษก",
  "มุทรา",
  "มุทริกา",
  "มุทะลุ",
  "มุทา",
  "มุทิกา",
  "มุทิงค์",
  "มุทิตา",
  "มุทุ",
  "มุทุตา",
  "มุนิ",
  "มุนินทร์",
  "มุนี",
  "มุบ",
  "มุบมิบ",
  "มุม",
  "มุมกลับ",
  "มุมก้ม",
  "มุมฉาก",
  "มุมตรง",
  "มุมป้าน",
  "มุมมอง",
  "มุมมืด",
  "มุมสะท้อน",
  "มุมหักเห",
  "มุมเงย",
  "มุมแย้ง",
  "มุมแหลม",
  "มุมไบ",
  "มุรธา",
  "มุรธาภิเษก",
  "มุสละ",
  "มุสลิม",
  "มุสา",
  "มุสาวาท",
  "มุสิก",
  "มุสโสลินี",
  "มุหงิด",
  "มุหน่าย",
  "มุหุต",
  "มุฮัมมัด",
  "มุโขโลกนะ",
  "มุ่ง",
  "มุ่งมั่น",
  "มุ่งมาด",
  "มุ่งหน้า",
  "มุ่งหมาย",
  "มุ่งหวัง",
  "มุ่น",
  "มุ่ย",
  "มุ้ง",
  "มุ้งลวด",
  "มุ้งสายบัว",
  "มุ้ม",
  "มูก",
  "มูกมัน",
  "มูกหลวง",
  "มูกเลือด",
  "มูตร",
  "มูน",
  "มูนดิน",
  "มูมมาม",
  "มูรติ",
  "มูรธา",
  "มูรธาภิเษก",
  "มูล",
  "มูลค่า",
  "มูลฐาน",
  "มูลนาย",
  "มูลนิธิ",
  "มูลฝอย",
  "มูละ",
  "มูลา",
  "มูลิกากร",
  "มูลเหตุ",
  "มูสัง",
  "มูสิก",
  "มูสิกทันต์",
  "มูสิกะ",
  "มูเซอ",
  "มู่ทู่",
  "มู่ลี่",
  "มู่เล่",
  "มเหยงค์",
  "มเหศ",
  "มเหศวร",
  "มเหศักดิ์",
  "มเหสักข์",
  "มเหสิ",
  "มเหสี",
  "มเหาฬาร",
  "มโน",
  "มโนกรรม",
  "มโนคติ",
  "มโนช",
  "มโนชญ์",
  "มโนทุจริต",
  "มโนธรรม",
  "มโนภาพ",
  "มโนมัย",
  "มโนรถ",
  "มโนรมย์",
  "มโนราห์",
  "มโนสาเร่",
  "มโนสุจริต",
  "มโนห์รา",
  "มโหรสพ",
  "มโหระทึก",
  "มโหรี",
  "มโหสถ",
  "มโหฬาร",
  "มไหศวรรย์",
  "ม็อบ",
  "ม่ง",
  "ม่น",
  "ม่วง",
  "ม่วน",
  "ม่อต้อ",
  "ม่อน",
  "ม่อย",
  "ม่อลอกม่อแลก",
  "ม่อห้อม",
  "ม่อฮ่อม",
  "ม่าน",
  "ม่านตา",
  "ม่านบังตา",
  "ม่าย",
  "ม่าห์",
  "ม่าเหมี่ยว",
  "ม้ง",
  "ม้วน",
  "ม้วนต้วน",
  "ม้วนหน้า",
  "ม้วย",
  "ม้า",
  "ม้าง",
  "ม้าน",
  "ม้านั่ง",
  "ม้าน้ำ",
  "ม้าม",
  "ม้ามืด",
  "ม้าลาย",
  "ม้าล่อ",
  "ม้าหินอ่อน",
  "ม้าเทศ",
  "ม้าเร็ว",
  "ยก",
  "ยกกระบัตร",
  "ยกกลีบ",
  "ยกครู",
  "ยกทรง",
  "ยกนะ",
  "ยกฟ้อง",
  "ยกยอ",
  "ยกย่อง",
  "ยกเครื่อง",
  "ยกเค้า",
  "ยกเมฆ",
  "ยกเลิก",
  "ยกเว้น",
  "ยง",
  "ยงโย่",
  "ยชุรเวท",
  "ยติ",
  "ยติภังค์",
  "ยถากรรม",
  "ยถาภูตญาณ",
  "ยนตรกรรม",
  "ยนตร์",
  "ยนต์",
  "ยม",
  "ยมก",
  "ยมทูต",
  "ยมนา",
  "ยมบาล",
  "ยมราช",
  "ยมล",
  "ยมะ",
  "ยมุนา",
  "ยมโดย",
  "ยมโลก",
  "ยรรยง",
  "ยล",
  "ยวง",
  "ยวด",
  "ยวดยาน",
  "ยวดยิ่ง",
  "ยวน",
  "ยวนยี",
  "ยวบ",
  "ยวบยาบ",
  "ยวรยาตร",
  "ยศ",
  "ยศ.ทบ.",
  "ยศ.ทร.",
  "ยศ.ทอ.",
  "ยอ",
  "ยอก",
  "ยอกย้อน",
  "ยอง",
  "ยองใย",
  "ยอด",
  "ยอดอก",
  "ยอดเยี่ยม",
  "ยอน",
  "ยอบ",
  "ยอบแยบ",
  "ยอม",
  "ยอมความ",
  "ยอมรับ",
  "ยะ",
  "ยะยอบ",
  "ยะยับ",
  "ยะลา",
  "ยะเยือก",
  "ยะโฮวา",
  "ยะโฮวาห์",
  "ยะไข่",
  "ยัก",
  "ยักขินี",
  "ยักข์",
  "ยักยอก",
  "ยักย้าย",
  "ยักษา",
  "ยักษิณี",
  "ยักษี",
  "ยักษ์",
  "ยักเยื้อง",
  "ยัง",
  "ยังไง",
  "ยัชโญปวีต",
  "ยัญ",
  "ยัญกรรม",
  "ยัญญะ",
  "ยัญพิธี",
  "ยัด",
  "ยัดเยียด",
  "ยัติภังค์",
  "ยัน",
  "ยันตร",
  "ยันตรกรรม",
  "ยันตร์",
  "ยันต์",
  "ยับ",
  "ยับยั้ง",
  "ยับเยิน",
  "ยัวรยาตร",
  "ยัวะ",
  "ยัษฏิ",
  "ยั่งยืน",
  "ยั่น",
  "ยั่นตะนี",
  "ยั่ว",
  "ยั่วยวน",
  "ยั่วยุ",
  "ยั่วเย้า",
  "ยั้ง",
  "ยั้ว",
  "ยั้วเยี้ย",
  "ยา",
  "ยาก",
  "ยากจน",
  "ยากวาด",
  "ยากันยุง",
  "ยากี้",
  "ยากูซ่า",
  "ยากเย็น",
  "ยากแค้น",
  "ยากไร้",
  "ยาคุ",
  "ยาคู",
  "ยาง",
  "ยางนอก",
  "ยางพารา",
  "ยางมะตอย",
  "ยางมะตูม",
  "ยางลบ",
  "ยางสน",
  "ยางอาย",
  "ยางใน",
  "ยาจก",
  "ยาจนา",
  "ยาฉุน",
  "ยาชา",
  "ยาซัด",
  "ยาด",
  "ยาดอง",
  "ยาดา",
  "ยาตร",
  "ยาตรา",
  "ยาถ่าย",
  "ยาธาตุ",
  "ยาน",
  "ยานคาง",
  "ยานพาหนะ",
  "ยานมาศ",
  "ยานอวกาศ",
  "ยานัตถุ์",
  "ยานี",
  "ยานุมาศ",
  "ยานเกราะ",
  "ยาพิษ",
  "ยาม",
  "ยามะ",
  "ยามักการ",
  "ยามา",
  "ยามาฮ่า",
  "ยาย",
  "ยายทวด",
  "ยายี",
  "ยาระบาย",
  "ยาว",
  "ยาวกาลิก",
  "ยาวชีวิก",
  "ยาวยืด",
  "ยาวี",
  "ยาวเฟื้อย",
  "ยาวเหยียด",
  "ยาสลบ",
  "ยาสั่ง",
  "ยาสีฟัน",
  "ยาสูบ",
  "ยาสูบ",
  "ยาหม่อง",
  "ยาหยี",
  "ยาฮู",
  "ยาเขียว",
  "ยาเบื่อ",
  "ยาเวห์",
  "ยาเสพติด",
  "ยาเส้น",
  "ยาเหลือง",
  "ยาแดง",
  "ยาแฝด",
  "ยาโป๊",
  "ยาใจ",
  "ยาไฉน",
  "ยำ",
  "ยำทวาย",
  "ยำเกรง",
  "ยำเยง",
  "ยำเยีย",
  "ยำใหญ่",
  "ยิก",
  "ยิง",
  "ยิงเป้า",
  "ยิฏฐะ",
  "ยิน",
  "ยินดี",
  "ยินยอม",
  "ยินร้าย",
  "ยิบ",
  "ยิบหยี",
  "ยิปซัม",
  "ยิปซี",
  "ยิม",
  "ยิมนาสติก",
  "ยิว",
  "ยิหวา",
  "ยิ่ง",
  "ยิ่งนัก",
  "ยิ่งยวด",
  "ยิ่งใหญ่",
  "ยิ้ม",
  "ยิ้มกริ่ม",
  "ยิ้มเผล่",
  "ยิ้มเยาะ",
  "ยิ้มแฉ่ง",
  "ยิ้มแต้",
  "ยิ้มแป้น",
  "ยิ้มแย้ม",
  "ยี",
  "ยีน",
  "ยียวน",
  "ยีราฟ",
  "ยีสต์",
  "ยี่",
  "ยี่ก่า",
  "ยี่ภู่",
  "ยี่สก",
  "ยี่สง",
  "ยี่สน",
  "ยี่สาน",
  "ยี่สุ่น",
  "ยี่หระ",
  "ยี่หร่า",
  "ยี่หุบ",
  "ยี่ห้อ",
  "ยี่เก",
  "ยี่เข่ง",
  "ยี่โถ",
  "ยี่โป้",
  "ยี้",
  "ยึกยัก",
  "ยึกยือ",
  "ยึด",
  "ยึดครอง",
  "ยึดถือ",
  "ยึดมั่น",
  "ยึดเหนี่ยว",
  "ยืด",
  "ยืดยาด",
  "ยืดยาว",
  "ยืดหยุ่น",
  "ยืดอก",
  "ยืดเยื้อ",
  "ยืน",
  "ยืนกราน",
  "ยืนต้น",
  "ยืนพื้น",
  "ยืนยง",
  "ยืนยัน",
  "ยืนหยัด",
  "ยืม",
  "ยื่น",
  "ยื้อ",
  "ยื้อยุด",
  "ยุ",
  "ยุกกระบัตร",
  "ยุกดิ",
  "ยุกติ",
  "ยุกติธรรม",
  "ยุกต์",
  "ยุค",
  "ยุคทอง",
  "ยุคนธร",
  "ยุคมืด",
  "ยุคล",
  "ยุคลบาท",
  "ยุคันต์",
  "ยุคันธร",
  "ยุคุนธร",
  "ยุคเข็ญ",
  "ยุง",
  "ยุด",
  "ยุต",
  "ยุติ",
  "ยุติธรรม",
  "ยุทธ",
  "ยุทธการ",
  "ยุทธนา",
  "ยุทธนาการ",
  "ยุทธนาธิการ",
  "ยุทธนาวี",
  "ยุทธปัจจัย",
  "ยุทธภัณฑ์",
  "ยุทธภูมิ",
  "ยุทธวิธี",
  "ยุทธศาสตร์",
  "ยุทธหัตถี",
  "ยุทธ์",
  "ยุทโธปกรณ์",
  "ยุบ",
  "ยุบยับ",
  "ยุบยิบ",
  "ยุบล",
  "ยุพดี",
  "ยุพราช",
  "ยุพา",
  "ยุพาน",
  "ยุพาพาล",
  "ยุพาพิน",
  "ยุพเรศ",
  "ยุยง",
  "ยุรยาตร",
  "ยุวชน",
  "ยุวดี",
  "ยุวทูต",
  "ยุวราช",
  "ยุวา",
  "ยุวาน",
  "ยุแยง",
  "ยุแหย่",
  "ยุโรป",
  "ยุ่ง",
  "ยุ่งขิง",
  "ยุ่งยาก",
  "ยุ่งเหยิง",
  "ยุ่บ",
  "ยุ่บยั่บ",
  "ยุ่มย่าม",
  "ยุ่ย",
  "ยุ้ง",
  "ยุ้ย",
  "ยู",
  "ยู",
  "ยูกันดา",
  "ยูง",
  "ยูถะ",
  "ยูถิกา",
  "ยูทาห์",
  "ยูนนาน",
  "ยูนิกซ์",
  "ยูนิเซฟ",
  "ยูนิโคด",
  "ยูนิโค้ด",
  "ยูรยาตร",
  "ยูริก",
  "ยูริก",
  "ยูวี",
  "ยูเครน",
  "ยูเนสโก",
  "ยูเรนัส",
  "ยูเรีย",
  "ยูเรเชีย",
  "ยูเรเซีย",
  "ยูเรเนียม",
  "ยูโกสลาเวีย",
  "ยูโด",
  "ยูโทเปีย",
  "ยูโร",
  "ยูโรเพียม",
  "ยูไล",
  "ยู่",
  "ยู่ยี่",
  "ยโส",
  "ยโสธร",
  "ยโสธรา",
  "ย็อกแย็ก",
  "ย่น",
  "ย่นย่อ",
  "ย่อ",
  "ย่อง",
  "ย่องเบา",
  "ย่องแย่ง",
  "ย่อท้อ",
  "ย่อม",
  "ย่อมเยา",
  "ย่อย",
  "ย่อยยับ",
  "ย่อส่วน",
  "ย่อหน้า",
  "ย่อหย่อน",
  "ย่ะ",
  "ย่า",
  "ย่าง",
  "ย่างกราย",
  "ย่างกุ้ง",
  "ย่างสด",
  "ย่างสามขุม",
  "ย่างเยื้อง",
  "ย่างเหยียบ",
  "ย่าทวด",
  "ย่าน",
  "ย่านพาโหม",
  "ย่านาง",
  "ย่าม",
  "ย่าหยา",
  "ย่ำ",
  "ย่ำต๊อก",
  "ย่ำยี",
  "ย่ำแย่",
  "ย้วย",
  "ย้อง",
  "ย้อน",
  "ย้อนยอก",
  "ย้อนรอย",
  "ย้อนศร",
  "ย้อนหลัง",
  "ย้อนแสง",
  "ย้อม",
  "ย้อย",
  "ย้อแย้",
  "ย้าย",
  "ย้าว",
  "ย้ำ",
  "ร.ง.",
  "ร.ด.",
  "ร.ต.",
  "ร.ต.ต.",
  "ร.ต.ท.",
  "ร.ต.อ.",
  "ร.ท.",
  "ร.น.",
  "ร.บ.",
  "ร.พ.",
  "ร.ฟ.ล.",
  "ร.ย.ล.",
  "ร.ย.ส.ท.",
  "ร.ล.",
  "ร.ศ.",
  "ร.ส.พ.",
  "ร.อ.",
  "รก",
  "รกชัฏ",
  "รกราก",
  "รกร้าง",
  "รกเรี้ยว",
  "รกเรื้อ",
  "รง",
  "รงควัตถุ",
  "รงควัตถุ",
  "รงค์",
  "รงรอง",
  "รจนา",
  "รจิต",
  "รจเรข",
  "รจเลข",
  "รชตะ",
  "รชนิ",
  "รชนี",
  "รชนีกร",
  "รชะ",
  "รณรงค์",
  "รด",
  "รดี",
  "รตนะ",
  "รตะ",
  "รติ",
  "รถ",
  "รถกระบะ",
  "รถจักร",
  "รถจี๊ป",
  "รถตู้",
  "รถทัวร์",
  "รถบรรทุก",
  "รถพยาบาล",
  "รถพ่วง",
  "รถม้า",
  "รถยนต์",
  "รถราง",
  "รถลาก",
  "รถสปอร์ต",
  "รถสิบล้อ",
  "รถเก๋ง",
  "รถเข็น",
  "รถเมล์",
  "รถแข่ง",
  "รถไฟ",
  "รถไฟฟ้า",
  "รน",
  "รบ",
  "รบกวน",
  "รบรา",
  "รบาญ",
  "รบเร้า",
  "รป.ม.",
  "รพี",
  "รม",
  "รมณี",
  "รมณียสถาน",
  "รมณีย์",
  "รมดำ",
  "รมย์",
  "รมเยศ",
  "รยางค์",
  "รร.จปร.",
  "รร.จอ.",
  "รร.ชท.",
  "รร.ตท.",
  "รร.นร.",
  "รร.นรต.",
  "รร.นอ.",
  "รวก",
  "รวง",
  "รวงผึ้ง",
  "รวงรัง",
  "รวด",
  "รวดเร็ว",
  "รวน",
  "รวนเร",
  "รวนเร",
  "รวบ",
  "รวบยอด",
  "รวบรวม",
  "รวบรัด",
  "รวม",
  "รวมพล",
  "รวมมิตร",
  "รวมหัว",
  "รวย",
  "รวยริน",
  "รวยรื่น",
  "รวันดา",
  "รวิ",
  "รวิวาร",
  "รวี",
  "รศนา",
  "รส",
  "รสชาติ",
  "รสนา",
  "รสนิยม",
  "รสสุคนธ์",
  "รสายนเวท",
  "รสิก",
  "รหัท",
  "รหัส",
  "รอ",
  "รอก",
  "รอง",
  "รองช้ำ",
  "รองทรง",
  "รองท้อง",
  "รองพื้น",
  "รองรับ",
  "รองเง็ง",
  "รองเท้า",
  "รอด",
  "รอดชีวิต",
  "รอดตัว",
  "รอดตาย",
  "รอน",
  "รอนแรม",
  "รอบ",
  "รอบคอบ",
  "รอบจัด",
  "รอบรู้",
  "รอบเดือน",
  "รอม",
  "รอมชอม",
  "รอมฎอน",
  "รอมร่อ",
  "รอย",
  "รอยร้าว",
  "รอยัล",
  "รอยัลตี้",
  "รอยเตอร์",
  "ระ",
  "ระกะ",
  "ระกา",
  "ระกำ",
  "ระคน",
  "ระคาง",
  "ระคาย",
  "ระฆัง",
  "ระงม",
  "ระงับ",
  "ระชวย",
  "ระดม",
  "ระดะ",
  "ระดับ",
  "ระดา",
  "ระดู",
  "ระด่าว",
  "ระตู",
  "ระทก",
  "ระทด",
  "ระทม",
  "ระทวย",
  "ระทา",
  "ระทึก",
  "ระนอง",
  "ระนาด",
  "ระนาดทุ้ม",
  "ระนาดเอก",
  "ระนาบ",
  "ระนาม",
  "ระนาว",
  "ระบบ",
  "ระบม",
  "ระบอบ",
  "ระบัด",
  "ระบับ",
  "ระบาด",
  "ระบาย",
  "ระบำ",
  "ระบิล",
  "ระบือ",
  "ระบุ",
  "ระมัดระวัง",
  "ระมาด",
  "ระยอง",
  "ระยะ",
  "ระยับ",
  "ระยั้ง",
  "ระยาบ",
  "ระยำ",
  "ระยิบระยับ",
  "ระย่อ",
  "ระย่อม",
  "ระย้า",
  "ระรวย",
  "ระรอง",
  "ระรัว",
  "ระราน",
  "ระริก",
  "ระรี่",
  "ระรึง",
  "ระรื่น",
  "ระรื้น",
  "ระร่อน",
  "ระร่าย",
  "ระลวง",
  "ระลอก",
  "ระลึก",
  "ระวัง",
  "ระวาง",
  "ระวาย",
  "ระวิง",
  "ระส่ำระสาย",
  "ระหกระเหิน",
  "ระหง",
  "ระหวย",
  "ระหว่าง",
  "ระหองระแหง",
  "ระหัด",
  "ระหาย",
  "ระอา",
  "ระอิดระอา",
  "ระอุ",
  "ระเกะระกะ",
  "ระเดียง",
  "ระเด่น",
  "ระเนน",
  "ระเนระนาด",
  "ระเนียด",
  "ระเบง",
  "ระเบิด",
  "ระเบิดขวด",
  "ระเบิดมือ",
  "ระเบียง",
  "ระเบียน",
  "ระเบียบ",
  "ระเบียบการ",
  "ระเบ็ง",
  "ระเมียร",
  "ระเริง",
  "ระเรียง",
  "ระเรื่อย",
  "ระเร้ง",
  "ระเหย",
  "ระเหระหน",
  "ระเหหน",
  "ระเหิด",
  "ระเหินระหก",
  "ระเห็จ",
  "ระแคะ",
  "ระแง้",
  "ระแด",
  "ระแทะ",
  "ระแนง",
  "ระแนะ",
  "ระแบบ",
  "ระแรง",
  "ระแวง",
  "ระแวดระวัง",
  "ระแหง",
  "ระโงก",
  "ระโงกหิน",
  "ระโยง",
  "ระโยงระยาง",
  "ระโหย",
  "ระไว",
  "รัก",
  "รักขิต",
  "รักข์",
  "รักตะ",
  "รักบี้",
  "รักษา",
  "รักษาการ",
  "รักษาการณ์",
  "รักษ์",
  "รักเร่",
  "รักแร้",
  "รักใคร่",
  "รัง",
  "รังควาน",
  "รังค์",
  "รังนก",
  "รังผึ้ง",
  "รังมด",
  "รังรอง",
  "รังวัด",
  "รังสรรค์",
  "รังสฤษฏ์",
  "รังสิ",
  "รังสิต",
  "รังสิมันตุ์",
  "รังสิมา",
  "รังสี",
  "รังสีวิทยา",
  "รังสีแพทย์",
  "รังเกียจ",
  "รังเพลิง",
  "รังแก",
  "รังแค",
  "รังแตน",
  "รังไข่",
  "รัจฉา",
  "รัช",
  "รัชกาล",
  "รัชชุ",
  "รัชชูปการ",
  "รัชฎาภิเษก",
  "รัชดา",
  "รัชดาภิเษก",
  "รัชทายาท",
  "รัชนะ",
  "รัชนี",
  "รัชนีกร",
  "รัชมังคลาภิเษก",
  "รัญจวน",
  "รัฏฐาภิปาลโนบาย",
  "รัฐ",
  "รัฐธรรมนูญ",
  "รัฐบาล",
  "รัฐบุรุษ",
  "รัฐประศาสนศาสตร์",
  "รัฐประศาสน์",
  "รัฐประศาสโนบาย",
  "รัฐประหาร",
  "รัฐพิธี",
  "รัฐมนตรี",
  "รัฐวิสาหกิจ",
  "รัฐศาสตร์",
  "รัฐสภา",
  "รัด",
  "รัดกุม",
  "รัดตัว",
  "รัดประคด",
  "รัดรึง",
  "รัดรูป",
  "รัดเกล้า",
  "รัต",
  "รัตกัมพล",
  "รัตคน",
  "รัตจันทน์",
  "รัตตัญญู",
  "รัตติ",
  "รัตติกาล",
  "รัตนชาติ",
  "รัตนตรัย",
  "รัตนบัลลังก์",
  "รัตนวราภรณ์",
  "รัตนสิงหาสน์",
  "รัตนะ",
  "รัตนา",
  "รัตนากร",
  "รัตนาธิเบศร์",
  "รัตนาภรณ์",
  "รัตนาวลี",
  "รัตนโกสินทรศก",
  "รัตนโกสินทร์",
  "รัตน์",
  "รัตมณี",
  "รัตมา",
  "รัถ",
  "รัถยา",
  "รัทเทอร์ฟอร์เดียม",
  "รัน",
  "รันทด",
  "รันทวย",
  "รันเวย์",
  "รับ",
  "รับขวัญ",
  "รับจ้าง",
  "รับช่วง",
  "รับซื้อ",
  "รับทราบ",
  "รับประกัน",
  "รับประทาน",
  "รับปาก",
  "รับผิด",
  "รับผิดชอบ",
  "รับฟัง",
  "รับฟ้อง",
  "รับมือ",
  "รับรอง",
  "รับรู้",
  "รับสมัคร",
  "รับสั่ง",
  "รับหน้า",
  "รับเหมา",
  "รับใช้",
  "รัม",
  "รัมณียสถาน",
  "รัมภา",
  "รัมมี่",
  "รัมย์",
  "รัย",
  "รัว",
  "รัศมิมัต",
  "รัศมิมาน",
  "รัศมี",
  "รัษฎากร",
  "รัสปูติน",
  "รัสสระ",
  "รัสสะ",
  "รัสเซีย",
  "รัสเซีย",
  "รัสเซียน",
  "รั่ว",
  "รั่วไหล",
  "รั้ง",
  "รั้งรอ",
  "รั้น",
  "รั้ว",
  "รา",
  "ราก",
  "รากขวัญ",
  "รากฐาน",
  "รากฟัน",
  "รากศัพท์",
  "รากษส",
  "รากสาด",
  "รากหญ้า",
  "รากเหง้า",
  "รากแก้ว",
  "ราคจริต",
  "ราคะ",
  "ราคา",
  "ราคิน",
  "ราคี",
  "ราง",
  "รางจืด",
  "รางชาง",
  "รางวัล",
  "ราช",
  "ราชกรณียกิจ",
  "ราชการ",
  "ราชกิจ",
  "ราชกิจจานุเบกษา",
  "ราชครู",
  "ราชคฤห์",
  "ราชญี",
  "ราชฐาน",
  "ราชดัด",
  "ราชทัณฑ์",
  "ราชทินนาม",
  "ราชทูต",
  "ราชธานี",
  "ราชนาวี",
  "ราชนิกุล",
  "ราชบัณฑิต",
  "ราชบัณฑิตยสถาน",
  "ราชบัลลังก์",
  "ราชบาตร",
  "ราชบุตร",
  "ราชบุรี",
  "ราชปะแตน",
  "ราชพฤกษ์",
  "ราชภัฏ",
  "ราชมัล",
  "ราชมาณพ",
  "ราชมาษ",
  "ราชมาส",
  "ราชยาน",
  "ราชย์",
  "ราชรถ",
  "ราชลัญจกร",
  "ราชวงศ์",
  "ราชวัติ",
  "ราชวโรงการ",
  "ราชสกุล",
  "ราชสมบัติ",
  "ราชสาส์น",
  "ราชสีห์",
  "ราชหัตถเลขา",
  "ราชองครักษ์",
  "ราชะ",
  "ราชัน",
  "ราชันย์",
  "ราชัย",
  "ราชา",
  "ราชาคณะ",
  "ราชาธิปไตย",
  "ราชาธิราช",
  "ราชานุญาต",
  "ราชานุสาวรีย์",
  "ราชาภิเษก",
  "ราชายตนะ",
  "ราชาวดี",
  "ราชาศัพท์",
  "ราชินิกุล",
  "ราชินี",
  "ราชินีกุล",
  "ราชินีนาถ",
  "ราชินูปถัมภ์",
  "ราชี",
  "ราชูปถัมภ์",
  "ราชูปโภค",
  "ราชเลขาธิการ",
  "ราชเลขานุการ",
  "ราชโองการ",
  "ราญ",
  "ราญรอน",
  "ราด",
  "ราต",
  "ราตร",
  "ราตรี",
  "ราน",
  "ราบ",
  "ราบคาบ",
  "ราบรื่น",
  "ราบเรียบ",
  "ราพณาสูร",
  "ราพณ์",
  "ราฟาเอล",
  "ราม",
  "รามสูร",
  "รามัญ",
  "รามา",
  "รามาธิบดี",
  "รามายณะ",
  "รามาวตาร",
  "รามือ",
  "รามเกียรติ์",
  "รามเทพ",
  "ราย",
  "รายการ",
  "รายงาน",
  "รายจ่าย",
  "รายชื่อ",
  "รายทาง",
  "รายรับ",
  "รายละเอียด",
  "รายล้อม",
  "รายวิชา",
  "รายได้",
  "ราว",
  "ราวนม",
  "ราวป่า",
  "ราวี",
  "ราศี",
  "ราษฎร",
  "ราษฎร์",
  "ราษตรี",
  "ราษราตรี",
  "ราสเบอร์รี",
  "ราหุ",
  "ราหุล",
  "ราหู",
  "ราเชน",
  "ราเชนทรยาน",
  "ราเชนทร์",
  "ราเมน",
  "ราเมศวร",
  "ราเม็ง",
  "ราโชวาท",
  "ราไชศวรรย์",
  "รำ",
  "รำคาญ",
  "รำงับ",
  "รำจวน",
  "รำบาญ",
  "รำพัด",
  "รำพัน",
  "รำพาย",
  "รำพึง",
  "รำมะนา",
  "รำมะนาด",
  "รำมะร่อ",
  "รำลึก",
  "รำวง",
  "รำหัด",
  "รำหัส",
  "รำเพย",
  "รำแพน",
  "รำไพ",
  "รำไร",
  "ริ",
  "ริก",
  "ริกเตอร์",
  "ริคเตอร์",
  "ริงโทน",
  "ริชาร์ด",
  "ริดสีดวง",
  "ริน",
  "ริบ",
  "ริบบิ้น",
  "ริบรี่",
  "ริบหรี่",
  "ริปุ",
  "ริปู",
  "ริม",
  "ริมฝีปาก",
  "ริยาด",
  "ริษยา",
  "ริอ่าน",
  "ริเริ่ม",
  "ริ้น",
  "ริ้ว",
  "ริ้วรอย",
  "รี",
  "รีด",
  "รีดักชัน",
  "รีดไถ",
  "รีต",
  "รีทัช",
  "รีบ",
  "รีบรุด",
  "รีบร้อน",
  "รีบเร่ง",
  "รีพอร์ท",
  "รีพับลิกัน",
  "รีม",
  "รีรอ",
  "รีวิว",
  "รีสอร์ต",
  "รีสอร์ท",
  "รีเทิร์น",
  "รีเนียม",
  "รีเฟรช",
  "รีเลย์",
  "รีเสิร์ช",
  "รีแบรนด์",
  "รีโมต",
  "รีโมท",
  "รีโอเดจาเนโร",
  "รีไซเคิล",
  "รีไทร์",
  "รี่",
  "รี้พล",
  "รี้ริก",
  "รึง",
  "รึ้ง",
  "รื่น",
  "รื่นรมย์",
  "รื่นเริง",
  "รื้น",
  "รื้อ",
  "รื้อถอน",
  "รื้อฟื้น",
  "รุ",
  "รุก",
  "รุกขชาติ",
  "รุกขมูล",
  "รุกขา",
  "รุกขเทวดา",
  "รุกข์",
  "รุกฆาต",
  "รุกราน",
  "รุกรุย",
  "รุกล้ำ",
  "รุกไล่",
  "รุงรัง",
  "รุจ",
  "รุจนะ",
  "รุจา",
  "รุจิ",
  "รุจิระ",
  "รุจิรา",
  "รุจิเรข",
  "รุจี",
  "รุด",
  "รุดหน้า",
  "รุต",
  "รุทธ์",
  "รุทระ",
  "รุธิร",
  "รุธิระ",
  "รุน",
  "รุนแรง",
  "รุบรู่",
  "รุม",
  "รุมบ้า",
  "รุมล้อม",
  "รุมเร้า",
  "รุย",
  "รุรุ",
  "รุสเซีย",
  "รุสโซ",
  "รุหะ",
  "รุเธียร",
  "รุ่ง",
  "รุ่งขึ้น",
  "รุ่งริ่ง",
  "รุ่งสว่าง",
  "รุ่งสาง",
  "รุ่งอรุณ",
  "รุ่งเช้า",
  "รุ่งเรือง",
  "รุ่งแจ้ง",
  "รุ่งโรจน์",
  "รุ่น",
  "รุ่ม",
  "รุ่มรวย",
  "รุ่มร่าม",
  "รุ่มร้อน",
  "รุ่ย",
  "รุ่ยร่าย",
  "รุ้ง",
  "รู",
  "รูจี",
  "รูด",
  "รูทีเนียม",
  "รูนีย์",
  "รูบิก",
  "รูบิค",
  "รูบิเดียม",
  "รูป",
  "รูปการณ์",
  "รูปฌาน",
  "รูปถ่าย",
  "รูปทรง",
  "รูปธรรม",
  "รูปพรรณ",
  "รูปพรหม",
  "รูปภพ",
  "รูปภาพ",
  "รูปร่าง",
  "รูปสมบัติ",
  "รูปิยะ",
  "รูปี",
  "รูปแบบ",
  "รูปโฉม",
  "รูมาตอยด์",
  "รูเล็ตต์",
  "รู่",
  "รู้",
  "รู้ความ",
  "รู้คุณ",
  "รู้งาน",
  "รู้จัก",
  "รู้ตัว",
  "รู้ทัน",
  "รู้สำนึก",
  "รู้สึก",
  "รู้เชิง",
  "รู้เท่า",
  "รู้เรื่อง",
  "รู้เห็น",
  "รู้แกว",
  "รู้แจ้ง",
  "รู้ใจ",
  "รโห",
  "รโหฐาน",
  "ร็อค",
  "ร็อคเก็ต",
  "ร่น",
  "ร่ม",
  "ร่มชูชีพ",
  "ร่มรื่น",
  "ร่มเกล้า",
  "ร่มเย็น",
  "ร่วง",
  "ร่วงโรย",
  "ร่วน",
  "ร่วม",
  "ร่วมมือ",
  "ร่วมรัก",
  "ร่วมสมัย",
  "ร่วมเพศ",
  "ร่วมใจ",
  "ร่อ",
  "ร่อง",
  "ร่องน้ำ",
  "ร่องรอย",
  "ร่องแร่ง",
  "ร่อน",
  "ร่อนเร่",
  "ร่อย",
  "ร่อยหรอ",
  "ร่อแร่",
  "ร่า",
  "ร่าง",
  "ร่างกาย",
  "ร่างแห",
  "ร่าน",
  "ร่าย",
  "ร่ายยาว",
  "ร่ายรำ",
  "ร่าเริง",
  "ร่ำ",
  "ร่ำรวย",
  "ร่ำร่ำ",
  "ร่ำร้อง",
  "ร่ำลา",
  "ร่ำเรียน",
  "ร่ำไป",
  "ร่ำไร",
  "ร่ำไห้",
  "ร้อง",
  "ร้องขอ",
  "ร้องทุกข์",
  "ร้องห่ม",
  "ร้องเรียน",
  "ร้องไห้",
  "ร้อน",
  "ร้อนตัว",
  "ร้อนรน",
  "ร้อนรุ่ม",
  "ร้อนวิชา",
  "ร้อนอาสน์",
  "ร้อนใจ",
  "ร้อย",
  "ร้อยกรอง",
  "ร้อยละ",
  "ร้อยหวาย",
  "ร้อยเอ็ด",
  "ร้อยแก้ว",
  "ร้า",
  "ร้าง",
  "ร้างรา",
  "ร้าน",
  "ร้านชำ",
  "ร้านรวง",
  "ร้าย",
  "ร้ายกาจ",
  "ร้ายแรง",
  "ร้าว",
  "ร้าวฉาน",
  "ร้าวราน",
  "ฤกษณะ",
  "ฤกษ์",
  "ฤคเวท",
  "ฤชา",
  "ฤชากร",
  "ฤชุ",
  "ฤณ",
  "ฤดี",
  "ฤดียา",
  "ฤดู",
  "ฤดูกาล",
  "ฤต",
  "ฤติยา",
  "ฤตุ",
  "ฤทธา",
  "ฤทธิ์",
  "ฤทัย",
  "ฤษภ",
  "ฤษยา",
  "ฤษี",
  "ฤาษี",
  "ฤๅ",
  "ฤๅดี",
  "ฤๅทัย",
  "ฤๅษี",
  "ฤๅสาย",
  "ล.ญ.",
  "ล.ว.",
  "ลก",
  "ลฆุ",
  "ลง",
  "ลงกา",
  "ลงขัน",
  "ลงคอ",
  "ลงตัว",
  "ลงทุน",
  "ลงท้าย",
  "ลงพุง",
  "ลงมือ",
  "ลงรอย",
  "ลงรัก",
  "ลงเอย",
  "ลงแขก",
  "ลงแรง",
  "ลงโทษ",
  "ลงโรง",
  "ลด",
  "ลดตัว",
  "ลดละ",
  "ลดหย่อน",
  "ลดหลั่น",
  "ลดา",
  "ลดาวัลย์",
  "ลดเลี้ยว",
  "ลน",
  "ลนลาน",
  "ลบ",
  "ลบล้าง",
  "ลบหลู่",
  "ลบอง",
  "ลบเลือน",
  "ลพ",
  "ลพบุรี",
  "ลพุช",
  "ลม",
  "ลมกรด",
  "ลมค้า",
  "ลมงวง",
  "ลมทะเล",
  "ลมบก",
  "ลมบน",
  "ลมบ้าหมู",
  "ลมปราณ",
  "ลมปาก",
  "ลมพิษ",
  "ลมว่าว",
  "ลมหนาว",
  "ลมหายใจ",
  "ลมาด",
  "ลมเสีย",
  "ลมแดด",
  "ลรรลุง",
  "ลลนา",
  "ลลิต",
  "ลวก",
  "ลวง",
  "ลวณะ",
  "ลวด",
  "ลวดลาย",
  "ลวดสปริง",
  "ลวดหนาม",
  "ลวนลาม",
  "ลวนะ",
  "ลวะ",
  "ลวิตร",
  "ลส.ชบ.",
  "ลหุ",
  "ลหุกาบัติ",
  "ลหุโทษ",
  "ลอก",
  "ลอการิทึม",
  "ลอกแลก",
  "ลอง",
  "ลองกอง",
  "ลองจิจูด",
  "ลองดี",
  "ลองภูมิ",
  "ลองเชิง",
  "ลองไน",
  "ลอจิสติกส์",
  "ลอด",
  "ลอดช่อง",
  "ลอตเตอรี่",
  "ลอน",
  "ลอนดอน",
  "ลอบ",
  "ลอบกัด",
  "ลอม",
  "ลอมชอม",
  "ลอมพอก",
  "ลอย",
  "ลอยชาย",
  "ลอยตัว",
  "ลอยนวล",
  "ลอยลำ",
  "ลอยแก้ว",
  "ลอยแพ",
  "ลอร์ด",
  "ลอว์เรนเซียม",
  "ลอสแองเจลิส",
  "ลออ",
  "ละ",
  "ละคร",
  "ละครนอก",
  "ละครรำ",
  "ละครร้อง",
  "ละครลิง",
  "ละครสัตว์",
  "ละครเพลง",
  "ละครใน",
  "ละติจูด",
  "ละติน",
  "ละทิ้ง",
  "ละบม",
  "ละบอง",
  "ละบือ",
  "ละมั่ง",
  "ละมาน",
  "ละมุ",
  "ละมุด",
  "ละมุน",
  "ละม่อม",
  "ละม้าย",
  "ละลวย",
  "ละลอก",
  "ละลาน",
  "ละลาบละล้วง",
  "ละลาย",
  "ละลิบ",
  "ละลุม",
  "ละล่ำละลัก",
  "ละล้า",
  "ละล้าละลัง",
  "ละล้าว",
  "ละวาด",
  "ละว้า",
  "ละหมาด",
  "ละหาน",
  "ละหาร",
  "ละหุ่ง",
  "ละห้อย",
  "ละอง",
  "ละออง",
  "ละอาย",
  "ละอ่อน",
  "ละเบ็ง",
  "ละเมอ",
  "ละเมาะ",
  "ละเมิด",
  "ละเมียด",
  "ละเลง",
  "ละเลย",
  "ละเลาะ",
  "ละเลิง",
  "ละเลียด",
  "ละเลียบ",
  "ละเล้า",
  "ละเวง",
  "ละเว้น",
  "ละเหย",
  "ละเหี่ย",
  "ละเอียด",
  "ละเอียดอ่อน",
  "ละแมะ",
  "ละแวก",
  "ละแอน",
  "ละโบม",
  "ละโมก",
  "ละโมบ",
  "ละโว้",
  "ละไม",
  "ละไล้",
  "ลัก",
  "ลักขณะ",
  "ลักขณา",
  "ลักขะ",
  "ลักขี",
  "ลักจั่น",
  "ลักปิดลักเปิด",
  "ลักพา",
  "ลักยิ้ม",
  "ลักลอบ",
  "ลักลั่น",
  "ลักษณนาม",
  "ลักษณะ",
  "ลักษณาการ",
  "ลักษณ์",
  "ลักษมณ์",
  "ลักษมาณา",
  "ลักษมี",
  "ลักษะ",
  "ลักหลับ",
  "ลักเซมเบิร์ก",
  "ลักเพศ",
  "ลักไก่",
  "ลัคคะ",
  "ลัคนา",
  "ลัคน์",
  "ลัง",
  "ลังกา",
  "ลังคี",
  "ลังถึง",
  "ลังลอง",
  "ลังสาด",
  "ลังเล",
  "ลัชชา",
  "ลัชชี",
  "ลัญจกร",
  "ลัญจ์",
  "ลัญฉกร",
  "ลัญฉน์",
  "ลัฐิ",
  "ลัฐิกา",
  "ลัด",
  "ลัดา",
  "ลัดเลาะ",
  "ลัตเวีย",
  "ลัทธิ",
  "ลัทธ์",
  "ลัน",
  "ลันตา",
  "ลันเต",
  "ลันเตา",
  "ลันโทม",
  "ลันไต",
  "ลับ",
  "ลับตา",
  "ลับหลัง",
  "ลับแล",
  "ลัพธิ",
  "ลัพธ์",
  "ลัภ",
  "ลัภนะ",
  "ลัภย์",
  "ลัมพ์",
  "ลัย",
  "ลั่ง",
  "ลั่น",
  "ลั่นทม",
  "ลา",
  "ลาก",
  "ลากข้าง",
  "ลาง",
  "ลางลิง",
  "ลางสาด",
  "ลาช",
  "ลาชะ",
  "ลาชา",
  "ลาซานญ่า",
  "ลาญ",
  "ลาด",
  "ลาดตระเวน",
  "ลาดพร้าว",
  "ลาดยาง",
  "ลาดเขา",
  "ลาดเท",
  "ลาดเลา",
  "ลาติน",
  "ลาน",
  "ลานบิน",
  "ลานีญา",
  "ลาบ",
  "ลาปาซ",
  "ลาพอน",
  "ลาภ",
  "ลาภปาก",
  "ลาภลอย",
  "ลาม",
  "ลามก",
  "ลามปาม",
  "ลามะ",
  "ลามเลีย",
  "ลาย",
  "ลายคราม",
  "ลายน้ำ",
  "ลายพร้อย",
  "ลายมือ",
  "ลายลักษณ์",
  "ลายสือ",
  "ลายเซ็น",
  "ลายเส้น",
  "ลายแทง",
  "ลาลา",
  "ลาว",
  "ลาว",
  "ลาวัณย์",
  "ลาวา",
  "ลาสเวกัส",
  "ลาออก",
  "ลาเต้",
  "ลำ",
  "ลำกล้อง",
  "ลำดวน",
  "ลำดับ",
  "ลำตัด",
  "ลำธาร",
  "ลำนำ",
  "ลำบอง",
  "ลำบาก",
  "ลำปาง",
  "ลำปำ",
  "ลำพวน",
  "ลำพอง",
  "ลำพัง",
  "ลำพู",
  "ลำพูน",
  "ลำภุขัน",
  "ลำมะลอก",
  "ลำยอง",
  "ลำลอง",
  "ลำลาบ",
  "ลำลึก",
  "ลำเข็ญ",
  "ลำเค็ญ",
  "ลำเจียก",
  "ลำเนา",
  "ลำเพา",
  "ลำเพ็ญ",
  "ลำเลาะ",
  "ลำเลิก",
  "ลำเลียง",
  "ลำเวียง",
  "ลำเอียก",
  "ลำเอียง",
  "ลำแข",
  "ลำแข้ง",
  "ลำแพน",
  "ลำแสง",
  "ลำโพง",
  "ลำไพ่",
  "ลำไย",
  "ลำไส้",
  "ลิ",
  "ลิกขา",
  "ลิกู",
  "ลิกเตนสไตน์",
  "ลิกไนต์",
  "ลิขนะ",
  "ลิขสิทธิ์",
  "ลิขิต",
  "ลิง",
  "ลิงก์",
  "ลิงคอล์น",
  "ลิงค์",
  "ลิงจุ่น",
  "ลิงลม",
  "ลิงโลด",
  "ลิจฉวี",
  "ลิด",
  "ลิดรอน",
  "ลิต",
  "ลิตมัส",
  "ลิตร",
  "ลิทัวเนีย",
  "ลินคอล์น",
  "ลินจง",
  "ลินลา",
  "ลินสีด",
  "ลินิน",
  "ลินุกซ์",
  "ลิบ",
  "ลิบลับ",
  "ลิบลิ่ว",
  "ลิปดา",
  "ลิปสติก",
  "ลิปิ",
  "ลิฟต์",
  "ลิมปนะ",
  "ลิมป์",
  "ลิมิต",
  "ลิมูซีน",
  "ลิลิต",
  "ลิว",
  "ลิสง",
  "ลิสต์",
  "ลิสบอน",
  "ลิเก",
  "ลิเทียม",
  "ลิเบีย",
  "ลิเภา",
  "ลิเวอร์พูล",
  "ลิไท",
  "ลิไทย",
  "ลิ่น",
  "ลิ่นฮื้อ",
  "ลิ่ม",
  "ลิ่มเลือด",
  "ลิ่ว",
  "ลิ้น",
  "ลิ้นควาย",
  "ลิ้นงูเห่า",
  "ลิ้นจี่",
  "ลิ้นชัก",
  "ลิ้นปี่",
  "ลิ้นหมา",
  "ลิ้นไก่",
  "ลิ้ม",
  "ลี",
  "ลีก",
  "ลีซอ",
  "ลีด",
  "ลีดเดอร์",
  "ลีนุกซ์",
  "ลีบ",
  "ลีลา",
  "ลีลาวดี",
  "ลีลาศ",
  "ลีฬหา",
  "ลีเมอร์",
  "ลีโอน",
  "ลี่",
  "ลี้",
  "ลี้ภัย",
  "ลี้ลับ",
  "ลึก",
  "ลึกซึ้ง",
  "ลึกลับ",
  "ลึกล้ำ",
  "ลึงค์",
  "ลืด",
  "ลืบ",
  "ลืม",
  "ลืมตน",
  "ลืมตัว",
  "ลืมตา",
  "ลืมต้น",
  "ลืมเลือน",
  "ลือ",
  "ลือชา",
  "ลือชื่อ",
  "ลือลั่น",
  "ลื่น",
  "ลื่อ",
  "ลื้น",
  "ลื้อ",
  "ลุ",
  "ลุก",
  "ลุกลน",
  "ลุกลาม",
  "ลุกลี้ลุกลน",
  "ลุกฮือ",
  "ลุค",
  "ลุง",
  "ลุพธ์",
  "ลุมพินี",
  "ลุมพี",
  "ลุมพู",
  "ลุย",
  "ลุยเซียนา",
  "ลุล่วง",
  "ลุ่ทาง",
  "ลุ่น",
  "ลุ่ม",
  "ลุ่มน้ำ",
  "ลุ่มลึก",
  "ลุ่มหลง",
  "ลุ่ย",
  "ลุ้ง",
  "ลุ้น",
  "ลุ้ย",
  "ลูก",
  "ลูกกรง",
  "ลูกกรอก",
  "ลูกกรุง",
  "ลูกกลอน",
  "ลูกกลิ้ง",
  "ลูกกวาด",
  "ลูกกะจ๊อก",
  "ลูกกุญแจ",
  "ลูกขนไก่",
  "ลูกขุน",
  "ลูกข่าง",
  "ลูกครึ่ง",
  "ลูกคลื่น",
  "ลูกความ",
  "ลูกคอ",
  "ลูกคิด",
  "ลูกคู่",
  "ลูกค้า",
  "ลูกจ้าง",
  "ลูกชาย",
  "ลูกชิด",
  "ลูกชิ้น",
  "ลูกชุบ",
  "ลูกช้าง",
  "ลูกซอง",
  "ลูกดอก",
  "ลูกดิ่ง",
  "ลูกตะกั่ว",
  "ลูกตุ้ม",
  "ลูกถ้วย",
  "ลูกทุ่ง",
  "ลูกนิมิต",
  "ลูกน้อง",
  "ลูกน้ำ",
  "ลูกบอล",
  "ลูกบาศก์",
  "ลูกบิด",
  "ลูกบ้าน",
  "ลูกประคบ",
  "ลูกประคำ",
  "ลูกปัด",
  "ลูกปืน",
  "ลูกผสม",
  "ลูกผู้ชาย",
  "ลูกผู้หญิง",
  "ลูกพรรค",
  "ลูกพี่",
  "ลูกฟูก",
  "ลูกมือ",
  "ลูกยาเธอ",
  "ลูกรอก",
  "ลูกระมาศ",
  "ลูกรัง",
  "ลูกลอย",
  "ลูกล้อ",
  "ลูกวัด",
  "ลูกศร",
  "ลูกศิษย์",
  "ลูกสมุน",
  "ลูกสะใภ้",
  "ลูกสาว",
  "ลูกสูบ",
  "ลูกหนัง",
  "ลูกหนี้",
  "ลูกหนู",
  "ลูกหมาก",
  "ลูกหม้อ",
  "ลูกหลง",
  "ลูกหลาน",
  "ลูกหาบ",
  "ลูกหิน",
  "ลูกอม",
  "ลูกเกด",
  "ลูกเขย",
  "ลูกเต้า",
  "ลูกเต๋า",
  "ลูกเธอ",
  "ลูกเบี้ยว",
  "ลูกเรือ",
  "ลูกเลี้ยง",
  "ลูกเล่น",
  "ลูกเสือ",
  "ลูกเหม็น",
  "ลูกเห็บ",
  "ลูกเอ็น",
  "ลูกแก้ว",
  "ลูกแหง่",
  "ลูกโซ่",
  "ลูกโป่ง",
  "ลูกโม่",
  "ลูกโลก",
  "ลูกไฟ",
  "ลูกไม้",
  "ลูขะ",
  "ลูทีเชียม",
  "ลูบ",
  "ลูบคม",
  "ลูบคลำ",
  "ลูบไล้",
  "ลูป",
  "ลูเซีย",
  "ลู่",
  "ล็อก",
  "ล็อกซเล่ย์",
  "ล็อกเกต",
  "ล็อกเกอร์",
  "ล็อค",
  "ล็อคเกอร์",
  "ล็อต",
  "ล็อบบี้",
  "ล่ก",
  "ล่ง",
  "ล่ม",
  "ล่มจม",
  "ล่มสลาย",
  "ล่วง",
  "ล่วงรู้",
  "ล่วงละเมิด",
  "ล่วงลับ",
  "ล่วงล้ำ",
  "ล่วงหน้า",
  "ล่วงเกิน",
  "ล่วงเลย",
  "ล่วม",
  "ล่อ",
  "ล่อง",
  "ล่องหน",
  "ล่อน",
  "ล่อนจ้อน",
  "ล่อย",
  "ล่อลวง",
  "ล่อหลอก",
  "ล่อแล่",
  "ล่อแหลม",
  "ล่ะ",
  "ล่า",
  "ล่าง",
  "ล่าช้า",
  "ล่าทัพ",
  "ล่าน",
  "ล่าม",
  "ล่าเตียง",
  "ล่ำ",
  "ล่ำลา",
  "ล่ำสัน",
  "ล้งเล้ง",
  "ล้น",
  "ล้นพ้น",
  "ล้นหลาม",
  "ล้นเหลือ",
  "ล้ม",
  "ล้มละลาย",
  "ล้มลุก",
  "ล้มเลิก",
  "ล้มเหลว",
  "ล้วง",
  "ล้วน",
  "ล้วนแล้ว",
  "ล้อ",
  "ล้อต๊อก",
  "ล้อม",
  "ล้อมวง",
  "ล้อหลอก",
  "ล้อเลียน",
  "ล้อเลื่อน",
  "ล้อเล่น",
  "ล้า",
  "ล้าง",
  "ล้างบาง",
  "ล้างผลาญ",
  "ล้าต้า",
  "ล้าน",
  "ล้านช้าง",
  "ล้านนา",
  "ล้าย",
  "ล้าสมัย",
  "ล้าหลัง",
  "ล้ำ",
  "ล้ำยุค",
  "ล้ำลึก",
  "ล้ำสมัย",
  "ล้ำหน้า",
  "ล้ำเลิศ",
  "ล้ำเส้น",
  "ฦๅ",
  "ฦๅชา",
  "ฦๅสาย",
  "ว.ค.",
  "ว.ฉ.",
  "ว.ช.",
  "ว.ด.ป.",
  "ว.ป.ถ.",
  "วก",
  "วกวน",
  "วง",
  "วงกต",
  "วงกบ",
  "วงกลม",
  "วงการ",
  "วงก์",
  "วงจร",
  "วงนอก",
  "วงรี",
  "วงศกร",
  "วงศา",
  "วงศ์",
  "วงศ์วาน",
  "วงษ์",
  "วงเงิน",
  "วงเล็บ",
  "วงเวียน",
  "วงแขน",
  "วงแหวน",
  "วงใน",
  "วจนะ",
  "วจี",
  "วจีกรรม",
  "วจีภาค",
  "วจีเภท",
  "วชิรปาณี",
  "วชิรหัตถ์",
  "วชิระ",
  "วชิราลงกรณ์",
  "วชิราวุธ",
  "วฏะ",
  "วฏาการ",
  "วณิช",
  "วณิชชา",
  "วณิชยา",
  "วณิชย์",
  "วณิพก",
  "วดี",
  "วท.บ.",
  "วทนะ",
  "วทัญญุตา",
  "วทัญญู",
  "วธุกา",
  "วธู",
  "วน",
  "วนศาสตร์",
  "วนสณฑ์",
  "วนสัณฑ์",
  "วนอุทยาน",
  "วนัส",
  "วนัสบดี",
  "วนา",
  "วนาดร",
  "วนาดอน",
  "วนานต์",
  "วนาราม",
  "วนาลัย",
  "วนาลี",
  "วนาวาส",
  "วนาศรม",
  "วนาสณฑ์",
  "วนาสัณฑ์",
  "วนิดา",
  "วนิพก",
  "วนเวียน",
  "วยัคฆ์",
  "วยากรณ์",
  "วรงค์",
  "วรณะ",
  "วรดนู",
  "วรทาน",
  "วรมหาวิหาร",
  "วรรค",
  "วรรคย์",
  "วรรช",
  "วรรชย์",
  "วรรณกรรม",
  "วรรณคดี",
  "วรรณนา",
  "วรรณพฤติ",
  "วรรณยุกต์",
  "วรรณยุต",
  "วรรณศิลป์",
  "วรรณะ",
  "วรรณึก",
  "วรรธกะ",
  "วรรธนะ",
  "วรรษ",
  "วรรษา",
  "วรวิหาร",
  "วรัญญู",
  "วรางคณา",
  "วรางคนา",
  "วราราม",
  "วราหะ",
  "วราห์",
  "วรุณ",
  "วฤก",
  "วลัช",
  "วลัญชน์",
  "วลัญช์",
  "วลัย",
  "วลาดิเมียร์",
  "วลาหก",
  "วลี",
  "วศค",
  "วศะ",
  "วศิน",
  "วสนะ",
  "วสภะ",
  "วสละ",
  "วสวัดดี",
  "วสวัตตี",
  "วสะ",
  "วสันตดิลก",
  "วสันตฤดู",
  "วสันตวิษุวัต",
  "วสันต์",
  "วสา",
  "วสี",
  "วสุ",
  "วสุธา",
  "วสุนธรา",
  "วสุมดี",
  "วหะ",
  "วอ",
  "วอก",
  "วอกแวก",
  "วอชิงตัน",
  "วอด",
  "วอดวาย",
  "วอน",
  "วอฟเฟิล",
  "วอมแวม",
  "วอร์ซอ",
  "วอร์ม",
  "วอร์มอัพ",
  "วอร์รูม",
  "วอลซ์",
  "วอลนัต",
  "วอลนัท",
  "วอลล์",
  "วอลล์สตรีท",
  "วอลเลย์บอล",
  "วอล์ก",
  "วอล์ค",
  "วอเตอร์",
  "วอแว",
  "วะ",
  "วัก",
  "วักกะ",
  "วัคคีย์",
  "วัคคุ",
  "วัคค์",
  "วัคซีน",
  "วัง",
  "วังก์",
  "วังชา",
  "วังวน",
  "วังศะ",
  "วังสะ",
  "วังหน้า",
  "วังหลวง",
  "วังหลัง",
  "วังเวง",
  "วัจกุฎี",
  "วัจจะ",
  "วัจฉละ",
  "วัจนะ",
  "วัจน์",
  "วัช",
  "วัชชะ",
  "วัชชี",
  "วัชฌ์",
  "วัชพืช",
  "วัชรปาณี",
  "วัชรยาน",
  "วัชรอาสน์",
  "วัชระ",
  "วัชราสน์",
  "วัชรินทร์",
  "วัชรี",
  "วัชเรนทร์",
  "วัฏ",
  "วัฏกะ",
  "วัฏจักร",
  "วัฏฏะ",
  "วัฏฏิ",
  "วัฏทุกข์",
  "วัฏสงสาร",
  "วัฒกะ",
  "วัฒกี",
  "วัฒนธรรม",
  "วัฒนะ",
  "วัฒนา",
  "วัณ",
  "วัณฏ์",
  "วัณณะ",
  "วัณนา",
  "วัณโรค",
  "วัด",
  "วัดผล",
  "วัดพื้น",
  "วัดราษฎร์",
  "วัดวา",
  "วัดหลวง",
  "วัต",
  "วัตตา",
  "วัตต์",
  "วัตถาภรณ์",
  "วัตถาลังการ",
  "วัตถุ",
  "วัตถุนิยม",
  "วัตถุประสงค์",
  "วัตถ์",
  "วัตนะ",
  "วัตร",
  "วัตรปฏิบัติ",
  "วัตสดร",
  "วัตสะ",
  "วัติ",
  "วัทน์",
  "วัน",
  "วันต์",
  "วันทนา",
  "วันทนาการ",
  "วันทนีย์",
  "วันทยหัตถ์",
  "วันทยาวุธ",
  "วันทยาหัตถ์",
  "วันทา",
  "วันทิ",
  "วันพระ",
  "วันเพ็ญ",
  "วันเวย์",
  "วันโกน",
  "วับ",
  "วับวาบ",
  "วับวาม",
  "วับแวบ",
  "วับแวม",
  "วัปปะ",
  "วัมมิกะ",
  "วัย",
  "วัยรุ่น",
  "วัยวุฒิ",
  "วัลก์",
  "วัลคุ",
  "วัลย์",
  "วัลลภ",
  "วัลลี",
  "วัว",
  "วัสดุ",
  "วัสตร์",
  "วัสนะ",
  "วัสน์",
  "วัสสะ",
  "วัสสา",
  "วัสสานฤดู",
  "วัสสานะ",
  "วัสโสทก",
  "วา",
  "วาก",
  "วากยสัมพันธ์",
  "วากยะ",
  "วาง",
  "วางก้าม",
  "วางตัว",
  "วางตา",
  "วางท่า",
  "วางมวย",
  "วางมาด",
  "วางมือ",
  "วางวาย",
  "วางโต",
  "วางใจ",
  "วาจก",
  "วาจา",
  "วาจาล",
  "วาจาไปยะ",
  "วาชเปยะ",
  "วาซาบิ",
  "วาณิช",
  "วาณิชกะ",
  "วาณิชย์",
  "วาณี",
  "วาด",
  "วาดเขียน",
  "วาต",
  "วาตภัย",
  "วาตะ",
  "วาติกัน",
  "วาท",
  "วาทกรรม",
  "วาทกะ",
  "วาทนะ",
  "วาทยกร",
  "วาทย์",
  "วาทศาสตร์",
  "วาทศิลป์",
  "วาทะ",
  "วาทิต",
  "วาทิน",
  "วาที",
  "วาน",
  "วานซืน",
  "วานร",
  "วานรินทร์",
  "วานิช",
  "วานิลลา",
  "วานิลา",
  "วานูอาตู",
  "วาบ",
  "วาบหวาม",
  "วาปี",
  "วาฟเฟิล",
  "วาม",
  "วามน",
  "วามนาวตาร",
  "วามะ",
  "วาย",
  "วาย",
  "วายชนม์",
  "วายปราณ",
  "วายร้าย",
  "วายวอด",
  "วายวาง",
  "วายะ",
  "วายามะ",
  "วายุ",
  "วายุกูล",
  "วายุภักษ์",
  "วาร",
  "วารสาร",
  "วารสารศาสตร์",
  "วาระ",
  "วาริ",
  "วาริช",
  "วาริชศาสตร์",
  "วาริท",
  "วาริธร",
  "วารี",
  "วารีช",
  "วารุณ",
  "วารุณี",
  "วาล",
  "วาลวีชนี",
  "วาลิกา",
  "วาลุกา",
  "วาล์ว",
  "วาว",
  "วาววับ",
  "วาววาม",
  "วาวแวว",
  "วาวแสง",
  "วาสนะ",
  "วาสนา",
  "วาสพ",
  "วาสะ",
  "วาสิน",
  "วาสี",
  "วาสุกรี",
  "วาสุกี",
  "วาสุเทพ",
  "วาหนะ",
  "วาหะ",
  "วาหินี",
  "วาฬ",
  "วาเนเดียม",
  "วาเลนไทน์",
  "วาโย",
  "วาไรตี้",
  "วิก",
  "วิกขัมภนะ",
  "วิกขัมภ์",
  "วิกตอเรีย",
  "วิกรม",
  "วิกรัย",
  "วิกรานต์",
  "วิกฤต",
  "วิกฤตการณ์",
  "วิกฤตกาล",
  "วิกฤติ",
  "วิกฤติการณ์",
  "วิกฤติกาล",
  "วิกล",
  "วิกลจริต",
  "วิกสิต",
  "วิกัต",
  "วิกัติ",
  "วิกัติการก",
  "วิกัป",
  "วิกัย",
  "วิการ",
  "วิกาล",
  "วิกาลโภชน์",
  "วิกิ",
  "วิกิพีเดีย",
  "วิกเขป",
  "วิคหะ",
  "วิฆาต",
  "วิฆเนศ",
  "วิฆเนศวร",
  "วิง",
  "วิงวอน",
  "วิงเวียน",
  "วิจฉิกะ",
  "วิจล",
  "วิจักขณ์",
  "วิจักษณ์",
  "วิจักษ์",
  "วิจัย",
  "วิจาร",
  "วิจารณญาณ",
  "วิจารณ์",
  "วิจิ",
  "วิจิกิจฉา",
  "วิจิต",
  "วิจิตร",
  "วิจิตรศิลป์",
  "วิจิน",
  "วิจุณ",
  "วิจุรณ",
  "วิชชา",
  "วิชชุ",
  "วิชชุดา",
  "วิชชุตา",
  "วิชชุลดา",
  "วิชญะ",
  "วิชน",
  "วิชนี",
  "วิชัย",
  "วิชา",
  "วิชาการ",
  "วิชาชีพ",
  "วิชาธร",
  "วิชานนะ",
  "วิชิต",
  "วิญญัตติ",
  "วิญญาณ",
  "วิญญาณกทรัพย์",
  "วิญญาน",
  "วิญญู",
  "วิญญูชน",
  "วิฑูรย์",
  "วิด",
  "วิดพื้น",
  "วิดีโอ",
  "วิตก",
  "วิตกจริต",
  "วิตถาร",
  "วิตามิน",
  "วิถี",
  "วิถีทาง",
  "วิทธะ",
  "วิทยฐานะ",
  "วิทยา",
  "วิทยากร",
  "วิทยากล",
  "วิทยาการ",
  "วิทยาคม",
  "วิทยาคาร",
  "วิทยาทาน",
  "วิทยาธร",
  "วิทยานิพนธ์",
  "วิทยานุสรณ์",
  "วิทยายน",
  "วิทยาลัย",
  "วิทยาศาสตร์",
  "วิทยาเขต",
  "วิทยุ",
  "วิทยุต",
  "วิทย์",
  "วิทวัส",
  "วิทัตถิ",
  "วิทัศน์",
  "วิทารณ์",
  "วิทิต",
  "วิทู",
  "วิทูร",
  "วิธ",
  "วิธวา",
  "วิธาน",
  "วิธี",
  "วิธีการ",
  "วิธุระ",
  "วิธู",
  "วิธูปนะ",
  "วิน",
  "วินตกะ",
  "วินัย",
  "วินัยธร",
  "วินัยปิฎก",
  "วินาที",
  "วินายก",
  "วินาศ",
  "วินาศกรรม",
  "วินาศภัย",
  "วินาศสันตะโร",
  "วินิจ",
  "วินิจฉัย",
  "วินิต",
  "วินิบาต",
  "วินิปาติก",
  "วินเซนต์",
  "วินโดวส์",
  "วินโดว์ส",
  "วิบัติ",
  "วิบาก",
  "วิบุล",
  "วิบุลย์",
  "วิบูล",
  "วิบูลย์",
  "วิป",
  "วิปการ",
  "วิปฏิสาร",
  "วิปปิ้ง",
  "วิประโยค",
  "วิปริต",
  "วิปลาส",
  "วิปวาส",
  "วิปักษ์",
  "วิปัสสก",
  "วิปัสสนา",
  "วิปัสสนายานิก",
  "วิปโยค",
  "วิพากษ์",
  "วิพิธทัศนา",
  "วิพุธ",
  "วิภว",
  "วิภวตัณหา",
  "วิภังค์",
  "วิภัช",
  "วิภัชพยากรณ์",
  "วิภัชภาค",
  "วิภัชวาที",
  "วิภัตติ",
  "วิภา",
  "วิภาค",
  "วิภาช",
  "วิภาดา",
  "วิภาวดี",
  "วิภาวี",
  "วิภาษ",
  "วิภาส",
  "วิภู",
  "วิภูษณะ",
  "วิภูษา",
  "วิภูษิต",
  "วิมน",
  "วิมล",
  "วิมลัก",
  "วิมังสา",
  "วิมัติ",
  "วิมาน",
  "วิมุข",
  "วิมุต",
  "วิมุตติ",
  "วิมเบิลดัน",
  "วิรงรอง",
  "วิระ",
  "วิรังรอง",
  "วิรัช",
  "วิรัต",
  "วิรัติ",
  "วิราคะ",
  "วิราม",
  "วิริยภาพ",
  "วิริยะ",
  "วิรุธ",
  "วิรุฬหก",
  "วิรุฬห์",
  "วิรูป",
  "วิรูปักษ์",
  "วิลล์",
  "วิลันดา",
  "วิลัย",
  "วิลาด",
  "วิลาป",
  "วิลาวัณย์",
  "วิลาศ",
  "วิลาส",
  "วิลาสินี",
  "วิลิปดา",
  "วิลิศมาหรา",
  "วิลเลจ",
  "วิลเลียม",
  "วิว",
  "วิวรณ์",
  "วิวรรธน์",
  "วิวัฏ",
  "วิวัฒนาการ",
  "วิวัฒน์",
  "วิวัน",
  "วิวาท",
  "วิวาหมงคล",
  "วิวาหะ",
  "วิวาห์",
  "วิวิต",
  "วิวิธ",
  "วิศรุต",
  "วิศว",
  "วิศวกร",
  "วิศวกรรม",
  "วิศวกรรมศาสตร์",
  "วิศัลย์",
  "วิศาขบูชา",
  "วิศาขา",
  "วิศาล",
  "วิศิษฏ์",
  "วิศิษฐ์",
  "วิศุทธิ์",
  "วิศุทธ์",
  "วิษณุ",
  "วิษณุกรรม",
  "วิษธร",
  "วิษัย",
  "วิษาณ",
  "วิษุวัต",
  "วิสกี้",
  "วิสคอนซิน",
  "วิสรรชนีย์",
  "วิสฤต",
  "วิสสุกรรม",
  "วิสัชนา",
  "วิสัญญี",
  "วิสัญญีภาพ",
  "วิสัญญีวิทยา",
  "วิสัญญีแพทย์",
  "วิสัย",
  "วิสัยทัศน์",
  "วิสาขบูชา",
  "วิสาขะ",
  "วิสาขา",
  "วิสามัญ",
  "วิสามานยนาม",
  "วิสาร",
  "วิสารทะ",
  "วิสาล",
  "วิสาสะ",
  "วิสาหกิจ",
  "วิสิฐ",
  "วิสุงคามสีมา",
  "วิสุทธิ์",
  "วิสุทธ์",
  "วิสูตร",
  "วิหค",
  "วิหลั่น",
  "วิหาร",
  "วิหิงสา",
  "วิฬาร",
  "วิฬาร์",
  "วิเคราะห์",
  "วิเชียร",
  "วิเทศ",
  "วิเทศสัมพันธ์",
  "วิเทโศบาย",
  "วิเนต",
  "วิเมลือง",
  "วิเรนทร์",
  "วิเลป",
  "วิเลปนะ",
  "วิเวก",
  "วิเศษ",
  "วิเศษณ์",
  "วิเสท",
  "วิเหสา",
  "วิโมกข์",
  "วิโยค",
  "วิโรจ",
  "วิโรจน์",
  "วิโรฒ",
  "วิโรธ",
  "วิโลกนะ",
  "วิโลม",
  "วิไล",
  "วิไลวรรณ",
  "วิไลวรรณ",
  "วิ่ง",
  "วิ่งผลัด",
  "วิ่งรอก",
  "วิ่งราว",
  "วิ่งเต้น",
  "วิ่งเปี้ยว",
  "วิ่น",
  "วี",
  "วี",
  "วีก้า",
  "วีจิ",
  "วีชนี",
  "วีซ่า",
  "วีณา",
  "วีดิทัศน์",
  "วีน",
  "วีรกรรม",
  "วีรชน",
  "วีรบุรุษ",
  "วีรสตรี",
  "วีระ",
  "วีสะ",
  "วีเจ",
  "วีไอพี",
  "วี่วัน",
  "วี่แวว",
  "วี้ด",
  "วืด",
  "วุฐิ",
  "วุฒ",
  "วุฒิ",
  "วุฒิชัย",
  "วุฒิบัตร",
  "วุฒิสภา",
  "วุฒิสมาชิก",
  "วุด",
  "วุบ",
  "วุลแฟรม",
  "วุ่น",
  "วุ่นวาย",
  "วุ้ง",
  "วุ้น",
  "วุ้นเส้น",
  "วุ้ย",
  "วูดวาด",
  "วูบ",
  "วูบวาบ",
  "วู่วาม",
  "วู้",
  "วเนจร",
  "วโนทยาน",
  "วโรกาส",
  "วโรดม",
  "วโรรส",
  "ว็อบ",
  "ว็อบแว็บ",
  "ว่องไว",
  "ว่อน",
  "ว่ะ",
  "ว่า",
  "ว่ากล่าว",
  "ว่าง",
  "ว่างเปล่า",
  "ว่างเว้น",
  "ว่าจ้าง",
  "ว่าด้วย",
  "ว่าที่",
  "ว่าน",
  "ว่านเครือ",
  "ว่าย",
  "ว่าว",
  "ว้อดก้า",
  "ว้อย",
  "ว้า",
  "ว้าก",
  "ว้าง",
  "ว้าย",
  "ว้าว",
  "ว้าวุ่น",
  "ว้าว่อน",
  "ว้าเหว่",
  "ศ.บ.",
  "ศ.ป.ก.",
  "ศ.ศ.ป.",
  "ศก",
  "ศกุน",
  "ศกุนตลา",
  "ศกุนต์",
  "ศกุนิ",
  "ศกุนี",
  "ศจี",
  "ศตกะ",
  "ศตพรรษ",
  "ศตภิษัช",
  "ศตวรรษ",
  "ศตะ",
  "ศนิ",
  "ศนิวาร",
  "ศฝร.ภ.",
  "ศพ",
  "ศมนะ",
  "ศมะ",
  "ศยาม",
  "ศยามล",
  "ศร",
  "ศรรกรา",
  "ศรวณะ",
  "ศรวณีย์",
  "ศรวิษฐา",
  "ศรัณยู",
  "ศรัณย์",
  "ศรัท",
  "ศรัทธา",
  "ศรัย",
  "ศราทธพรต",
  "ศราทธ์",
  "ศราพก",
  "ศรายุธ",
  "ศราวก",
  "ศราวณะ",
  "ศราวรณ์",
  "ศรี",
  "ศรีตรัง",
  "ศรีลังกา",
  "ศรีวิชัย",
  "ศรีษะ",
  "ศรีสะเกษ",
  "ศรุติ",
  "ศฤคาล",
  "ศฤงคาร",
  "ศฤงคาริน",
  "ศฤงคารี",
  "ศฤงค์",
  "ศลิษฏ์",
  "ศลิษา",
  "ศวะ",
  "ศวัส",
  "ศวา",
  "ศวาน",
  "ศศ.บ.",
  "ศศธร",
  "ศศพินทุ์",
  "ศศลักษณ์",
  "ศศะ",
  "ศศิ",
  "ศศิขัณฑ์",
  "ศศิธร",
  "ศศิน",
  "ศศิมณฑล",
  "ศศิวิมล",
  "ศศี",
  "ศษ.บ.",
  "ศส.บ.",
  "ศอ",
  "ศอก",
  "ศอกกลับ",
  "ศอกกำ",
  "ศอกกำมา",
  "ศักดา",
  "ศักดิ",
  "ศักดินา",
  "ศักดิ์",
  "ศักดิ์ศรี",
  "ศักดิ์สิทธิ์",
  "ศักติ",
  "ศักย",
  "ศักยภาพ",
  "ศักยะ",
  "ศักย์",
  "ศักร",
  "ศักราช",
  "ศักรินทร์",
  "ศักเรนทร์",
  "ศังกร",
  "ศัตรู",
  "ศันสนะ",
  "ศันสนีย์",
  "ศัพท์",
  "ศัยยา",
  "ศัล",
  "ศัลยกรรม",
  "ศัลยศาสตร์",
  "ศัลยแพทย์",
  "ศัลย์",
  "ศัสดร",
  "ศัสตร",
  "ศัสตรศาสตร์",
  "ศัสตรา",
  "ศัสตราวุธ",
  "ศากตะ",
  "ศากย",
  "ศากยบุตร",
  "ศากยพุทธ",
  "ศากยมุนี",
  "ศากยะ",
  "ศาฎก",
  "ศาณ",
  "ศานติ",
  "ศานต์",
  "ศาป",
  "ศารท",
  "ศารทวิษุวัติ",
  "ศารทูล",
  "ศาริกา",
  "ศาล",
  "ศาลจังหวัด",
  "ศาลชั้นต้น",
  "ศาลฎีกา",
  "ศาลทหาร",
  "ศาลปกครอง",
  "ศาลพระภูมิ",
  "ศาลรัฐธรรมนูญ",
  "ศาลล้มละลาย",
  "ศาลสูง",
  "ศาลสูงสุด",
  "ศาลอาญา",
  "ศาลอุทธรณ์",
  "ศาลา",
  "ศาลากลาง",
  "ศาลาดิน",
  "ศาลาราย",
  "ศาลาวัด",
  "ศาลเตี้ย",
  "ศาลเพียงตา",
  "ศาลแขวง",
  "ศาลแพ่ง",
  "ศาลแรงงาน",
  "ศาลโลก",
  "ศาศวัต",
  "ศาสดา",
  "ศาสตรา",
  "ศาสตราจารย์",
  "ศาสตร์",
  "ศาสนกิจ",
  "ศาสนจักร",
  "ศาสนธรรม",
  "ศาสนบุคคล",
  "ศาสนพิธี",
  "ศาสนวัตถุ",
  "ศาสนศาสตร์",
  "ศาสนสถาน",
  "ศาสนสมบัติ",
  "ศาสนา",
  "ศาสนิกชน",
  "ศาสนีย์",
  "ศาสนูปถัมภก",
  "ศาสน์",
  "ศิกษก",
  "ศิการ",
  "ศิขร",
  "ศิขริน",
  "ศิขรี",
  "ศิขัณฑ์",
  "ศิคาล",
  "ศิงขร",
  "ศิงขริน",
  "ศิตะ",
  "ศิถี",
  "ศิพิระ",
  "ศิรประภา",
  "ศิระ",
  "ศิรา",
  "ศิราภรณ์",
  "ศิรามพุช",
  "ศิรินทร์",
  "ศิริพงษ์",
  "ศิริราช",
  "ศิลป",
  "ศิลปกร",
  "ศิลปกรรม",
  "ศิลปกิจ",
  "ศิลปวัฒนธรรม",
  "ศิลปวัตถุ",
  "ศิลปวิทยา",
  "ศิลปศาสตร์",
  "ศิลปศึกษา",
  "ศิลปหัตถกรรม",
  "ศิลปะ",
  "ศิลปากร",
  "ศิลปิน",
  "ศิลปี",
  "ศิลป์",
  "ศิลา",
  "ศิลาฤกษ์",
  "ศิลาแลง",
  "ศิวลึงค์",
  "ศิวะ",
  "ศิวาลัย",
  "ศิวิไลซ์",
  "ศิวเวท",
  "ศิวโมกข์",
  "ศิศีระ",
  "ศิษฎิ",
  "ศิษฏ์",
  "ศิษยานุศิษย์",
  "ศิษย์",
  "ศิษย์เก่า",
  "ศิษย์เอก",
  "ศิโรรัตน์",
  "ศิโรราบ",
  "ศิโรเวฐน์",
  "ศีขร",
  "ศีต",
  "ศีตกาล",
  "ศีรษะ",
  "ศีล",
  "ศีลจุ่ม",
  "ศีลธรรม",
  "ศีลวัต",
  "ศีลอด",
  "ศึก",
  "ศึกษา",
  "ศึกษาธิการ",
  "ศึกษานิเทศก์",
  "ศึกษาศาสตร์",
  "ศุกรวรรณ",
  "ศุกรวาร",
  "ศุกระ",
  "ศุกร์",
  "ศุกล",
  "ศุกลปักษ์",
  "ศุจิ",
  "ศุทธะ",
  "ศุทธิ",
  "ศุนะ",
  "ศุนิ",
  "ศุภกร",
  "ศุภชลาศัย",
  "ศุภนิมิต",
  "ศุภมัสดุ",
  "ศุภมาตรา",
  "ศุภมาส",
  "ศุภอักษร",
  "ศุภางค์",
  "ศุภเคราะห์",
  "ศุลกากร",
  "ศุลการักษ์",
  "ศุลี",
  "ศุษิร",
  "ศูกร",
  "ศูทร",
  "ศูนยวาท",
  "ศูนย์",
  "ศูนย์กลาง",
  "ศูนย์การค้า",
  "ศูนย์ถ่วง",
  "ศูนย์สูตร",
  "ศูนย์หน้า",
  "ศูละ",
  "ศูลิน",
  "ษมา",
  "ษัฏ",
  "ษัฑ",
  "ษัณ",
  "ษัษ",
  "ษัษฐะ",
  "ษัษฐี",
  "ส.ก.",
  "ส.ก.ศ.ท.",
  "ส.ค.",
  "ส.ค.1",
  "ส.ค.ร.",
  "ส.ค.ส.",
  "ส.ต.",
  "ส.ต.ต.",
  "ส.ต.ท.",
  "ส.ต.อ.",
  "ส.ท.",
  "ส.ทร.",
  "ส.ป.ช.",
  "ส.ป.ส.ท.",
  "ส.ป.อ.",
  "ส.ร.",
  "ส.ล.น.",
  "ส.ว.",
  "ส.ว.ท.",
  "ส.ว.ส.ท.",
  "ส.ส.",
  "ส.ส.ท.",
  "ส.ส.ร.",
  "ส.ห.",
  "ส.อ.",
  "สก",
  "สกฏะ",
  "สกทาคามิผล",
  "สกทาคามิมรรค",
  "สกทาคามี",
  "สกนธ์",
  "สกปรก",
  "สกรณีย์",
  "สกรรจ์",
  "สกรรมกริยา",
  "สกรัม",
  "สกรีน",
  "สกล",
  "สกลนคร",
  "สกลมหาสังฆปริณายก",
  "สกลโลก",
  "สกวาที",
  "สกอตแลนด์",
  "สกัด",
  "สกา",
  "สกาย",
  "สกาว",
  "สกิทาคามิผล",
  "สกิทาคามิมรรค",
  "สกิทาคามี",
  "สกี",
  "สกุณ",
  "สกุณา",
  "สกุณี",
  "สกุน",
  "สกุนต์",
  "สกุล",
  "สกู๊ป",
  "สก็อตแลนด์",
  "สขะ",
  "สคริปต์",
  "สง",
  "สงกร",
  "สงกรานต์",
  "สงกา",
  "สงขลา",
  "สงคร",
  "สงคราม",
  "สงค์",
  "สงฆ์",
  "สงบ",
  "สงบสุข",
  "สงบเงียบ",
  "สงบเสงี่ยม",
  "สงวน",
  "สงสัย",
  "สงสาร",
  "สงสารวัฏ",
  "สงัด",
  "สงเคราะห์",
  "สง่า",
  "สง่างาม",
  "สง่าราศี",
  "สจ๊วต",
  "สฐะ",
  "สณฑ์",
  "สด",
  "สดกก๊อกธม",
  "สดชื่น",
  "สดมภ์",
  "สดับ",
  "สดับปกรณ์",
  "สดายุ",
  "สดำ",
  "สดุดี",
  "สดใส",
  "สตน",
  "สตภิสชะ",
  "สตรอนเชียม",
  "สตรอว์เบอร์รี",
  "สตรอเบอรี",
  "สตรอเบอร์รี",
  "สตริกนิน",
  "สตริง",
  "สตรี",
  "สตรีเพศ",
  "สตอกโฮล์ม",
  "สตอรี",
  "สตะ",
  "สตัฟฟ์",
  "สตัมภ์",
  "สตางค์",
  "สตาร์",
  "สตาร์ท",
  "สตาลิน",
  "สติ",
  "สติกเกอร์",
  "สติปัญญา",
  "สติปัฏฐาน",
  "สติ๊กเกอร์",
  "สตี",
  "สตีฟ",
  "สตีล",
  "สตึก",
  "สตู",
  "สตูดิโอ",
  "สตูป",
  "สตูล",
  "สต็อก",
  "สต็อค",
  "สต๊อก",
  "สต๊อค",
  "สถ.บ.",
  "สถบดี",
  "สถล",
  "สถลมารค",
  "สถวีระ",
  "สถาน",
  "สถานกงสุล",
  "สถานการณ์",
  "สถานที่",
  "สถานทูต",
  "สถานภาพ",
  "สถานะ",
  "สถานี",
  "สถาบัน",
  "สถาปนา",
  "สถาปนิก",
  "สถาปัตยกรรม",
  "สถาปัตยกรรมศาสตร์",
  "สถาปัตยเรขา",
  "สถาปัตยเวท",
  "สถาปัตย์",
  "สถาพร",
  "สถาวร",
  "สถิต",
  "สถิตยศาสตร์",
  "สถิติ",
  "สถิติศาสตร์",
  "สถิร",
  "สถีรวาท",
  "สถุล",
  "สถูป",
  "สทิง",
  "สทึง",
  "สทุม",
  "สธนะ",
  "สน",
  "สนง.สสอ.",
  "สนตะพาย",
  "สนทนา",
  "สนทรรศ",
  "สนทรรศน์",
  "สนธยา",
  "สนธิ",
  "สนธิสัญญา",
  "สนน",
  "สนนราคา",
  "สนม",
  "สนวน",
  "สนอง",
  "สนอบ",
  "สนอม",
  "สนะ",
  "สนัด",
  "สนับ",
  "สนับทึบ",
  "สนับมือ",
  "สนับสนุน",
  "สนับเพลา",
  "สนับแข้ง",
  "สนั่น",
  "สนาน",
  "สนาม",
  "สนามบิน",
  "สนามเพลาะ",
  "สนายุ",
  "สนิกะ",
  "สนิท",
  "สนิทสนม",
  "สนิธ",
  "สนิฟเฟอร์",
  "สนิม",
  "สนิมขุม",
  "สนิมสร้อย",
  "สนุก",
  "สนุกสนาน",
  "สนุกเกอร์",
  "สนุข",
  "สนุต",
  "สนุ่น",
  "สนเทศ",
  "สนเท่ห์",
  "สนใจ",
  "สบ",
  "สบง",
  "สบถ",
  "สบประมาท",
  "สบัน",
  "สบาย",
  "สบายใจ",
  "สบู่",
  "สปริง",
  "สปอต",
  "สปอนเซอร์",
  "สปอร์",
  "สปอร์ต",
  "สปา",
  "สปาย",
  "สปายแวร์",
  "สปาเกตตี",
  "สปิริต",
  "สปีชีส์",
  "สป็อต",
  "สพ.ญ.",
  "สพ.บ.",
  "สพาบ",
  "สภา",
  "สภาพ",
  "สภาพธรรม",
  "สภาวการณ์",
  "สภาวะ",
  "สม",
  "สมการ",
  "สมคบ",
  "สมควร",
  "สมจริง",
  "สมจารี",
  "สมญา",
  "สมญานาม",
  "สมณบริขาร",
  "สมณพราหมณ์",
  "สมณศักดิ์",
  "สมณสารูป",
  "สมณะ",
  "สมดุล",
  "สมถยานิก",
  "สมถวิปัสสนา",
  "สมถะ",
  "สมทบ",
  "สมนัย",
  "สมนาคุณ",
  "สมน้ำหน้า",
  "สมบัติ",
  "สมบุกสมบัน",
  "สมบูรณาญาสิทธิราชย์",
  "สมบูรณ์",
  "สมประกอบ",
  "สมประดี",
  "สมปฤดี",
  "สมปฤๅดี",
  "สมปัก",
  "สมผุส",
  "สมพง",
  "สมพงศ์",
  "สมพล",
  "สมพัตสร",
  "สมพาส",
  "สมภพ",
  "สมภาร",
  "สมมต",
  "สมมติ",
  "สมมติฐาน",
  "สมมติเทพ",
  "สมมาตร",
  "สมมุติ",
  "สมมุติฐาน",
  "สมมูล",
  "สมยอม",
  "สมร",
  "สมรด",
  "สมรภูมิ",
  "สมรรถ",
  "สมรรถนะ",
  "สมรรถภาพ",
  "สมรส",
  "สมรัก",
  "สมรู้",
  "สมฤดี",
  "สมฤติ",
  "สมวายะ",
  "สมสู่",
  "สมส่วน",
  "สมหวัง",
  "สมอ",
  "สมอง",
  "สมะ",
  "สมัคร",
  "สมัครใจ",
  "สมังคี",
  "สมัช",
  "สมัชชา",
  "สมัญญา",
  "สมัต",
  "สมัน",
  "สมันต์",
  "สมัย",
  "สมัยนิยม",
  "สมา",
  "สมาคม",
  "สมาจาร",
  "สมาชิก",
  "สมาทาน",
  "สมาธิ",
  "สมาน",
  "สมานฉันท์",
  "สมาบัติ",
  "สมาพันธรัฐ",
  "สมาพันธ์",
  "สมาร์ท",
  "สมาส",
  "สมิง",
  "สมิต",
  "สมิติ",
  "สมิติเวช",
  "สมิทธิ",
  "สมิทธ์",
  "สมี",
  "สมุก",
  "สมุจจัย",
  "สมุจเฉท",
  "สมุฏฐาน",
  "สมุด",
  "สมุทร",
  "สมุทรปราการ",
  "สมุทรศาสตร์",
  "สมุทรสงคราม",
  "สมุทรสาคร",
  "สมุทรเสนา",
  "สมุทรโคดม",
  "สมุทัย",
  "สมุน",
  "สมุนไพร",
  "สมุย",
  "สมุลแว้ง",
  "สมุห",
  "สมุหกลาโหม",
  "สมุหนาม",
  "สมุหนายก",
  "สมุหเทศาภิบาล",
  "สมุห์",
  "สมเด็จ",
  "สมเพช",
  "สมเสร็จ",
  "สมโพธน์",
  "สมโพธิ",
  "สมโภค",
  "สมโภช",
  "สมโมท",
  "สมโยค",
  "สมใจ",
  "สม่ำเสมอ",
  "สยด",
  "สยดสยอง",
  "สยนะ",
  "สยบ",
  "สยมพร",
  "สยมภู",
  "สยอง",
  "สยองขวัญ",
  "สยอน",
  "สยัมวรา",
  "สยาม",
  "สยามรัฐ",
  "สยามานุสติ",
  "สยามินทร์",
  "สยาย",
  "สยิว",
  "สยิ้ว",
  "สยุมพร",
  "สยุมภู",
  "สร",
  "สรง",
  "สรณคมน์",
  "สรณตรัย",
  "สรณะ",
  "สรณาคมน์",
  "สรตะ",
  "สรทะ",
  "สรนุก",
  "สรภะ",
  "สรภัญญะ",
  "สรภู",
  "สรม",
  "สรร",
  "สรรค์",
  "สรรพ",
  "สรรพคุณ",
  "สรรพนาม",
  "สรรพสามิต",
  "สรรพัชญ",
  "สรรพากร",
  "สรรพางค์",
  "สรรหา",
  "สรรเพชญ",
  "สรรเพชุดา",
  "สรรเสริญ",
  "สรลอน",
  "สรวง",
  "สรวม",
  "สรวมชีพ",
  "สรวล",
  "สรวลเส",
  "สระ",
  "สระกอ",
  "สระท้อน",
  "สระบุรี",
  "สระพรั่ง",
  "สระอาด",
  "สระแก้ว",
  "สรัสวดี",
  "สรั่ง",
  "สราญ",
  "สรีรกิจ",
  "สรีรธาตุ",
  "สรีรวิทยา",
  "สรีรศาสตร์",
  "สรีระ",
  "สรีรังคาร",
  "สรีรางคาร",
  "สรี้",
  "สรุป",
  "สรเนาะ",
  "สรเพชญ",
  "สรเลข",
  "สรเสริญ",
  "สรไน",
  "สร่ง",
  "สร่าง",
  "สร้อย",
  "สร้อยเศร้า",
  "สร้าง",
  "สร้างสรรค์",
  "สร้างเสริม",
  "สฤก",
  "สฤต",
  "สฤษฎิ",
  "สฤษฎี",
  "สฤษฎ์",
  "สฤษฏ์",
  "สฤษดิ์",
  "สลด",
  "สลดใจ",
  "สลบ",
  "สลบไสล",
  "สลวน",
  "สลวย",
  "สลอด",
  "สลอน",
  "สลอย",
  "สละ",
  "สละสลวย",
  "สลัก",
  "สลังงอร์",
  "สลัด",
  "สลัดได",
  "สลับ",
  "สลัม",
  "สลัว",
  "สลา",
  "สลาก",
  "สลากภัต",
  "สลาง",
  "สลาด",
  "สลาตัน",
  "สลาบ",
  "สลาย",
  "สลาเหิน",
  "สลิด",
  "สลิล",
  "สลิ่ม",
  "สลึก",
  "สลึง",
  "สลุต",
  "สลุบ",
  "สลุมพร",
  "สล็อต",
  "สล้าง",
  "สว.จร.",
  "สว.ธร.",
  "สว.ส.",
  "สว.สป.",
  "สว.สส.",
  "สว.อก.",
  "สวก",
  "สวการย์",
  "สวด",
  "สวน",
  "สวนครัว",
  "สวนป่า",
  "สวนสนุก",
  "สวนหย่อม",
  "สวนะ",
  "สวนาการ",
  "สวนิต",
  "สวบ",
  "สวภาพ",
  "สวม",
  "สวมกอด",
  "สวมรอย",
  "สวมเขา",
  "สวย",
  "สวยม",
  "สวยมภู",
  "สวรรค",
  "สวรรคต",
  "สวรรคาลัย",
  "สวรรค์",
  "สวรรยา",
  "สวระ",
  "สวราชย์",
  "สวรินทิรา",
  "สวะ",
  "สวัสดิการ",
  "สวัสดิภาพ",
  "สวัสดิมงคล",
  "สวัสดิ์",
  "สวัสดี",
  "สวัสติ",
  "สวัสติกะ",
  "สวา",
  "สวาคตะ",
  "สวาง",
  "สวาซิแลนด์",
  "สวาด",
  "สวาดิ",
  "สวาตี",
  "สวาท",
  "สวาบ",
  "สวาปาม",
  "สวามิ",
  "สวามินี",
  "สวามิภักดิ์",
  "สวามี",
  "สวาย",
  "สวาสดิ์",
  "สวาหะ",
  "สวาฮิลี",
  "สวิง",
  "สวิงสวาย",
  "สวิญญาณกทรัพย์",
  "สวิตช์",
  "สวิตเซอร์แลนด์",
  "สวิส",
  "สวีท",
  "สวีเดน",
  "สว่าง",
  "สว่างไสว",
  "สว่าน",
  "สว้าน",
  "สส.บ.",
  "สสาร",
  "สสารนิยม",
  "สสุระ",
  "สสุรี",
  "สหกรณ์",
  "สหการ",
  "สหจร",
  "สหชาต",
  "สหชาติ",
  "สหธรรม",
  "สหธรรมิก",
  "สหประชาชาติ",
  "สหพันธรัฐ",
  "สหพันธ์",
  "สหภาพ",
  "สหรัฐ",
  "สหรัฐ",
  "สหราชอาณาจักร",
  "สหศึกษา",
  "สหัช",
  "สหัชญาณ",
  "สหัมบดี",
  "สหัส",
  "สหัสธารา",
  "สหัสนัยน์",
  "สหัสรังสี",
  "สหัสวรรษ",
  "สหัสสะ",
  "สหัสา",
  "สหัสเนตร",
  "สหาย",
  "สอ",
  "สอง",
  "สอด",
  "สอดคล้อง",
  "สอดแทรก",
  "สอดแนม",
  "สอน",
  "สอบ",
  "สอบถาม",
  "สอบทาน",
  "สอบสวน",
  "สอบไล่",
  "สอพลอ",
  "สอย",
  "สะ",
  "สะกด",
  "สะกอ",
  "สะกอม",
  "สะกาง",
  "สะการะ",
  "สะกิด",
  "สะกิดสะเกา",
  "สะคราญ",
  "สะคร้อ",
  "สะค้าน",
  "สะดม",
  "สะดวก",
  "สะดิ้ง",
  "สะดึง",
  "สะดือ",
  "สะดุด",
  "สะดุ้ง",
  "สะตอ",
  "สะตาหมัน",
  "สะตึ",
  "สะตือ",
  "สะตุ",
  "สะทก",
  "สะทึก",
  "สะท้อน",
  "สะท้าน",
  "สะบะ",
  "สะบัก",
  "สะบักสะบอม",
  "สะบัด",
  "สะบัดช่อ",
  "สะบัดสะบิ้ง",
  "สะบันงา",
  "สะบั้น",
  "สะบึม",
  "สะบึมส์",
  "สะบู",
  "สะบ้า",
  "สะพรั่ง",
  "สะพรึงกลัว",
  "สะพรึบ",
  "สะพรึ่บ",
  "สะพัก",
  "สะพัง",
  "สะพัด",
  "สะพั้น",
  "สะพาน",
  "สะพาย",
  "สะระตะ",
  "สะระแหน่",
  "สะลาง",
  "สะลาบ",
  "สะลึมสะลือ",
  "สะวี้ดสะว้าด",
  "สะสม",
  "สะสวย",
  "สะสวย",
  "สะสาง",
  "สะออน",
  "สะอาง",
  "สะอาด",
  "สะอิดสะเอียน",
  "สะอิ้ง",
  "สะอึก",
  "สะอื้น",
  "สะอ้าน",
  "สะเก็ด",
  "สะเงาะสะแงะ",
  "สะเดา",
  "สะเดาะ",
  "สะเด็ด",
  "สะเด็ดยาด",
  "สะเด่า",
  "สะเต๊ะ",
  "สะเทิน",
  "สะเทิ้น",
  "สะเทือน",
  "สะเทือนใจ",
  "สะเทื้อน",
  "สะเปะสะปะ",
  "สะเพร่า",
  "สะเภา",
  "สะเหล่อ",
  "สะเอว",
  "สะเออะ",
  "สะเอ้ง",
  "สะแก",
  "สะแกวัลย์",
  "สะแกแสง",
  "สะแบง",
  "สะโตก",
  "สะโตง",
  "สะโพก",
  "สะโมง",
  "สะโหลสะเหล",
  "สะโอดสะอง",
  "สะใจ",
  "สะใภ้",
  "สะไอ",
  "สัก",
  "สักกะ",
  "สักกัจจะ",
  "สักกายทิฐิ",
  "สักการ",
  "สักการะ",
  "สักขี",
  "สักยะ",
  "สักวา",
  "สักหลาด",
  "สัค",
  "สัคคะ",
  "สังกร",
  "สังกรณี",
  "สังกรประโยค",
  "สังกะตัง",
  "สังกะวัง",
  "สังกะวาด",
  "สังกะสี",
  "สังกัจจายน์",
  "สังกัด",
  "สังกัปปะ",
  "สังกา",
  "สังการ",
  "สังกาศ",
  "สังกิเลส",
  "สังขกร",
  "สังขตธรรม",
  "สังขตะ",
  "สังขยา",
  "สังขลิก",
  "สังขลิกา",
  "สังขาร",
  "สังขารา",
  "สังข์",
  "สังคญาติ",
  "สังคม",
  "สังคมนิยม",
  "สังคมวิทยา",
  "สังคมศาสตร์",
  "สังคมศึกษา",
  "สังคมสงเคราะห์",
  "สังคหะ",
  "สังคัง",
  "สังคายนา",
  "สังคายนาย",
  "สังคีต",
  "สังคีติ",
  "สังค์",
  "สังฆกรรม",
  "สังฆการี",
  "สังฆทาน",
  "สังฆนายก",
  "สังฆปาโมกข์",
  "สังฆภัต",
  "สังฆมณฑล",
  "สังฆมนตรี",
  "สังฆราช",
  "สังฆสภา",
  "สังฆาฏิ",
  "สังฆาณัติ",
  "สังฆาทิเสส",
  "สังฆาธิการ",
  "สังฆานุสติ",
  "สังฆาวาส",
  "สังฆเถระ",
  "สังฆเภท",
  "สังยุตนิกาย",
  "สังวร",
  "สังวัจฉระ",
  "สังวัธยาย",
  "สังวาล",
  "สังวาส",
  "สังสกฤต",
  "สังสการ",
  "สังสนทนา",
  "สังสรรค์",
  "สังสารวัฏ",
  "สังสิทธิ",
  "สังสุทธิ",
  "สังสุทธ์",
  "สังหร",
  "สังหรณ์",
  "สังหาร",
  "สังหาริมทรัพย์",
  "สังหาริมะ",
  "สังหิต",
  "สังเกต",
  "สังเกตการณ์",
  "สังเขป",
  "สังเคราะห์",
  "สังเค็ด",
  "สังเวคะ",
  "สังเวช",
  "สังเวชนียสถาน",
  "สังเวย",
  "สังเวียน",
  "สังโฆ",
  "สังโยค",
  "สังโยชน์",
  "สัจ",
  "สัจกิริยา",
  "สัจจะ",
  "สัจญาณ",
  "สัจธรรม",
  "สัจนิยม",
  "สัจพจน์",
  "สัชฌะ",
  "สัชฌุ",
  "สัญจร",
  "สัญชาตญาณ",
  "สัญชาติ",
  "สัญฌา",
  "สัญญา",
  "สัญญาณ",
  "สัญญาบัตร",
  "สัญญี",
  "สัญนิยม",
  "สัญประกาศ",
  "สัญลักษณ์",
  "สัญเจตนา",
  "สัญโญชน์",
  "สัฐิ",
  "สัณฐาน",
  "สัณฐิติ",
  "สัณฑ์",
  "สัณห์",
  "สัด",
  "สัดจอง",
  "สัดส่วน",
  "สัต",
  "สัตการ",
  "สัตตบงกช",
  "สัตตบรรณ",
  "สัตตบุษย์",
  "สัตตสดก",
  "สัตตะ",
  "สัตตาหกรณียะ",
  "สัตตาหกาลิก",
  "สัตตู",
  "สัตถันดร",
  "สัตถา",
  "สัตถิ",
  "สัตถุ",
  "สัตถุศาสนา",
  "สัตบรรณ",
  "สัตบริภัณฑ์",
  "สัตบุรุษ",
  "สัตภัณฑ์",
  "สัตมวาร",
  "สัตมหาสถาน",
  "สัตยพรต",
  "สัตยวาที",
  "สัตยาธิษฐาน",
  "สัตยาบัน",
  "สัตยาเคราะห์",
  "สัตย์",
  "สัตวชาติ",
  "สัตวบาล",
  "สัตววิทยา",
  "สัตวา",
  "สัตวแพทย์",
  "สัตว์",
  "สัตสดก",
  "สัตหีบ",
  "สัตโลหะ",
  "สัทธรรม",
  "สัทธา",
  "สัทธาจริต",
  "สัทธาธิกะ",
  "สัทธิงวิหาริก",
  "สัทธินทรีย์",
  "สัทธิวิหาริก",
  "สัทวิทยา",
  "สัทศาสตร์",
  "สัทอักษร",
  "สัน",
  "สันดอน",
  "สันดาน",
  "สันดาป",
  "สันตติ",
  "สันตะปาปา",
  "สันตะวา",
  "สันติ",
  "สันติบาล",
  "สันติภาพ",
  "สันติวิธี",
  "สันติสุข",
  "สันตุฏฐี",
  "สันต์",
  "สันถวะ",
  "สันถวไมตรี",
  "สันถัต",
  "สันถาร",
  "สันทนะ",
  "สันทนาการ",
  "สันทะ",
  "สันทัด",
  "สันทัสนะ",
  "สันทาน",
  "สันทิฐิก",
  "สันทิส",
  "สันธาน",
  "สันนิธิ",
  "สันนิบาต",
  "สันนิวาส",
  "สันนิษฐาน",
  "สันสกฤต",
  "สันหลัง",
  "สันเขา",
  "สันเทหะ",
  "สันโดษ",
  "สับ",
  "สับปลับ",
  "สับปลี้",
  "สับปะรด",
  "สับสน",
  "สับหลีก",
  "สับหว่าง",
  "สับเปลี่ยน",
  "สัปคับ",
  "สัปดน",
  "สัปดาหะ",
  "สัปดาห์",
  "สัปตศก",
  "สัปทน",
  "สัปปะ",
  "สัปปิ",
  "สัปปุริส",
  "สัปปุรุษ",
  "สัประยุทธ์",
  "สัปหงก",
  "สัปเหร่อ",
  "สัพ",
  "สัพพะ",
  "สัพพัญญู",
  "สัพยอก",
  "สัพเพเหระ",
  "สัมนา",
  "สัมบูรณ์",
  "สัมปชัญญะ",
  "สัมปทา",
  "สัมปทาน",
  "สัมปยุต",
  "สัมประสิทธิ์",
  "สัมประหาร",
  "สัมปรายภพ",
  "สัมปรายิกภพ",
  "สัมปัตติ",
  "สัมปโยค",
  "สัมผัปลาป",
  "สัมผัปลาปะ",
  "สัมผัส",
  "สัมพล",
  "สัมพหุลา",
  "สัมพัจฉรฉินท์",
  "สัมพัตสร",
  "สัมพัทธภาพ",
  "สัมพัทธ์",
  "สัมพันธน์",
  "สัมพันธภาพ",
  "สัมพันธมิตร",
  "สัมพันธไมตรี",
  "สัมพันธ์",
  "สัมพาหะ",
  "สัมพุทธ",
  "สัมพุทธะ",
  "สัมภวะ",
  "สัมภัต",
  "สัมภัตตะ",
  "สัมภาระ",
  "สัมภาษณ์",
  "สัมภเวสี",
  "สัมมนา",
  "สัมมัปธาน",
  "สัมมา",
  "สัมมาคารวะ",
  "สัมมาชีพ",
  "สัมฤทธิศก",
  "สัมฤทธิ์",
  "สัมโพธิ",
  "สัมโภคกาย",
  "สัมโมทนียกถา",
  "สัยน์",
  "สัลเลข",
  "สัสดี",
  "สัสตทิฐิ",
  "สัสสะ",
  "สัสสุ",
  "สัสสู",
  "สั่ง",
  "สั่งสนทนา",
  "สั่งสม",
  "สั่งสอน",
  "สั่งเสีย",
  "สั่น",
  "สั่นเทา",
  "สั่นเทิ้ม",
  "สั้น",
  "สา",
  "สาก",
  "สากกะเบือ",
  "สากรรจ์",
  "สากล",
  "สากัจฉา",
  "สากัลย์",
  "สากิยะ",
  "สาขา",
  "สาคร",
  "สาคู",
  "สาคเรศ",
  "สาง",
  "สาชล",
  "สาฎก",
  "สาฏิก",
  "สาณี",
  "สาด",
  "สาทร",
  "สาทิตย์",
  "สาทิส",
  "สาทิสลักษณ์",
  "สาทุ",
  "สาธก",
  "สาธยะ",
  "สาธยาย",
  "สาธารณชน",
  "สาธารณประโยชน์",
  "สาธารณภัย",
  "สาธารณรัฐ",
  "สาธารณสถาน",
  "สาธารณสมบัติ",
  "สาธารณสุข",
  "สาธารณะ",
  "สาธารณูปการ",
  "สาธารณูปโภค",
  "สาธารณ์",
  "สาธิต",
  "สาธุ",
  "สาธุการ",
  "สาธุชน",
  "สาธุสะ",
  "สาน",
  "สานุ",
  "สานุศิษย์",
  "สานู",
  "สาบ",
  "สาบสูญ",
  "สาบาน",
  "สาบเสือ",
  "สาป",
  "สาปสรร",
  "สาปส่ง",
  "สาปแช่ง",
  "สาปไตย",
  "สาม",
  "สามง่าม",
  "สามชุก",
  "สามช่า",
  "สามนตราช",
  "สามนต์",
  "สามยทรัพย์",
  "สามล",
  "สามล้อ",
  "สามหาว",
  "สามะ",
  "สามัคคี",
  "สามัญ",
  "สามัญชน",
  "สามัญสำนึก",
  "สามัตถิยะ",
  "สามานยนาม",
  "สามานย์",
  "สามารถ",
  "สามิต",
  "สามินี",
  "สามิภักดิ์",
  "สามี",
  "สามีจิกรรม",
  "สามเณร",
  "สามเณรี",
  "สามเวท",
  "สามเหลี่ยม",
  "สามแยก",
  "สาย",
  "สายชู",
  "สายดิน",
  "สายดิ่ง",
  "สายตรวจ",
  "สายตา",
  "สายน้ำ",
  "สายบัว",
  "สายพาน",
  "สายฟ้า",
  "สายยาง",
  "สายยู",
  "สายรก",
  "สายรุ้ง",
  "สายลับ",
  "สายล่อฟ้า",
  "สายวัด",
  "สายสวาท",
  "สายสะดือ",
  "สายสะพาย",
  "สายสัมพันธ์",
  "สายสิญจน์",
  "สายสืบ",
  "สายส่ง",
  "สายหยุด",
  "สายอากาศ",
  "สายัณห์",
  "สายาห์",
  "สายเลือด",
  "สายโลหิต",
  "สายใย",
  "สายไหม",
  "สาร",
  "สารขัณฑ์",
  "สารคดี",
  "สารตรา",
  "สารถี",
  "สารท",
  "สารทฤดู",
  "สารทุกข์",
  "สารธรรม",
  "สารนาถ",
  "สารนิเทศ",
  "สารบบ",
  "สารบรรณ",
  "สารบัญ",
  "สารบาญ",
  "สารบาญชี",
  "สารประกอบ",
  "สารประโยชน์",
  "สารพัด",
  "สารพัน",
  "สารพางค์",
  "สารภาพ",
  "สารภี",
  "สารละลาย",
  "สารวัตร",
  "สารสนเทศ",
  "สารส้ม",
  "สารหนู",
  "สาระ",
  "สาระพา",
  "สาระยำ",
  "สาระวารี",
  "สาระสะมา",
  "สาระแน",
  "สารัตถประโยชน์",
  "สารัตถศึกษา",
  "สารัตถะ",
  "สารัทธ์",
  "สารัมภ์",
  "สาราณียกร",
  "สาราณียธรรม",
  "สาราณียะ",
  "สาราณียากร",
  "สารานุกรม",
  "สารีบุตร",
  "สารีริกธาตุ",
  "สารูป",
  "สาละ",
  "สาละวน",
  "สาละวิน",
  "สาลิ",
  "สาลิกา",
  "สาลินี",
  "สาลี",
  "สาลี่",
  "สาลู",
  "สาว",
  "สาวก",
  "สาวน้อย",
  "สาวัตถี",
  "สาวิกา",
  "สาวิตร",
  "สาวิตรี",
  "สาวใช้",
  "สาวใหญ่",
  "สาสน",
  "สาสนา",
  "สาสน์",
  "สาสม",
  "สาส์น",
  "สาหรี",
  "สาหร่าย",
  "สาหัส",
  "สาเก",
  "สาเหตุ",
  "สาแหรก",
  "สาโท",
  "สาโรช",
  "สาโลหิต",
  "สาไถย",
  "สำ",
  "สำคัญ",
  "สำซ่าง",
  "สำทับ",
  "สำนวน",
  "สำนอง",
  "สำนัก",
  "สำนักงาน",
  "สำนักพิมพ์",
  "สำนักสงฆ์",
  "สำนาน",
  "สำนึก",
  "สำนึง",
  "สำบัด",
  "สำปะลอ",
  "สำปะหลัง",
  "สำปันนี",
  "สำปั้น",
  "สำมะงา",
  "สำมะลอ",
  "สำมะหา",
  "สำมะเลเทเมา",
  "สำมะโน",
  "สำมะโนครัว",
  "สำรด",
  "สำรวจ",
  "สำรวม",
  "สำรวย",
  "สำรวล",
  "สำรอก",
  "สำรอง",
  "สำรับ",
  "สำราก",
  "สำราญ",
  "สำริด",
  "สำลัก",
  "สำลาน",
  "สำลี",
  "สำส่อน",
  "สำหรวด",
  "สำหรับ",
  "สำหา",
  "สำออย",
  "สำอาง",
  "สำเนา",
  "สำเนียง",
  "สำเภา",
  "สำเรา",
  "สำเริง",
  "สำเร็จ",
  "สำเร็จรูป",
  "สำเหนียก",
  "สำเหร่",
  "สำแดง",
  "สำแลง",
  "สำโรง",
  "สิ",
  "สิกขมานา",
  "สิกขา",
  "สิกขาบท",
  "สิกขิม",
  "สิกข์",
  "สิข",
  "สิขร",
  "สิขรี",
  "สิขา",
  "สิขานล",
  "สิขี",
  "สิขเรศ",
  "สิคาล",
  "สิง",
  "สิงขร",
  "สิงคลิ้ง",
  "สิงคลี",
  "สิงคาร",
  "สิงคาล",
  "สิงคี",
  "สิงคโปร์",
  "สิงค์",
  "สิงสถิต",
  "สิงสู่",
  "สิงหนาท",
  "สิงหบัญชร",
  "สิงหรา",
  "สิงหราช",
  "สิงหล",
  "สิงหา",
  "สิงหาคม",
  "สิงหาสน์",
  "สิงห์",
  "สิงห์บุรี",
  "สิงโต",
  "สิญจน์",
  "สิตะ",
  "สิตางศุ์",
  "สิถิล",
  "สิทธัตถะ",
  "สิทธา",
  "สิทธาจารย์",
  "สิทธารถ",
  "สิทธิ",
  "สิทธิกร",
  "สิทธิการิยะ",
  "สิทธิชัย",
  "สิทธิบัตร",
  "สิทธิโชค",
  "สิทธิ์",
  "สิทธิ์ขาด",
  "สิทธ์",
  "สิธยะ",
  "สิน",
  "สินค้า",
  "สินจ้าง",
  "สินทรัพย์",
  "สินธพ",
  "สินธี",
  "สินธุ",
  "สินธุระ",
  "สินธุ์",
  "สินธู",
  "สินน้ำใจ",
  "สินบน",
  "สินสมรส",
  "สินสอด",
  "สินาด",
  "สินิทธ์",
  "สินี",
  "สินเชื่อ",
  "สินเทา",
  "สินเธาว์",
  "สินแร่",
  "สินไถ่",
  "สินไหม",
  "สิบ",
  "สิปปะ",
  "สิมพลี",
  "สิมิลัน",
  "สิระ",
  "สิริ",
  "สิริกิติ์",
  "สิรินธร",
  "สิรี",
  "สิลา",
  "สิว",
  "สิวะ",
  "สิวาลัย",
  "สิวิกา",
  "สิหิงค์",
  "สิเนรุ",
  "สิเนหก",
  "สิเนหะ",
  "สิเนหา",
  "สิเน่หา",
  "สิโรดม",
  "สิโรตม์",
  "สิ่ง",
  "สิ่งก่อสร้าง",
  "สิ่งของ",
  "สิ่งปฏิกูล",
  "สิ่งพิมพ์",
  "สิ่งศักดิ์สิทธิ์",
  "สิ่งแวดล้อม",
  "สิ่ว",
  "สิ้น",
  "สิ้นสุด",
  "สิ้นเชิง",
  "สี",
  "สีกา",
  "สีกุน",
  "สีข้าง",
  "สีชอล์ก",
  "สีด",
  "สีดอ",
  "สีดา",
  "สีตลรัศมี",
  "สีตโลทก",
  "สีถ่าน",
  "สีทันดร",
  "สีน้ำ",
  "สีน้ำมัน",
  "สีผึ้ง",
  "สีฝุ่น",
  "สีมันต์",
  "สีมา",
  "สีลม",
  "สีละมัน",
  "สีวลี",
  "สีวิกา",
  "สีสอ",
  "สีสะ",
  "สีสา",
  "สีสุก",
  "สีหนาท",
  "สีหนุ",
  "สีหน้า",
  "สีหบัญชร",
  "สีหมุนี",
  "สีหราช",
  "สีหะ",
  "สีหโมนี",
  "สีหไสยา",
  "สีหไสยาสน์",
  "สีห์",
  "สีเทียน",
  "สีเสียด",
  "สีโตทก",
  "สีโปสเตอร์",
  "สี่",
  "สี่เหลี่ยม",
  "สี่แยก",
  "สี้",
  "สึก",
  "สึกหรอ",
  "สึง",
  "สึนามิ",
  "สืบ",
  "สืบค้น",
  "สืบทอด",
  "สืบสวน",
  "สืบสาว",
  "สืบเสาะ",
  "สื่อ",
  "สื่อผสม",
  "สื่อมวลชน",
  "สื่อสาร",
  "สุ",
  "สุ.จิ.ปุ.ลิ.",
  "สุก",
  "สุกข์",
  "สุกงอม",
  "สุกดิบ",
  "สุกปลั่ง",
  "สุกร",
  "สุกรม",
  "สุกำศพ",
  "สุกียากี้",
  "สุกี้",
  "สุกี้ยากี้",
  "สุกใส",
  "สุข",
  "สุขนาฏกรรม",
  "สุขภัณฑ์",
  "สุขภาพ",
  "สุขลักษณะ",
  "สุขวิทยา",
  "สุขศาลา",
  "สุขศึกษา",
  "สุขา",
  "สุขาภิบาล",
  "สุขารมณ์",
  "สุขาวดี",
  "สุขิน",
  "สุขี",
  "สุขุม",
  "สุขุมวิท",
  "สุขุมาล",
  "สุคต",
  "สุคติ",
  "สุคนธ",
  "สุคนธชาติ",
  "สุคนธรส",
  "สุคนธ์",
  "สุครีพ",
  "สุคันธรส",
  "สุคันธ์",
  "สุงกะ",
  "สุงกากร",
  "สุงสิง",
  "สุงสุมาร",
  "สุจริต",
  "สุจหนี่",
  "สุจิ",
  "สุจิต",
  "สุจิตร",
  "สุชน",
  "สุชัมบดี",
  "สุชา",
  "สุชาดา",
  "สุญ",
  "สุญญากาศ",
  "สุญตา",
  "สุญนิยม",
  "สุณ",
  "สุณิสา",
  "สุด",
  "สุดท้าย",
  "สุดา",
  "สุต",
  "สุตกวี",
  "สุตตนิบาต",
  "สุตตะ",
  "สุตตันตปิฎก",
  "สุตตันตะ",
  "สุติ",
  "สุทธ",
  "สุทธาวาส",
  "สุทธิ",
  "สุทธ์",
  "สุทรรศน์",
  "สุทัศน์",
  "สุทโธทนะ",
  "สุธา",
  "สุธารส",
  "สุธาสินี",
  "สุธาสี",
  "สุธาโภชน์",
  "สุธี",
  "สุนทร",
  "สุนทรพจน์",
  "สุนทราภรณ์",
  "สุนทรี",
  "สุนทรียภาพ",
  "สุนทรียศาสตร์",
  "สุนทรียะ",
  "สุนทรีย์",
  "สุนัข",
  "สุนันท์",
  "สุนีย์",
  "สุบดี",
  "สุบรรณ",
  "สุบิน",
  "สุปรีดิ์",
  "สุปรีย์",
  "สุปาณี",
  "สุพพัต",
  "สุพรรณ",
  "สุพรรณถัน",
  "สุพรรณบัฏ",
  "สุพรรณบุรี",
  "สุพรรณภาชน์",
  "สุพรรณราช",
  "สุพรรณศรี",
  "สุพรรณิการ์",
  "สุภร",
  "สุภัค",
  "สุภา",
  "สุภาพ",
  "สุภาพชน",
  "สุภาษิต",
  "สุม",
  "สุมทุม",
  "สุมน",
  "สุมนะ",
  "สุมนัส",
  "สุมนา",
  "สุมะ",
  "สุมาตรา",
  "สุมาลี",
  "สุรคต",
  "สุรงค์",
  "สุรนารี",
  "สุรบดี",
  "สุรภาพ",
  "สุรภี",
  "สุรยุทธ์",
  "สุรสีหนาท",
  "สุรังค์",
  "สุรัติ",
  "สุรัสวดี",
  "สุรา",
  "สุรางคนา",
  "สุรางคนางค์",
  "สุรางค์จำเรียง",
  "สุรารักษ์",
  "สุราลัย",
  "สุราษฎร์",
  "สุราษฎร์ธานี",
  "สุรินทราหู",
  "สุรินทร์",
  "สุรินทร์",
  "สุริยกันต์",
  "สุริยกานต์",
  "สุริยการ",
  "สุริยกาล",
  "สุริยคติ",
  "สุริยคราส",
  "สุริยง",
  "สุริยจักรวาล",
  "สุริยน",
  "สุริยมณฑล",
  "สุริยยาตร",
  "สุริยยาตร์",
  "สุริยวงศ์",
  "สุริยะ",
  "สุริยัน",
  "สุริยา",
  "สุริยาสน์",
  "สุริยุปราคา",
  "สุริเยนทร์",
  "สุริเยศ",
  "สุริโย",
  "สุรีย์",
  "สุรุ่ยสุร่าย",
  "สุรเชษฐ์",
  "สุรเสียง",
  "สุรโลก",
  "สุลต่าน",
  "สุวคนธ์",
  "สุวภาพ",
  "สุวรรณ",
  "สุวรรณภูมิ",
  "สุวะ",
  "สุวาน",
  "สุวินัย",
  "สุวิมล",
  "สุษิระ",
  "สุสาน",
  "สุหนัต",
  "สุหนี่",
  "สุหร่ง",
  "สุหร่าย",
  "สุหฤท",
  "สุหัท",
  "สุเทพ",
  "สุเมธ",
  "สุเมรุ",
  "สุเหร่า",
  "สุเอซ",
  "สุโข",
  "สุโขทัย",
  "สุโนก",
  "สุไหง",
  "สุ่ม",
  "สุ้มเสียง",
  "สู",
  "สูง",
  "สูงส่ง",
  "สูจิ",
  "สูจิบัตร",
  "สูญ",
  "สูญญากาศ",
  "สูญสิ้น",
  "สูญหาย",
  "สูญเปล่า",
  "สูญเสีย",
  "สูด",
  "สูต",
  "สูตร",
  "สูติ",
  "สูติกรรม",
  "สูตินรีเวช",
  "สูติบัตร",
  "สูติศาสตร์",
  "สูติแพทย์",
  "สูท",
  "สูทกรรม",
  "สูทศาสตร์",
  "สูบ",
  "สูปะ",
  "สูร",
  "สูรยกานต์",
  "สูรย์",
  "สูริ",
  "สูสี",
  "สู่",
  "สู่ขอ",
  "สู่รู้",
  "สู่สม",
  "สู้",
  "สเกต",
  "สเกตช์",
  "สเกล",
  "สเกลาร์",
  "สเก็ตช์",
  "สเตชัน",
  "สเตชั่น",
  "สเตนเลส",
  "สเตย์",
  "สเตริโอ",
  "สเตอริโอ",
  "สเตียรอยด์",
  "สเตเดียม",
  "สเต็ป",
  "สเต็ม",
  "สเต๊ก",
  "สเปก",
  "สเปกตรัม",
  "สเปกโทรสโกป",
  "สเปกโทรสโคป",
  "สเปค",
  "สเปซ",
  "สเปน",
  "สเปิร์ม",
  "สแกน",
  "สแกนดิเนเวีย",
  "สแกนเดียม",
  "สแกนเนอร์",
  "สแควร์",
  "สแตนดาร์ด",
  "สแตนฟอร์ด",
  "สแตนเลส",
  "สแต็ก",
  "สแปม",
  "สแลง",
  "สแล็ก",
  "สโตน",
  "สโตร์",
  "สโมธาน",
  "สโมสร",
  "สโรช",
  "สโรชะ",
  "สโรชา",
  "สโลวะเกีย",
  "สโลวัก",
  "สโลวีเนีย",
  "สโลว์",
  "สโลแกน",
  "สไตรค์",
  "สไตล์",
  "สไนเปอร์",
  "สไบ",
  "สไปริลลัม",
  "สไปเดอร์",
  "สไลด์",
  "ส่ง",
  "ส่งท้าย",
  "ส่งสการ",
  "ส่งเดช",
  "ส่งเสริม",
  "ส่งเสีย",
  "ส่งเสียง",
  "ส่วน",
  "ส่วนกลาง",
  "ส่วนตัว",
  "ส่วนบุญ",
  "ส่วนประกอบ",
  "ส่วนผสม",
  "ส่วนพระองค์",
  "ส่วนรวม",
  "ส่วนร่วม",
  "ส่วนลด",
  "ส่วนสัด",
  "ส่วนเกิน",
  "ส่วนแบ่ง",
  "ส่วย",
  "ส่อ",
  "ส่อง",
  "ส่อน",
  "ส่อเสียด",
  "ส่า",
  "ส่าน",
  "ส่าย",
  "ส่าหรี",
  "ส่าเหล้า",
  "ส่ำ",
  "ส้น",
  "ส้นตีน",
  "ส้ม",
  "ส้มกุ้ง",
  "ส้มฉุน",
  "ส้มตำ",
  "ส้มป่อย",
  "ส้มมือ",
  "ส้มลิ้ม",
  "ส้มเช้า",
  "ส้วง",
  "ส้วม",
  "ส้วย",
  "ส้อง",
  "ส้อม",
  "ส้อมเสียง",
  "ส้าง",
  "ส้าย",
  "ห.ร.ม.",
  "หก",
  "หกคะเมน",
  "หกล้ม",
  "หง",
  "หงก",
  "หงวน",
  "หงสบาท",
  "หงสรถ",
  "หงสา",
  "หงสาวดี",
  "หงส์",
  "หงส์หยก",
  "หงอ",
  "หงอก",
  "หงองแหงง",
  "หงอด",
  "หงอน",
  "หงอนไก่",
  "หงอย",
  "หงอยก๋อย",
  "หงอยเหงา",
  "หงัก",
  "หงับ",
  "หงาย",
  "หงายท้อง",
  "หงายหลัง",
  "หงำ",
  "หงำเหงอะ",
  "หงำเหงือก",
  "หงิก",
  "หงิง",
  "หงิม",
  "หงึก",
  "หงุงหงิง",
  "หงุดหงิด",
  "หงุบ",
  "หงุ่ย",
  "หง่อง",
  "หง่อม",
  "หง่อย",
  "หง่าง",
  "หง่าว",
  "หญิง",
  "หญิบ",
  "หญ้า",
  "หญ้าฝรั่น",
  "หญ้ายายเภา",
  "หด",
  "หดหาย",
  "หดหู่",
  "หตะ",
  "หทัย",
  "หน",
  "หนทาง",
  "หนวก",
  "หนวกหู",
  "หนวด",
  "หนอ",
  "หนอก",
  "หนอง",
  "หนองคาย",
  "หนองบัวลำภู",
  "หนองแซง",
  "หนองใน",
  "หนอน",
  "หนอนตายหยาก",
  "หนัก",
  "หนักข้อ",
  "หนักหนา",
  "หนักหน่วง",
  "หนักแน่น",
  "หนักใจ",
  "หนัง",
  "หนังกลับ",
  "หนังตะลุง",
  "หนังสด",
  "หนังสติ๊ก",
  "หนังสือ",
  "หนังสือพิมพ์",
  "หนังเรียด",
  "หนังใหญ่",
  "หนับ",
  "หนั่น",
  "หนา",
  "หนาด",
  "หนาน",
  "หนานไห่",
  "หนาม",
  "หนามเตย",
  "หนาว",
  "หนาวเหน็บ",
  "หนาแน่น",
  "หนำ",
  "หนำเลี้ยบ",
  "หนำใจ",
  "หนี",
  "หนีบ",
  "หนี้",
  "หนี้สิน",
  "หนี้สูญ",
  "หนึก",
  "หนึบ",
  "หนึ่ง",
  "หนืด",
  "หนุ",
  "หนุน",
  "หนุนหลัง",
  "หนุนเนื่อง",
  "หนุบ",
  "หนุมาน",
  "หนุ่ม",
  "หนุ่ย",
  "หนู",
  "หน็อยแน่",
  "หน่วง",
  "หน่วงเหนี่ยว",
  "หน่วย",
  "หน่วยกิต",
  "หน่วยก้าน",
  "หน่อ",
  "หน่อมแน้ม",
  "หน่อย",
  "หน่อไม้",
  "หน่าง",
  "หน่าย",
  "หน่ายหนี",
  "หน่ายแหนง",
  "หน้า",
  "หน้ากระดาน",
  "หน้ากาก",
  "หน้ากาฬ",
  "หน้าจั่ว",
  "หน้าฉาน",
  "หน้าตัก",
  "หน้าตา",
  "หน้าต่าง",
  "หน้าทับ",
  "หน้าที่",
  "หน้าที่นั่ง",
  "หน้าท้อง",
  "หน้าบัน",
  "หน้าปัด",
  "หน้าผา",
  "หน้าผาก",
  "หน้ามุข",
  "หน้าม้า",
  "หน้าอก",
  "หน้าเลือด",
  "หน้าแข้ง",
  "หน้าไม้",
  "หมก",
  "หมกมุ่น",
  "หมด",
  "หมดจด",
  "หมวก",
  "หมวด",
  "หมวน",
  "หมวย",
  "หมอ",
  "หมอก",
  "หมอขวัญ",
  "หมอความ",
  "หมอง",
  "หมองมัว",
  "หมองหมาง",
  "หมองหม่น",
  "หมองู",
  "หมองใจ",
  "หมอดู",
  "หมอตำแย",
  "หมอทำขวัญ",
  "หมอน",
  "หมอนขวาน",
  "หมอนข้าง",
  "หมอนทอง",
  "หมอนวด",
  "หมอบ",
  "หมอผี",
  "หมอย",
  "หมอยา",
  "หมอลำ",
  "หมอเสน่ห์",
  "หมอแคน",
  "หมัก",
  "หมักหมม",
  "หมัด",
  "หมัน",
  "หมับ",
  "หมั่น",
  "หมั่นโถว",
  "หมั่นไส้",
  "หมั้น",
  "หมา",
  "หมาก",
  "หมากฝรั่ง",
  "หมากรุก",
  "หมากสง",
  "หมากหอม",
  "หมากฮอส",
  "หมากเก็บ",
  "หมากเม่า",
  "หมาง",
  "หมางเมิน",
  "หมางใจ",
  "หมาด",
  "หมาป่า",
  "หมามุ่ย",
  "หมามุ้ย",
  "หมาย",
  "หมายขัง",
  "หมายความ",
  "หมายค้น",
  "หมายจับ",
  "หมายตา",
  "หมายปล่อย",
  "หมายปอง",
  "หมายมั่น",
  "หมายเกณฑ์",
  "หมายเรียก",
  "หมายเลข",
  "หมายเหตุ",
  "หมายใจ",
  "หมาร่า",
  "หมาหมู่",
  "หมาไม้",
  "หมิง",
  "หมิ่น",
  "หมิ่นเหม่",
  "หมี",
  "หมี่",
  "หมึก",
  "หมึกจีน",
  "หมืน",
  "หมื่น",
  "หมุด",
  "หมุน",
  "หมุนเวียน",
  "หมุบ",
  "หมุบหมับ",
  "หมุบหมิบ",
  "หมุยขาว",
  "หมุ่ย",
  "หมู",
  "หมูป่า",
  "หมูยอ",
  "หมูหย็อง",
  "หมูหริ่ง",
  "หมูหัน",
  "หมูแดง",
  "หมูแผ่น",
  "หมูแฮม",
  "หมู่",
  "หมู่บ้าน",
  "หม่น",
  "หม่อ",
  "หม่อง",
  "หม่อน",
  "หม่อม",
  "หม่อมฉัน",
  "หม่อมราชวงศ์",
  "หม่อมหลวง",
  "หม่อมห้าม",
  "หม่อมเจ้า",
  "หม่า",
  "หม่านโถว",
  "หม่ำ",
  "หม้อ",
  "หม้อตาล",
  "หม้อน้ำ",
  "หม้อห้อม",
  "หม้อแกง",
  "หม้อแปลง",
  "หม้าย",
  "หม้ำ",
  "หยก",
  "หยด",
  "หยดย้อย",
  "หยวก",
  "หยวน",
  "หยวบ",
  "หยอก",
  "หยอกเย้า",
  "หยอกเอิน",
  "หยอง",
  "หยอด",
  "หยอน",
  "หยอมแหยม",
  "หยอย",
  "หยัก",
  "หยักรั้ง",
  "หยักศก",
  "หยักเหยา",
  "หยักไย่",
  "หยัง",
  "หยังหยัง",
  "หยัด",
  "หยัน",
  "หยับ",
  "หยั่ง",
  "หยั่งทราบ",
  "หยั่งรู้",
  "หยั่งเสียง",
  "หยั่วเมือง",
  "หยากเยื่อ",
  "หยากไย่",
  "หยาด",
  "หยาบ",
  "หยาบคาย",
  "หยาบช้า",
  "หยาบหยาม",
  "หยาบโลน",
  "หยาม",
  "หยาว",
  "หยำเป",
  "หยำเหยอะ",
  "หยำแหยะ",
  "หยิก",
  "หยิบ",
  "หยิบมือ",
  "หยิบยก",
  "หยิบยืม",
  "หยิบหย่ง",
  "หยิบโหย่ง",
  "หยิม",
  "หยิ่ง",
  "หยี",
  "หยี่",
  "หยุกหยิก",
  "หยุด",
  "หยุบ",
  "หยุมหยิม",
  "หยุ่น",
  "หยูกยา",
  "หย็อกหย็อย",
  "หย็อง",
  "หย็องกรอด",
  "หย็องแหย็ง",
  "หย็อมแหย็ม",
  "หย็อย",
  "หย่ง",
  "หย่อง",
  "หย่อน",
  "หย่อม",
  "หย่อย",
  "หย่า",
  "หย้าว",
  "หรคุณ",
  "หรณะ",
  "หรดาล",
  "หรดี",
  "หรรษา",
  "หรรษ์",
  "หรอ",
  "หรอก",
  "หรอย",
  "หระ",
  "หรับ",
  "หรา",
  "หริ",
  "หริณะ",
  "หริต",
  "หริตกี",
  "หริภุญชัย",
  "หริรักษ์",
  "หริวงศ์",
  "หริ่ง",
  "หรีด",
  "หรีตกี",
  "หรี่",
  "หรือ",
  "หรุบ",
  "หรุบรู่",
  "หรุบหรู่",
  "หรุ่ม",
  "หรู",
  "หรูหรา",
  "หร็อมแหร็ม",
  "หฤทย์",
  "หฤทัย",
  "หฤษฎี",
  "หฤษฎ์",
  "หฤหรรษ์",
  "หฤโหด",
  "หลง",
  "หลงจู๊",
  "หลงผิด",
  "หลงใหล",
  "หลด",
  "หลน",
  "หลบ",
  "หลบฉาก",
  "หลบมุม",
  "หลวง",
  "หลวงจีน",
  "หลวงตา",
  "หลวงปู่",
  "หลวงพระบาง",
  "หลวงพี่",
  "หลวงพ่อ",
  "หลวม",
  "หลวมตัว",
  "หลอ",
  "หลอก",
  "หลอกลวง",
  "หลอกล่อ",
  "หลอกล้อ",
  "หลอด",
  "หลอดลม",
  "หลอดอาหาร",
  "หลอดเลือด",
  "หลอน",
  "หลอม",
  "หลอมตัว",
  "หลอมเหลว",
  "หละ",
  "หละหลวม",
  "หลัก",
  "หลักการ",
  "หลักชัย",
  "หลักฐาน",
  "หลักทรัพย์",
  "หลักลอย",
  "หลักสูตร",
  "หลักเกณฑ์",
  "หลักเมือง",
  "หลักแหลม",
  "หลักแหล่ง",
  "หลัง",
  "หลังคา",
  "หลังเต่า",
  "หลัด",
  "หลับ",
  "หลับนก",
  "หลับใน",
  "หลัว",
  "หลั่ง",
  "หลั่งไหล",
  "หลั่น",
  "หลา",
  "หลาก",
  "หลากหลาย",
  "หลากใจ",
  "หลาน",
  "หลาบ",
  "หลาบจำ",
  "หลาม",
  "หลาย",
  "หลายหลาก",
  "หลายแหล่",
  "หลาว",
  "หลิท",
  "หลิน",
  "หลินจือ",
  "หลินฮุ่ย",
  "หลิม",
  "หลิว",
  "หลิ่ง",
  "หลิ่ว",
  "หลี",
  "หลีก",
  "หลีบ",
  "หลีฮื้อ",
  "หลีโก",
  "หลืบ",
  "หลุกหลิก",
  "หลุด",
  "หลุดพ้น",
  "หลุดลอย",
  "หลุดลุ่ย",
  "หลุน",
  "หลุบ",
  "หลุม",
  "หลุมพราง",
  "หลุมพอ",
  "หลุมพี",
  "หลุมโจน",
  "หลุยส์",
  "หลู่",
  "หล็อน",
  "หล่น",
  "หล่ม",
  "หล่อ",
  "หล่อน",
  "หล่อลื่น",
  "หล่อหลอม",
  "หล่อฮังก้วย",
  "หล่อเลี้ยง",
  "หล่ะ",
  "หล้า",
  "หวง",
  "หวงก้าง",
  "หวงห้าม",
  "หวงแหน",
  "หวด",
  "หวน",
  "หวย",
  "หวอ",
  "หวอด",
  "หวะ",
  "หวัง",
  "หวังดี",
  "หวังใจ",
  "หวัด",
  "หวันยิหวา",
  "หวัว",
  "หวัวร่อ",
  "หวัวเราะ",
  "หวั่น",
  "หวั่นกลัว",
  "หวั่นวิตก",
  "หวั่นหวาด",
  "หวั่นเกรง",
  "หวั่นใจ",
  "หวั่นไหว",
  "หวา",
  "หวาก",
  "หวาด",
  "หวาดกลัว",
  "หวาดผวา",
  "หวาดหวั่น",
  "หวาดเกรง",
  "หวาดเสียว",
  "หวาดไหว",
  "หวาน",
  "หวานเย็น",
  "หวาม",
  "หวาย",
  "หวำ",
  "หวิด",
  "หวิว",
  "หวี",
  "หวีด",
  "หวี่",
  "หวือ",
  "หวุดหวิด",
  "หวูด",
  "หว่า",
  "หว่าง",
  "หว่าน",
  "หว่านล้อม",
  "หว้า",
  "หอ",
  "หอก",
  "หอกซัด",
  "หอคอย",
  "หอคำ",
  "หอง",
  "หอฉัน",
  "หอน",
  "หอบ",
  "หอประชุม",
  "หอพัก",
  "หอม",
  "หอมหวน",
  "หอย",
  "หอสมุด",
  "หอไตร",
  "หะ",
  "หะยี",
  "หะหาย",
  "หัก",
  "หักมุก",
  "หักล้าง",
  "หักหาญ",
  "หักห้าม",
  "หักเห",
  "หักโหม",
  "หัจญี",
  "หัจญ์",
  "หัช",
  "หัฏฐะ",
  "หัด",
  "หัต",
  "หัตถกรรม",
  "หัตถการ",
  "หัตถกิจ",
  "หัตถบาส",
  "หัตถพันธ์",
  "หัตถศาสตร์",
  "หัตถศิลป์",
  "หัตถศึกษา",
  "หัตถาจารย์",
  "หัตถาภรณ์",
  "หัตถินี",
  "หัตถี",
  "หัตถ์",
  "หัน",
  "หันตรา",
  "หันเห",
  "หับ",
  "หับเผย",
  "หัย",
  "หัว",
  "หัวขั้ว",
  "หัวขโมย",
  "หัวข้อ",
  "หัวคะแนน",
  "หัวคิด",
  "หัวค่ำ",
  "หัวจุก",
  "หัวนม",
  "หัวนอน",
  "หัวป่า",
  "หัวมุม",
  "หัวร่อ",
  "หัวหน่าว",
  "หัวหน้า",
  "หัวหอก",
  "หัวอก",
  "หัวเข่า",
  "หัวเทียน",
  "หัวเราะ",
  "หัวเรื่อง",
  "หัวเห็ด",
  "หัวแร้ง",
  "หัวโขน",
  "หัวโจก",
  "หัวใจ",
  "หัวใส",
  "หัวไหล่",
  "หัส",
  "หัสดนตรี",
  "หัสดิน",
  "หัสดี",
  "หัสดีลิงค์",
  "หัสตะ",
  "หัสต์",
  "หัสนาฏกรรม",
  "หัสนิยาย",
  "หั่น",
  "หั้น",
  "หา",
  "หาก",
  "หาง",
  "หางว่าว",
  "หางเครื่อง",
  "หางเลข",
  "หางเสียง",
  "หางเสือ",
  "หางแถว",
  "หาญ",
  "หาด",
  "หาบ",
  "หาบเร่",
  "หาม",
  "หาย",
  "หายตัว",
  "หายนะ",
  "หายใจ",
  "หาร",
  "หารือ",
  "หาว",
  "หาวนอน",
  "หาสะ",
  "หำ",
  "หิงคุ",
  "หิงสา",
  "หิงห้อย",
  "หิด",
  "หิต",
  "หิตานุหิตประโยชน์",
  "หิน",
  "หินงอก",
  "หินชาติ",
  "หินทราย",
  "หินปูน",
  "หินยาน",
  "หินย้อย",
  "หินอ่อน",
  "หิมพาน",
  "หิมพานต์",
  "หิมวัต",
  "หิมวันต์",
  "หิมวา",
  "หิมะ",
  "หิมาลัย",
  "หิรัญ",
  "หิรัญญิการ์",
  "หิรัญบัฏ",
  "หิรัณยรัศมี",
  "หิรัณย์",
  "หิริ",
  "หิว",
  "หิ่งหาย",
  "หิ่งห้อย",
  "หิ้ง",
  "หิ้ว",
  "หี",
  "หีด",
  "หีนยาน",
  "หีบ",
  "หีบห่อ",
  "หีบเพลง",
  "หึ",
  "หึง",
  "หึงสา",
  "หึ่ง",
  "หืด",
  "หืน",
  "หือ",
  "หื่น",
  "หื้อ",
  "หุง",
  "หุน",
  "หุนหัน",
  "หุบ",
  "หุบผา",
  "หุบเขา",
  "หุบเหว",
  "หุยฮา",
  "หุ่น",
  "หุ่นกระบอก",
  "หุ่นยนต์",
  "หุ้น",
  "หุ้นลม",
  "หุ้นส่วน",
  "หุ้ม",
  "หู",
  "หูก",
  "หูกระต่าย",
  "หูกวาง",
  "หูช้าง",
  "หูด",
  "หูรูด",
  "หูหนาน",
  "หูเป่ย",
  "หูเป่ย์",
  "หู่",
  "หโยดม",
  "ห่ม",
  "ห่วง",
  "ห่วงใย",
  "ห่วย",
  "ห่อ",
  "ห่อน",
  "ห่อหมก",
  "ห่อเหี่ยว",
  "ห่า",
  "ห่าง",
  "ห่างเหิน",
  "ห่าน",
  "ห่าม",
  "ห้วง",
  "ห้วงน้ำ",
  "ห้วน",
  "ห้วย",
  "ห้อ",
  "ห้อง",
  "ห้องชุด",
  "ห้องน้ำ",
  "ห้องสมุด",
  "ห้องเครื่อง",
  "ห้องแถว",
  "ห้องโถง",
  "ห้อม",
  "ห้อมล้อม",
  "ห้อย",
  "ห้อยโหน",
  "ห้า",
  "ห้าง",
  "ห้าม",
  "ห้ามปราม",
  "ห้ามล้อ",
  "ห้าว",
  "ห้าวหาญ",
  "ห้ำ",
  "ห้ำหั่น",
  "อ.ก.ค.",
  "อ.ก.จ.",
  "อ.จ.",
  "อ.ช.พ.",
  "อ.ตร.",
  "อ.บ.",
  "อ.ส.ท.",
  "อ.ส.ม.ท.",
  "อ.ส.ย.",
  "อ.อ.ป.",
  "อก",
  "อกตัญญุตา",
  "อกตัญญู",
  "อกตเวทิตา",
  "อกตเวที",
  "อกนิษฐ์",
  "อกรณีย์",
  "อกรรมกริยา",
  "อกร่อง",
  "อกัปปิยวัตถุ",
  "อกัปปิยะ",
  "อกุศล",
  "อกไก่",
  "อคติ",
  "อคาธ",
  "องก์",
  "องคชาต",
  "องคต",
  "องคมนตรี",
  "องครักษ์",
  "องคาพยพ",
  "องคุลิมาล",
  "องคุลี",
  "องค์",
  "องค์กร",
  "องค์การ",
  "องศา",
  "องศ์",
  "องอาจ",
  "องุ่น",
  "อจลา",
  "อจินตา",
  "อจินไตย",
  "อจิระ",
  "อชะ",
  "อชันตา",
  "อชาตศัตรู",
  "อชิน",
  "อชินี",
  "อชิระ",
  "อฏวี",
  "อณิ",
  "อณู",
  "อด",
  "อดกลั้น",
  "อดทน",
  "อดสู",
  "อดอยาก",
  "อดออม",
  "อดิถี",
  "อดิศร",
  "อดิศวร",
  "อดิศัย",
  "อดิเทพ",
  "อดิเรก",
  "อดีต",
  "อดีตกาล",
  "อดีตชาติ",
  "อดีตภพ",
  "อดุล",
  "อดุลยเดช",
  "อดุลย์",
  "อติ",
  "อติชาต",
  "อติชาตบุตร",
  "อติมานะ",
  "อติราช",
  "อติสาร",
  "อติเรก",
  "อถรรพเวท",
  "อทระ",
  "อทินนาทาน",
  "อธรรม",
  "อธิ",
  "อธิกมาส",
  "อธิกรณ์",
  "อธิกวาร",
  "อธิกสุรทิน",
  "อธิการ",
  "อธิการบดี",
  "อธิคม",
  "อธิฏฐาน",
  "อธิบดี",
  "อธิบาย",
  "อธิป",
  "อธิปไตย",
  "อธิมาตร",
  "อธิมุตติ",
  "อธิราช",
  "อธิวาส",
  "อธิวาสนะ",
  "อธิศีล",
  "อธิษฐาน",
  "อธิโมกข์",
  "อธึก",
  "อนงคณะ",
  "อนงคเลขา",
  "อนงค์",
  "อนธการ",
  "อนนต์",
  "อนยะ",
  "อนรรฆ",
  "อนรรถ",
  "อนล",
  "อนวัช",
  "อนัญ",
  "อนัตตา",
  "อนันตริยกรรม",
  "อนันต์",
  "อนัม",
  "อนาคต",
  "อนาคตกาล",
  "อนาคามิผล",
  "อนาคามิมรรค",
  "อนาคามี",
  "อนาจาร",
  "อนาถ",
  "อนาถา",
  "อนาทร",
  "อนาธิปไตย",
  "อนามัย",
  "อนามิกา",
  "อนารยชน",
  "อนารยธรรม",
  "อนารยะ",
  "อนาลัย",
  "อนำ",
  "อนิจ",
  "อนิจกรรม",
  "อนิจจัง",
  "อนิจจา",
  "อนิฏฐารมณ์",
  "อนิยต",
  "อนิยม",
  "อนิล",
  "อนิวรรต",
  "อนิวรรตน์",
  "อนีกะ",
  "อนีจะ",
  "อนึก",
  "อนึ่ง",
  "อนุ",
  "อนุกร",
  "อนุกรม",
  "อนุกรรมการ",
  "อนุกระเบียด",
  "อนุกาชาด",
  "อนุการ",
  "อนุกูล",
  "อนุคามิก",
  "อนุจร",
  "อนุช",
  "อนุชน",
  "อนุชา",
  "อนุชาต",
  "อนุชาตบุตร",
  "อนุชิต",
  "อนุญาต",
  "อนุญาโตตุลาการ",
  "อนุตร",
  "อนุทิน",
  "อนุบท",
  "อนุบาล",
  "อนุประโยค",
  "อนุปริญญา",
  "อนุปสัมบัน",
  "อนุปัสนา",
  "อนุพงศ์",
  "อนุพัทธ์",
  "อนุพันธ์",
  "อนุภรรยา",
  "อนุภริยา",
  "อนุภาค",
  "อนุภาษ",
  "อนุมัติ",
  "อนุมาตรา",
  "อนุมาน",
  "อนุมูล",
  "อนุรักษนิยม",
  "อนุรักษ์",
  "อนุราช",
  "อนุราธ",
  "อนุราธะ",
  "อนุราธา",
  "อนุรูป",
  "อนุวงศ์",
  "อนุวรรตน์",
  "อนุวัต",
  "อนุวัตน์",
  "อนุวัตร",
  "อนุวัติ",
  "อนุวาต",
  "อนุศาสก",
  "อนุศาสนาจารย์",
  "อนุศาสน์",
  "อนุศิษฏ์",
  "อนุสติ",
  "อนุสนธิ",
  "อนุสร",
  "อนุสรณ์",
  "อนุสัญญา",
  "อนุสัย",
  "อนุสาวรีย์",
  "อนุสาสนี",
  "อนุเคราะห์",
  "อนุเถระ",
  "อนุโพธ",
  "อนุโมทนา",
  "อนุโยค",
  "อนุโลม",
  "อบ",
  "อบรม",
  "อบอวล",
  "อบอุ่น",
  "อบอ้าว",
  "อบาย",
  "อบายภูมิ",
  "อบายมุข",
  "อบเชย",
  "อปจายนธรรม",
  "อปจายนมัย",
  "อปมงคล",
  "อปยศ",
  "อประไมย",
  "อปรัณณชาติ",
  "อปรา",
  "อปราชัย",
  "อปราชิต",
  "อปริมาณ",
  "อปลักษณ์",
  "อปวาท",
  "อปโลกน์",
  "อพพะ",
  "อพยพ",
  "อพอลโล",
  "อพาร์ตเมนต์",
  "อพาร์ตเมนท์",
  "อพาร์ทเมนต์",
  "อพาร์ทเมนท์",
  "อภัพ",
  "อภัพบุคคล",
  "อภัย",
  "อภัยทาน",
  "อภัยโทษ",
  "อภิ",
  "อภิฆาต",
  "อภิชฌา",
  "อภิชน",
  "อภิชนาธิปไตย",
  "อภิชัย",
  "อภิชาต",
  "อภิชาตบุตร",
  "อภิชิต",
  "อภิญญา",
  "อภิญญาณ",
  "อภิธรรม",
  "อภิธาน",
  "อภินันทนาการ",
  "อภินันท์",
  "อภินัย",
  "อภินิหาร",
  "อภิบาล",
  "อภิปรัชญา",
  "อภิปราย",
  "อภิมหาอำนาจ",
  "อภิมานะ",
  "อภิมุข",
  "อภิรดี",
  "อภิรติ",
  "อภิรมย์",
  "อภิรักษ์",
  "อภิราม",
  "อภิรุต",
  "อภิรุม",
  "อภิรูป",
  "อภิลักขิต",
  "อภิลักขิตสมัย",
  "อภิวันท์",
  "อภิวาท",
  "อภิวาทน์",
  "อภิสมัย",
  "อภิสมาจาร",
  "อภิสมโพธิ",
  "อภิสัมโพธิ",
  "อภิสัมโพธิญาณ",
  "อภิสิต",
  "อภิสิทธิ์",
  "อภิเนษกรมณ์",
  "อภิเลปน์",
  "อภิเษก",
  "อภิไธย",
  "อภูตะ",
  "อม",
  "อมตธรรม",
  "อมตบท",
  "อมตะ",
  "อมนุษย์",
  "อมพะนำ",
  "อมยิ้ม",
  "อมร",
  "อมรรัตน์",
  "อมรา",
  "อมราวดี",
  "อมรินทร์",
  "อมฤต",
  "อมฤตบท",
  "อมฤตรส",
  "อมัตร",
  "อมาตยาธิปไตย",
  "อมาตย์",
  "อมาวสี",
  "อมาวสุ",
  "อมาวาสี",
  "อมิตร",
  "อมเรนทร์",
  "อมเรศ",
  "อยาก",
  "อยุติธรรม",
  "อยุทธ์",
  "อยุธยา",
  "อยู่",
  "อย่า",
  "อย่าง",
  "อย่างไร",
  "อร",
  "อรชร",
  "อรชุน",
  "อรดี",
  "อรติ",
  "อรทัย",
  "อรนุช",
  "อรพินท์",
  "อรพิม",
  "อรรค",
  "อรรฆ",
  "อรรฆย์",
  "อรรจน์",
  "อรรณพ",
  "อรรถ",
  "อรรถกถา",
  "อรรถกถาจารย์",
  "อรรถกร",
  "อรรถกวี",
  "อรรถคดี",
  "อรรถประโยชน์",
  "อรรถรส",
  "อรรถาธิบาย",
  "อรรธ",
  "อรรธนิศา",
  "อรรธภาค",
  "อรรธสระ",
  "อรสุม",
  "อรสุมพล",
  "อรหะ",
  "อรหัง",
  "อรหัต",
  "อรหัตผล",
  "อรหัตมรรค",
  "อรหัน",
  "อรหันตฆาต",
  "อรหันต์",
  "อรัญ",
  "อรัญญิก",
  "อรัญวาส",
  "อรัญวาสี",
  "อรัณย์",
  "อราดี",
  "อริ",
  "อริน",
  "อรินทร์",
  "อริยกะ",
  "อริยทรัพย์",
  "อริยบุคคล",
  "อริยผล",
  "อริยมรรค",
  "อริยสงฆ์",
  "อริยสัจ",
  "อริยะ",
  "อริสมันต์",
  "อริสโตเติล",
  "อรุณ",
  "อรุโณทัย",
  "อรุ่ม",
  "อรูป",
  "อรูปฌาน",
  "อรูปพรหม",
  "อรูปภพ",
  "อรูปภูมิ",
  "อรไท",
  "อร่อย",
  "อร่าม",
  "อลงกต",
  "อลงกรณ์",
  "อลงการ",
  "อลวน",
  "อลหม่าน",
  "อลักเอลื่อ",
  "อลังการ",
  "อลัชชี",
  "อลาสกา",
  "อลิงค์",
  "อลิซาเบธ",
  "อลึงค์",
  "อลึ่งฉึ่ง",
  "อลเวง",
  "อล่องฉ่อง",
  "อล่างฉ่าง",
  "อวกาศ",
  "อวจร",
  "อวชัย",
  "อวชาต",
  "อวชาตบุตร",
  "อวด",
  "อวดดี",
  "อวดอ้าง",
  "อวตาร",
  "อวน",
  "อวบ",
  "อวบอั๋น",
  "อวมงคล",
  "อวย",
  "อวยชัย",
  "อวยพร",
  "อวยวะ",
  "อวรรค",
  "อวรุทธก",
  "อวรุทธ์",
  "อวล",
  "อวสาน",
  "อวหาร",
  "อวอร์ด",
  "อวันตี",
  "อวัยวะ",
  "อวัสดา",
  "อวาจี",
  "อวิจี",
  "อวิชชา",
  "อวิญญาณกทรัพย์",
  "อวิญญู",
  "อวิรุทธ์",
  "อวิหิงสา",
  "อวิโรธนะ",
  "อวิโรธน์",
  "อวีจิ",
  "อส.รด.",
  "อสงไขย",
  "อสนี",
  "อสนีบาต",
  "อสภะ",
  "อสมการ",
  "อสมมาตร",
  "อสรพิษ",
  "อสังหาริมทรัพย์",
  "อสังหาริมะ",
  "อสัญกรรม",
  "อสัญญี",
  "อสัญแดหวา",
  "อสัตถพฤกษ์",
  "อสัตย์",
  "อสัมภิน",
  "อสัมภินพงศ์",
  "อสัมภินวงศ์",
  "อสิ",
  "อสิต",
  "อสิธารา",
  "อสิเลสะ",
  "อสีตยานุพยัญชนะ",
  "อสีติ",
  "อสุ",
  "อสุจิ",
  "อสุนีบาต",
  "อสุภ",
  "อสุภกรรมฐาน",
  "อสุภสัญญา",
  "อสุรกาย",
  "อสุรา",
  "อสุรี",
  "อสุเรศ",
  "อสูร",
  "อหังการ",
  "อหิ",
  "อหิงสา",
  "อหิวาตกโรค",
  "อหิวาต์",
  "อหึงสา",
  "ออ",
  "ออก",
  "ออกซิเจน",
  "ออกซิเดชัน",
  "ออกญา",
  "ออกตัว",
  "ออกฤทธิ์",
  "ออกลาย",
  "ออกหาก",
  "ออกเทน",
  "ออกแขก",
  "ออกแบบ",
  "ออกโรง",
  "ออกไซด์",
  "ออด",
  "ออดอ้อน",
  "ออดิชั่น",
  "ออดิทอเรียม",
  "ออดแอด",
  "ออตโตมัน",
  "ออทิสติก",
  "ออนซอน",
  "ออนซ์",
  "ออนไลน์",
  "ออปติก",
  "ออปติคอล",
  "ออปติคัล",
  "ออฟ",
  "ออฟฟิศ",
  "ออฟไลน์",
  "ออม",
  "ออมชอม",
  "ออมซอม",
  "ออยล์",
  "ออรัล",
  "ออราเคิล",
  "ออริกอน",
  "ออร์คิด",
  "ออร์เดอร์",
  "ออร์แกน",
  "ออร์แกนิก",
  "ออร์แกนิค",
  "ออสการ์",
  "ออสซี่",
  "ออสเตรีย",
  "ออสเตรเลีย",
  "ออสเมียม",
  "ออสโล",
  "ออเจ้า",
  "ออเซาะ",
  "ออเดอร์",
  "ออโต้",
  "ออโรรา",
  "ออโรร่า",
  "อะ",
  "อะคริลิก",
  "อะครีลิก",
  "อะคร้าว",
  "อะซีติก",
  "อะซีโตน",
  "อะดรีนาลิน",
  "อะดรีนาลีน",
  "อะดุง",
  "อะตอม",
  "อะพอลโล",
  "อะมิโน",
  "อะมีบา",
  "อะร้าอร่าม",
  "อะลุ่มอล่วย",
  "อะลุ้มอล่วย",
  "อะลูมินา",
  "อะลูมิเนียม",
  "อะหม",
  "อะหลั่ย",
  "อะเคื้อ",
  "อะเซทิลีน",
  "อะเมซอน",
  "อะเมริเซียม",
  "อะแจ",
  "อะแลสกา",
  "อะไร",
  "อะไหล่",
  "อัก",
  "อักกะ",
  "อักขรวิธี",
  "อักขรวิบัติ",
  "อักขรสมัย",
  "อักขระ",
  "อักขรานุกรม",
  "อักขะ",
  "อักษร",
  "อักษรศาสตร์",
  "อักษรสาส์น",
  "อักษะ",
  "อักอ่วน",
  "อักเษาหิณี",
  "อักเสบ",
  "อักโกธะ",
  "อักโข",
  "อักโขภิณี",
  "อักโขเภณี",
  "อัคคะ",
  "อัคคิ",
  "อัคคี",
  "อัคคีภัย",
  "อัคนิ",
  "อัคนี",
  "อัคร",
  "อัครชายา",
  "อัครมเหสี",
  "อัครราชทูต",
  "อัครสมณทูต",
  "อัง",
  "อังกนะ",
  "อังกฤษ",
  "อังกะลุง",
  "อังกา",
  "อังกาบ",
  "อังกุระ",
  "อังกุศ",
  "อังกูร",
  "อังก์",
  "อังคณะ",
  "อังคณา",
  "อังคาร",
  "อังคาส",
  "อังคีรส",
  "อังคุฐ",
  "อังคุตรนิกาย",
  "อังฆาต",
  "อังรีดูนังต์",
  "อังวะ",
  "อังศุ",
  "อังศุก",
  "อังศุธร",
  "อังศุมาลี",
  "อังสกุฏ",
  "อังสตรอม",
  "อังสนา",
  "อังสภาระ",
  "อังสะ",
  "อังสา",
  "อังแพลม",
  "อัจกลับ",
  "อัจจิ",
  "อัจจิมา",
  "อัจจุตะ",
  "อัจฉรา",
  "อัจฉริยบุคคล",
  "อัจฉริยภาพ",
  "อัจฉริยลักษณะ",
  "อัจฉริยลักษณ์",
  "อัจฉริยะ",
  "อัจนา",
  "อัชฌัตติก",
  "อัชฌา",
  "อัชฌาจาร",
  "อัชฌาศัย",
  "อัชฌาสัย",
  "อัญขยม",
  "อัญชนะ",
  "อัญชนะศักราช",
  "อัญชลี",
  "อัญชัน",
  "อัญชุลี",
  "อัญญะ",
  "อัญดิตถีย์",
  "อัญประกาศ",
  "อัญมณี",
  "อัญรูป",
  "อัญเชิญ",
  "อัญเดียรถีย์",
  "อัญเดียรถีย์",
  "อัฏ",
  "อัฏฐบาน",
  "อัฏฐะ",
  "อัฏฐังคิกมรรค",
  "อัฏนา",
  "อัฐ",
  "อัฐทิศ",
  "อัฐบริขาร",
  "อัฐบาน",
  "อัฐม",
  "อัฐมี",
  "อัฐศก",
  "อัฐฬส",
  "อัฐิ",
  "อัฐเคราะห์",
  "อัฒจันทร์",
  "อัฒภาค",
  "อัฒมาส",
  "อัฒรัตติ",
  "อัณฑชะ",
  "อัณฑะ",
  "อัณฑโกส",
  "อัณณพ",
  "อัด",
  "อัดฉีด",
  "อัดอั้น",
  "อัดแจ",
  "อัต",
  "อัตคัด",
  "อัตคัต",
  "อัตชีวประวัติ",
  "อัตตะปือ",
  "อัตตา",
  "อัตตาธิปไตย",
  "อัตตโนบท",
  "อัตถะ",
  "อัตถ์",
  "อัตนัย",
  "อัตภาพ",
  "อัตรชะ",
  "อัตรา",
  "อัตราส่วน",
  "อัตลักษณ์",
  "อัตลัด",
  "อัตวินิบาตกรรม",
  "อัตวิสัย",
  "อัตโนมัติ",
  "อัททา",
  "อัทธา",
  "อัทธาน",
  "อัทธายุ",
  "อัทธ์",
  "อัธยาตมวิทยา",
  "อัธยาย",
  "อัธยาศัย",
  "อัน",
  "อันดร",
  "อันดอร์รา",
  "อันดับ",
  "อันดามัน",
  "อันตกะ",
  "อันตกาล",
  "อันตคุณ",
  "อันตรกิริยา",
  "อันตรธาน",
  "อันตรภาค",
  "อันตรวาสก",
  "อันตรา",
  "อันตราย",
  "อันตรายิกธรรม",
  "อันตะ",
  "อันติกะ",
  "อันติมสัจ",
  "อันติมะ",
  "อันธการ",
  "อันธพาล",
  "อันธิกา",
  "อันเดอร์",
  "อันเต",
  "อันเตปุริก",
  "อันเตวาสิก",
  "อันเวส",
  "อันแถ้ง",
  "อันโดรเมดา",
  "อันโต",
  "อันโตชน",
  "อันโตนาที",
  "อันโทล",
  "อับ",
  "อับจน",
  "อับปาง",
  "อับราฮัม",
  "อับอาย",
  "อับเฉา",
  "อัปปะ",
  "อัปภาคย์",
  "อัปมงคล",
  "อัปยศ",
  "อัประมาณ",
  "อัประไมย",
  "อัปราชัย",
  "อัปรีย์",
  "อัปลักษณ์",
  "อัปสร",
  "อัปเกรด",
  "อัปเดต",
  "อัปเปหิ",
  "อัปโหลด",
  "อัพพุท",
  "อัพภันดร",
  "อัพภาน",
  "อัพภาส",
  "อัพภูตธรรม",
  "อัพยากฤต",
  "อัพเกรด",
  "อัพเดต",
  "อัพโพหาริก",
  "อัพโหลด",
  "อัฟกานิสถาน",
  "อัฟริกา",
  "อัมพร",
  "อัมพฤกษ์",
  "อัมพวัน",
  "อัมพวา",
  "อัมพา",
  "อัมพาต",
  "อัมพิละ",
  "อัมพุ",
  "อัมพุช",
  "อัมพุชินี",
  "อัมพุท",
  "อัมมาน",
  "อัมสเตอร์ดัม",
  "อัยกา",
  "อัยการ",
  "อัยกี",
  "อัยยะ",
  "อัยยิกา",
  "อัลกอริทึม",
  "อัลกออิดะห์",
  "อัลกุรอาน",
  "อัลคาเทล",
  "อัลคาไลน์",
  "อัลจาซีราห์",
  "อัลตรา",
  "อัลตราซาวด์",
  "อัลตราซาวนด์",
  "อัลตราไวโอเลต",
  "อัลบัม",
  "อัลบั้ม",
  "อัลมอนด์",
  "อัลลอยด์",
  "อัลเฟรด",
  "อัลเลาะห์",
  "อัลไซเมอร์",
  "อัลไต",
  "อัศจรรย์",
  "อัศว",
  "อัศวยุช",
  "อัศวานึก",
  "อัศวิน",
  "อัศวินี",
  "อัศวเมธ",
  "อัศเจรีย์",
  "อัษฎมงคล",
  "อัษฎางคิกมรรค",
  "อัษฎายุธ",
  "อัษฎาวุธ",
  "อัษฏมงคล",
  "อัสกัณ",
  "อัสซุส",
  "อัสดง",
  "อัสดงคต",
  "อัสดร",
  "อัสนี",
  "อัสมิมานะ",
  "อัสสชิ",
  "อัสสนี",
  "อัสสมี",
  "อัสสะ",
  "อัสสัตถพฤกษ์",
  "อัสสัม",
  "อัสสัมชัญ",
  "อัสสานึก",
  "อัสสาสะ",
  "อัสสุ",
  "อัสสุชล",
  "อัสสุธารา",
  "อั่ว",
  "อั้ก",
  "อั้งยี่",
  "อั้งโล่",
  "อั้น",
  "อั้ม",
  "อั๊ก",
  "อั๋น",
  "อา",
  "อากร",
  "อากังขา",
  "อากัป",
  "อากัปกิริยา",
  "อาการ",
  "อาการนาม",
  "อากาศ",
  "อากาศธาตุ",
  "อากาศยาน",
  "อากูล",
  "อาขยาต",
  "อาขยาน",
  "อาข่า",
  "อาคม",
  "อาครหายณี",
  "อาคันตุกภัต",
  "อาคันตุกวัตร",
  "อาคันตุกะ",
  "อาคาร",
  "อาคารชุด",
  "อาคเนย์",
  "อาฆาต",
  "อางขนาง",
  "อาจ",
  "อาจม",
  "อาจริยวัตร",
  "อาจริยวาท",
  "อาจหาญ",
  "อาจอง",
  "อาจาด",
  "อาจาร",
  "อาจารย์",
  "อาจารี",
  "อาจิณ",
  "อาชญา",
  "อาชญากร",
  "อาชญากรรม",
  "อาชญาบัตร",
  "อาชญาสิทธิ์",
  "อาชวะ",
  "อาชา",
  "อาชาไนย",
  "อาชีพ",
  "อาชีวก",
  "อาชีวศึกษา",
  "อาชีวะ",
  "อาญา",
  "อาญาสิทธิ์",
  "อาฏานา",
  "อาณัติ",
  "อาณา",
  "อาณาจักร",
  "อาณานิคม",
  "อาณาประโยชน์",
  "อาณาเขต",
  "อาด",
  "อาดุลย์",
  "อาดูร",
  "อาดูลย์",
  "อาตมภาพ",
  "อาตมัน",
  "อาตมา",
  "อาถรรพณ์",
  "อาถรรพเวท",
  "อาถรรพ์",
  "อาทร",
  "อาทิ",
  "อาทิจจวาร",
  "อาทิตยมณฑล",
  "อาทิตยวาร",
  "อาทิตย์",
  "อาทีนพ",
  "อาทีนวะ",
  "อาทึก",
  "อาธรรม",
  "อาธรรม์",
  "อาธาน",
  "อาธาร",
  "อาน",
  "อานน",
  "อานนท์",
  "อานันทนะ",
  "อานันท์",
  "อานัม",
  "อานาปานะ",
  "อานาปานัสสติ",
  "อานิสงค์",
  "อานิสงส์",
  "อานุภาพ",
  "อานุภาวะ",
  "อาบ",
  "อาบัติ",
  "อาบัน",
  "อาบูดาบี",
  "อาปณกะ",
  "อาปณะ",
  "อาปะ",
  "อาปานะ",
  "อาพัทธ์",
  "อาพันธนะ",
  "อาพันธ์",
  "อาพาธ",
  "อาฟต้า",
  "อาฟริกา",
  "อาฟเตอร์",
  "อาภรณ์",
  "อาภัพ",
  "อาภัสระ",
  "อาภา",
  "อาภากร",
  "อาภาส",
  "อามลกะ",
  "อามัย",
  "อามิษ",
  "อามิส",
  "อาย",
  "อายตนะ",
  "อายตะ",
  "อายน",
  "อายัด",
  "อายัต",
  "อายัน",
  "อายาจนะ",
  "อายานะ",
  "อายุ",
  "อายุขัย",
  "อายุตกะ",
  "อายุธ",
  "อายุรกรรม",
  "อายุรศาสตร์",
  "อายุรเวช",
  "อายุรเวท",
  "อายุรแพทย์",
  "อายุวัฒนะ",
  "อายุษ",
  "อารดี",
  "อารติ",
  "อารทรา",
  "อารบิก",
  "อารมณ์",
  "อารยชน",
  "อารยชาติ",
  "อารยธรรม",
  "อารยประเทศ",
  "อารยะ",
  "อารยัน",
  "อาระ",
  "อาระเบีย",
  "อารักขา",
  "อารักษ์",
  "อารัญ",
  "อารัญญิก",
  "อารัณยกะ",
  "อารัณย์",
  "อารัติ",
  "อารัมภกถา",
  "อารัมภบท",
  "อารัมภะ",
  "อารัมภ์",
  "อาราธนา",
  "อาราธน์",
  "อาราม",
  "อารามิก",
  "อารี",
  "อารุม",
  "อาร์",
  "อาร์กติก",
  "อาร์กอน",
  "อาร์กิวเมนต์",
  "อาร์คันซอ",
  "อาร์ต",
  "อาร์ติสต์",
  "อาร์พีจี",
  "อาร์ม",
  "อาร์เจนตินา",
  "อาร์เซนอล",
  "อาร์เมเนีย",
  "อาลปนะ",
  "อาละวาด",
  "อาลักษณ์",
  "อาลัย",
  "อาลัว",
  "อาลี",
  "อาว",
  "อาวรณ์",
  "อาวัชนาการ",
  "อาวัล",
  "อาวาส",
  "อาวาสิก",
  "อาวาหมงคล",
  "อาวาหะ",
  "อาวุต",
  "อาวุธ",
  "อาวุโส",
  "อาว์",
  "อาศรม",
  "อาศรมบท",
  "อาศัย",
  "อาศิรพจน์",
  "อาศิรพาท",
  "อาศิรวาท",
  "อาศุ",
  "อาศเลษา",
  "อาษาฒ",
  "อาสน",
  "อาสนศาลา",
  "อาสนะ",
  "อาสน์",
  "อาสวะ",
  "อาสัญ",
  "อาสัตย์",
  "อาสา",
  "อาสาฬหบูชา",
  "อาสาฬหะ",
  "อาสาฬห์",
  "อาสิญจ์",
  "อาสิน",
  "อาหม",
  "อาหรับ",
  "อาหรับ",
  "อาหาร",
  "อาหารว่าง",
  "อาฬหก",
  "อาเกียรณ์",
  "อาเจียน",
  "อาเซม",
  "อาเซอร์ไบจาน",
  "อาเซียน",
  "อาเซ็ม",
  "อาเด๊ะ",
  "อาเทศ",
  "อาเทสนา",
  "อาเพศ",
  "อาเวค",
  "อาเศียรพจน์",
  "อาเศียรพาท",
  "อาเศียรวาท",
  "อาโนเนะ",
  "อาโป",
  "อาโปกสิณ",
  "อาโปธาตุ",
  "อาโลก",
  "อาโลกกสิณ",
  "อำ",
  "อำนนต์",
  "อำนรรฆ",
  "อำนวย",
  "อำนาจ",
  "อำนาจเจริญ",
  "อำนาถ",
  "อำนิฐ",
  "อำนิษฐ์",
  "อำปลัง",
  "อำพน",
  "อำพราง",
  "อำพล",
  "อำพะนำ",
  "อำพัน",
  "อำมร",
  "อำมฤคโชค",
  "อำมฤต",
  "อำมหิต",
  "อำมาตยาธิปไตย",
  "อำมาตย์",
  "อำยวน",
  "อำรุง",
  "อำลา",
  "อำอวม",
  "อำเภอ",
  "อำแดง",
  "อำไพ",
  "อิก",
  "อิกัวนา",
  "อิง",
  "อิงค์",
  "อิงอร",
  "อิจฉา",
  "อิฉัน",
  "อิชยา",
  "อิฏฐารมณ์",
  "อิฐ",
  "อิฐผล",
  "อิด",
  "อิดออด",
  "อิดเอื้อน",
  "อิดโรย",
  "อิตถี",
  "อิตถีลิงค์",
  "อิตาลี",
  "อิตาเลียน",
  "อิติวุตตกะ",
  "อิตเทรียม",
  "อิตเทอร์เบียม",
  "อิทธิ",
  "อิทธิปาฏิหาริย์",
  "อิทธิพล",
  "อิทธิฤทธิ์",
  "อิน",
  "อินซูลิน",
  "อินดอร์",
  "อินดัสตรีส์",
  "อินทขีล",
  "อินทนนท์",
  "อินทนิล",
  "อินทปัตถ์",
  "อินทผลัม",
  "อินทรชิต",
  "อินทรธนู",
  "อินทรวงศ์",
  "อินทรวิเชียร",
  "อินทรศักดิ์",
  "อินทราณี",
  "อินทราทิตย์",
  "อินทราภิเษก",
  "อินทรายุธ",
  "อินทรี",
  "อินทรียสังวร",
  "อินทรีย์",
  "อินทร์",
  "อินทิกรัล",
  "อินทิเกรต",
  "อินทิเกรเตอร์",
  "อินทีวร",
  "อินทุ",
  "อินท์",
  "อินพุต",
  "อินพุท",
  "อินฟราเรด",
  "อินัง",
  "อินังขังขอบ",
  "อินเดีย",
  "อินเดีย",
  "อินเดียนา",
  "อินเดียนแดง",
  "อินเดียม",
  "อินเตอร์",
  "อินเตอร์เน็ต",
  "อินเทล",
  "อินเทอร์เน็ต",
  "อินเทอร์เฟซ",
  "อินโดจีน",
  "อินโดนีเซีย",
  "อิบารากิ",
  "อิมพีเรียล",
  "อิมัลชัน",
  "อิรวดี",
  "อิระวดี",
  "อิรัก",
  "อิริยา",
  "อิริยาบถ",
  "อิริยาบท",
  "อิริเดียม",
  "อิรุพเพท",
  "อิลลินอยส์",
  "อิศร",
  "อิศวร",
  "อิษฏี",
  "อิษฏ์",
  "อิส",
  "อิสตรี",
  "อิสตันบูล",
  "อิสรชน",
  "อิสรภาพ",
  "อิสระ",
  "อิสราเอล",
  "อิสริยยศ",
  "อิสริยะ",
  "อิสริยาภรณ์",
  "อิสลาม",
  "อิสลามาบัด",
  "อิสสา",
  "อิสัตรี",
  "อิสิ",
  "อิสี",
  "อิหม่าม",
  "อิหร่าน",
  "อิหลักอิเหลื่อ",
  "อิออน",
  "อิเควทอเรียล",
  "อิเลคโทรนิคส์",
  "อิเลียด",
  "อิเล็กตรอน",
  "อิเล็กทรอนิกส์",
  "อิเล็กทริก",
  "อิเล็กทริค",
  "อิเล็กโทน",
  "อิเหนา",
  "อิ่ม",
  "อิ่มตัว",
  "อิ่มหนำ",
  "อิ่มเอม",
  "อิ่มเอิบ",
  "อิ่มแปร้",
  "อี",
  "อี",
  "อีก",
  "อีก้อ",
  "อีก๋อย",
  "อีจู้",
  "อีฉัน",
  "อีซูซุ",
  "อีตัว",
  "อีทุบ",
  "อีนุงตุงนัง",
  "อีนูน",
  "อีมู",
  "อียิปต์",
  "อีรม",
  "อีรุงตุงนัง",
  "อีลุ่ยฉุยแฉก",
  "อีลุ้ม",
  "อีศ",
  "อีศวร",
  "อีส",
  "อีสต์",
  "อีสาน",
  "อีสุกอีใส",
  "อีสเตอร์",
  "อีหรอบ",
  "อีหลักอีเหลื่อ",
  "อีหลี",
  "อีหลุกขลุกขลัก",
  "อีหลุกขลุกขลุ่ย",
  "อีเจียน",
  "อีเต้อ",
  "อีเทอร์",
  "อีเพา",
  "อีเมล",
  "อีเมล์",
  "อีเลฟเวน",
  "อีเลฟเว่น",
  "อีเลิ้ง",
  "อีเวนท์",
  "อีเหนียว",
  "อีเหน็บ",
  "อีเหละเขละขละ",
  "อีเหละเขะขะ",
  "อีเห็น",
  "อีแต๋น",
  "อีแปะ",
  "อีแอ่น",
  "อีโก้ง",
  "อีโต้",
  "อีโน",
  "อีโบลา",
  "อีโบล่า",
  "อีโปง",
  "อีโรติก",
  "อีโหน่อีเหน่",
  "อีโหลกโขลกเขลก",
  "อี่",
  "อี้",
  "อี๊",
  "อี๊ด",
  "อี๋",
  "อี๋อ๋อ",
  "อึ",
  "อึก",
  "อึกทึก",
  "อึกอัก",
  "อึง",
  "อึงคะนึง",
  "อึงมี่",
  "อึงอล",
  "อึด",
  "อึดตะปือ",
  "อึดอัด",
  "อึดใจ",
  "อึน",
  "อึมครึม",
  "อึ่ง",
  "อึ่งยาง",
  "อึ่งอ่าง",
  "อึ้ก",
  "อึ้ง",
  "อึ้ดทึ่ด",
  "อึ้ม",
  "อึ๊ก",
  "อึ๊บ",
  "อึ๋ม",
  "อืด",
  "อืดอาด",
  "อือ",
  "อื่น",
  "อื้น",
  "อื้อ",
  "อื้อฉาว",
  "อื้อซ่า",
  "อื้ออึง",
  "อื้อฮือ",
  "อุ",
  "อุ.อา.ก.ส.",
  "อุก",
  "อุกกา",
  "อุกกาบาต",
  "อุกฉกรรจ์",
  "อุกฤษฏ์",
  "อุกลาบาต",
  "อุกอาจ",
  "อุค",
  "อุคระ",
  "อุคหนิมิต",
  "อุจ",
  "อุจจาระ",
  "อุจฉุ",
  "อุจาด",
  "อุจเฉท",
  "อุจเฉททิฐิ",
  "อุชุ",
  "อุชเชนี",
  "อุซเบกิสถาน",
  "อุฏฐาการ",
  "อุณรุท",
  "อุณหภูมิ",
  "อุณหะ",
  "อุณหาหาร",
  "อุณหิส",
  "อุณา",
  "อุณาโลม",
  "อุด",
  "อุดม",
  "อุดมการณ์",
  "อุดมคติ",
  "อุดมศึกษา",
  "อุดร",
  "อุดรธานี",
  "อุดหนุน",
  "อุดอู้",
  "อุดเตา",
  "อุด้ง",
  "อุตดม",
  "อุตดร",
  "อุตตมะ",
  "อุตตรผลคุนี",
  "อุตตรภัททะ",
  "อุตตรภัทรบท",
  "อุตตรายัน",
  "อุตตราสาฬหะ",
  "อุตตานภาพ",
  "อุตพิด",
  "อุตมภาพ",
  "อุตมางค์",
  "อุตรกุรุทวีป",
  "อุตรดิตถ์",
  "อุตรนิกาย",
  "อุตรผลคุนี",
  "อุตรภัทรบท",
  "อุตรา",
  "อุตราภิมุข",
  "อุตรายัน",
  "อุตราวรรต",
  "อุตราวัฏ",
  "อุตราษาฒ",
  "อุตราสงค์",
  "อุตริ",
  "อุตริมนุสธรรม",
  "อุตลุด",
  "อุตสาหกรรม",
  "อุตสาหการ",
  "อุตสาหะ",
  "อุตส่าห์",
  "อุตุ",
  "อุตุนิยม",
  "อุตุนิยมวิทยา",
  "อุทก",
  "อุทกธาร",
  "อุทกธารา",
  "อุทกภัย",
  "อุทกวิทยา",
  "อุทกศาสตร์",
  "อุทธรณ์",
  "อุทธัจ",
  "อุทยาน",
  "อุทร",
  "อุทริยะ",
  "อุทลุม",
  "อุทัช",
  "อุทัย",
  "อุทัยธานี",
  "อุทาน",
  "อุทาร",
  "อุทาหรณ์",
  "อุทิศ",
  "อุทุมพร",
  "อุธัจ",
  "อุบ",
  "อุบล",
  "อุบลรัตน์",
  "อุบลราชธานี",
  "อุบอิบ",
  "อุบะ",
  "อุบัติ",
  "อุบัติภัย",
  "อุบัติเหตุ",
  "อุบาท",
  "อุบาทว์",
  "อุบาย",
  "อุบาลี",
  "อุบาสก",
  "อุบาสิกา",
  "อุบ๊ะ",
  "อุปกรณ์",
  "อุปกรม",
  "อุปการ",
  "อุปการคุณ",
  "อุปการะ",
  "อุปการี",
  "อุปกิเลส",
  "อุปจาร",
  "อุปถัมภก",
  "อุปถัมภ์",
  "อุปทม",
  "อุปทาน",
  "อุปทูต",
  "อุปธิ",
  "อุปนัย",
  "อุปนายก",
  "อุปนายิกา",
  "อุปนิกขิต",
  "อุปนิษัท",
  "อุปนิสัย",
  "อุปบัติ",
  "อุปปาติกะ",
  "อุปพัทธ์",
  "อุปพันธ์",
  "อุปมา",
  "อุปมาน",
  "อุปมาโวหาร",
  "อุปยุวราช",
  "อุปรากร",
  "อุปราคา",
  "อุปราช",
  "อุปริ",
  "อุปริมปริยาย",
  "อุปสงค์",
  "อุปสมบท",
  "อุปสมบัน",
  "อุปสรรค",
  "อุปสัมบัน",
  "อุปสัมปทา",
  "อุปฮาด",
  "อุปัชฌายวัตร",
  "อุปัชฌายะ",
  "อุปัชฌาย์",
  "อุปัฏฐาก",
  "อุปัฏฐานะ",
  "อุปัทวะ",
  "อุปัทวันตราย",
  "อุปัทวเหตุ",
  "อุปาทาน",
  "อุปาหนา",
  "อุปเทศ",
  "อุปเท่ห์",
  "อุปเวท",
  "อุปโภค",
  "อุปโลกน์",
  "อุปไมย",
  "อุภัย",
  "อุมงค์",
  "อุย",
  "อุยกูร์",
  "อุยยาน",
  "อุยยาม",
  "อุรณะ",
  "อุรพี",
  "อุระ",
  "อุรังคธาตุ",
  "อุรังอุตัง",
  "อุรัจฉทะ",
  "อุรัจฉัท",
  "อุรา",
  "อุรุ",
  "อุรุกวัย",
  "อุรุมชี",
  "อุลกมณี",
  "อุลตรา",
  "อุลตร้า",
  "อุลามก",
  "อุลิด",
  "อุลโลจ",
  "อุษณกร",
  "อุษณกาล",
  "อุษณรัศมี",
  "อุษณรุจี",
  "อุษณาการ",
  "อุษณีษ์",
  "อุษมะ",
  "อุษมัน",
  "อุษา",
  "อุษาคเณย์",
  "อุษาโยค",
  "อุสภ",
  "อุสสาหะ",
  "อุสส่าห์",
  "อุสา",
  "อุสุ",
  "อุสุภ",
  "อุสุภราช",
  "อุสุม",
  "อุหรับ",
  "อุหลบ",
  "อุฬาร",
  "อุเทน",
  "อุเทศ",
  "อุเทสิกเจดีย์",
  "อุเบกขา",
  "อุเหม่",
  "อุแว้",
  "อุโฆษ",
  "อุโบสถ",
  "อุโบสถกรรม",
  "อุโบสถหัตถี",
  "อุโมงค์",
  "อุโลก",
  "อุไร",
  "อุ่น",
  "อุ่นเครื่อง",
  "อุ่นใจ",
  "อุ่ย",
  "อุ้ง",
  "อุ้ม",
  "อุ้มชู",
  "อุ้มสม",
  "อุ้ย",
  "อุ้ยอ้าย",
  "อุ๊ย",
  "อุ๋ย",
  "อู",
  "อูคูเลเล่",
  "อูฐ",
  "อูด",
  "อูบันตู",
  "อูบุนตู",
  "อูม",
  "อูย",
  "อูรดู",
  "อูราล",
  "อูรุ",
  "อูลานบาตอร์",
  "อู่",
  "อู้",
  "อู้ฟู่",
  "อู้อี้",
  "อู๋อี๋",
  "อเจลก",
  "อเจละ",
  "อเนก",
  "อเนกคุณ",
  "อเนกประสงค์",
  "อเนกรรถประโยค",
  "อเนจอนาถ",
  "อเปหิ",
  "อเมริกัน",
  "อเมริกา",
  "อเวจี",
  "อเสกขบุคคล",
  "อเสกขะ",
  "อเสขบุคคล",
  "อเสขะ",
  "อเหตุกทิฐิ",
  "อโฆษะ",
  "อโณทัย",
  "อโนชา",
  "อโนดาต",
  "อโยธยา",
  "อโลหะ",
  "อโศก",
  "อโหสิ",
  "อโหสิกรรม",
  "อ่วม",
  "อ่อง",
  "อ่อน",
  "อ่อนข้อ",
  "อ่อนช้อย",
  "อ่อนด้อย",
  "อ่อนน้อม",
  "อ่อนหวาน",
  "อ่อนหัด",
  "อ่อนเปลี้ย",
  "อ่อนเพลีย",
  "อ่อนแอ",
  "อ่อนโยน",
  "อ่อนใจ",
  "อ่อนไหว",
  "อ่อม",
  "อ่อย",
  "อ่า",
  "อ่าง",
  "อ่างขาง",
  "อ่างทอง",
  "อ่างเก็บน้ำ",
  "อ่าน",
  "อ่าว",
  "อ่ำ",
  "อ้น",
  "อ้ม",
  "อ้วก",
  "อ้วน",
  "อ้วนท้วน",
  "อ้วนพี",
  "อ้อ",
  "อ้องแอ้ง",
  "อ้อน",
  "อ้อนวอน",
  "อ้อนออด",
  "อ้อนแอ้น",
  "อ้อม",
  "อ้อมค้อม",
  "อ้อมแอ้ม",
  "อ้อย",
  "อ้อยส้อย",
  "อ้อยอิ่ง",
  "อ้อแอ้",
  "อ้า",
  "อ้าง",
  "อ้างว้าง",
  "อ้างอิง",
  "อ้าย",
  "อ้าว",
  "อ้ำ",
  "อ้ำอึ้ง",
  "อ๊อด",
  "อ๊อดแอ๊ด",
  "อ๊ะ",
  "อ๋อ",
  "อ๋อง",
  "อ๋อม",
  "อ๋อย",
  "อ๋า",
  "ฮ.จ.",
  "ฮ.ท.",
  "ฮ.ฝ.",
  "ฮ.ล.",
  "ฮ.ศ.",
  "ฮกเกี้ยน",
  "ฮด",
  "ฮวงจุ้ย",
  "ฮวงซุ้ย",
  "ฮวงโห",
  "ฮวน",
  "ฮวบ",
  "ฮวบฮาบ",
  "ฮอกกี้",
  "ฮอกไกโด",
  "ฮองเฮา",
  "ฮอด",
  "ฮอต",
  "ฮอตดอก",
  "ฮอนชู",
  "ฮอนดูรัส",
  "ฮอนด้า",
  "ฮอนโนลูลู",
  "ฮอปกินส์",
  "ฮอร์โมน",
  "ฮอลลีวูด",
  "ฮอลลีวู้ด",
  "ฮอลันดา",
  "ฮอลแลนด์",
  "ฮะ",
  "ฮะเบส",
  "ฮะเรีย",
  "ฮัก",
  "ฮังการี",
  "ฮังเล",
  "ฮัจญะฮ์",
  "ฮัจญี",
  "ฮัจญ์",
  "ฮันกึล",
  "ฮันนีมูน",
  "ฮับ",
  "ฮับเบิล",
  "ฮัม",
  "ฮัลโลวีน",
  "ฮัลโหล",
  "ฮั่น",
  "ฮั้ว",
  "ฮา",
  "ฮากกา",
  "ฮาจญ์",
  "ฮานอย",
  "ฮานามิ",
  "ฮาป่า",
  "ฮาม",
  "ฮามาส",
  "ฮาราคีรี",
  "ฮาราเร",
  "ฮาร์ด",
  "ฮาร์ดดิสก์",
  "ฮาร์ดแวร์",
  "ฮาลาล",
  "ฮาวาย",
  "ฮาห์เนียม",
  "ฮาเนดะ",
  "ฮาเร็ม",
  "ฮาโลวีน",
  "ฮิ",
  "ฮิจเราะห์",
  "ฮิต",
  "ฮิตาชิ",
  "ฮิตเลอร์",
  "ฮินดี",
  "ฮินดู",
  "ฮิบรู",
  "ฮิปฮอป",
  "ฮิปโป",
  "ฮิปโปโปเตมัส",
  "ฮิรางานะ",
  "ฮิวมัส",
  "ฮิสทีเรีย",
  "ฮิโรชิมา",
  "ฮีบรู",
  "ฮีเลียม",
  "ฮีโร่",
  "ฮึ",
  "ฮึก",
  "ฮึกหาญ",
  "ฮึกห้าว",
  "ฮึกฮัก",
  "ฮึกเหิม",
  "ฮึกโหม",
  "ฮึด",
  "ฮึดฮัด",
  "ฮึย",
  "ฮึ่ม",
  "ฮึ่ย",
  "ฮืดฮาด",
  "ฮือ",
  "ฮื่อ",
  "ฮื้อ",
  "ฮื้อฉี่",
  "ฮุด",
  "ฮุนเซน",
  "ฮุนเซ็น",
  "ฮุนได",
  "ฮุบ",
  "ฮุยเลฮุย",
  "ฮุสตัน",
  "ฮุสเซ็น",
  "ฮูก",
  "ฮูม",
  "ฮูลาฮูป",
  "ฮูล่าฮูป",
  "ฮู้",
  "ฮ็อต",
  "ฮ็อตด็อก",
  "ฮ่อ",
  "ฮ่องกง",
  "ฮ่องเต้",
  "ฮ่อม",
  "ฮ่อยจ๊อ",
  "ฮ่างหลวง",
  "ฮ้วนหมู",
  "ฮ้อ",
  "ฮ้า",
  "เก",
  "เกก",
  "เกกมะเหรก",
  "เกงกอย",
  "เกงเขง",
  "เกจิ",
  "เกจิอาจารย์",
  "เกชา",
  "เกณฑ์",
  "เกด",
  "เกตส์",
  "เกตุ",
  "เกตเวย์",
  "เกน",
  "เกม",
  "เกมส์",
  "เกย",
  "เกยูร",
  "เกย์",
  "เกรง",
  "เกรงกลัว",
  "เกรงขาม",
  "เกรงใจ",
  "เกรด",
  "เกรน",
  "เกรนาดีนส์",
  "เกรย์",
  "เกรอะ",
  "เกราะ",
  "เกริก",
  "เกริน",
  "เกริ่น",
  "เกรียก",
  "เกรียง",
  "เกรียงไกร",
  "เกรียด",
  "เกรียน",
  "เกรียบ",
  "เกรียม",
  "เกรียมกรม",
  "เกรียว",
  "เกรี้ยว",
  "เกรี้ยวกราด",
  "เกรเนดา",
  "เกร็ง",
  "เกร็ด",
  "เกร่อ",
  "เกลศ",
  "เกลอ",
  "เกลา",
  "เกลาะ",
  "เกลียง",
  "เกลียด",
  "เกลียว",
  "เกลี่ย",
  "เกลี้ย",
  "เกลี้ยกล่อม",
  "เกลี้ยง",
  "เกลี้ยงเกลา",
  "เกลือ",
  "เกลือก",
  "เกลือกกลั้ว",
  "เกลือกกลิ้ง",
  "เกลื่อน",
  "เกลื่อนกลาด",
  "เกลื่อนกล่น",
  "เกลื้อ",
  "เกลื้อน",
  "เกล็ด",
  "เกล็ดเลือด",
  "เกล้า",
  "เกวัฏ",
  "เกวียน",
  "เกศ",
  "เกศธาตุ",
  "เกศว",
  "เกศวะ",
  "เกศา",
  "เกศินี",
  "เกศี",
  "เกษตร",
  "เกษตรกร",
  "เกษตรกรรม",
  "เกษตรศาสตร์",
  "เกษม",
  "เกษมณี",
  "เกษมสันต์",
  "เกษียณ",
  "เกษียน",
  "เกษียร",
  "เกษียรสมุทร",
  "เกส",
  "เกสต์เฮาส์",
  "เกสร",
  "เกสรี",
  "เกสา",
  "เกสี",
  "เกอิชา",
  "เกะ",
  "เกะกะ",
  "เกา",
  "เกาต์",
  "เกาทัณฑ์",
  "เกาบิล",
  "เกาลัด",
  "เกาลิน",
  "เกาหลี",
  "เกาหลี",
  "เกาะ",
  "เกาะแกะ",
  "เกาเหลา",
  "เกาเหลียง",
  "เกาไศย",
  "เกิด",
  "เกิน",
  "เกิบ",
  "เกิร์ล",
  "เกิ้ง",
  "เกีย",
  "เกียกกาย",
  "เกียง",
  "เกียจ",
  "เกียจคร้าน",
  "เกียด",
  "เกียน",
  "เกียรติ",
  "เกียรติคุณ",
  "เกียรตินิยม",
  "เกียรติประวัติ",
  "เกียรติภูมิ",
  "เกียรติมุข",
  "เกียรติยศ",
  "เกียรติศักดิ์",
  "เกียรติ์",
  "เกียร์",
  "เกี่ยง",
  "เกี่ยงงอน",
  "เกี่ยว",
  "เกี่ยวข้อง",
  "เกี่ยวดอง",
  "เกี่ยวพัน",
  "เกี่ยวโยง",
  "เกี้ยมอี๋",
  "เกี้ยมไฉ่",
  "เกี้ยว",
  "เกี้ยวพาน",
  "เกี้ยวพาราสี",
  "เกี๊ยว",
  "เกี๊ยะ",
  "เกี๋ยง",
  "เกือก",
  "เกือบ",
  "เกื้อ",
  "เกื้อกูล",
  "เกเร",
  "เก็ง",
  "เก็จ",
  "เก็ด",
  "เก็บ",
  "เก็บกวาด",
  "เก็บงำ",
  "เก็บตก",
  "เก็บเกี่ยว",
  "เก่ง",
  "เก่งกาจ",
  "เก่า",
  "เก้",
  "เก้กัง",
  "เก้ง",
  "เก้งก้าง",
  "เก้อ",
  "เก้อเขิน",
  "เก้า",
  "เก้าอี้",
  "เก๊",
  "เก๊ก",
  "เก๊กฮวย",
  "เก๊ะ",
  "เก๋",
  "เก๋ง",
  "เก๋งเคง",
  "เก๋า",
  "เก๋ากี้",
  "เข",
  "เขก",
  "เขจร",
  "เขดา",
  "เขต",
  "เขน",
  "เขนง",
  "เขนย",
  "เขน็ด",
  "เขบ็จขบวน",
  "เขบ็ต",
  "เขม",
  "เขมร",
  "เขมา",
  "เขมือบ",
  "เขม็ง",
  "เขม็ดแขม่",
  "เขม่น",
  "เขม่า",
  "เขม้น",
  "เขม้นขะมัก",
  "เขย",
  "เขยก",
  "เขยิน",
  "เขยิบ",
  "เขยื้อน",
  "เขย่ง",
  "เขย่า",
  "เขย้อแขย่ง",
  "เขรอะ",
  "เขลง",
  "เขลอะ",
  "เขละ",
  "เขลา",
  "เขลาะ",
  "เขว",
  "เขวี้ยง",
  "เขษม",
  "เขฬะ",
  "เขะขะ",
  "เขา",
  "เขิง",
  "เขิน",
  "เขิบ",
  "เขียง",
  "เขียด",
  "เขียดตะปาด",
  "เขียน",
  "เขียม",
  "เขียว",
  "เขียวหวาน",
  "เขียวเสวย",
  "เขียะ",
  "เขี่ย",
  "เขี่ยน",
  "เขี้ยว",
  "เขือ",
  "เขือง",
  "เขือม",
  "เขื่อง",
  "เขื่อน",
  "เข็ง",
  "เข็ญ",
  "เข็ด",
  "เข็น",
  "เข็ม",
  "เข็มกลัด",
  "เข็มขัด",
  "เข็มทิศ",
  "เข็มหมุด",
  "เข่ง",
  "เข่น",
  "เข่า",
  "เข้",
  "เข้ม",
  "เข้มขาบ",
  "เข้มข้น",
  "เข้มงวด",
  "เข้มแข็ง",
  "เข้า",
  "เข้าขา",
  "เข้าชื่อ",
  "เข้าตัว",
  "เข้าถึง",
  "เข้าทรง",
  "เข้าที",
  "เข้าท่า",
  "เข้าเค้า",
  "เข้าเนื้อ",
  "เข้าเล่ม",
  "เข้าใจ",
  "เค",
  "เคณฑะ",
  "เคด",
  "เคน",
  "เคนตักกี",
  "เคนทักกี",
  "เคนยา",
  "เคบับ",
  "เคปเวิร์ด",
  "เคมบริดจ์",
  "เคมี",
  "เคมีภัณฑ์",
  "เคย",
  "เคยตัว",
  "เคย์แมน",
  "เครง",
  "เครงครา",
  "เครงครำ",
  "เครดิต",
  "เครดิตฟองซิเอร์",
  "เครน",
  "เครป",
  "เครมลิน",
  "เครา",
  "เคราหณี",
  "เคราะห์",
  "เครียด",
  "เครียว",
  "เครือ",
  "เครือข่าย",
  "เครือรัฐ",
  "เครื่อง",
  "เครื่องกล",
  "เครื่องกัณฑ์",
  "เครื่องครัว",
  "เครื่องจักร",
  "เครื่องดนตรี",
  "เครื่องต้น",
  "เครื่องทุ่นแรง",
  "เครื่องบิน",
  "เครื่องบูชา",
  "เครื่องประดับ",
  "เครื่องปรุง",
  "เครื่องปรุงรส",
  "เครื่องมือ",
  "เครื่องยนต์",
  "เครื่องราง",
  "เครื่องร่อน",
  "เครื่องล่าง",
  "เครื่องสาย",
  "เครื่องสำอาง",
  "เครื่องสุกำศพ",
  "เครื่องหมาย",
  "เครื่องเขิน",
  "เครื่องเคียง",
  "เครื่องเงิน",
  "เครื่องเซ่น",
  "เครื่องเทศ",
  "เครื่องเรือน",
  "เครื่องเล่น",
  "เครื่องแกง",
  "เครื่องแบบ",
  "เครื่องใน",
  "เคร่ง",
  "เคร่งขรึม",
  "เคร่งครัด",
  "เคร่งเครียด",
  "เคร่า",
  "เคลม",
  "เคลิบเคลิ้ม",
  "เคลิ้ม",
  "เคลีย",
  "เคลียคลอ",
  "เคลียร์",
  "เคลือบ",
  "เคลือบฟัน",
  "เคลือบแคลง",
  "เคลือบแฝง",
  "เคลื่อน",
  "เคลื่อนที่",
  "เคลื่อนย้าย",
  "เคลื่อนไหว",
  "เคล็ด",
  "เคล่าคล่อง",
  "เคล้ง",
  "เคล้น",
  "เคล้า",
  "เคว้ง",
  "เคว้งคว้าง",
  "เคส",
  "เคหสถาน",
  "เคหะ",
  "เคหา",
  "เคอราติน",
  "เคอร์ฟิว",
  "เคอร์เซอร์",
  "เคอร์เนล",
  "เคอะ",
  "เคาน์เตอร์",
  "เคารพ",
  "เคาะ",
  "เคาะแคะ",
  "เคียง",
  "เคียด",
  "เคียดแค้น",
  "เคียน",
  "เคียม",
  "เคียร",
  "เคียว",
  "เคี่ยม",
  "เคี่ยว",
  "เคี่ยวเข็ญ",
  "เคี้ยว",
  "เคี้ยวเอื้อง",
  "เคือง",
  "เคืองขุ่น",
  "เคเบิล",
  "เค็ด",
  "เค็ม",
  "เค้ก",
  "เค้ง",
  "เค้น",
  "เค้า",
  "เค้าโครง",
  "เค้เก้",
  "เฆี่ยน",
  "เงก",
  "เงย",
  "เงอะ",
  "เงอะงะ",
  "เงา",
  "เงาะ",
  "เงิน",
  "เงินตรา",
  "เงินยวง",
  "เงินเดือน",
  "เงียบ",
  "เงียบกริบ",
  "เงียบเชียบ",
  "เงียบเหงา",
  "เงี่ยง",
  "เงี่ยน",
  "เงี่ยหู",
  "เงี้ยว",
  "เงือก",
  "เงือด",
  "เงือบ",
  "เงื่อง",
  "เงื่องหงอย",
  "เงื่อน",
  "เงื่อนงำ",
  "เงื่อนเวลา",
  "เงื่อนไข",
  "เงื้อ",
  "เงื้อม",
  "เงื้อมมือ",
  "เง็ง",
  "เง่า",
  "เง้า",
  "เจ",
  "เจ",
  "เจดียสถาน",
  "เจดีย์",
  "เจต",
  "เจตคติ",
  "เจตจำนง",
  "เจตนา",
  "เจตนารมณ์",
  "เจตพังคี",
  "เจตภูต",
  "เจตมูลเพลิง",
  "เจตสิก",
  "เจน",
  "เจนีวา",
  "เจนไน",
  "เจมส์",
  "เจรจา",
  "เจริญ",
  "เจริญพร",
  "เจริด",
  "เจรียง",
  "เจล",
  "เจลียง",
  "เจว็ด",
  "เจษฎา",
  "เจอ",
  "เจอร์เมเนียม",
  "เจอะ",
  "เจา",
  "เจาะ",
  "เจาะจง",
  "เจิด",
  "เจิม",
  "เจิ่ง",
  "เจิ่น",
  "เจีย",
  "เจียง",
  "เจียด",
  "เจียน",
  "เจียม",
  "เจียร",
  "เจียระบาด",
  "เจียระไน",
  "เจียว",
  "เจี๊ยบ",
  "เจี๊ยว",
  "เจี๊ยวจ๊าว",
  "เจี๋ยน",
  "เจี๋ยมเจี้ยม",
  "เจือ",
  "เจือจาง",
  "เจือจาน",
  "เจือปน",
  "เจือสม",
  "เจื่อน",
  "เจื้อย",
  "เจื้อยแจ้ว",
  "เจโตวิมุติ",
  "เจได",
  "เจ็ด",
  "เจ็ดตะคลี",
  "เจ็ต",
  "เจ็บ",
  "เจ่ง",
  "เจ่อ",
  "เจ่า",
  "เจ้ง",
  "เจ้อเจียง",
  "เจ้า",
  "เจ้ากรม",
  "เจ้ากรรม",
  "เจ้าของ",
  "เจ้าขา",
  "เจ้าข้า",
  "เจ้าคณะ",
  "เจ้าค่ะ",
  "เจ้าจอม",
  "เจ้าชู้",
  "เจ้าตัว",
  "เจ้าถิ่น",
  "เจ้าที่",
  "เจ้าทุกข์",
  "เจ้าท่า",
  "เจ้านาย",
  "เจ้าบ่าว",
  "เจ้าบ้าน",
  "เจ้าประคุณ",
  "เจ้าประคู้น",
  "เจ้าพนักงาน",
  "เจ้าพระคุณ",
  "เจ้าพระยา",
  "เจ้าพายุ",
  "เจ้าพ่อ",
  "เจ้าฟ้า",
  "เจ้าภาพ",
  "เจ้ามือ",
  "เจ้าสังกัด",
  "เจ้าสัว",
  "เจ้าสาว",
  "เจ้าหนี้",
  "เจ้าหน้าที่",
  "เจ้าอาวาส",
  "เจ้าเนื้อ",
  "เจ้าเรือน",
  "เจ้าแม่",
  "เจ๊",
  "เจ๊ก",
  "เจ๊ง",
  "เจ๊สัว",
  "เจ๊า",
  "เจ๊าะแจ๊ะ",
  "เจ๋ง",
  "เจ๋อ",
  "เจ๋อเจ๊อะ",
  "เฉ",
  "เฉก",
  "เฉด",
  "เฉท",
  "เฉนียน",
  "เฉพาะ",
  "เฉย",
  "เฉยเมย",
  "เฉลย",
  "เฉลว",
  "เฉลา",
  "เฉลิม",
  "เฉลียง",
  "เฉลียบ",
  "เฉลียว",
  "เฉลี่ย",
  "เฉลี่ยง",
  "เฉวียง",
  "เฉวียน",
  "เฉอะแฉะ",
  "เฉา",
  "เฉาก๊วย",
  "เฉาฮื้อ",
  "เฉาะ",
  "เฉาโฉด",
  "เฉิงตู",
  "เฉิด",
  "เฉิดฉัน",
  "เฉิดฉาย",
  "เฉิดฉิน",
  "เฉิบ",
  "เฉิ่ม",
  "เฉียง",
  "เฉียงพร้านางแอ",
  "เฉียด",
  "เฉียบ",
  "เฉียบขาด",
  "เฉียบพลัน",
  "เฉียบแหลม",
  "เฉียว",
  "เฉี่ยว",
  "เฉือน",
  "เฉื่อย",
  "เฉื่อยชา",
  "เฉโก",
  "เฉไฉ",
  "เฉ่ง",
  "เชงเลง",
  "เชตวัน",
  "เชน",
  "เชนไน",
  "เชฟ",
  "เชฟรอน",
  "เชฟโรเลต",
  "เชย",
  "เชยชม",
  "เชลง",
  "เชลซี",
  "เชลย",
  "เชลยศักดิ์",
  "เชลยศึก",
  "เชลล์",
  "เชลียง",
  "เชลียร์",
  "เชลแล็ก",
  "เชวง",
  "เชษฐะ",
  "เชษฐา",
  "เชอ",
  "เชอร์รี่",
  "เชอร์โนบิล",
  "เชาวน์",
  "เชาว์",
  "เชิง",
  "เชิงกราน",
  "เชิงกล",
  "เชิงชั้น",
  "เชิงชาย",
  "เชิงซ้อน",
  "เชิงตะกอน",
  "เชิงมุม",
  "เชิงอรรถ",
  "เชิงเดียว",
  "เชิงเดี่ยว",
  "เชิงเทิน",
  "เชิญ",
  "เชิด",
  "เชิดชู",
  "เชิ้ต",
  "เชียง",
  "เชียงราย",
  "เชียงใหม่",
  "เชียบ",
  "เชียร",
  "เชียร์",
  "เชียว",
  "เชี่ยน",
  "เชี่ยนหมาก",
  "เชี่ยม",
  "เชี่ยว",
  "เชี่ยวชาญ",
  "เชือก",
  "เชือด",
  "เชือน",
  "เชือนแช",
  "เชื่อ",
  "เชื่อง",
  "เชื่องช้า",
  "เชื่องช้า",
  "เชื่อถือ",
  "เชื่อฟัง",
  "เชื่อม",
  "เชื่อมือ",
  "เชื่อวัน",
  "เชื่อใจ",
  "เชื้อ",
  "เชื้อชาติ",
  "เชื้อสาย",
  "เชื้อเชิญ",
  "เชื้อเพลิง",
  "เชื้อโรค",
  "เชื้อไฟ",
  "เช็ก",
  "เช็ก",
  "เช็ค",
  "เช็ง",
  "เช็งเม้ง",
  "เช็ด",
  "เช่น",
  "เช่า",
  "เช่าซื้อ",
  "เช้ง",
  "เช้งวับ",
  "เช้า",
  "เช้าตรู่",
  "เช้ามืด",
  "เซ",
  "เซซัง",
  "เซต",
  "เซน",
  "เซนติกรัม",
  "เซนติลิตร",
  "เซนติเกรด",
  "เซนติเมตร",
  "เซนต์",
  "เซนส์",
  "เซนเซอร์",
  "เซนเตอร์",
  "เซนได",
  "เซปักตะกร้อ",
  "เซฟตี้",
  "เซมิ",
  "เซรามิก",
  "เซราะ",
  "เซรุ่ม",
  "เซลติก",
  "เซลลูลอยด์",
  "เซลลูโลส",
  "เซลล์",
  "เซลส์",
  "เซลส์แมน",
  "เซลเซียส",
  "เซอร์",
  "เซอร์วิส",
  "เซอร์เบีย",
  "เซอร์โคเนียม",
  "เซอร์ไพรส์",
  "เซอะ",
  "เซา",
  "เซาตูเม",
  "เซาท์",
  "เซาะ",
  "เซิง",
  "เซิร์บ",
  "เซิร์ฟเวอร์",
  "เซิ้ง",
  "เซียน",
  "เซียบ",
  "เซียมซี",
  "เซียร์รา",
  "เซียว",
  "เซี่ยงไฮ้",
  "เซี่ยว",
  "เซี่ยวกาง",
  "เซี้ยว",
  "เซื่อง",
  "เซเชลส์",
  "เซเนกัล",
  "เซเวน",
  "เซเว่น",
  "เซแคนต์",
  "เซ็ก",
  "เซ็กซี่",
  "เซ็กซ์",
  "เซ็กส์",
  "เซ็กเตอร์",
  "เซ็กเมนต์",
  "เซ็ง",
  "เซ็งแซ่",
  "เซ็น",
  "เซ็นทรัล",
  "เซ็นทารา",
  "เซ็นเซอร์",
  "เซ็นเตอร์",
  "เซ่ง",
  "เซ่น",
  "เซ่นไหว้",
  "เซ่อ",
  "เซ่อซ่า",
  "เซ้ง",
  "เซ้าซี้",
  "เฌอ",
  "เฌอเอม",
  "เญยธรรม",
  "เฒ่า",
  "เณร",
  "เด",
  "เดก",
  "เดกซ์โทรส",
  "เดคากรัม",
  "เดคาลิตร",
  "เดคาเมตร",
  "เดช",
  "เดชนะ",
  "เดชน์",
  "เดชะ",
  "เดชานุภาพ",
  "เดซิกรัม",
  "เดซิลิตร",
  "เดซิเมตร",
  "เดน",
  "เดนตาย",
  "เดนมาร์ก",
  "เดนมาร์ก",
  "เดนเวอร์",
  "เดบิต",
  "เดย์",
  "เดรัจฉาน",
  "เดลตา",
  "เดลต้า",
  "เดลล์",
  "เดลาแวร์",
  "เดลินิวส์",
  "เดลไฟ",
  "เดวิด",
  "เดสก์ท็อป",
  "เดอะ",
  "เดา",
  "เดาสวด",
  "เดาสุ่ม",
  "เดาะ",
  "เดิน",
  "เดินทาง",
  "เดินสะพัด",
  "เดินสาย",
  "เดินเหิน",
  "เดิม",
  "เดิมพัน",
  "เดิ่น",
  "เดียง",
  "เดียงสา",
  "เดียด",
  "เดียดฉันท์",
  "เดียนเบียนฟู",
  "เดียรดาษ",
  "เดียรถีย์",
  "เดียรัจฉาน",
  "เดียว",
  "เดียวกัน",
  "เดียวดาย",
  "เดียะ",
  "เดี่ยว",
  "เดี้ยง",
  "เดี๊ยะ",
  "เดี๋ยว",
  "เดี๋ยวนี้",
  "เดี๋ยวเดียว",
  "เดือก",
  "เดือด",
  "เดือดดาล",
  "เดือดร้อน",
  "เดือน",
  "เดือนมืด",
  "เดือนหงาย",
  "เดือย",
  "เดื่อ",
  "เดื่อง",
  "เดเบียน",
  "เดโช",
  "เดโม",
  "เดโมแครต",
  "เด็ก",
  "เด็จ",
  "เด็ด",
  "เด็ดขาด",
  "เด็ดดวง",
  "เด็ดเดี่ยว",
  "เด่",
  "เด่น",
  "เด้ง",
  "เด้อ",
  "เด้า",
  "เด๊ะ",
  "เด๋อ",
  "เด๋อด๋า",
  "เตช",
  "เตมีย์",
  "เตย",
  "เตรตา",
  "เตรียม",
  "เตรียมตรม",
  "เตร็ด",
  "เตร็ดเตร่",
  "เตร่",
  "เตละ",
  "เตลิด",
  "เตลุคู",
  "เตว็ด",
  "เตหะราน",
  "เตอะ",
  "เตะ",
  "เตา",
  "เตาผิง",
  "เตาฟู่",
  "เตารีด",
  "เตาสูบ",
  "เตาะแตะ",
  "เตาแก๊ส",
  "เตาไฟ",
  "เติบ",
  "เติม",
  "เติร์ก",
  "เติร์กเมนิสถาน",
  "เติ่ง",
  "เตียง",
  "เตียน",
  "เตียบ",
  "เตียรถ์",
  "เตียว",
  "เตี่ยว",
  "เตี้ย",
  "เตี๊ยม",
  "เตี๋ยม",
  "เตือน",
  "เตโช",
  "เตโชธาตุ",
  "เต็ง",
  "เต็นท์",
  "เต็ม",
  "เต่ง",
  "เต่อ",
  "เต่า",
  "เต่าทอง",
  "เต่าเกียด",
  "เต้น",
  "เต้นรำ",
  "เต้า",
  "เต้าทึง",
  "เต้ารับ",
  "เต้าส่วน",
  "เต้าหู้",
  "เต้าหู้ยี้",
  "เต้าฮวย",
  "เต้าเจี้ยว",
  "เต้าเสียบ",
  "เต๊ก",
  "เต๊ะ",
  "เต๊าะ",
  "เต๋า",
  "เถกิง",
  "เถน",
  "เถร",
  "เถรวาท",
  "เถระ",
  "เถรานุเถระ",
  "เถรี",
  "เถลิง",
  "เถลือกถลน",
  "เถลไถล",
  "เถอะ",
  "เถา",
  "เถาวัลย์",
  "เถาะ",
  "เถิก",
  "เถิง",
  "เถิด",
  "เถิดเทิง",
  "เถิน",
  "เถียง",
  "เถียร",
  "เถือ",
  "เถือก",
  "เถื่อน",
  "เถ่อ",
  "เถ้า",
  "เถ้าแก่",
  "เถ้าแก่เนี้ย",
  "เท",
  "เทกซัส",
  "เทกระจาด",
  "เทค",
  "เทคนิค",
  "เทคนีเชียม",
  "เทครัว",
  "เทควันโด",
  "เทคโน",
  "เทคโนแครต",
  "เทคโนโลยี",
  "เทนนิส",
  "เทนเนสซี",
  "เทป",
  "เทปโก",
  "เทพ",
  "เทพดา",
  "เทพธิดา",
  "เทพนม",
  "เทพนิยม",
  "เทพนิยาย",
  "เทพบุตร",
  "เทพยดา",
  "เทพยุดา",
  "เทพยเจ้า",
  "เทพรัตน",
  "เทพสังหรณ์",
  "เทพา",
  "เทพารักษ์",
  "เทพิน",
  "เทพินทร์",
  "เทพี",
  "เทพเจ้า",
  "เทฟลอน",
  "เทมเพลต",
  "เทรด",
  "เทรนด์",
  "เทรนเนอร์",
  "เทรลเลอร์",
  "เทรลเล่อร์",
  "เทริด",
  "เทลลูเรียม",
  "เทลอาวีฟ",
  "เทวดา",
  "เทวทหะ",
  "เทวทัณฑ์",
  "เทวทัต",
  "เทวทูต",
  "เทวธรรม",
  "เทวนาครี",
  "เทวนิยม",
  "เทวบัญชา",
  "เทวบุตร",
  "เทวรูป",
  "เทววิทยา",
  "เทวศ",
  "เทวษ",
  "เทวสถาน",
  "เทวัญ",
  "เทวัน",
  "เทวา",
  "เทวาธิราช",
  "เทวาลัย",
  "เทวินทร์",
  "เทวี",
  "เทวโลก",
  "เทศ",
  "เทศกาล",
  "เทศนา",
  "เทศนาโวหาร",
  "เทศน์",
  "เทศบัญญัติ",
  "เทศบาล",
  "เทศมนตรี",
  "เทศะ",
  "เทศาภิบาล",
  "เทสโก้",
  "เทสโทสเตอโรน",
  "เทหวัตถุ",
  "เทห์",
  "เทห์ฟากฟ้า",
  "เทห์ฟากฟ้า",
  "เทอญ",
  "เทอม",
  "เทอร์มินัล",
  "เทอร์เบียม",
  "เทอร์โบ",
  "เทอร์โม",
  "เทอร์โมมิเตอร์",
  "เทอะทะ",
  "เทา",
  "เทิด",
  "เทิดทูน",
  "เทิน",
  "เทิบ",
  "เทิบทาบ",
  "เทิ่ง",
  "เทิ้ม",
  "เทียด",
  "เทียน",
  "เทียนชนวน",
  "เทียนพรรษา",
  "เทียบ",
  "เทียบเคียง",
  "เทียบเท่า",
  "เทียม",
  "เทียมทาน",
  "เทียร",
  "เทียว",
  "เที่ยง",
  "เที่ยงคืน",
  "เที่ยงตรง",
  "เที่ยงธรรม",
  "เที่ยงวัน",
  "เที่ยงแท้",
  "เที่ยว",
  "เที้ยน",
  "เที้ยร",
  "เทือ",
  "เทือก",
  "เทือกเขา",
  "เทือกเถา",
  "เทื่อ",
  "เทื้อ",
  "เทเพนทร์",
  "เทเลกราฟ",
  "เทเลคอม",
  "เทเวศ",
  "เทเวศร์",
  "เทเวศวร์",
  "เทโพ",
  "เทโวโรหนะ",
  "เท็กซัส",
  "เท็กซ์",
  "เท็จ",
  "เท่",
  "เท่ห์",
  "เท่อ",
  "เท่า",
  "เท่ากับ",
  "เท่าตัว",
  "เท่าทัน",
  "เท่าทุน",
  "เท่านั้น",
  "เท่ารึง",
  "เท่าเทียม",
  "เท่าใด",
  "เท่าไร",
  "เท้ง",
  "เท้งเต้ง",
  "เท้อ",
  "เท้า",
  "เท้าช้าง",
  "เท้ายายม่อม",
  "เธค",
  "เธนุ",
  "เธอ",
  "เธียร",
  "เนกขัม",
  "เนกาตีฟ",
  "เนคเทค",
  "เนชันแนล",
  "เนชั่น",
  "เนชั่นแนล",
  "เนตบอล",
  "เนตร",
  "เนตรนารี",
  "เนติ",
  "เนติบัณฑิต",
  "เนบราสกา",
  "เนบิวลา",
  "เนปจูน",
  "เนปทูเนียม",
  "เนปาล",
  "เนมิ",
  "เนย",
  "เนยเทียม",
  "เนยใส",
  "เนรกัณฐี",
  "เนรคุณ",
  "เนรนาด",
  "เนรมิต",
  "เนระพูสี",
  "เนรัญชรา",
  "เนรเทศ",
  "เนวาดา",
  "เนวิน",
  "เนวิส",
  "เนสาด",
  "เนสเล่",
  "เนอ",
  "เนอะ",
  "เนา",
  "เนาวนิต",
  "เนาวรัตน์",
  "เนิน",
  "เนิบ",
  "เนิบนาบ",
  "เนิร์สเซอรี",
  "เนิร์สเซอรี่",
  "เนิ่น",
  "เนิ่นนาน",
  "เนียง",
  "เนียน",
  "เนียม",
  "เนียร",
  "เนียรทุกข์",
  "เนียรนาท",
  "เนียรเทศ",
  "เนี้ยบ",
  "เนือง",
  "เนืองนอง",
  "เนืองนิตย์",
  "เนืองแน่น",
  "เนือย",
  "เนื่อง",
  "เนื้อ",
  "เนื้อความ",
  "เนื้อคู่",
  "เนื้องอก",
  "เนื้อตัว",
  "เนื้อตาย",
  "เนื้อที่",
  "เนื้อผ้า",
  "เนื้อร้อง",
  "เนื้อร้าย",
  "เนื้อหา",
  "เนื้อเค็ม",
  "เนื้อเปื่อย",
  "เนื้อเพลง",
  "เนื้อเยื่อ",
  "เนื้อเรื่อง",
  "เนื้อแท้",
  "เนื้อไม้",
  "เนเธอร์แลนด์",
  "เนแบรสกา",
  "เน็ต",
  "เน็ตบุค",
  "เน็ตบุ๊ก",
  "เน็ตบุ๊ค",
  "เน็ตเวิร์ก",
  "เน็ตเวิร์ค",
  "เน่า",
  "เน้น",
  "เน้อ",
  "เบงกอล",
  "เบงกาซี",
  "เบงกาลี",
  "เบญกานี",
  "เบญกาย",
  "เบญจกัลยาณี",
  "เบญจกามคุณ",
  "เบญจขันธ์",
  "เบญจดุริยางค์",
  "เบญจธรรม",
  "เบญจบรรพต",
  "เบญจพรรณ",
  "เบญจมบพิตร",
  "เบญจมาศ",
  "เบญจรงค์",
  "เบญจศก",
  "เบญจศีล",
  "เบญจะ",
  "เบญจา",
  "เบญจางคประดิษฐ์",
  "เบญจางค์",
  "เบญจเพส",
  "เบญญา",
  "เบญพาด",
  "เบตา",
  "เบตาดีน",
  "เบต้า",
  "เบน",
  "เบนกาซี",
  "เบนจามิน",
  "เบนซิน",
  "เบนซ์",
  "เบนิน",
  "เบนโตะ",
  "เบนโล",
  "เบบี้",
  "เบรก",
  "เบรค",
  "เบราว์เซอร์",
  "เบริลเลียม",
  "เบรุต",
  "เบลอ",
  "เบลารุส",
  "เบลีซ",
  "เบลเกรด",
  "เบลเยียม",
  "เบส",
  "เบอร์",
  "เบอร์คีเลียม",
  "เบอร์มิวดา",
  "เบอร์รี",
  "เบอร์ลิน",
  "เบอร์เกอร์",
  "เบอะ",
  "เบอะบะ",
  "เบะ",
  "เบา",
  "เบาความ",
  "เบาบาง",
  "เบาปัญญา",
  "เบามือ",
  "เบาราณ",
  "เบาสมอง",
  "เบาหวาน",
  "เบาะ",
  "เบาะแส",
  "เบาแรง",
  "เบาโหวง",
  "เบาใจ",
  "เบิก",
  "เบิกความ",
  "เบิกบาน",
  "เบิร์ด",
  "เบิร์น",
  "เบิ่ง",
  "เบียด",
  "เบียดบัง",
  "เบียดเบียน",
  "เบียดเสียด",
  "เบียน",
  "เบียร์",
  "เบี่ยง",
  "เบี่ยงบ่าย",
  "เบี้ย",
  "เบี้ยล่าง",
  "เบี้ยว",
  "เบี้ยหวัด",
  "เบี้ยเลี้ยง",
  "เบือ",
  "เบือน",
  "เบื่อ",
  "เบื้อ",
  "เบื้อง",
  "เบื้องต้น",
  "เบื้องบน",
  "เบื้องหน้า",
  "เบื้องหลัง",
  "เบเกอรี่",
  "เบ็ด",
  "เบ็ดเตล็ด",
  "เบ็ดเสร็จ",
  "เบ็นซ์",
  "เบ่ง",
  "เบ้",
  "เบ้อ",
  "เบ้อเริ่ม",
  "เบ้อเริ่มเทิ่ม",
  "เบ้อเร่อ",
  "เบ้อเร่อเท่อ",
  "เบ้า",
  "เบ้าตา",
  "เบ๊จี๋",
  "เปก",
  "เปตพลี",
  "เปตอง",
  "เปปเปอร์มินต์",
  "เปยยาล",
  "เปรต",
  "เปรม",
  "เปรมปรีดิ์",
  "เปรย",
  "เปรอ",
  "เปรอะ",
  "เปรอะเปื้อน",
  "เประ",
  "เปราะ",
  "เปราะบาง",
  "เปรียง",
  "เปรียญ",
  "เปรียบ",
  "เปรียบเทียบ",
  "เปรียบเปรย",
  "เปรียว",
  "เปรียะ",
  "เปรี่ยม",
  "เปรี้ยง",
  "เปรี้ยงปร้าง",
  "เปรี้ยว",
  "เปรี้ยวปาก",
  "เปรี้ยวหวาน",
  "เปรี๊ยะ",
  "เปรื่อง",
  "เปรื่องปราด",
  "เปรื้อย",
  "เปรู",
  "เปร็ง",
  "เปล",
  "เปลญวน",
  "เปลว",
  "เปลา",
  "เปลาะ",
  "เปลี่ยน",
  "เปลี่ยนตัว",
  "เปลี่ยนมือ",
  "เปลี่ยนหน้า",
  "เปลี่ยนแปลง",
  "เปลี่ยนใจ",
  "เปลี่ยม",
  "เปลี่ยว",
  "เปลี้ย",
  "เปลือก",
  "เปลือง",
  "เปลือย",
  "เปลื้อง",
  "เปล่ง",
  "เปล่งปลั่ง",
  "เปล่า",
  "เปล่าดาย",
  "เปล่าเปลี่ยว",
  "เปล้า",
  "เปศะ",
  "เปศัส",
  "เปสการ",
  "เปสละ",
  "เปสุญวาท",
  "เปอร์",
  "เปอร์เซีย",
  "เปอร์เซ็นต์",
  "เปอร์โตริโก",
  "เปอร์โยต์",
  "เปะ",
  "เปะปะ",
  "เปา",
  "เปาบุ้นจิ้น",
  "เปาะ",
  "เปาะเปี๊ยะ",
  "เปาะแปะ",
  "เปิก",
  "เปิง",
  "เปิงมาง",
  "เปิด",
  "เปิดฉาก",
  "เปิดผนึก",
  "เปิดเปิง",
  "เปิดเผย",
  "เปิดโปง",
  "เปิบ",
  "เปิ่น",
  "เปิ๊บ",
  "เปีย",
  "เปียก",
  "เปียกปูน",
  "เปียงยาง",
  "เปียะ",
  "เปียโน",
  "เปี่ยม",
  "เปี้ยว",
  "เปี๊ยก",
  "เปี๊ยบ",
  "เปี๊ยว",
  "เปี๊ยะ",
  "เปือก",
  "เปื่อย",
  "เปื้อน",
  "เปเปอร์",
  "เปโซ",
  "เป็ด",
  "เป็ดน้ำ",
  "เป็ดเทศ",
  "เป็น",
  "เป็นกลาง",
  "เป็นต่อ",
  "เป็นต้น",
  "เป็นรอง",
  "เป็นลม",
  "เป็นห่วง",
  "เป็นอยู่",
  "เป็นใจ",
  "เป็นไง",
  "เป็นไร",
  "เป่ง",
  "เป่า",
  "เป่ากบ",
  "เป่ายิงฉุบ",
  "เป่ายิ้งฉุบ",
  "เป้",
  "เป้ง",
  "เป้ย",
  "เป้อเย้อ",
  "เป้า",
  "เป้านิ่ง",
  "เป้าหมาย",
  "เป๊ก",
  "เป๊ปซี่",
  "เป๊ะ",
  "เป๊า",
  "เป๊าะ",
  "เป๋",
  "เป๋ง",
  "เป๋อ",
  "เป๋อเหลอ",
  "เป๋า",
  "เป๋าฮื้อ",
  "เผ",
  "เผง",
  "เผชิญ",
  "เผชิญหน้า",
  "เผดิม",
  "เผดียง",
  "เผด็จ",
  "เผด็จการ",
  "เผด็จศึก",
  "เผนิก",
  "เผย",
  "เผยอ",
  "เผยิบ",
  "เผยิบผยาบ",
  "เผยแผ่",
  "เผยแพร่",
  "เผล",
  "เผลอ",
  "เผลอไผล",
  "เผละ",
  "เผละผละ",
  "เผลาะ",
  "เผลาะแผละ",
  "เผลียง",
  "เผล็ด",
  "เผล่",
  "เผอิญ",
  "เผอิล",
  "เผอเรอ",
  "เผะ",
  "เผา",
  "เผาะ",
  "เผิน",
  "เผิ้ง",
  "เผียน",
  "เผือ",
  "เผือก",
  "เผือด",
  "เผือน",
  "เผื่อ",
  "เผื่อน",
  "เผื่อแผ่",
  "เผ็ด",
  "เผ่น",
  "เผ่า",
  "เผ่าพันธุ์",
  "เผ้า",
  "เฝอ",
  "เฝือ",
  "เฝือก",
  "เฝือง",
  "เฝื่อน",
  "เฝ้า",
  "เพ",
  "เพกา",
  "เพคะ",
  "เพจ",
  "เพจเจอร์",
  "เพชฉลูกรรม",
  "เพชฌฆาต",
  "เพชร",
  "เพชรกลับ",
  "เพชรดา",
  "เพชรบุรี",
  "เพชรบูรณ์",
  "เพชรปาณี",
  "เพชรฤกษ์",
  "เพชรสังฆาต",
  "เพชรหลีก",
  "เพชรหึง",
  "เพชรายุธ",
  "เพฑูริย์",
  "เพณี",
  "เพดาน",
  "เพดานบิน",
  "เพดานปาก",
  "เพท",
  "เพทนา",
  "เพทนาการ",
  "เพทาย",
  "เพทุบาย",
  "เพนกวิน",
  "เพนซิลวาเนีย",
  "เพนซิลเวเนีย",
  "เพนตากอน",
  "เพนียด",
  "เพนแทกซ์",
  "เพรง",
  "เพรซีโอดิเมียม",
  "เพรส",
  "เพรา",
  "เพราะ",
  "เพริด",
  "เพริศ",
  "เพริศพราย",
  "เพริศพริ้ง",
  "เพริศแพร้ว",
  "เพรียก",
  "เพรียกพร้อง",
  "เพรียง",
  "เพรียบ",
  "เพรียว",
  "เพรียวบาง",
  "เพรียวลม",
  "เพรี้ยม",
  "เพรือง",
  "เพรื่อ",
  "เพล",
  "เพลง",
  "เพลงยาว",
  "เพลงเชิด",
  "เพลซ",
  "เพลท",
  "เพลย์บอย",
  "เพลา",
  "เพลาะ",
  "เพลิง",
  "เพลิงกัลป์",
  "เพลิดเพลิน",
  "เพลิน",
  "เพลินตา",
  "เพลินใจ",
  "เพลีย",
  "เพลี่ยง",
  "เพลี่ยงพล้ำ",
  "เพลี้ย",
  "เพลโต",
  "เพล็ด",
  "เพล้โพล้",
  "เพศ",
  "เพส",
  "เพสลาด",
  "เพอร์ออกไซด์",
  "เพอิญ",
  "เพะ",
  "เพา",
  "เพาะ",
  "เพาะกาย",
  "เพาะชำ",
  "เพาะปลูก",
  "เพาเวอร์",
  "เพิก",
  "เพิกถอน",
  "เพิกเฉย",
  "เพิง",
  "เพิดเพ้ย",
  "เพิ่ง",
  "เพิ่ม",
  "เพิ่มพูน",
  "เพิ่มเติม",
  "เพียง",
  "เพียงตา",
  "เพียงพอ",
  "เพียงออ",
  "เพียบ",
  "เพียบพร้อม",
  "เพียบแประ",
  "เพียบแปร้",
  "เพียร",
  "เพียว",
  "เพี้ย",
  "เพี้ยง",
  "เพี้ยน",
  "เพื่อ",
  "เพื่อน",
  "เพื่อนตาย",
  "เพื่อนบ้าน",
  "เพื่อนฝูง",
  "เพื่อนยาก",
  "เพื่อนเกลอ",
  "เพโทบาย",
  "เพไนย",
  "เพ็ก",
  "เพ็ง",
  "เพ็จ",
  "เพ็ญ",
  "เพ็ดทูล",
  "เพ่ง",
  "เพ่งเล็ง",
  "เพ่นพ่าน",
  "เพ่อ",
  "เพ้ย",
  "เพ้อ",
  "เพ้อฝัน",
  "เพ้อเจ้อ",
  "เฟซบุ๊ก",
  "เฟมินิสต์",
  "เฟรช",
  "เฟรชชี่",
  "เฟรนช์",
  "เฟรม",
  "เฟลด์สปาร์",
  "เฟส",
  "เฟอร์นิเจอร์",
  "เฟอร์รี่",
  "เฟอร์เมียม",
  "เฟอะ",
  "เฟอะฟะ",
  "เฟะ",
  "เฟะฟะ",
  "เฟะฟะ",
  "เฟิน",
  "เฟิร์น",
  "เฟิร์ม",
  "เฟิร์มแวร์",
  "เฟี้ยม",
  "เฟี้ยว",
  "เฟี้ยวฟ้าว",
  "เฟือ",
  "เฟือง",
  "เฟือน",
  "เฟือย",
  "เฟื่อง",
  "เฟื่องฟุ้ง",
  "เฟื่องฟู",
  "เฟื่องฟ้า",
  "เฟื้อ",
  "เฟื้อง",
  "เฟื้อย",
  "เฟ็ด",
  "เฟ้น",
  "เฟ้อ",
  "เภกะ",
  "เภตรา",
  "เภท",
  "เภทภัย",
  "เภทุบาย",
  "เภรี",
  "เภสัช",
  "เภสัชกร",
  "เภสัชกรรม",
  "เภสัชวิทยา",
  "เภสัชศาสตร์",
  "เม",
  "เม.ย.",
  "เมกะ",
  "เมกะเฮิรตซ์",
  "เมขลา",
  "เมคอัพ",
  "เมฆ",
  "เมฆา",
  "เมฆินทร์",
  "เมฆี",
  "เมจิ",
  "เมจิก",
  "เมจิค",
  "เมดิเตอร์เรเนียน",
  "เมตตา",
  "เมตร",
  "เมตริก",
  "เมตริกซ์",
  "เมตริกตัน",
  "เมตไตรย",
  "เมถุน",
  "เมท",
  "เมทนี",
  "เมทนีดล",
  "เมทริกซ์",
  "เมทัล",
  "เมทานอล",
  "เมทินี",
  "เมทิลแอลกอฮอล์",
  "เมธ",
  "เมธา",
  "เมธาวี",
  "เมธี",
  "เมน",
  "เมน",
  "เมนทอล",
  "เมนู",
  "เมนเดลีเวียม",
  "เมมโมรี",
  "เมรัย",
  "เมริเดียน",
  "เมรุ",
  "เมรุมาศ",
  "เมรุราช",
  "เมล",
  "เมลานิน",
  "เมลามีน",
  "เมลือง",
  "เมลเบิร์น",
  "เมล็ด",
  "เมล์",
  "เมล์อากาศ",
  "เมษ",
  "เมษา",
  "เมษายน",
  "เมหนะ",
  "เมหะ",
  "เมห์",
  "เมอร์ซีเดส",
  "เมอร์เซเดส",
  "เมะ",
  "เมา",
  "เมาดิบ",
  "เมาท์",
  "เมามัน",
  "เมามัว",
  "เมามาย",
  "เมารี",
  "เมาลี",
  "เมาส์",
  "เมาฬี",
  "เมาะ",
  "เมาะตะมะ",
  "เมาะลำเลิง",
  "เมิง",
  "เมิน",
  "เมินเฉย",
  "เมิล",
  "เมีย",
  "เมียง",
  "เมียงมอง",
  "เมียนมาร์",
  "เมียน้อย",
  "เมียหลวง",
  "เมี่ยง",
  "เมี่ยงคำ",
  "เมี่ยงลาว",
  "เมี่ยงส้ม",
  "เมี้ยน",
  "เมือ",
  "เมือก",
  "เมือง",
  "เมืองขึ้น",
  "เมืองท่า",
  "เมืองนอก",
  "เมืองหลวง",
  "เมือบ",
  "เมื่อ",
  "เมื่อกี้",
  "เมื่อตะกี้",
  "เมื่อนั้น",
  "เมื่อย",
  "เมื่อยขบ",
  "เมื่อยล้า",
  "เมื่อใด",
  "เมื่อไร",
  "เมื่อไหร่",
  "เมื้อ",
  "เมเปิล",
  "เมโท",
  "เมโสโปเตเมีย",
  "เม็ก",
  "เม็กซิกัน",
  "เม็กซิโก",
  "เม็ง",
  "เม็ด",
  "เม็ดเงิน",
  "เม็ดเลือด",
  "เม็ดโลหิต",
  "เม่น",
  "เม่นทะเล",
  "เม่า",
  "เม้ง",
  "เม้น",
  "เม้ม",
  "เม้า",
  "เย",
  "เยง",
  "เยซู",
  "เยน",
  "เยภุยยสิกา",
  "เยรูซาเล็ม",
  "เยลลี่",
  "เยอ",
  "เยอบีรา",
  "เยอบีร่า",
  "เยอรมนี",
  "เยอรมัน",
  "เยอรมัน",
  "เยอร์บีรา",
  "เยอร์บีร่า",
  "เยอว",
  "เยอะ",
  "เยอะแยะ",
  "เยา",
  "เยาวชน",
  "เยาวพา",
  "เยาวพาณี",
  "เยาวพาน",
  "เยาวมาลย์",
  "เยาวยอด",
  "เยาวราช",
  "เยาวลักษณ์",
  "เยาวเรศ",
  "เยาว์",
  "เยาะ",
  "เยาะเย้ย",
  "เยิง",
  "เยิน",
  "เยินยอ",
  "เยิบ",
  "เยิบยาบ",
  "เยิ่น",
  "เยิ่นเย้อ",
  "เยิ้ม",
  "เยีย",
  "เยียงผา",
  "เยียดยัด",
  "เยียน",
  "เยียบ",
  "เยียรบับ",
  "เยียรยง",
  "เยียว",
  "เยียวยา",
  "เยี่ยง",
  "เยี่ยงอย่าง",
  "เยี่ยม",
  "เยี่ยมกราย",
  "เยี่ยมยอด",
  "เยี่ยมเยียน",
  "เยี่ยมเยือน",
  "เยี่ยว",
  "เยือ",
  "เยือก",
  "เยือกเย็น",
  "เยือง",
  "เยือน",
  "เยื่อ",
  "เยื่อง",
  "เยื่อเคย",
  "เยื่อใย",
  "เยื้อ",
  "เยื้อง",
  "เยื้องกราย",
  "เยื้องยัก",
  "เยื้องย่าง",
  "เยื้อน",
  "เยเมน",
  "เยโฮวาห์",
  "เย็ด",
  "เย็น",
  "เย็นฉ่ำ",
  "เย็นชา",
  "เย็นชืด",
  "เย็นตา",
  "เย็นตาโฟ",
  "เย็นวาบ",
  "เย็นวูบ",
  "เย็นเจี๊ยบ",
  "เย็นเฉียบ",
  "เย็นเตาโฟ",
  "เย็นเยียบ",
  "เย็นเยือก",
  "เย็นใจ",
  "เย็บ",
  "เย็บกี่",
  "เย็บจักร",
  "เย็บด้าย",
  "เย่อ",
  "เย่อหยิ่ง",
  "เย้",
  "เย้ย",
  "เย้ยหยัน",
  "เย้ว",
  "เย้า",
  "เย้าหยอก",
  "เรข",
  "เรขา",
  "เรขาคณิต",
  "เรซิน",
  "เรซิ่น",
  "เรณุ",
  "เรณู",
  "เรดอน",
  "เรดาร์",
  "เรดิโอ",
  "เรต",
  "เรตติ้ง",
  "เรนโบว์",
  "เรรวน",
  "เรวดี",
  "เรอ",
  "เรา",
  "เราะ",
  "เราะราย",
  "เราะร้าย",
  "เราเตอร์",
  "เริง",
  "เริงรมย์",
  "เริงใจ",
  "เริด",
  "เริดร้าง",
  "เริม",
  "เริศร้าง",
  "เริ่ม",
  "เริ่มต้น",
  "เริ่มแรก",
  "เริ้ม",
  "เรียก",
  "เรียกคืน",
  "เรียกตัว",
  "เรียกร้อง",
  "เรียกหา",
  "เรียง",
  "เรียงความ",
  "เรียงตัว",
  "เรียงพิมพ์",
  "เรียงราย",
  "เรียงเบอร์",
  "เรียงเม็ด",
  "เรียด",
  "เรียน",
  "เรียนรู้",
  "เรียบ",
  "เรียบร้อย",
  "เรียบร้อย",
  "เรียบวุธ",
  "เรียบเรียง",
  "เรียม",
  "เรียลไทม์",
  "เรียว",
  "เรี่ย",
  "เรี่ยม",
  "เรี่ยมเร้",
  "เรี่ยราด",
  "เรี่ยว",
  "เรี่ยวแรง",
  "เรี่ยไร",
  "เรี้ย",
  "เรี้ยวรก",
  "เรือ",
  "เรือก",
  "เรือกลไฟ",
  "เรือกสวน",
  "เรือกอและ",
  "เรือกำปั่น",
  "เรือง",
  "เรืองนาม",
  "เรืองรอง",
  "เรืองรอง",
  "เรืองแสง",
  "เรือจ้าง",
  "เรือด",
  "เรือดำน้ำ",
  "เรือตรวจการณ์",
  "เรือตังเก",
  "เรือธง",
  "เรือน",
  "เรือนจำ",
  "เรือนหอ",
  "เรือนำร่อง",
  "เรือนเบี้ย",
  "เรือนแก้ว",
  "เรือนแพ",
  "เรือบด",
  "เรือบิน",
  "เรือประมง",
  "เรือพิฆาต",
  "เรือพ่วง",
  "เรือยนต์",
  "เรือยาว",
  "เรือรบ",
  "เรือลากจูง",
  "เรือสำปั้น",
  "เรือสำเภา",
  "เรือหลวง",
  "เรือหางยาว",
  "เรืออีโปง",
  "เรือเอี้ยมจุ๊น",
  "เรือโดยสาร",
  "เรือโยง",
  "เรือใบ",
  "เรื่อ",
  "เรื่อง",
  "เรื่องราว",
  "เรื่องสั้น",
  "เรื่อย",
  "เรื่อยเจื้อย",
  "เรื่อยเฉื่อย",
  "เรื่อยเปื่อย",
  "เรื้อ",
  "เรื้อง",
  "เรื้อน",
  "เรื้อรัง",
  "เรเดียม",
  "เรไร",
  "เร็ง",
  "เร็ว",
  "เร่",
  "เร่ง",
  "เร่งด่วน",
  "เร่งมือ",
  "เร่งรัด",
  "เร่งรีบ",
  "เร่งเร้า",
  "เร่ร่อน",
  "เร่ว",
  "เร่อ",
  "เร่อร่า",
  "เร่า",
  "เร่าร้อน",
  "เร้ง",
  "เร้น",
  "เร้นลับ",
  "เร้า",
  "เลก",
  "เลกเชอร์",
  "เลข",
  "เลขคณิต",
  "เลขผา",
  "เลขหมาย",
  "เลขา",
  "เลขาธิการ",
  "เลขานุการ",
  "เลคเชอร์",
  "เลฑฑุ",
  "เลณฑุ",
  "เลณะ",
  "เลดี้",
  "เลน",
  "เลนส์",
  "เลนส์นูน",
  "เลนส์เว้า",
  "เลบง",
  "เลบานอน",
  "เลปกร",
  "เลปน์",
  "เลย",
  "เลย",
  "เลยเถิด",
  "เลว",
  "เลวง",
  "เลวูโลส",
  "เลศ",
  "เลศนัย",
  "เลษฏุ",
  "เลสเต",
  "เลสเบี้ยน",
  "เลหลัง",
  "เลหะ",
  "เลอ",
  "เลอมาน",
  "เลอสรวง",
  "เลอะ",
  "เลอะเทอะ",
  "เลอะเลือน",
  "เลอเลิศ",
  "เลอโฉม",
  "เละ",
  "เละเทะ",
  "เลา",
  "เลากัย",
  "เลาะ",
  "เลาะลัด",
  "เลิก",
  "เลิกรา",
  "เลิกร้าง",
  "เลิกล้ม",
  "เลิง",
  "เลินเล่อ",
  "เลิฟ",
  "เลิศ",
  "เลิศเลอ",
  "เลิ่กลั่ก",
  "เลิ้ง",
  "เลีย",
  "เลียง",
  "เลียงผา",
  "เลียงฝ้าย",
  "เลียงมัน",
  "เลียดก๊ก",
  "เลียน",
  "เลียนไฟ",
  "เลียบ",
  "เลียบเคียง",
  "เลียว",
  "เลี่ยง",
  "เลี่ยน",
  "เลี่ยม",
  "เลี้ยง",
  "เลี้ยงชีพ",
  "เลี้ยงดู",
  "เลี้ยงต้อย",
  "เลี้ยว",
  "เลี้ยวลด",
  "เลือก",
  "เลือกตั้ง",
  "เลือกสรร",
  "เลือกเฟ้น",
  "เลือง",
  "เลือด",
  "เลือดกำเดา",
  "เลือดฝาด",
  "เลือดร้อน",
  "เลือดหมู",
  "เลือดอุ่น",
  "เลือดเนื้อ",
  "เลือดเย็น",
  "เลือน",
  "เลือนราง",
  "เลื่อง",
  "เลื่องลือ",
  "เลื่อน",
  "เลื่อนลอย",
  "เลื่อนเปื้อน",
  "เลื่อม",
  "เลื่อมพราย",
  "เลื่อมใส",
  "เลื่อย",
  "เลื่อยฉลุ",
  "เลื่อยลันดา",
  "เลื่อยล้า",
  "เลื่อยวงเดือน",
  "เลื้อย",
  "เลื้อยคลาน",
  "เลเซอร์",
  "เลเป",
  "เลเพ",
  "เลเยอร์",
  "เลโซโท",
  "เลโนโว",
  "เล็ก",
  "เล็กน้อย",
  "เล็ง",
  "เล็ด",
  "เล็ดลอด",
  "เล็น",
  "เล็บ",
  "เล็บครุฑ",
  "เล็ม",
  "เล่งฮื้อ",
  "เล่น",
  "เล่นงาน",
  "เล่นชู้",
  "เล่นตัว",
  "เล่นลิ้น",
  "เล่นหัว",
  "เล่นแง่",
  "เล่ม",
  "เล่ห์",
  "เล่ห์กระเท่ห์",
  "เล่ห์กล",
  "เล่ห์เหลี่ยม",
  "เล่อ",
  "เล่อล่า",
  "เล่า",
  "เล่าลือ",
  "เล่าเรียน",
  "เล้ง",
  "เล้า",
  "เล้าโลม",
  "เวกเตอร์",
  "เวก้า",
  "เวค",
  "เวคิน",
  "เวคี",
  "เวจ",
  "เวจกุฎี",
  "เวจมรรค",
  "เวช",
  "เวชกรรม",
  "เวชภัณฑ์",
  "เวชยันต์",
  "เวชศาสตร์",
  "เวฐน์",
  "เวณิ",
  "เวณิก",
  "เวณิกา",
  "เวณุ",
  "เวตน์",
  "เวตร",
  "เวตาล",
  "เวท",
  "เวทคู",
  "เวทนา",
  "เวทมนตร์",
  "เวทย์",
  "เวทัลละ",
  "เวทางคศาสตร์",
  "เวทางค์",
  "เวทานตะ",
  "เวทานต์",
  "เวทิ",
  "เวที",
  "เวธะ",
  "เวน",
  "เวนคืน",
  "เวนิส",
  "เวนไตย",
  "เวมัต",
  "เวยยากรณะ",
  "เวร",
  "เวรกรรม",
  "เวรมณี",
  "เวรี",
  "เวลส์",
  "เวลา",
  "เวศม์",
  "เวศยา",
  "เวศย์",
  "เวสต์",
  "เวสน์",
  "เวสภู",
  "เวสม์",
  "เวสวัณ",
  "เวสสะ",
  "เวสสันดร",
  "เวสสุกรรม",
  "เวสสุวัณ",
  "เวสารัช",
  "เวสาลี",
  "เวสิยา",
  "เวสิเคิล",
  "เวหน",
  "เวหะ",
  "เวหา",
  "เวหาส",
  "เวฬุ",
  "เวฬุการ",
  "เวฬุริยะ",
  "เวฬุวัน",
  "เวอร์",
  "เวอร์จิเนีย",
  "เวอร์ชวล",
  "เวอร์ชัน",
  "เวอร์ชั่น",
  "เวอร์มอนต์",
  "เวิก",
  "เวิร์ก",
  "เวิร์กช็อป",
  "เวิร์กสเตชัน",
  "เวิร์กสเตชั่น",
  "เวิร์ค",
  "เวิร์คสเตชัน",
  "เวิร์คสเตชั่น",
  "เวิร์ด",
  "เวิร์ม",
  "เวิร์ลด์",
  "เวิลด์",
  "เวิ้ง",
  "เวิ้งว้าง",
  "เวียง",
  "เวียงจันทน์",
  "เวียงวัง",
  "เวียด",
  "เวียดกง",
  "เวียดนาม",
  "เวียดนาม",
  "เวียน",
  "เวียนนา",
  "เวียนเทียน",
  "เวียร",
  "เวี่ย",
  "เวี่ยว",
  "เวเนซุเอลา",
  "เวเฟอร์",
  "เวเลนซี",
  "เวโรจน์",
  "เวไนย",
  "เว็บ",
  "เว่อ",
  "เว้",
  "เว้น",
  "เว้ย",
  "เว้า",
  "เว้าวอน",
  "เศรณี",
  "เศรษฐ",
  "เศรษฐกิจ",
  "เศรษฐศาสตร์",
  "เศรษฐี",
  "เศรษฐ์",
  "เศร้า",
  "เศร้าสร้อย",
  "เศร้าสลด",
  "เศร้าหมอง",
  "เศร้าโศก",
  "เศร้าใจ",
  "เศลษ",
  "เศวต",
  "เศวตฉัตร",
  "เศวตร",
  "เศวตัมพร",
  "เศษ",
  "เศษซ้อน",
  "เศษวรรค",
  "เศษส่วน",
  "เศษเกิน",
  "เศษเหล็ก",
  "เศาจ",
  "เศารยะ",
  "เศาร์",
  "เศิก",
  "เศียร",
  "เส",
  "เสก",
  "เสกขบุคคล",
  "เสกขะ",
  "เสกสรร",
  "เสกสรรค์",
  "เสขบุคคล",
  "เสขะ",
  "เสงี่ยม",
  "เสฉวน",
  "เสฏฐี",
  "เสณี",
  "เสด",
  "เสด็จ",
  "เสตุ",
  "เสถียร",
  "เสถียรภาพ",
  "เสทะ",
  "เสน",
  "เสนง",
  "เสนหา",
  "เสนอ",
  "เสนะ",
  "เสนา",
  "เสนากุฎ",
  "เสนางคนิกร",
  "เสนางค์",
  "เสนาธิการ",
  "เสนานี",
  "เสนาบดี",
  "เสนาสนะ",
  "เสนาะ",
  "เสนี",
  "เสนียด",
  "เสนียะ",
  "เสนีย์",
  "เสน่ง",
  "เสน่หา",
  "เสน่ห์",
  "เสน่า",
  "เสบย",
  "เสบียง",
  "เสบียงกรัง",
  "เสพ",
  "เสพติด",
  "เสพย์",
  "เสภา",
  "เสมหะ",
  "เสมอ",
  "เสมอภาค",
  "เสมอหน้า",
  "เสมอเหมือน",
  "เสมา",
  "เสมียน",
  "เสมียนตรา",
  "เสมือน",
  "เสม็ด",
  "เสย",
  "เสริด",
  "เสริม",
  "เสริมสร้าง",
  "เสริมสวย",
  "เสริมส่ง",
  "เสรี",
  "เสรีธรรม",
  "เสรีนิยม",
  "เสรีภาพ",
  "เสรีไทย",
  "เสร็จ",
  "เสร็จสรรพ",
  "เสร็จสิ้น",
  "เสลด",
  "เสลบรรพต",
  "เสลภูมิ",
  "เสลา",
  "เสลี่ยง",
  "เสลือกสลน",
  "เสวก",
  "เสวกามาตย์",
  "เสวนะ",
  "เสวนา",
  "เสวย",
  "เสวียน",
  "เสสรวง",
  "เสสรวล",
  "เสสรวล",
  "เสา",
  "เสาธง",
  "เสารภย์",
  "เสารี",
  "เสาร์",
  "เสาวคนธ์",
  "เสาวณิต",
  "เสาวธาร",
  "เสาวนะ",
  "เสาวนา",
  "เสาวนีย์",
  "เสาวภา",
  "เสาวภาคย์",
  "เสาวภาพ",
  "เสาวรภย์",
  "เสาวรส",
  "เสาวลักษณ์",
  "เสาหฤท",
  "เสาะ",
  "เสาะแสะ",
  "เสาเข็ม",
  "เสิร์จ",
  "เสิร์ช",
  "เสิร์ฟ",
  "เสีย",
  "เสียง",
  "เสียด",
  "เสียดสี",
  "เสียดาย",
  "เสียดแทง",
  "เสียดแทรก",
  "เสียที",
  "เสียบ",
  "เสียม",
  "เสียมราฐ",
  "เสียมเรียบ",
  "เสียรู้",
  "เสียว",
  "เสียวซ่าน",
  "เสียวไส้",
  "เสียสละ",
  "เสียหลัก",
  "เสียหาย",
  "เสียเชิง",
  "เสียเที่ยว",
  "เสียเปรียบ",
  "เสียเปล่า",
  "เสียแรง",
  "เสียใจ",
  "เสี่ยง",
  "เสี่ยงทาย",
  "เสี่ยม",
  "เสี่ยว",
  "เสี้ยน",
  "เสี้ยนศึก",
  "เสี้ยนหนาม",
  "เสี้ยม",
  "เสี้ยมสอน",
  "เสี้ยว",
  "เสือ",
  "เสือก",
  "เสือกคลาน",
  "เสือกสน",
  "เสือกไส",
  "เสือดาว",
  "เสือดำ",
  "เสือปลา",
  "เสือป่า",
  "เสือหมอบ",
  "เสือโคร่ง",
  "เสือไฟ",
  "เสื่อ",
  "เสื่อกก",
  "เสื่อกระจูด",
  "เสื่อน้ำมัน",
  "เสื่อม",
  "เสื่อมคลาย",
  "เสื่อมถอย",
  "เสื่อมทราม",
  "เสื่อมสลาย",
  "เสื่อมสูญ",
  "เสื่อมเสีย",
  "เสื่อมโทรม",
  "เสื่อลำแพน",
  "เสื้อ",
  "เสื้อกล้าม",
  "เสื้อกั๊ก",
  "เสื้อครุย",
  "เสื้อเกราะ",
  "เสื้อเมือง",
  "เสื้อแสง",
  "เสเพล",
  "เสแสร้ง",
  "เสโท",
  "เสโล",
  "เส็ง",
  "เส็งเคร็ง",
  "เส้ง",
  "เส้น",
  "เส้นชัย",
  "เส้นตรง",
  "เส้นตาย",
  "เส้นทาง",
  "เส้นรุ้ง",
  "เส้นสาย",
  "เส้นหมี่",
  "เส้นเลือด",
  "เส้นเสียง",
  "เส้นเอ็น",
  "เส้นแวง",
  "เส้นใย",
  "เส้า",
  "เส้าหลิน",
  "เห",
  "เหง",
  "เหงา",
  "เหงาหงอย",
  "เหงือก",
  "เหงื่อ",
  "เหงื่อกาฬ",
  "เหง่ง",
  "เหง้า",
  "เหติ",
  "เหตุ",
  "เหตุการณ์",
  "เหตุผล",
  "เหนงนายพราน",
  "เหนอะ",
  "เหนอะหนะ",
  "เหนาะ",
  "เหนียง",
  "เหนียม",
  "เหนียว",
  "เหนียวแน่น",
  "เหนี่ยง",
  "เหนี่ยน",
  "เหนี่ยว",
  "เหนี่ยวนำ",
  "เหนี่ยวรั้ง",
  "เหนือ",
  "เหนื่อย",
  "เหนื่อยหน่าย",
  "เหน็ดเหนื่อย",
  "เหน็บ",
  "เหน็บชา",
  "เหน็บแนม",
  "เหน่ง",
  "เหน่อ",
  "เหน้า",
  "เหม",
  "เหมย",
  "เหมวดี",
  "เหมันตฤดู",
  "เหมันต์",
  "เหมา",
  "เหมายัน",
  "เหมาะ",
  "เหมาะสม",
  "เหมาะเจาะ",
  "เหมาะเหม็ง",
  "เหมียว",
  "เหมี่ยว",
  "เหมือง",
  "เหมือด",
  "เหมือน",
  "เหมื่อย",
  "เหม็ง",
  "เหม็น",
  "เหม่",
  "เหม่ง",
  "เหม่อ",
  "เหย",
  "เหยง",
  "เหยา",
  "เหยาะ",
  "เหยาะแหยะ",
  "เหยิง",
  "เหยิบ",
  "เหยียด",
  "เหยียดหยาม",
  "เหยียบ",
  "เหยี่ยว",
  "เหยือก",
  "เหยื่อ",
  "เหยเก",
  "เหย่อย",
  "เหย่า",
  "เหย้า",
  "เหรอ",
  "เหรอะ",
  "เหรัญญิก",
  "เหรา",
  "เหราะ",
  "เหรียญ",
  "เหลน",
  "เหลว",
  "เหลวแหลก",
  "เหลวไหล",
  "เหลอ",
  "เหลอหลา",
  "เหลา",
  "เหลาะแหละ",
  "เหลิง",
  "เหลิงเจิ้ง",
  "เหลียน",
  "เหลียว",
  "เหลียวแล",
  "เหลี่ยม",
  "เหลือ",
  "เหลือก",
  "เหลือขอ",
  "เหลือง",
  "เหลือบ",
  "เหลือม",
  "เหลือร้าย",
  "เหลือล้น",
  "เหลือหลอ",
  "เหลือหลาย",
  "เหลือเกิน",
  "เหลือเชื่อ",
  "เหลือเฟือ",
  "เหลือแสน",
  "เหลือแหล่",
  "เหลือใจ",
  "เหลื่อม",
  "เหลื่อมล้ำ",
  "เหล็ก",
  "เหล็กกล้า",
  "เหล็กจาร",
  "เหล็กส่ง",
  "เหล็กหล่อ",
  "เหล็กเส้น",
  "เหล็กใน",
  "เหล็กไหล",
  "เหล่",
  "เหล่า",
  "เหล่ากอ",
  "เหล้า",
  "เหว",
  "เหวง",
  "เหวย",
  "เหวอะ",
  "เหวอะหวะ",
  "เหวี่ยง",
  "เหว่",
  "เหอหนาน",
  "เหอะ",
  "เหอเป่ย",
  "เหอเป่ย์",
  "เหะ",
  "เหะหะ",
  "เหา",
  "เหาะ",
  "เหิน",
  "เหินห่าง",
  "เหิม",
  "เหิมหาญ",
  "เหิมเกริม",
  "เหียง",
  "เหียน",
  "เหี่ยว",
  "เหี่ยวย่น",
  "เหี่ยวแห้ง",
  "เหี้ย",
  "เหี้ยน",
  "เหี้ยม",
  "เหี้ยมหาญ",
  "เหี้ยมเกรียม",
  "เหี้ยมโหด",
  "เหือด",
  "เหือดหาย",
  "เหือดแห้ง",
  "เหื่อ",
  "เห็จ",
  "เห็ด",
  "เห็น",
  "เห็นด้วย",
  "เห็นอ้ม",
  "เห็นแก่",
  "เห็นใจ",
  "เห็บ",
  "เห่",
  "เห่ย",
  "เห่อ",
  "เห่อเหิม",
  "เห่า",
  "เห้งเจีย",
  "เอ",
  "เอ",
  "เอก",
  "เอกจิต",
  "เอกฉันท์",
  "เอกชน",
  "เอกซเรย์",
  "เอกทัศน์",
  "เอกนัย",
  "เอกบุคคล",
  "เอกบุรุษ",
  "เอกพจน์",
  "เอกภพ",
  "เอกภาพ",
  "เอกมัย",
  "เอกรรถประโยค",
  "เอกราช",
  "เอกรูป",
  "เอกลักษณ์",
  "เอกวาดอร์",
  "เอกศก",
  "เอกสาร",
  "เอกสิทธิ์",
  "เอกอุ",
  "เอกัคตา",
  "เอกังสพยากรณ์",
  "เอกังสวาที",
  "เอกา",
  "เอกาธิปไตย",
  "เอกเขนก",
  "เอกเทศ",
  "เอง",
  "เอช",
  "เอดส์",
  "เอดินบะระ",
  "เอดิสัน",
  "เอตทัคคะ",
  "เอทานอล",
  "เอทิล",
  "เอทิลีน",
  "เอธิโอเปีย",
  "เอน",
  "เอนจิน",
  "เอนทรานซ์",
  "เอนเอียง",
  "เอนโทรปี",
  "เอนไซม์",
  "เอฟ",
  "เอฟเฟ็กต์",
  "เอม",
  "เอมอร",
  "เอมิเรตส์",
  "เอย",
  "เอราวัณ",
  "เอริเทรีย",
  "เอร็ดอร่อย",
  "เอลซัลวาดอร์",
  "เอลนีโญ",
  "เอลิซาเบธ",
  "เอว",
  "เอวัง",
  "เอส",
  "เอสพลานาด",
  "เอสพลานาร์ด",
  "เอสเตอร์",
  "เอสเปรสโซ",
  "เอสเพรสโซ",
  "เอสโตรเจน",
  "เอสโตเนีย",
  "เอสโทรเจน",
  "เอฬกะ",
  "เอฬา",
  "เออ",
  "เออร์เบียม",
  "เอออวย",
  "เออออ",
  "เอะอะ",
  "เอะอะมะเทิ่ง",
  "เอะใจ",
  "เอา",
  "เอาการ",
  "เอางาน",
  "เอาจริง",
  "เอาต์",
  "เอาต์พุต",
  "เอาต์พุท",
  "เอาทาร",
  "เอาทารย์",
  "เอาท์",
  "เอาท์ดอร์",
  "เอาท์พุต",
  "เอาท์พุท",
  "เอารส",
  "เอาฬาร",
  "เอาะลาย",
  "เอาเปรียบ",
  "เอาเยี่ยง",
  "เอาใจ",
  "เอิก",
  "เอิกเกริก",
  "เอิบ",
  "เอิบอาบ",
  "เอิ้น",
  "เอียง",
  "เอียงอาย",
  "เอียงเอน",
  "เอียด",
  "เอียน",
  "เอี่ยน",
  "เอี่ยม",
  "เอี่ยว",
  "เอี้ยง",
  "เอี้ยมจุ๊น",
  "เอี้ยมเฟี้ยม",
  "เอี้ยว",
  "เอี๊ยด",
  "เอี๊ยม",
  "เอือด",
  "เอือน",
  "เอือม",
  "เอื่อย",
  "เอื้อ",
  "เอื้อง",
  "เอื้อน",
  "เอื้อม",
  "เอื้อย",
  "เอื้อเฟื้อ",
  "เอื๊อก",
  "เอเคอร์",
  "เอเชีย",
  "เอเซอร์",
  "เอเซีย",
  "เอเดรียติก",
  "เอเธนส์",
  "เอเยนต์",
  "เอเวอเรสต์",
  "เอแบค",
  "เอ็กซ์",
  "เอ็กซ์เพรส",
  "เอ็กซ์โป",
  "เอ็กซ์โพเนนเชียล",
  "เอ็กโซเซต์",
  "เอ็กโซเซ่ต์",
  "เอ็ง",
  "เอ็ด",
  "เอ็ดตะโร",
  "เอ็ดอึง",
  "เอ็ดเวิร์ด",
  "เอ็น",
  "เอ็น",
  "เอ็นจิน",
  "เอ็นจีโอ",
  "เอ็นดู",
  "เอ็นทรานซ์",
  "เอ็นอ่อน",
  "เอ็นเตอร์เทน",
  "เอ็ม",
  "เอ็มบริโอ",
  "เอ็มโพเรียม",
  "เอ่ย",
  "เอ่อ",
  "เอ้",
  "เอ้กา",
  "เอ้อระเหย",
  "เอ้อเร้อ",
  "เอ้อเฮอ",
  "เอ้เต",
  "เอ๊",
  "เอ๊กซ์",
  "เอ๊ย",
  "เอ๊ว",
  "เอ๊ะ",
  "เอ๊าะ",
  "เอ๋",
  "เอ๋ง",
  "เอ๋ย",
  "เอ๋อ",
  "เฮ",
  "เฮกตาร์",
  "เฮกโตกรัม",
  "เฮกโตลิตร",
  "เฮกโตเมตร",
  "เฮง",
  "เฮงซวย",
  "เฮติ",
  "เฮนรี",
  "เฮนรี่",
  "เฮฟวี",
  "เฮฟวี่",
  "เฮย",
  "เฮลซิงกิ",
  "เฮละโล",
  "เฮลิคอปเตอร์",
  "เฮอ",
  "เฮอริเคน",
  "เฮอร์ริเคน",
  "เฮอร์เซโกวีนา",
  "เฮฮา",
  "เฮิรตซ์",
  "เฮีย",
  "เฮี้ยน",
  "เฮี้ยบ",
  "เฮี้ยว",
  "เฮือก",
  "เฮือน",
  "เฮเลน",
  "เฮโมโกลบิน",
  "เฮโรอีน",
  "เฮโล",
  "เฮ็ด",
  "เฮ่ย",
  "เฮ่อ",
  "เฮ้ย",
  "เฮ้ว",
  "เฮ้อ",
  "แก",
  "แกง",
  "แกงคั่ว",
  "แกงค์",
  "แกงจืด",
  "แกงบวด",
  "แกงป่า",
  "แกงส้ม",
  "แกงเผ็ด",
  "แกงแนง",
  "แกงได",
  "แกน",
  "แกนีมีด",
  "แกม",
  "แกมมา",
  "แกมมา",
  "แกมม่า",
  "แกมเบีย",
  "แกรก",
  "แกรนด์",
  "แกรนิต",
  "แกระ",
  "แกรไฟต์",
  "แกร็น",
  "แกร่ง",
  "แกร่ว",
  "แกล",
  "แกลน",
  "แกลบ",
  "แกลลอน",
  "แกละ",
  "แกลเลียม",
  "แกล้ง",
  "แกล้ม",
  "แกล้ว",
  "แกว",
  "แกว่ง",
  "แกว่งกวัด",
  "แกว่งไกว",
  "แกสโซฮอล์",
  "แกะ",
  "แกะรอย",
  "แกแล",
  "แกโดลิเนียม",
  "แก่",
  "แก่ง",
  "แก่งแย่ง",
  "แก่น",
  "แก่นสาร",
  "แก่นแก้ว",
  "แก่แดด",
  "แก้",
  "แก้ขัด",
  "แก้ง",
  "แก้ตัว",
  "แก้ม",
  "แก้ลำ",
  "แก้ว",
  "แก้วตา",
  "แก้วหู",
  "แก้เผ็ด",
  "แก้ไข",
  "แก๊ง",
  "แก๊ป",
  "แก๊ส",
  "แก๊สโซฮอล์",
  "แข",
  "แขก",
  "แขกเต้า",
  "แขน",
  "แขนง",
  "แขม",
  "แขม็บ",
  "แขม่ว",
  "แขยง",
  "แขย่ง",
  "แขวก",
  "แขวง",
  "แขวน",
  "แขวนลอย",
  "แขวะ",
  "แข็ง",
  "แข็งกร้าว",
  "แข็งกล้า",
  "แข็งขัน",
  "แข็งข้อ",
  "แข็งตัว",
  "แข็งเมือง",
  "แข็งแกร่ง",
  "แข็งแรง",
  "แข็งใจ",
  "แข่ง",
  "แข่งขัน",
  "แข่น",
  "แข้",
  "แข้ง",
  "แข้น",
  "แค",
  "แคช",
  "แคชเชียร์",
  "แคชเมียร์",
  "แคดเมียม",
  "แคตตาล็อก",
  "แคทรีนา",
  "แคทวอล์ค",
  "แคน",
  "แคนซัส",
  "แคนดิเดต",
  "แคนตาลูป",
  "แคนยอน",
  "แคนาดา",
  "แคนู",
  "แคนเบอร์รา",
  "แคบ",
  "แคบหมู",
  "แคป",
  "แคปซูล",
  "แคม",
  "แคมปัส",
  "แคมป์",
  "แคมฟรอก",
  "แคมฟร็อก",
  "แคมเปญ",
  "แครก",
  "แครกเกอร์",
  "แครง",
  "แครอท",
  "แคระ",
  "แคริบเบียน",
  "แคร็ก",
  "แคร็กเกอร์",
  "แคร่",
  "แคร์",
  "แคลคูลัส",
  "แคลง",
  "แคลน",
  "แคลอรี",
  "แคลิฟอร์เนีย",
  "แคลิฟอร์เนียม",
  "แคลเซียม",
  "แคล่วคล่อง",
  "แคล้ว",
  "แคว",
  "แควก",
  "แคว้น",
  "แคสติง",
  "แคสติ้ง",
  "แคสต์",
  "แคสสินี",
  "แคสเปียน",
  "แคะ",
  "แคเมอรูน",
  "แคแตร",
  "แคแล",
  "แคแสด",
  "แคโทด",
  "แคโรทีน",
  "แคโรไลนา",
  "แค็ตตาล็อก",
  "แค่",
  "แค่น",
  "แค้",
  "แค้น",
  "แง",
  "แงะ",
  "แง่",
  "แง่ง",
  "แง่งอน",
  "แง่น",
  "แง้ม",
  "แจ",
  "แจก",
  "แจกจ่าย",
  "แจกัน",
  "แจง",
  "แจงลอน",
  "แจตร",
  "แจบ",
  "แจม",
  "แจรง",
  "แจว",
  "แจะ",
  "แจ็กพอต",
  "แจ็กพ็อต",
  "แจ็กสัน",
  "แจ็กเก็ต",
  "แจ่ง",
  "แจ่ม",
  "แจ่มแจ้ง",
  "แจ่มใส",
  "แจ่ว",
  "แจ้",
  "แจ้ง",
  "แจ้น",
  "แจ้ว",
  "แจ๊กพอต",
  "แจ๊กพ็อต",
  "แจ๊กเก็ต",
  "แจ๊ด",
  "แจ๊ดแจ๋",
  "แจ๊ส",
  "แจ๋",
  "แจ๋ว",
  "แฉ",
  "แฉก",
  "แฉง",
  "แฉลบ",
  "แฉล้ม",
  "แฉะ",
  "แฉะแบะ",
  "แฉ่",
  "แฉ่ง",
  "แช",
  "แชงมา",
  "แชนเนล",
  "แชนแนล",
  "แชบ๊วย",
  "แชมปิยอง",
  "แชมป์",
  "แชมพู",
  "แชมเปญ",
  "แชมเปี้ยน",
  "แชร์",
  "แชล่ม",
  "แชสซี",
  "แชะ",
  "แชเชือน",
  "แช็ต",
  "แช่",
  "แช่ง",
  "แช่ม",
  "แช่มชื่น",
  "แช่มช้อย",
  "แช่อิ่ม",
  "แช่เย็น",
  "แช่แข็ง",
  "แซ",
  "แซกโซโฟน",
  "แซง",
  "แซงแซว",
  "แซด",
  "แซด",
  "แซนด์วิช",
  "แซบ",
  "แซม",
  "แซมบ้า",
  "แซมเบีย",
  "แซยิด",
  "แซลมอน",
  "แซว",
  "แซะ",
  "แซ็ก",
  "แซ็กคาริน",
  "แซ็กโซโฟน",
  "แซ่",
  "แซ่ซ้อง",
  "แซ่ด",
  "แซ่ว",
  "แด",
  "แดก",
  "แดกงา",
  "แดกดัน",
  "แดกแด้",
  "แดง",
  "แดด",
  "แดดาล",
  "แดดิ้น",
  "แดน",
  "แดนซ์",
  "แดนเซอร์",
  "แดรี่",
  "แดวู",
  "แดะ",
  "แดะแด๋",
  "แด็ก",
  "แด่",
  "แด่น",
  "แด่ว",
  "แตก",
  "แตกคอ",
  "แตกคอก",
  "แตกฉาน",
  "แตกดับ",
  "แตกตื่น",
  "แตกพาน",
  "แตกร้าว",
  "แตกหัก",
  "แตกแยก",
  "แตง",
  "แตงกวา",
  "แตงเม",
  "แตงโม",
  "แตด",
  "แตน",
  "แตร",
  "แตรงอน",
  "แตรฝรั่ง",
  "แตรฟันฟาร์",
  "แตรวง",
  "แตระ",
  "แตรเดี่ยว",
  "แตะ",
  "แต่",
  "แต่ง",
  "แต่งงาน",
  "แต่งตั้ง",
  "แต่ละ",
  "แต่ว่า",
  "แต้",
  "แต้จิ๋ว",
  "แต้ม",
  "แต้มคู",
  "แต้มต่อ",
  "แต้ว",
  "แต้วแร้ว",
  "แต้วแล้ว",
  "แต้แว้ด",
  "แต๊ดแต๋",
  "แต๋ว",
  "แถ",
  "แถก",
  "แถง",
  "แถน",
  "แถบ",
  "แถม",
  "แถมพก",
  "แถลง",
  "แถลงการณ์",
  "แถลบ",
  "แถว",
  "แทกติค",
  "แทคติค",
  "แทง",
  "แทงกั๊ก",
  "แทงทวย",
  "แทงวิสัย",
  "แทงโก้",
  "แทตย์",
  "แทน",
  "แทนซาเนีย",
  "แทนทาลัม",
  "แทนนิน",
  "แทนเจนต์",
  "แทบ",
  "แทบเล็ต",
  "แทรก",
  "แทรกซอน",
  "แทรกซึม",
  "แทรกซ้อน",
  "แทรกเตอร์",
  "แทรกแซง",
  "แทลเลียม",
  "แทสเมเนีย",
  "แทะ",
  "แทะโลม",
  "แท็ก",
  "แท็กซี่",
  "แท็งก์",
  "แท็งก์น้ำ",
  "แท็บ",
  "แท่ง",
  "แท่น",
  "แท่นพิมพ์",
  "แท่นมณฑล",
  "แท่นหมึก",
  "แท้",
  "แท้ง",
  "แนท",
  "แนบ",
  "แนบเนียน",
  "แนบแน่น",
  "แนม",
  "แนว",
  "แนวคิด",
  "แนวทาง",
  "แนวป่า",
  "แนวรบ",
  "แนวร่วม",
  "แนวหน้า",
  "แนวหลัง",
  "แนวโน้ม",
  "แนสแดค",
  "แนะ",
  "แนะนำ",
  "แนะแนว",
  "แนะแหน",
  "แน่",
  "แน่ง",
  "แน่ชัด",
  "แน่น",
  "แน่นหนา",
  "แน่นอน",
  "แน่นิ่ง",
  "แน่นแฟ้น",
  "แน่บ",
  "แน่ว",
  "แน่วแน่",
  "แน่ะ",
  "แน่แท้",
  "แน่แน่ว",
  "แน่ใจ",
  "แบ",
  "แบก",
  "แบกะดิน",
  "แบกแดด",
  "แบคทีเรีย",
  "แบคโฮ",
  "แบงก์",
  "แบงค์",
  "แบด",
  "แบดมินตัน",
  "แบต",
  "แบตเตอรี่",
  "แบน",
  "แบนด์วิดท์",
  "แบนเนอร์",
  "แบนโจ",
  "แบบ",
  "แบบฉบับ",
  "แบบฝึกหัด",
  "แบบพิมพ์",
  "แบบสอบถาม",
  "แบบอย่าง",
  "แบบแปลน",
  "แบบแผน",
  "แบรนด์",
  "แบล็ก",
  "แบล็ค",
  "แบหลา",
  "แบะ",
  "แบะท่า",
  "แบะแฉะ",
  "แบเบาะ",
  "แบเรียม",
  "แบ็กโฮ",
  "แบ็บ",
  "แบ่ง",
  "แบ่งปัน",
  "แบ่งเบา",
  "แบ่งแยก",
  "แบ้",
  "แป",
  "แปซิฟิก",
  "แปด",
  "แปดปน",
  "แปดเปื้อน",
  "แปทู",
  "แปบ",
  "แปม",
  "แปร",
  "แปรก",
  "แปรง",
  "แปรปรวน",
  "แปรปรวน",
  "แปรผัน",
  "แปรพักตร์",
  "แปรรูป",
  "แปรอักษร",
  "แประ",
  "แปร่ง",
  "แปร้น",
  "แปร๊ด",
  "แปร๋",
  "แปร๋น",
  "แปล",
  "แปลก",
  "แปลกปลอม",
  "แปลง",
  "แปลน",
  "แปลบ",
  "แปล้",
  "แปล๊บ",
  "แปะ",
  "แปะโป้ง",
  "แป้ก",
  "แป้ง",
  "แป้งนวล",
  "แป้งฝุ่น",
  "แป้งมัน",
  "แป้งร่ำ",
  "แป้งสาลี",
  "แป้งสิงคโปร์",
  "แป้งหมี่",
  "แป้งเปียก",
  "แป้น",
  "แป้ว",
  "แป๊ด",
  "แป๊น",
  "แป๊บ",
  "แป๊ว",
  "แป๊ะ",
  "แป๊ะซะ",
  "แป๊ะเจี๊ยะ",
  "แป๋ง",
  "แป๋ว",
  "แผก",
  "แผง",
  "แผงลอย",
  "แผด",
  "แผดเผา",
  "แผน",
  "แผนก",
  "แผนการ",
  "แผนงาน",
  "แผนที่",
  "แผนผัง",
  "แผนภาพ",
  "แผนภูมิ",
  "แผล",
  "แผลง",
  "แผละ",
  "แผล็บ",
  "แผล็ว",
  "แผ่",
  "แผ่น",
  "แผ่นดิน",
  "แผ่นเสียง",
  "แผ่ว",
  "แผ้ว",
  "แผ้วพาน",
  "แฝก",
  "แฝง",
  "แฝด",
  "แพ",
  "แพกเกจ",
  "แพกเก็ต",
  "แพง",
  "แพงพวย",
  "แพตช์",
  "แพตเทิร์น",
  "แพทยศาสตร์",
  "แพทยสภา",
  "แพทย์",
  "แพทเทิร์น",
  "แพน",
  "แพนก",
  "แพนงเชิง",
  "แพนงเชิญ",
  "แพนดา",
  "แพนด้า",
  "แพร",
  "แพรก",
  "แพรว",
  "แพรวพราว",
  "แพร่",
  "แพร่",
  "แพร่ง",
  "แพร่งพราย",
  "แพร่หลาย",
  "แพร้ว",
  "แพลง",
  "แพลตฟอร์ม",
  "แพลทินัม",
  "แพลน",
  "แพลม",
  "แพละ",
  "แพละโลม",
  "แพลเลเดียม",
  "แพล็บ",
  "แพว",
  "แพศยา",
  "แพศย์",
  "แพะ",
  "แพ็ค",
  "แพ่ง",
  "แพ่น",
  "แพ้",
  "แพ้ท้อง",
  "แพ้ว",
  "แฟกซ์",
  "แฟง",
  "แฟชั่น",
  "แฟซ่า",
  "แฟน",
  "แฟนซี",
  "แฟนตาซี",
  "แฟบ",
  "แฟรงก์เฟิร์ต",
  "แฟรนเซียม",
  "แฟรนไชส์",
  "แฟรี",
  "แฟรี่",
  "แฟร์",
  "แฟลกซ์",
  "แฟลช",
  "แฟลต",
  "แฟล็ก",
  "แฟล็ต",
  "แฟะ",
  "แฟ็กซ์",
  "แฟ่",
  "แฟ้บ",
  "แฟ้ม",
  "แมก",
  "แมกกาซีน",
  "แมกซ์เวลล์",
  "แมกนีเซียม",
  "แมกไซไซ",
  "แมค",
  "แมคอินทอช",
  "แมคเคอเรล",
  "แมง",
  "แมงกะพรุน",
  "แมงกานิน",
  "แมงกานีส",
  "แมงคา",
  "แมงคาเรือง",
  "แมงช้าง",
  "แมงดา",
  "แมงมุม",
  "แมงลัก",
  "แมชชีน",
  "แมชีน",
  "แมชีนเนอรี่",
  "แมน",
  "แมนจู",
  "แมนจูเรีย",
  "แมนชั่น",
  "แมนดาริน",
  "แมนฮัตตัน",
  "แมนเชสเตอร์",
  "แมมบ้า",
  "แมมโบ้",
  "แมริแลนด์",
  "แมรีแลนด์",
  "แมลง",
  "แมลงช้าง",
  "แมลงปอ",
  "แมลงภู่",
  "แมลงวัน",
  "แมลงเม่า",
  "แมลบ",
  "แมว",
  "แมวน้ำ",
  "แมวป่า",
  "แมวมอง",
  "แมวเซา",
  "แมสซาชูเซตส์",
  "แมะ",
  "แม็กกาซีน",
  "แม่",
  "แม่กอง",
  "แม่กุญแจ",
  "แม่คุณ",
  "แม่ค้า",
  "แม่งาน",
  "แม่ชี",
  "แม่ตะงาว",
  "แม่ทัพ",
  "แม่น",
  "แม่นม",
  "แม่นยำ",
  "แม่น้ำ",
  "แม่บท",
  "แม่บ้าน",
  "แม่พระ",
  "แม่พิมพ์",
  "แม่มด",
  "แม่ม่าย",
  "แม่ยก",
  "แม่ยาย",
  "แม่ร้าง",
  "แม่สี",
  "แม่สื่อ",
  "แม่ฮ่องสอน",
  "แม่เจ้า",
  "แม่เบี้ย",
  "แม่เพลง",
  "แม่เรือน",
  "แม่เลี้ยง",
  "แม่เล้า",
  "แม่เหล็ก",
  "แม่แรง",
  "แม่ไม้",
  "แม้",
  "แม้น",
  "แม้ว",
  "แยก",
  "แยกย้าย",
  "แยกแยะ",
  "แยง",
  "แยงซี",
  "แยงซีเกียง",
  "แยงแย่",
  "แยงแย้",
  "แยบ",
  "แยบคาย",
  "แยบยล",
  "แยม",
  "แยมโรล",
  "แยะ",
  "แยแส",
  "แย็บ",
  "แย่",
  "แย่ง",
  "แย่งชิง",
  "แย้",
  "แย้ง",
  "แย้ม",
  "แย้มพราย",
  "แย้มยิ้ม",
  "แย้มสรวล",
  "แร",
  "แรก",
  "แรกนา",
  "แรกนาขวัญ",
  "แรง",
  "แรงงาน",
  "แรงดึงดูด",
  "แรงดูด",
  "แรงผลัก",
  "แรงม้า",
  "แรงเทียน",
  "แรงเหวี่ยง",
  "แรงใจ",
  "แรด",
  "แรม",
  "แรมรอน",
  "แรมรา",
  "แรมโบ้",
  "แรมโรย",
  "แรลลี",
  "แรลลี่",
  "แระ",
  "แรเงา",
  "แร็กเกต",
  "แร่",
  "แร่ง",
  "แร้ง",
  "แร้นแค้น",
  "แร้ว",
  "แล",
  "แลก",
  "แลกเปลี่ยน",
  "แลง",
  "แลน",
  "แลนด์",
  "แลนทานัม",
  "แลบ",
  "และ",
  "และเล็ม",
  "แลเหลียว",
  "แล็กเกอร์",
  "แล็กโทส",
  "แล็บ",
  "แล็ปท็อป",
  "แล่",
  "แล่ง",
  "แล่น",
  "แล้",
  "แล้ง",
  "แล้ว",
  "แล้วกัน",
  "แวง",
  "แวด",
  "แวดล้อม",
  "แวดวง",
  "แวน",
  "แวนคูเวอร์",
  "แวนดา",
  "แวบ",
  "แวบวับ",
  "แวม",
  "แวมไพร์",
  "แวว",
  "แววตา",
  "แวววาม",
  "แวววาว",
  "แวะ",
  "แวะเวียน",
  "แว็บ",
  "แว็ม",
  "แว่น",
  "แว่นขยาย",
  "แว่นตา",
  "แว่นแคว้น",
  "แว่ว",
  "แว้",
  "แว้ง",
  "แว้งกัด",
  "แว้ด",
  "แส",
  "แสก",
  "แสกหน้า",
  "แสง",
  "แสด",
  "แสดง",
  "แสดงออก",
  "แสตมป์",
  "แสน",
  "แสนกล",
  "แสนยากร",
  "แสนยานุภาพ",
  "แสนย์",
  "แสนรู้",
  "แสบ",
  "แสม",
  "แสยก",
  "แสยง",
  "แสยะ",
  "แสรก",
  "แสร้ง",
  "แสร้งว่า",
  "แสลง",
  "แสล้ม",
  "แสวง",
  "แสะ",
  "แสเถา",
  "แส่",
  "แส้",
  "แห",
  "แหก",
  "แหง",
  "แหงน",
  "แหงแก๋",
  "แหง่",
  "แหง่ง",
  "แหน",
  "แหนง",
  "แหนงหน่าย",
  "แหนบ",
  "แหนม",
  "แหบ",
  "แหม",
  "แหมะ",
  "แหม็บ",
  "แหม่",
  "แหม่ม",
  "แหย",
  "แหยง",
  "แหยม",
  "แหยะ",
  "แหย็ม",
  "แหย่",
  "แหย่ง",
  "แหลก",
  "แหลกลาญ",
  "แหลกเหลว",
  "แหลน",
  "แหลม",
  "แหละ",
  "แหล่",
  "แหล่ง",
  "แหว",
  "แหวก",
  "แหวกว่าย",
  "แหวกแนว",
  "แหวด",
  "แหวน",
  "แหวว",
  "แหวะ",
  "แหว่ง",
  "แหะ",
  "แห่",
  "แห่ง",
  "แห่แหน",
  "แห้",
  "แห้ง",
  "แห้น",
  "แห้ว",
  "แอ",
  "แอก",
  "แอกทิเนียม",
  "แอคคอร์",
  "แอคคอร์ด",
  "แอคทีฟ",
  "แองเจลิน่า",
  "แองโกลา",
  "แอด",
  "แอดมิชชั่น",
  "แอดมิสชัน",
  "แอตแลนตา",
  "แอตแลนติก",
  "แอตแลนติส",
  "แอน",
  "แอนดรอยด์",
  "แอนด์",
  "แอนตาร์กติก",
  "แอนตาร์กติกา",
  "แอนติกา",
  "แอนติบอดี",
  "แอนติอิเล็กตรอน",
  "แอนติเจน",
  "แอนะล็อก",
  "แอนิเมชัน",
  "แอนิเมชั่น",
  "แอนแทร็กซ์",
  "แอบ",
  "แอปพริคอท",
  "แอปพลิเคชัน",
  "แอปพลิเคชั่น",
  "แอปเปิล",
  "แอปเปิ้ล",
  "แอพพริคอท",
  "แอพพลิเคชัน",
  "แอพพลิเคชั่น",
  "แอพริคอต",
  "แอฟริกัน",
  "แอฟริกา",
  "แอม",
  "แอมบาสซาเดอร์",
  "แอมบาสเดอร์",
  "แอมพลิจูด",
  "แอมมิเตอร์",
  "แอมเวย์",
  "แอมแปร์",
  "แอมโมเนีย",
  "แอมโมเนียม",
  "แอริโซนา",
  "แอร่ม",
  "แอร์",
  "แอล",
  "แอลกอฮอลิซึม",
  "แอลกอฮอล์",
  "แอลจีเรีย",
  "แอลฟา",
  "แอลฟา",
  "แอลฟ่า",
  "แอลมอนด์",
  "แอลเจียร์",
  "แอลเบเนีย",
  "แอว",
  "แอสทาทีน",
  "แอสพาร์แตม",
  "แอสฟัลต์",
  "แอสเซมบลี",
  "แอสเซมเบลอร์",
  "แอสเตอร์",
  "แอสไพริน",
  "แอหนัง",
  "แออัด",
  "แอโนด",
  "แอโรบิก",
  "แอโรบิค",
  "แอ็กชั่น",
  "แอ็คชั่น",
  "แอ่ง",
  "แอ่น",
  "แอ่ว",
  "แอ้",
  "แอ้งแม้ง",
  "แอ้ด",
  "แอ้ม",
  "แอ้วแซ่ว",
  "แอ๊ด",
  "แอ๊บแบ๊ว",
  "แอ๋",
  "แอ๋ม",
  "แอ๋ว",
  "แฮ",
  "แฮก",
  "แฮกเกอร์",
  "แฮนด์",
  "แฮนด์บอล",
  "แฮนด์เฮลด์",
  "แฮปปี้",
  "แฮฟเนียม",
  "แฮม",
  "แฮมเบอร์เกอร์",
  "แฮะ",
  "แฮ็กเกอร์",
  "แฮ่",
  "แฮ่กึ๊น",
  "โก",
  "โกก",
  "โกกนุท",
  "โกกิลา",
  "โกง",
  "โกงกาง",
  "โกงโก้",
  "โกญจนาท",
  "โกญจา",
  "โกฏิ",
  "โกฐ",
  "โกฐาส",
  "โกณฑัญญะ",
  "โกณะ",
  "โกดัง",
  "โกตดิวัวร์",
  "โกทัณฑ์",
  "โกน",
  "โกมล",
  "โกมุท",
  "โกย",
  "โกรก",
  "โกรกกราก",
  "โกรง",
  "โกรงเกรง",
  "โกรญจ",
  "โกรต๋น",
  "โกรธ",
  "โกรธขึ้ง",
  "โกรธา",
  "โกรธเกรี้ยว",
  "โกรม",
  "โกรย",
  "โกรศ",
  "โกร่ง",
  "โกร่งกร่าง",
  "โกร๋งเกร๋ง",
  "โกร๋น",
  "โกลกาตา",
  "โกลด์",
  "โกลน",
  "โกลบอล",
  "โกลาหล",
  "โกลิยะ",
  "โกวิท",
  "โกศ",
  "โกศล",
  "โกษม",
  "โกสน",
  "โกสัช",
  "โกสัมพี",
  "โกสินทร์",
  "โกสีย์",
  "โกสุม",
  "โกหก",
  "โกะ",
  "โกเชาว์",
  "โกเต็กซ์",
  "โกเมน",
  "โกเมศ",
  "โกโก้",
  "โกโรโกเต",
  "โกโรโกโรก",
  "โกโรโกโส",
  "โกไล",
  "โกไสย",
  "โก่",
  "โก่ง",
  "โก่น",
  "โก้",
  "โก้งเก้ง",
  "โก้งโค้ง",
  "โก้เก๋",
  "โก๊ะ",
  "โก๋",
  "โก๋น",
  "โข",
  "โขก",
  "โขกสับ",
  "โขง",
  "โขด",
  "โขดง",
  "โขน",
  "โขนง",
  "โขม",
  "โขมง",
  "โขมด",
  "โขยก",
  "โขยกเขยก",
  "โขยง",
  "โขยด",
  "โขย่ง",
  "โขลก",
  "โขลง",
  "โขลน",
  "โขษม",
  "โข่ง",
  "โค",
  "โคก",
  "โคจร",
  "โคตมะ",
  "โคตมี",
  "โคตร",
  "โคตรบอง",
  "โคตรบูรณ์",
  "โคธา",
  "โคน",
  "โคนม",
  "โคบอลต์",
  "โคบาล",
  "โคปผกะ",
  "โคม",
  "โคมลอย",
  "โคมูตร",
  "โคม่า",
  "โครก",
  "โครกคราก",
  "โครง",
  "โครงการ",
  "โครงงาน",
  "โครงสร้าง",
  "โครงเรื่อง",
  "โครนา",
  "โครม",
  "โครมคราม",
  "โครมาโทกราฟี",
  "โคราช",
  "โครำ",
  "โครเมียม",
  "โครเอเชีย",
  "โครโมโซม",
  "โคร่ง",
  "โคร่งคร่าง",
  "โคล",
  "โคลง",
  "โคลงเคลง",
  "โคลน",
  "โคลอมเบีย",
  "โคลัมบัส",
  "โคลัมโบ",
  "โควตา",
  "โควต้า",
  "โคออร์ดิเนต",
  "โคอะล่า",
  "โคอาลา",
  "โคอาล่า",
  "โคเคน",
  "โคเซแคนต์",
  "โคเปนเฮเกน",
  "โคแทนเจนต์",
  "โคโยตี",
  "โคโยตี้",
  "โคโลราโด",
  "โคไซน์",
  "โค่ง",
  "โค่น",
  "โค้ก",
  "โค้ง",
  "โค้ช",
  "โค้ด",
  "โฆรวิส",
  "โฆษก",
  "โฆษณา",
  "โฆษะ",
  "โฆษิต",
  "โง",
  "โงก",
  "โงกเงก",
  "โงง",
  "โงงเงง",
  "โงน",
  "โงนเงน",
  "โงเง",
  "โง่",
  "โง่ง",
  "โง่งเง่ง",
  "โง่เขลา",
  "โง้ง",
  "โจก",
  "โจง",
  "โจงกระเบน",
  "โจงโคร่ง",
  "โจท",
  "โจทก์",
  "โจทนา",
  "โจทย์",
  "โจน",
  "โจม",
  "โจมตี",
  "โจร",
  "โจรกรรม",
  "โจรสลัด",
  "โจล",
  "โจว",
  "โจษ",
  "โจษจัน",
  "โจเซฟ",
  "โจ่งครึ่ม",
  "โจ่งครุ่ม",
  "โจ่งแจ้ง",
  "โจ๊ก",
  "โจ๋",
  "โจ๋งครึ่ม",
  "โจ๋งเจ๋ง",
  "โฉ",
  "โฉงเฉง",
  "โฉด",
  "โฉดเฉา",
  "โฉนด",
  "โฉบ",
  "โฉม",
  "โฉมงาม",
  "โฉมฉาย",
  "โฉมตรู",
  "โฉมยง",
  "โฉมศรี",
  "โฉมหน้า",
  "โฉมเฉลา",
  "โฉลก",
  "โฉเก",
  "โฉเบ๊",
  "โฉ่",
  "โฉ่งฉ่าง",
  "โชก",
  "โชกโชน",
  "โชค",
  "โชงโลง",
  "โชดก",
  "โชดึก",
  "โชต",
  "โชตก",
  "โชติ",
  "โชติก",
  "โชติช่วง",
  "โชติรส",
  "โชน",
  "โชมโรม",
  "โชย",
  "โชยงการ",
  "โชยชาย",
  "โชยติส",
  "โชว์",
  "โชว์รูม",
  "โชห่วย",
  "โซ",
  "โซก",
  "โซงโขดง",
  "โซดา",
  "โซดาไฟ",
  "โซน",
  "โซนาร์",
  "โซนี่",
  "โซฟิเทล",
  "โซม",
  "โซมาเลีย",
  "โซยุซ",
  "โซยูซ",
  "โซรม",
  "โซล",
  "โซลา",
  "โซลาร์",
  "โซลูชัน",
  "โซลูชั่น",
  "โซเชียล",
  "โซเซ",
  "โซเดียม",
  "โซเฟีย",
  "โซเวียต",
  "โซโล",
  "โซโลมอน",
  "โซโล่",
  "โซ่",
  "โซ่ง",
  "โด",
  "โดกเดก",
  "โดด",
  "โดดเดี่ยว",
  "โดน",
  "โดนัท",
  "โดนัลด์",
  "โดม",
  "โดมร",
  "โดมินิกัน",
  "โดมินิกา",
  "โดมิโน",
  "โดย",
  "โดยสาร",
  "โดรณ",
  "โดรายากิ",
  "โดราเอมอน",
  "โดฮา",
  "โดเมน",
  "โดเรมอน",
  "โดเวอร์",
  "โด่",
  "โด่ง",
  "โด่งดัง",
  "โด่เด่",
  "โต",
  "โตก",
  "โตงเตง",
  "โตชิบา",
  "โตฎก",
  "โตน",
  "โตนด",
  "โตมร",
  "โตย",
  "โตรก",
  "โตรอนโต",
  "โตเกียว",
  "โตเบโก",
  "โตโก",
  "โตโยต้า",
  "โต่ง",
  "โต้",
  "โต้ง",
  "โต้ตอบ",
  "โต้เถียง",
  "โต้แย้ง",
  "โต้โผ",
  "โต๊ด",
  "โต๊ะ",
  "โต๊ะจีน",
  "โต๊ะหมู่",
  "โต๊ะอิหม่าม",
  "โต๋",
  "โต๋เต๋",
  "โถ",
  "โถง",
  "โถงเถง",
  "โถบ",
  "โถม",
  "โถมนาการ",
  "โท",
  "โทกเทก",
  "โทง",
  "โทงเทง",
  "โทณะ",
  "โทน",
  "โทนโท่",
  "โทมนัส",
  "โทมัส",
  "โทมาฮอก",
  "โทมาฮอว์ก",
  "โทมาฮอว์ค",
  "โทร",
  "โทรคมนาคม",
  "โทรจัน",
  "โทรทรรศน์",
  "โทรทัศน์",
  "โทรพิมพ์",
  "โทรภาพ",
  "โทรม",
  "โทรศัพท์",
  "โทรสาร",
  "โทรอนโต",
  "โทรเลข",
  "โทรโข่ง",
  "โทษ",
  "โทษา",
  "โทษานุโทษ",
  "โทสะ",
  "โทสาคติ",
  "โทหฬินี",
  "โทโส",
  "โท่",
  "โธวนะ",
  "โธ่",
  "โน",
  "โนติส",
  "โนน",
  "โนมพรรณ",
  "โนรา",
  "โนรี",
  "โนวา",
  "โนเกีย",
  "โนเบล",
  "โนเบเลียม",
  "โนเวลล์",
  "โนโวเทล",
  "โน่น",
  "โน้ต",
  "โน้ตบุค",
  "โน้ตบุ๊ก",
  "โน้ตบุ๊ค",
  "โน้น",
  "โน้ม",
  "โน้มน้าว",
  "โบ",
  "โบก",
  "โบกขรณี",
  "โบกขรพรรษ",
  "โบกี้",
  "โบชุก",
  "โบต",
  "โบตัน",
  "โบตั๋น",
  "โบทอกซ์",
  "โบท็อกซ์",
  "โบนัส",
  "โบย",
  "โบรกเกอร์",
  "โบรชัวร์",
  "โบรมีน",
  "โบรอน",
  "โบราณ",
  "โบราณคดี",
  "โบราณวัตถุ",
  "โบราณสถาน",
  "โบลิเวีย",
  "โบว์",
  "โบว์ลิ่ง",
  "โบสถ์",
  "โบแดง",
  "โบ้",
  "โบ้ย",
  "โบ๊เบ๊",
  "โบ๋",
  "โป",
  "โปก",
  "โปกขรณี",
  "โปกขรพรรษ",
  "โปง",
  "โปงลาง",
  "โปฐบท",
  "โปฐปทมาส",
  "โปดก",
  "โปตถกะ",
  "โปน",
  "โปร",
  "โปรง",
  "โปรด",
  "โปรดปราน",
  "โปรดักชั่น",
  "โปรดิวเซอร์",
  "โปรตอน",
  "โปรตีน",
  "โปรตุเกส",
  "โปรย",
  "โปรยทาน",
  "โปรยปราย",
  "โปรเจกต์",
  "โปรเจกเตอร์",
  "โปรเจคท์",
  "โปรเจสเตอโรน",
  "โปรเจ็กต์",
  "โปรเจ็กเตอร์",
  "โปรเจ็คท์",
  "โปรเซส",
  "โปรเซสเซอร์",
  "โปรเตสแตนต์",
  "โปรเตสแตนท์",
  "โปรแกรม",
  "โปรแกรมเมอร์",
  "โปรแทรกเตอร์",
  "โปรโตคอล",
  "โปรโมชั่น",
  "โปรโมต",
  "โปรโมท",
  "โปรโมเตอร์",
  "โปร่ง",
  "โปร่งแสง",
  "โปร่งใส",
  "โปลิศ",
  "โปลิโอ",
  "โปล่ง",
  "โปสก",
  "โปสต์การ์ด",
  "โปสเตอร์",
  "โปะ",
  "โปเก",
  "โปเกมอน",
  "โปแลนด์",
  "โปโล",
  "โปโลน้ำ",
  "โป่",
  "โป่ง",
  "โป่งข่าม",
  "โป่งวิด",
  "โป้",
  "โป้ง",
  "โป้ปด",
  "โป๊",
  "โป๊ก",
  "โป๊กเกอร์",
  "โป๊ป",
  "โป๊ยก่าย",
  "โป๊ยเซียน",
  "โป๊ะ",
  "โป๊ะจ้าย",
  "โผ",
  "โผง",
  "โผงผาง",
  "โผฏฐัพพะ",
  "โผน",
  "โผย",
  "โผลกเผลก",
  "โผละ",
  "โผล่",
  "โผอน",
  "โผะ",
  "โผเผ",
  "โพ",
  "โพก",
  "โพกพาย",
  "โพง",
  "โพงพาง",
  "โพชฌงค์",
  "โพซิตรอน",
  "โพด",
  "โพทะเล",
  "โพธ",
  "โพธิ",
  "โพธิญาณ",
  "โพธิบัลลังก์",
  "โพธิสมภาร",
  "โพธิสัตว์",
  "โพธิ์",
  "โพน",
  "โพนทะนา",
  "โพบาย",
  "โพย",
  "โพยก๊วน",
  "โพยภัย",
  "โพยม",
  "โพรก",
  "โพรง",
  "โพรมีเทียม",
  "โพระดก",
  "โพรเจสเทอโรน",
  "โพรเซส",
  "โพรเซสเซอร์",
  "โพรโทคอล",
  "โพรโทแอกทิเนียม",
  "โพลง",
  "โพลน",
  "โพลล์",
  "โพละ",
  "โพลารอยด์",
  "โพลาไรซ์",
  "โพลิเมอร์",
  "โพลีเอทิลีน",
  "โพล่",
  "โพล่ง",
  "โพล้ง",
  "โพล้เพล้",
  "โพสต์",
  "โพสพ",
  "โพแทสเซียม",
  "โพ้น",
  "โพ้นทะเล",
  "โฟกัส",
  "โฟน",
  "โฟม",
  "โฟร์โมสต์",
  "โฟลเดอร์",
  "โฟล์ค",
  "โภค",
  "โภคทรัพย์",
  "โภคภัณฑ์",
  "โภคะ",
  "โภคิน",
  "โภคี",
  "โภช",
  "โภชก",
  "โภชนะ",
  "โภชนา",
  "โภชนากร",
  "โภชนาการ",
  "โภชนาหาร",
  "โภชนียะ",
  "โภชย์",
  "โภไคย",
  "โภไคศวรรย์",
  "โม",
  "โมก",
  "โมกข์",
  "โมกษะ",
  "โมคคัลลานะ",
  "โมฆกรรม",
  "โมฆสัญญา",
  "โมฆะ",
  "โมฆียกรรม",
  "โมฆียะ",
  "โมง",
  "โมงครุ่ม",
  "โมจิ",
  "โมซัมบิก",
  "โมดูล",
  "โมทนา",
  "โมนาโก",
  "โมนาโค",
  "โมบาย",
  "โมบายล์",
  "โมรา",
  "โมรี",
  "โมร็อกโก",
  "โมลิบดีนัม",
  "โมลี",
  "โมหจริต",
  "โมหะ",
  "โมหันธ์",
  "โมหาคติ",
  "โมเดล",
  "โมเดิร์น",
  "โมเด็ม",
  "โมเต็ล",
  "โมเนีย",
  "โมเม",
  "โมเมนตัม",
  "โมเมนต์",
  "โมเย",
  "โมเรส",
  "โมเลกุล",
  "โมเสก",
  "โมเสส",
  "โมโตโรลา",
  "โมโตโรล่า",
  "โมโนเรล",
  "โมโนแซ็กคาไรด์",
  "โมโห",
  "โมไนย",
  "โมไบล์",
  "โม่",
  "โม่ง",
  "โม่ห์",
  "โม้",
  "โย",
  "โยก",
  "โยกย้าย",
  "โยกเยก",
  "โยกโคลง",
  "โยกโย้",
  "โยคยะ",
  "โยคะ",
  "โยคาพจร",
  "โยคาวจร",
  "โยคิน",
  "โยคี",
  "โยคเกณฑ์",
  "โยง",
  "โยงโย่",
  "โยชนา",
  "โยชน์",
  "โยถิกะ",
  "โยทะกา",
  "โยธวาทิต",
  "โยธา",
  "โยธิน",
  "โยน",
  "โยนก",
  "โยนกลอง",
  "โยนิโส",
  "โยนี",
  "โยม",
  "โยเกิร์ต",
  "โยเย",
  "โยโกฮามา",
  "โยโย่",
  "โยโส",
  "โย่ง",
  "โย่งเย่ง",
  "โย้",
  "โย้เย้",
  "โร",
  "โรค",
  "โรคจิต",
  "โรคา",
  "โรคาพาธ",
  "โรง",
  "โรงครัว",
  "โรงงาน",
  "โรงทาน",
  "โรงนา",
  "โรงพยาบาล",
  "โรงพัก",
  "โรงพิมพ์",
  "โรงสี",
  "โรงสีข้าว",
  "โรงอาหาร",
  "โรงเจ",
  "โรงเตี๊ยม",
  "โรงเรียน",
  "โรงเรือน",
  "โรงเลี้ยง",
  "โรงเลื่อย",
  "โรงแรม",
  "โรจ",
  "โรจน์",
  "โรดแมป",
  "โรดไอแลนด์",
  "โรตารี",
  "โรตี",
  "โรท",
  "โรธ",
  "โรนัลโด",
  "โรนัลโด้",
  "โรบินสัน",
  "โรม",
  "โรมรัน",
  "โรมัน",
  "โรมาเนีย",
  "โรย",
  "โรยรา",
  "โรล",
  "โรลออน",
  "โรหิณี",
  "โรหิต",
  "โรเดียม",
  "โรเนียว",
  "โรเบิร์ต",
  "โรเมอร์",
  "โรเร",
  "โรแมนติก",
  "โรแมนติค",
  "โร่",
  "โลก",
  "โลกธรรม",
  "โลกธาตุ",
  "โลกนาถ",
  "โลกบาล",
  "โลกย์",
  "โลกวัชชะ",
  "โลกวิทู",
  "โลกัตถจริยา",
  "โลกันตร์",
  "โลกัย",
  "โลกา",
  "โลกาธิบดี",
  "โลกาธิปไตย",
  "โลกานุวัตร",
  "โลกาภิวัตน์",
  "โลกามิส",
  "โลกายัต",
  "โลกาวินาศ",
  "โลกิยะ",
  "โลกียวัตร",
  "โลกียวิสัย",
  "โลกียสุข",
  "โลกีย์",
  "โลกุตรธรรม",
  "โลกุตรภูมิ",
  "โลกุตระ",
  "โลกเชษฐ์",
  "โลง",
  "โลจนะ",
  "โลชั่น",
  "โลซาน",
  "โลซานน์",
  "โลณะ",
  "โลด",
  "โลดลิ่ว",
  "โลดเต้น",
  "โลดแล่น",
  "โลดโผน",
  "โลตัส",
  "โลท",
  "โลน",
  "โลภ",
  "โลม",
  "โลมะ",
  "โลมา",
  "โลมเล้า",
  "โลลุป",
  "โลหกุมภี",
  "โลหะ",
  "โลหัช",
  "โลหิต",
  "โลเล",
  "โลโก้",
  "โลโต",
  "โลโล",
  "โลโล้",
  "โล่",
  "โล่ง",
  "โล่งอก",
  "โล่งโจ้ง",
  "โล่งโต้ง",
  "โล่งโถง",
  "โล่งใจ",
  "โล่ติ๊น",
  "โล้",
  "โล้ง",
  "โล้งโต้ง",
  "โล้น",
  "โว",
  "โวการ",
  "โวทาน",
  "โวย",
  "โวยวาย",
  "โวลต์",
  "โวหาร",
  "โว่",
  "โว่ง",
  "โว้ย",
  "โว้เว้",
  "โศก",
  "โศกนาฏกรรม",
  "โศกศัลย์",
  "โศกสลด",
  "โศกา",
  "โศกาดูร",
  "โศกาลัย",
  "โศกี",
  "โศกเศร้า",
  "โศจิ",
  "โศธนะ",
  "โศภน",
  "โศภะ",
  "โศภา",
  "โศภิต",
  "โศภิน",
  "โศภิษฐ์",
  "โศภี",
  "โศรดา",
  "โศรตร",
  "โศลก",
  "โษฑศัน",
  "โสก",
  "โสกราตีส",
  "โสกันต์",
  "โสกโดก",
  "โสณฑ์",
  "โสณิ",
  "โสณี",
  "โสด",
  "โสดก",
  "โสดม",
  "โสดา",
  "โสดาบัน",
  "โสดาปัตติผล",
  "โสดาปัตติมรรค",
  "โสต",
  "โสตถิ",
  "โสตทัศนวัสดุ",
  "โสตทัศนอุปกรณ์",
  "โสตทัศนูปกรณ์",
  "โสตินทรีย์",
  "โสทก",
  "โสทร",
  "โสธก",
  "โสธนะ",
  "โสน",
  "โสภณ",
  "โสภา",
  "โสภิณี",
  "โสภิต",
  "โสภี",
  "โสม",
  "โสมนัส",
  "โสมม",
  "โสมย์",
  "โสรจ",
  "โสรวาร",
  "โสร่ง",
  "โสวรรณ",
  "โสหุ้ย",
  "โสฬส",
  "โสเภณี",
  "โสโครก",
  "โสโร",
  "โหง",
  "โหงพราย",
  "โหงว",
  "โหงวเฮ้ง",
  "โหด",
  "โหดร้าย",
  "โหดเหี้ยม",
  "โหน",
  "โหนก",
  "โหน่ง",
  "โหม",
  "โหมด",
  "โหม่ง",
  "โหย",
  "โหยกเหยก",
  "โหยง",
  "โหยหวน",
  "โหย่ง",
  "โหร",
  "โหรง",
  "โหรงเหรง",
  "โหรดาจารย์",
  "โหระพา",
  "โหรา",
  "โหราจารย์",
  "โหราศาสตร์",
  "โหล",
  "โหลงโจ้ง",
  "โหลด",
  "โหลน",
  "โหลยโท่ย",
  "โหล่",
  "โหว",
  "โหวกเหวก",
  "โหวง",
  "โหวงเหวง",
  "โหวด",
  "โหวต",
  "โหว่",
  "โหว้",
  "โห่",
  "โอ",
  "โอ",
  "โอก",
  "โอกาศ",
  "โอกาส",
  "โอกินาวา",
  "โอคลาโฮมา",
  "โอค็อตสค์",
  "โอฆชล",
  "โอฆสงสาร",
  "โอฆะ",
  "โองการ",
  "โองโขดง",
  "โอชะ",
  "โอชา",
  "โอซากา",
  "โอฐ",
  "โอด",
  "โอดครวญ",
  "โอดีสซีย์",
  "โอดโอย",
  "โอตตัปปะ",
  "โอทนะ",
  "โอน",
  "โอนอ่อน",
  "โอนเอน",
  "โอบ",
  "โอบอุ้ม",
  "โอบอ้อม",
  "โอบามา",
  "โอปปาติกะ",
  "โอภา",
  "โอภาส",
  "โอม",
  "โอมาน",
  "โอย",
  "โอยัวะ",
  "โอรส",
  "โอรสาธิราช",
  "โอรสาราม",
  "โอริยา",
  "โอริสสา",
  "โอละพ่อ",
  "โอลิมปัส",
  "โอลิมปิก",
  "โอลิมเปีย",
  "โอวัลติน",
  "โอวาท",
  "โอษฐชะ",
  "โอษฐภัย",
  "โอษฐ์",
  "โอสถ",
  "โอสถกรรม",
  "โอหัง",
  "โอห์ม",
  "โอฬาร",
  "โอฬาริก",
  "โอฬารึก",
  "โออิชิ",
  "โอเค",
  "โอเซลทามิเวียร์",
  "โอเดียน",
  "โอเปร่า",
  "โอเปอเรเตอร์",
  "โอเพน",
  "โอเพ่น",
  "โอเมกา",
  "โอเมก้า",
  "โอเรกอน",
  "โอเลี้ยง",
  "โอเวอร์",
  "โอเอซิส",
  "โอโซน",
  "โอไฮโอ",
  "โอ่",
  "โอ่ง",
  "โอ่อวด",
  "โอ่อ่า",
  "โอ่โถง",
  "โอ้",
  "โอ้ก",
  "โอ้กอ้าก",
  "โอ้ย",
  "โอ้อวด",
  "โอ้เอ้",
  "โอ้โถง",
  "โอ้โลม",
  "โอ้โฮ",
  "โอ๊ก",
  "โอ๊ต",
  "โอ๊ย",
  "โอ๋",
  "โฮ",
  "โฮก",
  "โฮกฮาก",
  "โฮกฮือ",
  "โฮจิมินห์",
  "โฮป",
  "โฮปเวลล์",
  "โฮม",
  "โฮลดิงส์",
  "โฮลวีต",
  "โฮลเมียม",
  "โฮสต์",
  "โฮสเตส",
  "โฮะ",
  "โฮเต็ล",
  "โฮเมอร์",
  "โฮ่ง",
  "โฮ้ง",
  "ใกล้",
  "ใคร",
  "ใคร่",
  "ใคร่ครวญ",
  "ใจ",
  "ใจความ",
  "ใจคอ",
  "ใช่",
  "ใช้",
  "ใช้งาน",
  "ใช้สอย",
  "ใด",
  "ใต้",
  "ใต้ถุน",
  "ใน",
  "ในหลวง",
  "ใบ",
  "ใบขับขี่",
  "ใบจอง",
  "ใบตอง",
  "ใบบอก",
  "ใบบุญ",
  "ใบปลิว",
  "ใบพัด",
  "ใบระกา",
  "ใบระกา",
  "ใบรับรอง",
  "ใบลา",
  "ใบสั่ง",
  "ใบสำคัญ",
  "ใบสุทธิ",
  "ใบหน้า",
  "ใบอนุญาต",
  "ใบเบิกทาง",
  "ใบเลี้ยง",
  "ใบเสร็จ",
  "ใบแทรก",
  "ใบโพ",
  "ใบไม้",
  "ใบ้",
  "ใฝ่",
  "ใฝ่ฝัน",
  "ใย",
  "ใยหิน",
  "ใส",
  "ใส่",
  "ใส่ความ",
  "ใส่ใจ",
  "ใส่ไคล้",
  "ใส่ไฟ",
  "ใหญ่",
  "ใหม่",
  "ให้",
  "ให้การ",
  "ให้ท่า",
  "ให้ท้าย",
  "ให้ร้าย",
  "ให้หลัง",
  "ไก",
  "ไกด์",
  "ไกพัล",
  "ไกร",
  "ไกรพ",
  "ไกรลาส",
  "ไกรศร",
  "ไกรศรี",
  "ไกรสร",
  "ไกรสรี",
  "ไกรสิทธิ",
  "ไกล",
  "ไกลาส",
  "ไกล่",
  "ไกล่เกลี่ย",
  "ไกว",
  "ไกวัล",
  "ไก่",
  "ไก่กอม",
  "ไก่ชน",
  "ไก่บ้าน",
  "ไก่ป่า",
  "ไก่ฟ้า",
  "ไก่เขี่ย",
  "ไก๊",
  "ไก๋",
  "ไข",
  "ไขข้อ",
  "ไขควง",
  "ไขมัน",
  "ไขว่",
  "ไขว่ห้าง",
  "ไขว้",
  "ไขสันหลัง",
  "ไขสือ",
  "ไข่",
  "ไข่มุก",
  "ไข่หงส์",
  "ไข่เค็ม",
  "ไข่เยี่ยวม้า",
  "ไข่เหี้ย",
  "ไข้",
  "ไค",
  "ไคฟง",
  "ไครสต์เชิร์ช",
  "ไครสเลอร์",
  "ไคร้",
  "ไคร้เครือ",
  "ไคล",
  "ไคลแมกซ์",
  "ไคลแม็กซ์",
  "ไคล้",
  "ไคโตซาน",
  "ไคโร",
  "ไค้",
  "ไง",
  "ไง้",
  "ไจ",
  "ไจ้",
  "ไฉน",
  "ไฉไล",
  "ไช",
  "ไชนะ",
  "ไชน่า",
  "ไชย",
  "ไชยา",
  "ไชยานุชิต",
  "ไชโย",
  "ไซ",
  "ไซต์",
  "ไซน์",
  "ไซบอร์ก",
  "ไซบีเรีย",
  "ไซปรัส",
  "ไซยาไนด์",
  "ไซร้",
  "ไซเกิล",
  "ไซเบอร์",
  "ไซเรน",
  "ไซโคลน",
  "ไซโล",
  "ไซ่ง่อน",
  "ไซ้",
  "ไญยธรรม",
  "ได",
  "ไดนามิก",
  "ไดนามิกส์",
  "ไดนามิค",
  "ไดนามิคส์",
  "ไดนาโม",
  "ไดนาไมต์",
  "ไดฟุกุ",
  "ไดฟูกุ",
  "ไดรว์",
  "ไดรเวอร์",
  "ไดออกไซด์",
  "ไดเรกตริกซ์",
  "ไดเรกทอรี",
  "ไดเอ็ต",
  "ไดแซ็กคาไรด์",
  "ไดโนเสาร์",
  "ไดโอด",
  "ได้",
  "ได้การ",
  "ได้ที",
  "ได้ยิน",
  "ได้เสีย",
  "ได้แก่",
  "ได้ใจ",
  "ไต",
  "ไตร",
  "ไตรกิศยา",
  "ไตรจักร",
  "ไตรจีวร",
  "ไตรดายุค",
  "ไตรตรึงษ์",
  "ไตรทวาร",
  "ไตรปิฎก",
  "ไตรภพ",
  "ไตรภาคี",
  "ไตรภูมิ",
  "ไตรมาส",
  "ไตรย",
  "ไตรยางศ์",
  "ไตรรงค์",
  "ไตรรัตน์",
  "ไตรลักษณ์",
  "ไตรสรณคมน์",
  "ไตรสิกขา",
  "ไตรเพท",
  "ไตรโลก",
  "ไตร่ตรอง",
  "ไต่",
  "ไต่คู้",
  "ไต่ถาม",
  "ไต่สวน",
  "ไต่เต้า",
  "ไต้",
  "ไต้ก๋ง",
  "ไต้ฝุ่น",
  "ไต้หวัน",
  "ไต้หวัน",
  "ไต๋",
  "ไถ",
  "ไถง",
  "ไถล",
  "ไถ่",
  "ไถ่ถอน",
  "ไถ่ถาม",
  "ไถ้",
  "ไท",
  "ไททัน",
  "ไททานิก",
  "ไททานิค",
  "ไทฟอยด์",
  "ไทม์",
  "ไทย",
  "ไทยดำ",
  "ไทยทาน",
  "ไทยธรรม",
  "ไทยน้อย",
  "ไทยรัฐ",
  "ไทยแลนด์",
  "ไทยใหญ่",
  "ไทร",
  "ไทรบุรี",
  "ไทรอยด์",
  "ไทรโยค",
  "ไทวะ",
  "ไทเทรต",
  "ไทเทเนียม",
  "ไทเป",
  "ไทเฮา",
  "ไท้",
  "ไน",
  "ไนจีเรีย",
  "ไนต์คลับ",
  "ไนท์",
  "ไนน์",
  "ไนลอน",
  "ไนล์",
  "ไนเจอร์",
  "ไนเม็กซ์",
  "ไนโตรเจน",
  "ไนโอเบียม",
  "ไบต์",
  "ไบนารี",
  "ไบเบิล",
  "ไบแซนไทน์",
  "ไบโอ",
  "ไบโอติน",
  "ไบโอเทค",
  "ไบโพลาห์",
  "ไบ่",
  "ไป",
  "ไปยาล",
  "ไปรษณียบัตร",
  "ไปรษณียภัณฑ์",
  "ไปรษณียากร",
  "ไปรษณีย์",
  "ไปล่",
  "ไปศาจ",
  "ไป่",
  "ไป๋",
  "ไผ",
  "ไผท",
  "ไผ่",
  "ไฝ",
  "ไพ",
  "ไพจิตร",
  "ไพชน",
  "ไพชยนต์",
  "ไพฑูรย์",
  "ไพที",
  "ไพธอน",
  "ไพบูลย์",
  "ไพพรรณ",
  "ไพร",
  "ไพรจิตร",
  "ไพรชน",
  "ไพรชยนต์",
  "ไพรฑูรย์",
  "ไพรที",
  "ไพรบูลย์",
  "ไพรวัน",
  "ไพรสณฑ์",
  "ไพรสัณฑ์",
  "ไพรัช",
  "ไพรำ",
  "ไพริน",
  "ไพรินทร์",
  "ไพรี",
  "ไพรเมต",
  "ไพรเราะ",
  "ไพร่",
  "ไพร่พล",
  "ไพร่ฟ้า",
  "ไพร่สม",
  "ไพร่ส่วย",
  "ไพร่หลวง",
  "ไพล",
  "ไพลิน",
  "ไพล่",
  "ไพศาขะ",
  "ไพศาล",
  "ไพสพ",
  "ไพสิฐ",
  "ไพหาร",
  "ไพเราะ",
  "ไพเศษ",
  "ไพโรจน์",
  "ไพโอเนียร์",
  "ไพ่",
  "ไพ่ตาย",
  "ไพ่ป๊อก",
  "ไฟ",
  "ไฟฉาย",
  "ไฟต์",
  "ไฟธาตุ",
  "ไฟฟ้า",
  "ไฟร์ฟอกซ์",
  "ไฟร์วอลล์",
  "ไฟลต์",
  "ไฟลท์",
  "ไฟล์",
  "ไฟเบอร์",
  "ไฟแช็ก",
  "ไฟแนนซ์",
  "ไภริน",
  "ไภรี",
  "ไภษัชคุรุ",
  "ไภษัชย์",
  "ไมกา",
  "ไมครอน",
  "ไมค์",
  "ไมตรี",
  "ไมถิลี",
  "ไมยราบ",
  "ไมยราพณ์",
  "ไมล์",
  "ไมเกรน",
  "ไมเคิล",
  "ไมโคร",
  "ไมโครกรัม",
  "ไมโครซอฟท์",
  "ไมโครนีเซีย",
  "ไมโครฟิล์ม",
  "ไมโครมิเตอร์",
  "ไมโครลิตร",
  "ไมโครเมตร",
  "ไมโครเวฟ",
  "ไมโครโฟน",
  "ไมโตคอนเดรีย",
  "ไมโทคอนเดรีย",
  "ไม่",
  "ไม้",
  "ไม้กลัด",
  "ไม้กวาด",
  "ไม้กางเขน",
  "ไม้ขีดไฟ",
  "ไม้จัตวา",
  "ไม้จิ้มฟัน",
  "ไม้ตรี",
  "ไม้ตาย",
  "ไม้ตีพริก",
  "ไม้ที",
  "ไม้บรรทัด",
  "ไม้ผัด",
  "ไม้มลาย",
  "ไม้ม้วน",
  "ไม้ยมก",
  "ไม้ระแนง",
  "ไม้หน้า",
  "ไม้หมอน",
  "ไม้หันอากาศ",
  "ไม้อัด",
  "ไม้เกาหลัง",
  "ไม้เด็ด",
  "ไม้เท้า",
  "ไม้เมตร",
  "ไม้เรียว",
  "ไม้เอก",
  "ไม้โท",
  "ไม้ไต่คู้",
  "ไย",
  "ไยดี",
  "ไยไพ",
  "ไย่",
  "ไร",
  "ไรย์",
  "ไรเฟิล",
  "ไร่",
  "ไร้",
  "ไลท์",
  "ไลน์",
  "ไลบรารี",
  "ไลบีเรีย",
  "ไลฟ์",
  "ไลย",
  "ไลลา",
  "ไลเซนส์",
  "ไล่",
  "ไล่ที่",
  "ไล่หลัง",
  "ไล่ออก",
  "ไล่เบี้ย",
  "ไล่เลียง",
  "ไล่เลี่ย",
  "ไล่เลี่ย",
  "ไล้",
  "ไว",
  "ไวกิ้ง",
  "ไวกูณฐ์",
  "ไวฑูรย์",
  "ไวตามิลค์",
  "ไวทย์",
  "ไวน์",
  "ไวพจน์",
  "ไวยากรณ์",
  "ไวยาวัจกร",
  "ไวยาวัจมัย",
  "ไวรัส",
  "ไววรรณ",
  "ไวษณพ",
  "ไวอะกร้า",
  "ไวอากร้า",
  "ไวเบรเตอร์",
  "ไวแมกซ์",
  "ไวโอมิง",
  "ไวโอลิน",
  "ไวไฟ",
  "ไว้",
  "ไว้ชื่อ",
  "ไว้ตัว",
  "ไว้ทุกข์",
  "ไว้ลาย",
  "ไว้หน้า",
  "ไว้อาลัย",
  "ไว้ใจ",
  "ไศล",
  "ไศวะ",
  "ไส",
  "ไสย",
  "ไสยศาสตร์",
  "ไสยา",
  "ไสยาสน์",
  "ไสยเวท",
  "ไสร้",
  "ไสว",
  "ไส้",
  "ไส้กรอก",
  "ไส้ตัน",
  "ไส้ติ่ง",
  "ไส้ศึก",
  "ไส้อั่ว",
  "ไส้เดือน",
  "ไส้ไก่",
  "ไห",
  "ไหง",
  "ไหน",
  "ไหม",
  "ไหมพรม",
  "ไหม้",
  "ไหรณย์",
  "ไหร่",
  "ไหล",
  "ไหล่",
  "ไหว",
  "ไหวพริบ",
  "ไหว้",
  "ไหหม่า",
  "ไหหลำ",
  "ไห่",
  "ไห้",
  "ไอ",
  "ไอ",
  "ไอกรน",
  "ไอคอน",
  "ไอซี",
  "ไอซียู",
  "ไอซ์",
  "ไอซ์แลนด์",
  "ไอดอล",
  "ไอดาโฮ",
  "ไอติม",
  "ไอน์สไตน์",
  "ไอน์สไตเนียม",
  "ไอพอด",
  "ไอพ็อด",
  "ไอยรา",
  "ไอยเรศ",
  "ไอราพต",
  "ไอราวัณ",
  "ไอราวัต",
  "ไอริช",
  "ไอร์แลนด์",
  "ไอศกรีม",
  "ไอศวรรย์",
  "ไอศุริยสมบัติ",
  "ไอศูรย์",
  "ไอออน",
  "ไอเดีย",
  "ไอเสีย",
  "ไอแพด",
  "ไอโซโทป",
  "ไอโฟน",
  "ไอโอดีน",
  "ไอโอวา",
  "ไอโอเนียน",
  "ไอ้",
  "ไฮกุ",
  "ไฮดรอกไซด์",
  "ไฮดรอลิก",
  "ไฮดรา",
  "ไฮบริด",
  "ไฮสคูล",
  "ไฮฮี",
  "ไฮเทค",
  "ไฮเปอร์",
  "ไฮเพอร์โบลา",
  "ไฮเวย์",
  "ไฮเอนด์",
  "ไฮแจ็ค",
  "ไฮโกรมิเตอร์",
  "ไฮโซ",
  "ไฮโดร",
  "ไฮโดรคาร์บอน",
  "ไฮโดรมิเตอร์",
  "ไฮโดรลิก",
  "ไฮโดรเจน",
  "ไฮโล",
  "ไฮไฟ",
  "ไฮไลต์",
  "ไฮไลท์",
  "ไฮ้",
  "ใหลตาย"
];

var compoundWords;
var thaiWords;
var wordTree;
var workingArray;
var brokenWords;
// Retrive dictionary from internet or local storage
function buildThaiDictionary() {
  thaiWords = {};
  wordTree = {};
  compoundWords = {};

  readDictionry(dictionaryWords);
}

function readDictionry(words) {
  if(!Array.isArray(words)) {
    words = words.split("\n");
  }

  for(var i in words) {
    var word = words[i];
    if(word.length>0) {
      if(word.search(/,/)>=0) {
        compoundWords = word.split(':');
        word = compoundWords[0];
        compoundWords[word] = compoundWords[1].split(',');
      }
      thaiWords[word] = true;
      generateWordTree(word);
    }
  }
}

function generateWordTree(word) {
  var path = wordTree;
  for(var i in word) {
    var c = word[i];
    if(!path[c]) {
      path[c] = {};
    }
    path = path[c];
  }
}

function queryWordTree(word) {
  var isFound = true;
  var path = wordTree;
  for(var i in word) {
    var c = word[i];
    if(!path[c]) {
      isFound = false;
      break;
    }
    path = path[c];
  }
  return isFound;
}

function tokenize(string) {

  string = filterSymbols(string);
  string = convertLowerCase(string);

  workingArray = string.split(" ");
  var resultArray = [];

  for(var i in workingArray) {
    var string = workingArray[i];
    if(string.search(/[ก-๙]/)>=0) {
      var thaiTokens = breakThaiWords(string);
      for(var j in thaiTokens) {
        string = thaiTokens[j];
        if(string.length>0) {
          resultArray.push(string);
        }
      }
    } else {
      if(string.length>0) {
        resultArray.push(string);
      }
    }
  }
  return resultArray;
}

function convertLowerCase(string) {
  return string.toLowerCase();
}

function filterSymbols(data) {
  data = data.replace(/(\n)/g, '');
  data = data.replace(/[^a-z 0-9 ก-๙]/gi,' ');
  return data;
}


function breakThaiWords(string) {
  var words = [];
  var index = 0;
  var currentWord = '';
  var spareWord = '';
  var badWord = '';
  var nextWordAble = false;
  for(var i in string) {
    var c = string[i];
    var checkWord = currentWord+c;

    if(queryWordTree(checkWord)) {
      currentWord = checkWord;
      if(thaiWords[currentWord]) {
        if(badWord!='') {
          words[index] = badWord.substring(0,badWord.length-1);
          badWord = '';
          index++;
        }

        if(compoundWords[checkWord]) {
          brokenWords = compoundWords[checkWord]
          for(var j in brokenWords) {
            words[index++] = brokenWords[j];
          }
          index--;
        } else {
          words[index] = checkWord;
        }
        spareWord = '';
      } else {
        spareWord += c;
      }
      nextWordAble = true;
    } else {
      if(nextWordAble) {
        nextWordAble = false;
        currentWord = spareWord + c;
        spareWord = c;
        index++;
      } else {
        if(badWord=='') {
          badWord = currentWord + c;
        } else {
          badWord += c;
        }
        currentWord = c;
      }
    }
  }
  if(badWord!='') {
    words[index] = badWord;
  }
  return words;
}


export {buildThaiDictionary, tokenize, readDictionry}