const languages = [
  {
    id: 'th-TH',
    class: 'THTH',
  },
  {
    id: 'en-US',
    class: 'ENUS',
  },
  {
    id: 'jp-JP',
    class: 'JPJP',
  },
  {
    id: 'ar',
    class: 'AR',
  }
];

const categories = [
  {
    id: 'doctors',
    name: 'Doctors ',
    class: 'doctor',
    indexName: 'Doctors',
    sortDesktop: 1
  },
  {
    id: 'centers',
    name: 'Centers ',
    class: 'centers',
    indexName: 'Centers',
    sortDesktop: 3
  },
  {
    id: 'products',
    name: 'Packages ',
    class: 'product',
    indexName: 'Products',
    sortDesktop: 2
  },
  {
    id: 'treatments',
    name: 'Treatments ',
    class: 'treatments',
    indexName: 'Treatments',
    sortDesktop: 5
  },
  {
    id: 'blogs',
    name: 'Health Blogs ',
    class: 'blog',
    indexName: 'Blogs',
    sortDesktop: 4
  },
  {
    id: 'conditions',
    name: 'Conditions ',
    class: 'conditions',
    indexName: 'Conditions',
    sortDesktop: 6
  }
];

const advanceFilters = [
  {
    id: 'doctors',
    indexName: 'Doctors',
    filters: [
      {
        id: 'doctor_type',
        name_en: 'Type of Doctor',
        name_th: 'ประเภทของแพทย์',
        items: [
          {
            id: 'adult',
            name_en: 'Adults Only',
            name_th: 'แพทย์สำหรับผู้ใหญ่',
          },
          {
            id: 'children',
            name_en: 'Children & Adolescents Only',
            name_th: 'แพทย์สำหรับเด็กและวัยรุ่น',
          },
        ],
      },
      {
        id: 'gender',
        name_en: 'Doctor\'s Gender',
        name_th: 'เพศของแพทย์',
        items: [
          {
            id: 'M',
            name_en: 'Male',
            name_th: 'ชาย',
          },
          {
            id: 'F',
            name_en: 'Female',
            name_th: 'หญิง',
          },
        ],
      },
    ],
  },
];

const primaryLanguage = 'th-TH';
const secondaryLanguage = 'en-US';
const localStorageKey = 'AUTOCOMPLETE_RECENT_SEARCHES';
const buyNowUri = 'https://www.bumrungrad.com/shoppingcartloading?skunumber=';
const queryStringCouponCode = 'CouponCode';
const appointmentUri =
  'https://www.bumrungrad.com/book-appointment/Appointment?doctorid=';
const queryCharactor = 2;
const seeAllUri = '/search-result';
const jwtTokenName = 'bumrungrad.com.idtoken';
const insightsCookieKey = 'algolia-insights';
const cookieExpireInDays = 7;
const insightsExpiresCookieKey = 'algolia-insights-expires';
const appId = 'UAZDTJTA24';
const apiKey = '51ecd222c767af3c744022fb3ee77e5b';
const algoliaEventName = {
  Result: {
    clickObjectIDAfterSearch: '{indexName}_Result_Click_Object',
    clickBuynowAfterSearch: {
      Products: '{indexName}_Result_Click_Buynow',
      Doctors: '{indexName}_Result_Click_Bookappointment',
    },
    clickFilter: '{indexName}_Result_Click_Filter',
  },
  Suggestion: {
    clickObjectIDAfterSearch: '{indexName}_Suggestion_Click_Object',
    clickBuynowAfterSearch: {
      Products: '{indexName}_Suggestion_Click_Buynow',
      Doctors: '{indexName}_Suggestion_Click_Bookappointment',
    },
    clickFilter: '{indexName}_Suggestion_Click_Filter',
  },
};
const kontentaiBannerItem = 'https://deliver.kontent.ai/3d9c42d1-db86-00e9-5db3-165ae6aabfa6/items?system.type=suggestion_search_banner';
export {
  languages,
  categories,
  primaryLanguage,
  secondaryLanguage,
  localStorageKey,
  buyNowUri,
  queryCharactor,
  seeAllUri,
  advanceFilters,
  jwtTokenName,
  insightsCookieKey,
  cookieExpireInDays,
  insightsExpiresCookieKey,
  appId,
  apiKey,
  appointmentUri,
  algoliaEventName,
  queryStringCouponCode,
  kontentaiBannerItem
};
