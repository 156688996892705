import $ from 'jquery';
import '@algolia/autocomplete-theme-classic';
import {
  languages,
  categories,
  primaryLanguage,
  secondaryLanguage,
  localStorageKey,
  buyNowUri,
  queryCharactor,
  seeAllUri,
  advanceFilters,
  appointmentUri,
  appId,
  apiKey,
  algoliaEventName,
  queryStringCouponCode,
} from './config';
import {
  superEncodeURI,
  getQueries,
  getItemName,
  addRecent,
  addSuggestion,
  gotoResultPage,
  getArrayFilter,
  addFilter,
  getB2cId,
  bindClickObjectIDAfterSearch,
  replaceHighLightSpecialty,
  getBanner
} from './common';

const { algoliasearch } = window;

const client = algoliasearch(appId, apiKey);

const indexSuggestion = client.initIndex('Pentagon_query_suggestions');
const aafilterLocalStorageKey = 'aasuggestionfilter';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
let queryStringContentType = params.t?.trim() ?? '';
let defaultSearhQuery = params.q ?? '';
let defaultContentType =
  queryStringContentType == ''
    ? []
    : decodeURIComponent(queryStringContentType)?.split(',');
defaultContentType = defaultContentType.filter(x =>
  categories.find(y => y.id == x) ? true : false
);

let defaultSearhFilter = {};
const aafilterLocalStorage = localStorage.getItem(aafilterLocalStorageKey);
defaultSearhFilter = JSON.parse(
  aafilterLocalStorage == null ? '{}' : aafilterLocalStorage
);
if (defaultSearhFilter == null) {
  defaultSearhFilter = {};
}

let currentLanguage = 'th-TH';

languages.forEach(function(language) {
  if ($('body').hasClass(language.class)) {
    currentLanguage = language.id;
  }
});

const aa = require('search-insights');

let queries = [
  {
    indexName: 'Doctors',
    query: defaultSearhQuery,
    params: {
      hitsPerPage: 6,
      clickAnalytics: true,
      filters: '',
      distinct: 1,
      page: 0,
    },
  },
  {
    indexName: 'Centers',
    query: defaultSearhQuery,
    params: {
      hitsPerPage: 3,
      clickAnalytics: true,
      filters: '',
      distinct: 1,
      page: 0,
    },
  },
  {
    indexName: 'Treatments',
    query: defaultSearhQuery,
    params: {
      hitsPerPage: 3,
      clickAnalytics: true,
      filters: '',
      distinct: 1,
      page: 0,
    },

  },
  {
    indexName: 'Products',
    query: defaultSearhQuery,
    params: {
      hitsPerPage: 4,
      clickAnalytics: true,
      filters: '',
      distinct: 1,
      page: 0,
    },
  },
  {
    indexName: 'Blogs',
    query: defaultSearhQuery,
    params: {
      hitsPerPage: 3,
      clickAnalytics: true,
      filters: '',
      distinct: 1,
      page: 0,
    },
  },
  {
    indexName: 'Conditions',
    query: defaultSearhQuery,
    params: {
      hitsPerPage: 3,
      clickAnalytics: true,
      filters: '',
      distinct: 1,
      page: 0,
    },
  }
];

const afterKeyUp = 0;

const body = $('body');

const triggerElement = $('.algoliaTextSearch');
const themeColor = triggerElement.data('theme-color');
const themeSecondaryColor = triggerElement.data('theme-secondary-color');

const aaSearchDivBackdrop = {
  id: 'withBackdrop',
  class: 'modal fade in show d-block',
};
const $aaSearchDivBackdrop = $('<div>', aaSearchDivBackdrop);

const aaSearchDivShadow = {
  id: 'algoliaSearchContainer',
  class: 'searchbar collapse show fade-in-right',
};
const $aaSearchDivShadow = $('<div>', aaSearchDivShadow);

$aaSearchDivBackdrop.append($aaSearchDivShadow);

const aaSearchDivColBack = {
  class: 'col-auto',
};
const $aaSearchDivColBack = $('<div>', aaSearchDivColBack);

const $aaIconBack = $(
  '<svg fill="' +
    themeSecondaryColor +
    '" enable-background="new 0 0 141.732 141.732" height="30px" id="Livello_1" version="1.1" viewBox="0 0 141.732 141.732" width="30px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Livello_29"><path d="M105.614,118.681c3.398,3.396,3.4,8.912,0,12.311c-3.396,3.399-8.91,3.398-12.311,0c-0.02-0.02-0.035-0.04-0.053-0.061   l-0.025,0.022l-57.66-57.66l0.024-0.022c-1.61-1.579-2.608-3.775-2.608-6.208c-0.002-2.73,1.258-5.166,3.229-6.762l-0.06-0.058   l57.66-57.66l0.025,0.024c0.018-0.021,0.033-0.039,0.053-0.058c3.4-3.4,8.912-3.4,12.312,0c3.398,3.396,3.398,8.908,0,12.311   c-0.021,0.02-0.041,0.034-0.061,0.054l0.023,0.024L54.043,67.063l51.54,51.54l-0.025,0.021   C105.573,118.646,105.594,118.66,105.614,118.681"></path></g><g id="Livello_1_1_"></g></svg>'
);

const aaSearchDivBack = {
  class: 'btn-back',
};
const $aaSearchDivBack = $('<a>', aaSearchDivBack);

$aaSearchDivBack.append($aaIconBack);
$aaSearchDivColBack.append($aaSearchDivBack);

const aaSearchDivContainer = {
  class: 'container',
};
const $aaSearchDivContainer = $('<div>', aaSearchDivContainer);

const aaSearchDivRow = {
  class: 'row sticky',
};
const $aaSearchDivRow = $('<div>', aaSearchDivRow);

const aaSearchDivRowFlex = {
  class: 'col',
  css: {
    'padding-left': '0',
  },
};
const $aaSearchDivRowFlex = $('<div>', aaSearchDivRowFlex);

const aaSearchDivButtonWrapper = {
  class: 'aa-Form',
};
const $aaSearchDivButtonWrapper = $('<div>', aaSearchDivButtonWrapper);

const aaSearchInputWrapperPrefix = {
  class: 'aa-InputWrapperPrefix',
};
const $aaSearchInputWrapperPrefix = $('<div>', aaSearchInputWrapperPrefix);

const aaSearchInputWrapper = {
  class: 'aa-InputWrapper',
};
const $aaSearchInputWrapper = $('<div>', aaSearchInputWrapper);

const aaSearchInput = {
  class: 'aa-Input',
};
const $aaSearchInput = $('<input>', aaSearchInput);

const aaSearchInputWrapperPrefixlabel = {
  class: 'aa-Label',
};

const $aaSearchInputWrapperPrefixlabel = $(
  '<label>',
  aaSearchInputWrapperPrefixlabel
);

const aaSearchInputWrapperPrefixButton = {
  class: 'aa-SubmitButton',
};
const $aaSearchInputWrapperPrefixButton = $(
  '<button>',
  aaSearchInputWrapperPrefixButton
);

const $aaSearchInputWrapperSuffix = $(
  '<div class="aa-InputWrapperSuffix"><button class="aa-ClearButton" type="reset" title="Clear"><svg class="aa-ClearIcon" viewBox="0 0 24 24" width="18" height="18" fill="currentColor"><path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path></svg></button></div>'
);

const $aaIconSvgSearch = $(
  '<svg fill="' +
    themeSecondaryColor +
    '" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40px" height="40px"><path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"></path></svg>'
);

const aaSearchDivRowContent = {
  class: 'row',
};

const $aaSearchDivRowContent = $('<div>', aaSearchDivRowContent);

// Recent Search
const aaSearchDivContentRecent = {
  class: 'col-12 col-md-6',
};
const $aaSearchDivContentRecent = $('<div>', aaSearchDivContentRecent);
const $aaSearchDivContentRecentHeader = $(
  '<section><header style="color:' +
    themeColor +
    '"><h3>Recent Search</h3></header></section>'
);

const aaRecentUl = {
  class: 'ul-list',
};

const $aaSearchDivContentRecentUl = $('<ul>', aaRecentUl);

$aaSearchDivContentRecentHeader.append($aaSearchDivContentRecentUl);
$aaSearchDivContentRecent.append($aaSearchDivContentRecentHeader);

// Popular Search
const aaSearchDivContentPopular = {
  class: 'col-12 col-md-6',
};
const $aaSearchDivContentPopular = $('<div>', aaSearchDivContentPopular);

const $aaSearchDivContentPopularHeader = $(
  '<section><header style="color:' +
    themeColor +
    '"><h3>Popular Search</h3></header></section>'
);

const aaPopularUl = {
  class: 'ul-list',
};

const $aaSearchDivContentPopularUl = $('<ul>', aaPopularUl);

$aaSearchDivContentPopularHeader.append($aaSearchDivContentPopularUl);
$aaSearchDivContentPopular.append($aaSearchDivContentPopularHeader);

//Banner
const aaSearchDivContentBanner = {
  class: 'col-12 col-md-12',
};

const $aaSearchDivContentBanner = $('<div>', aaSearchDivContentBanner);
var $aaSearchDivContentBannerHeader = '';
getBanner(currentLanguage).then((res=> {
  if(res != null && res.items.length > 0){
    let item = res.items[0].elements;
    $aaSearchDivContentBannerHeader = $(
      `<section><a href="${item.banner_link_url.value}"><img  src="${item.banner_image.value[0].url}"  alt="${item.banner_image.value[0].description?item.banner_image.value[0].description:item.campaign_name.value}"  style="width:100%;padding-bottom:25px;"></a></section>`
    );
    $aaSearchDivContentBanner.append($aaSearchDivContentBannerHeader);
  }
 
  
}));



//Search result
const aaSearchDivContentSectionDoctor = {
  class: 'col-12 d-flex',
  css: {
    'flex-wrap': 'wrap',
  }
};

const $aaSearchDivContentSectionDoctor = $(
  '<div>',
  aaSearchDivContentSectionDoctor
);

const $aaSearchDivContentSectionDoctorHeader = $(
  '<section class="pt-3"><header style="color:' +
    themeColor +
    '"><h3>Doctor</h3></header></section>'
);

const aaSectionDoctorUl = {
  class: 'ul-list',
};

const $aaSearchDivContentSectionDoctorUl = $('<ul>', aaSectionDoctorUl);

$aaSearchDivContentSectionDoctorHeader.append(
  $aaSearchDivContentSectionDoctorUl
);
$aaSearchDivContentSectionDoctor.append($aaSearchDivContentSectionDoctorHeader);

$aaSearchInputWrapper.append($aaSearchInput);
$aaSearchInputWrapperPrefixButton.append($aaIconSvgSearch);
$aaSearchInputWrapperPrefixlabel.append($aaSearchInputWrapperPrefixButton);
$aaSearchInputWrapperPrefix.append($aaSearchInputWrapperPrefixlabel);

$aaSearchDivButtonWrapper.append($aaSearchInputWrapperPrefix);
$aaSearchDivButtonWrapper.append($aaSearchInputWrapper);
$aaSearchDivButtonWrapper.append($aaSearchInputWrapperSuffix);

$aaSearchDivRowFlex.append($aaSearchDivButtonWrapper);
$aaSearchDivRow.append($aaSearchDivColBack);
$aaSearchDivRow.append($aaSearchDivRowFlex);

$aaSearchDivContainer.append($aaSearchDivRow);
$aaSearchDivContainer.append($aaSearchDivRowContent);

$aaSearchDivShadow.append($aaSearchDivContainer);
$aaSearchDivBackdrop.append($aaSearchDivShadow);

$aaSearchInputWrapperPrefixButton.click(function() {
  $aaSearchInput.trigger('enterKey');
});

$aaSearchInput.keyup(function(e) {
  if (e.keyCode == 13) {
    $(this).trigger('enterKey');
  }
});

$aaSearchInput.bind('enterKey', function(e) {
  var query = $(this)
    .val()
    .trim();

  if (query.length > 0) {
    gotoResultPage(query);
  }
});

triggerElement.click(function() {
  if ($('body').find('div#withBackdrop').length > 0) {
    $aaSearchDivBackdrop.addClass('show');
    $aaSearchDivBackdrop.addClass('d-block');
  } else {
    body.append($aaSearchDivBackdrop);
  }

  $aaSearchInput.trigger('focus');
  $aaSearchInput.val('');
  $aaSearchDivContentSectionDoctor.html('');
  $('body').addClass('modal-open');
  defaultSearch();
});
function delay(callback, ms) {
  var timer = 0;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      callback.apply(context, args);
    }, ms || 0);
  };
}
$aaSearchInput.keyup(
  delay(function() {
    typoKeyUp($(this));
  }, afterKeyUp)
);

function typoKeyUp(object) {
  let searchText = object.val().trim();
  if (searchText != '' && searchText.length >= queryCharactor) {
    search(searchText);
    $aaSearchDivContentBanner.html('');
    $aaSearchDivContentRecent.html('');
    $aaSearchDivContentPopular.html('');
    $aaSearchDivRowContent.append($aaSearchDivContentSectionDoctor);
  } else if (searchText == '') {
    $aaSearchDivContentSectionDoctorUl.children('li').remove();
    defaultSearch();
  }
}
function search(query) {
  var objectIDs = [];

  searchBytype(query, objectIDs);
}
function searchBytype(query, objectIDs) {
  var countSection = 0;
  client
    .multipleQueries(
      getQueries(
        query,
        secondaryLanguage,
        primaryLanguage,
        currentLanguage,
        languages,
        queries,
        defaultSearhFilter,
        null,
        0,
        null
      )
    )
    .then(({ results }) => {
      var categoriesObject = {};
      var $aaSearchDivContentSectionDoctorUl = {};
      var $aaSearchDivContentSectionDoctorHeader = {};

      results.forEach(function({
        hits,
        page,
        nbPages,
        nbHits,
        queryID,
        index,
      }) {
        categoriesObject[index] = {};
        categoriesObject[index]['page'] = page;
        categoriesObject[index]['nbPages'] = nbPages;
        categoriesObject[index]['queryID'] = queryID;
        categoriesObject[index]['nbHits'] = nbHits;

        $aaSearchDivContentSectionDoctorHeader[index] = $(
          '<section class="pt-3 col-12 col-md-6 aaSectionResult ' +'order-md-'+ categories.find(x => x.indexName == index).sortDesktop + ' ' +
            index +
            '"><header style="color:' +
            themeColor +
            '"><h3>' +
            categories.find(x => x.indexName == index).name +
            '(' +
            nbHits +
            ')</h3></header></section>'
        );

        const aaSectionDoctorUl = {
          class: checkShowSlide(index, hits)
            ? 'ul-list splide__list'
            : 'ul-list',
        };

        addFilter(
          algoliaEventName.Suggestion.clickFilter,
          $aaSearchDivContentSectionDoctorHeader[index],
          index,
          defaultSearhFilter,
          themeSecondaryColor,
          typoKeyUp,
          $aaSearchInput,
          aafilterLocalStorageKey
        );

        if (checkShowSlide(index, hits)) {
          var track = {
            class: 'splide__track',
          };
          var $x = $('<div>', track);
          var sectionSlide = {
            class:
              'splidesuggestion ' +
              index +
              (index === categories.find(x => x.id == 'doctors').indexName &&
              hits.length > 0
                ? ' splide'
                : ''),
          };
          var $sectionSlide = $('<div>', sectionSlide);
        }

        $aaSearchDivContentSectionDoctorUl[index] = $(
          '<ul>',
          aaSectionDoctorUl
        );

        //check for add slide
        if (checkShowSlide(index, hits)) {
          $aaSearchDivContentSectionDoctorHeader[index].append($sectionSlide);
          $sectionSlide.append($x);
          $x.append($aaSearchDivContentSectionDoctorUl[index]);
        } else {
          $aaSearchDivContentSectionDoctorHeader[index].append(
            $aaSearchDivContentSectionDoctorUl[index]
          );
        }
        if (hits.length > 0) {
          hits.forEach(function(hit, i) {
            if (i == 0) {
            }

            let resultItem;
            switch (index) {
              case categories.find(x => x.id == 'doctors').indexName:
                resultItem = getDoctorResultItem(
                  hit,
                  categories.find(x => x.id == 'doctors').class,
                  queryID,
                  index
                );
                break;
              case categories.find(x => x.id == 'blogs').indexName:
                resultItem = getBlogResultItem(
                  hit,
                  categories.find(x => x.id == 'blogs').class,
                  queryID,
                  index
                );
                break;
              case categories.find(x => x.id == 'products').indexName:
                resultItem = getPackageResultItem(
                  hit,
                  categories.find(x => x.id == 'products').class,
                  queryID,
                  index
                );
                break;
              default:
                resultItem = getResultItem(
                  hit,
                  categories.find(x => x.indexName == index).class,
                  queryID,
                  index
                );
            }

            $aaSearchDivContentSectionDoctorUl[index].append(resultItem);
            objectIDs.push(hit.objectID);
          });
        }
      });

      $aaSearchDivContentSectionDoctor.removeClass('d-none');
      $aaSearchDivContentSectionDoctor.html('');
      $aaSearchDivRowContent.append($aaSearchDivContentSectionDoctor);

      categories.forEach(function(item) {
        
        if (
          categoriesObject.hasOwnProperty(item.indexName) &&
          $aaSearchDivContentSectionDoctorUl[item.indexName] &&
          $aaSearchDivContentSectionDoctorUl[item.indexName]?.length > 0 &&
          $aaSearchDivContentSectionDoctorUl[item.indexName].children().length >
            0
        ) {
          const $li = $(
            '<li class="li-aaSearchResult text-left col-12 ' +
              item.indexName +
              '-aa li-seeAll">'
          );
          const filterArray = getArrayFilter(defaultSearhFilter);
          if(categoriesObject[item.indexName]['nbHits'] > queries.find(x=>x.indexName==categories.find(x => x.indexName == item.indexName).indexName).params.hitsPerPage){
            $aaSearchDivContentSectionDoctorUl[item.indexName].append(
              $li.append(
                $(
                  '<hr><a class="aaMoreResult btn btn-sm btn-link" style="text-decoration: underline;color:' +
                    themeColor +
                    '"   href="' +
                    seeAllUri +
                    '?q=' +
                    encodeURIComponent(query) +
                    '&t=' +
                    categories.find(x => x.indexName == item.indexName).id +
                    (defaultSearhFilter != undefined
                      ? '&f=' +
                        encodeURIComponent(
                          filterArray
                            .filter(x => Object.keys(x).length > 0)
                            .join('&')
                        )
                      : '') +
                    '" data-content-type="' +
                    item.indexName +
                    '" data-query-id="' +
                    categoriesObject[item.indexName].queryID +
                    '" data-index-name="' +
                    item.indexName +
                    '" data-page="' +
                    categoriesObject[item.indexName].page +
                    '" data-nbpages="' +
                    categoriesObject[item.indexName].nbPages +
                    '" style="color:' +
                    themeColor +
                    ';">See all</button>'
                )
              )
            );
          }
          

          $aaSearchDivContentSectionDoctor.append(
            $aaSearchDivContentSectionDoctorHeader[item.indexName]
          );
          countSection++;
        } else {
          const aaSectionDoctorUl = {
            class: 'ul-list',
          };

          let clearFilter = '';

          if (advanceFilters.find(x => x.indexName == item.indexName) != null) {
            clearFilter =
              ', try to remove some filters or <a class="aaclear-filter aaclear-filter-' +
              item.id +
              '" data-filter="' +
              item.id +
              '">clear filter</a>';
          }

          const $li = $('<li class="li-aaSearchResult">');
          $aaSearchDivContentSectionDoctorUl[item.indexName] = $(
            '<ul>',
            aaSectionDoctorUl
          );
          $aaSearchDivContentSectionDoctorHeader[item.indexName].append(
            $aaSearchDivContentSectionDoctorUl[item.indexName]
          );
          $aaSearchDivContentSectionDoctorUl[item.indexName].append(
            $li.append(
              $(
                '<a style="color:' +
                  themeSecondaryColor +
                  '"><div class="aaItemNoResult">No results found' +
                  clearFilter +
                  '</div></a>'
              )
            )
          );
        }
      });


    
      categories.forEach(function(item) {
        $aaSearchDivContentSectionDoctor.append( $aaSearchDivContentSectionDoctorHeader[item.indexName]);
      });
    })
    .then(() => {
      $('.aaMoreResult').click(function() {
        const objMoreResult = $(this);
        const contentType = objMoreResult.data('content-type');
      });
      if($('.splidesuggestion.Doctors .aaMoreResult').length >0){
        $('.aaSectionResult.Doctors').append(
          '<hr>',
          $('.splidesuggestion.Doctors .aaMoreResult')
        );
      }
      
      $('.li-aaSearchResult a').click(function() {
        addRecent(query, localStorageKey);
      });

      $('#withBackdrop .aaItemNoResult .aaclear-filter').click(function() {
        if (defaultSearhFilter.hasOwnProperty($(this).data('filter'))) {
          Object.keys(defaultSearhFilter[$(this).data('filter')]).forEach(
            item => {
              $('#withBackdrop input[type=checkbox][name=aa' + item + ']').attr(
                'checked',
                false
              );
              defaultSearhFilter[$(this).data('filter')][item] = [];
            }
          );
          typoKeyUp($aaSearchInput);
        }
      });

      bindClickObjectIDAfterSearch(
        'section.aaSectionResult',
        '#withBackdrop #algoliaSearchContainer .li-aaSearchResult  a:not(.aaMoreResult):not(.convertion)',
        algoliaEventName.Suggestion.clickObjectIDAfterSearch
      );
      queries.forEach(item => {
        bindClickObjectIDAfterSearch(
          'section.aaSectionResult',
          `#withBackdrop #algoliaSearchContainer .li-aaSearchResult.${item.indexName}  a.convertion:not(.aaMoreResult)`,
          algoliaEventName.Suggestion.clickBuynowAfterSearch[item.indexName]
        );
      });

      if (countSection === 0) {
        $aaSearchDivRowContent.append($aaSearchDivContentSectionDoctor);
        addSuggestion(
          indexSuggestion,
          $aaSearchDivContentSectionDoctor,
          $aaSearchDivContentSectionDoctor,
          themeColor,
          themeSecondaryColor
        );
      }
    })
    .then(() => {
      if ($('.splidesuggestion.Doctors .li-aaSearchResult').length > 0) {
        new Splide('.splidesuggestion.Doctors', {
          arrowPath:
            'm15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z',
          perPage: 2,
          breakpoints: {
            768: {
              perPage: 1,
            },
          },
        }).mount();
      }
    });
}
function checkShowSlide(index, hits) {
  return (
    index === categories.find(x => x.id == 'doctors').indexName &&
    hits.length > 0
  );
}

function getDoctorResultItem(hit, className, query_id, index) {
  const $li = $(
    `<li class="li-aaSearchResult col-12 col-lg-6 ${className}-aa ${index} splide__slide">`
  );

  let stringHtml = '<div class="splide__slide__container">';
  stringHtml +=
    '<a href="' +
    hit.url_slug +
    '" style="padding-left:unset" target="_blank"  data-query-id="' +
    query_id +
    '" data-index-name="' +
    index +
    '"  data-object-id="' +
    hit.objectID +
    '" target="_blank">';
  stringHtml += '<div class="aaResult">';
  stringHtml +=
    '<div class="aaResult-thumbnail" style="background-image:url(' +
    superEncodeURI(hit.thumbnail) +
    ');"  ></div><br/>';
  stringHtml += '<p class="doctor-name-aa">' + getItemName(hit) + '</p>';
  stringHtml +=
    '<hr/><p class="doctor-specialty-aa-title" " style="color:' +
    themeColor +
    ';" >Specialties</p><p class="doctor-specialty-aa">' +
    hit.specialties
      ?.map((item, key) => {
        return replaceHighLightSpecialty(
          hit,
          key,
          item.includes(',') ? item.split(',')[1] : item
        );
      })
      .join('<br/>') +
    '</p>';
  stringHtml +=
    '<a class="btn btn-sm convertion"  href="' +
    appointmentUri +
    hit.mdcode +
    '"  target="_blank" style="background-color:' +
    themeColor +
    ';color:white"  data-query-id="' +
    query_id +
    '" data-index-name="' +
    index +
    '"  data-object-id="' +
    hit.objectID +
    '" target="_blank">Book appointment</a>';
  stringHtml += '</div>';
  stringHtml += '</a></div>';
  $li.append($(stringHtml));

  return $li;
}
function getResultItem(hit, className, query_id, index) {
  const $li = $(
    `<li class="li-aaSearchResult row  ${className}-aa ${index}"  style="color:${themeSecondaryColor};">`
  );
  let thumbnail = '';
  if (hit.thumbnail != null && hit.thumbnail != '') {
    thumbnail =
      '<div class="aaResult-thumbnail" style="background-image:url(' +
      hit.thumbnail +
      ')" ></div>';
  }

  $li.append(
    $(
      `<div class=" col-6 col-lg-5 d-flex"><a class="d-flex" href="${
        hit.url_slug
      }" target="_blank" data-query-id="${query_id}" data-index-name="${index}" data-object-id="${
        hit.objectID
      }" style="color: ${themeSecondaryColor}">${thumbnail}</a></div><div class=" col-6 col-lg-7 content-title"><a href="${
        hit.url_slug
      }" target="_blank" data-query-id="${query_id}" data-index-name="${index}" data-object-id="${
        hit.objectID
      }" style="color: ${themeSecondaryColor}">${getItemName(
        hit,
        120
      )}</a><br/><a href="${
        hit.url_slug
      }" target="_blank" class="aaLink"   data-query-id="${query_id}" data-object-id="${
        hit.objectID
      }" data-index-name="${index}">Read more</a></div>`
    )
  );

  return $li;
}

function getPackageResultItem(hit, className, query_id, index) {
  const $li = $(
    `<li class="li-aaSearchResult row  ${className}-aa ${index}"  style="color:${themeSecondaryColor};">`
  );
  let thumbnail = '';
  if (hit.thumbnail != null && hit.thumbnail != '') {
    thumbnail =
      '<div class="aaResult-thumbnail" style="background-image:url(' +
      hit.thumbnail +
      ')" ></div>';
  }

  if (hit.price != undefined && hit.price != '') {
    let trackingID = hit.hasOwnProperty('sku_number') && hit.sku_number?hit.sku_number:"";
    $li.append(
      $(
        `<div class=" col-6 col-lg-5 d-flex"><a class=" d-flex" href="${
          hit.url_slug
        }" target="_blank" data-query-id="${query_id}" data-index-name="${index}" data-object-id="${
          hit.objectID
        }" data-tracking-id="${
          trackingID
        }" style="color: ${themeSecondaryColor}">${thumbnail}</a></div><div class=" col-6 col-lg-7"><a href="${
          hit.url_slug
        }" target="_blank" data-query-id="${query_id}" data-index-name="${index}" data-object-id="${
          hit.objectID
        }" data-tracking-id="${
          trackingID
        }" style="color: ${themeSecondaryColor}">${getItemName(
          hit,
          120
        )}</a><br/><p class="text-left aaPrice pt-2  pt-lg-3 pr-2" style="color: ${themeColor};" >${hit.price?.toLocaleString(
          'en-US',
          { currency: 'THB' }
        )} THB</p><a href="${
          hit.sku_number === undefined || hit.sku_number === ''
            ? hit.url_slug
            : `${buyNowUri}${hit.sku_number}${
                hit.hasOwnProperty('coupon_code') && hit.coupon_code
                  ? `&${queryStringCouponCode}=${hit.coupon_code}`
                  : ''
              }`
        }" target="_blank" class="${
          hit.sku_number === undefined || hit.sku_number === ''
            ? 'aaLink'
            : 'btn btn-sm buybow convertion'
        }" ${
          hit.sku_number === undefined || hit.sku_number === ''
            ? ''
            : `style="background-color:${themeColor};color:white;`
        }"  data-query-id="${query_id}" data-object-id="${
          hit.objectID
        }" data-tracking-id="${
          trackingID
        }" data-index-name="${index}">${
          hit.sku_number === undefined || hit.sku_number === ''
            ? 'More Info'
            : 'Buy Now'
        }</a></div>`
      )
    );
  }

  return $li;
}

function getBlogResultItem(hit, className, query_id, index) {
  const $li = $(
    '<li class="li-aaSearchResult row  ' +
      hit.content_type +
      '-aa"  style="color:' +
      themeSecondaryColor +
      ';">'
  );
  let thumbnail = '';
  if (hit.thumbnail != null && hit.thumbnail != '') {
    thumbnail = `<div class="aaResult-thumbnail" style="background-image:url(${hit.thumbnail});"></div>`;
  }

  $li.append(
    $(
      `<div class=" col-6 col-lg-5 d-flex"><a class="d-flex" href="${
        hit.url_slug
      }" target="_blank" data-query-id="${query_id}" data-index-name="${index}" data-object-id="${
        hit.objectID
      }" style="color: ${themeSecondaryColor}">${thumbnail}</a></div><div class=" col-6 col-lg-7 content-title"><a href="${

        hit.url_slug
      }" target="_blank" data-query-id="${query_id}" data-index-name="${index}" data-object-id="${
        hit.objectID
      }" style="color: ${themeSecondaryColor}">${getItemName(
        hit,
        120
      )}</a><br/><a href="${
        hit.url_slug
      }" target="_blank" class="aaLink"   data-query-id="${query_id}" data-object-id="${
        hit.objectID
      }" data-index-name="${index}">Read more</a></div>`
    )
  );

  return $li;
}

function defaultSearch() {
  defaultBanner();
  defaultRecent();
  defaultPopular();
  $aaSearchDivContentSectionDoctor.html('');
  $aaSearchDivContentSectionDoctor.addClass('d-none');
}

function defaultBanner() {
  $aaSearchDivContentBanner.append($aaSearchDivContentBannerHeader);
  $aaSearchDivRowContent.append($aaSearchDivContentBanner);
}

function defaultPopular() {
  $aaSearchDivContentPopularHeader.append($aaSearchDivContentPopularUl);
  $aaSearchDivContentPopular.append($aaSearchDivContentPopularHeader);
  $aaSearchDivRowContent.append($aaSearchDivContentPopular);
  $aaSearchDivContentPopularUl.html('');

  indexSuggestion
    .search('', {
      hitsPerPage: 5,
      facets: 'content_type',
    })
    .then(({ hits }) => {
      hits.forEach(function(hit) {
        const $li = $('<li class="li-aaSearchPopular">');
        $aaSearchDivContentPopularUl.append(
          $li.append(
            $(
              '<a style="color:' +
                themeSecondaryColor +
                '"><span>' +
                hit.query +
                '</span></a>'
            )
          )
        );
      });
    })
    .then(() => {
      $('li.li-aaSearchPopular').bind('click', function(e) {
        const query = $(this)
          .children('a')
          .children('span')
          .html();
        $aaSearchInput.val(query);
        addRecent(query, localStorageKey);
        search(query);
        $aaSearchDivContentRecent.html('');
        $aaSearchDivContentPopular.html('');
        $aaSearchDivRowContent.append($aaSearchDivContentSectionDoctor);
      });
    });
}

function defaultRecent() {
  let maps = JSON.parse(localStorage.getItem(localStorageKey) || '[]');
  if (maps.length > 0) {
    $aaSearchDivContentRecentHeader.append($aaSearchDivContentRecentUl);
    $aaSearchDivContentRecent.append($aaSearchDivContentRecentHeader);
    $aaSearchDivRowContent.append($aaSearchDivContentRecent);
    $aaSearchDivContentRecentUl.html('');
    maps.forEach(function(query) {
      const $li = $('<li class="li-aaSearchRecent">');

      $aaSearchDivContentRecentUl.append(
        $li.append(
          $(
            '<a style="color:' +
              themeSecondaryColor +
              '"><span>' +
              decodeURIComponent(query.id).replace(
                /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
                ''
              ) +
              '</span></a>'
          )
        )
      );
    });
    $aaSearchDivRowContent.append($aaSearchDivContentRecent);
  }
  $('li.li-aaSearchRecent').bind('click', function(e) {
    const query = $(this)
      .children('a')
      .children('span')
      .html();
    $aaSearchInput.val(query);
    addRecent(query, localStorageKey);
    search(query);
    $aaSearchDivContentBanner.html('');
    $aaSearchDivContentRecent.html('');
    $aaSearchDivContentPopular.html('');
    $aaSearchDivRowContent.append($aaSearchDivContentSectionDoctor);
  });
}

$aaSearchDivBack.click(function() {
  $aaSearchDivBackdrop.removeClass('show');
  $aaSearchDivBackdrop.removeClass('d-block');
  $('body').removeClass('modal-open');
});

$aaSearchInputWrapperSuffix.click(function() {
  $aaSearchInput.val('');
  $aaSearchDivContentSectionDoctorUl.children('li').remove();
  defaultSearch();
  $aaSearchInput.trigger('focus');
});

function ViewedObjectIDs(indexName, resultObjectIDs) {
  aa('viewedObjectIDs', {
    index: 'indexName',
    eventName: 'Search_All',
    objectIDs: resultObjectIDs,
  });
}

$(
  '<style>#algoliaSearchContainer ul li a em {color: ' +
    themeColor +
    ';}</style>'
).appendTo('head');
$(
  '<style>#algoliaSearchContainer .li-aaSearchResult.doctor-aa .aaResult p {color: ' +
    themeSecondaryColor +
    ';}</style>'
).appendTo('head');
