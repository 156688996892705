import { buildThaiDictionary, tokenize} from './assets/js/tokenizer';
import 'regenerator-runtime/runtime'
import { languages, categories, seeAllUri, localStorageKey, advanceFilters, insightsCookieKey, cookieExpireInDays, insightsExpiresCookieKey, jwtTokenName, appId, apiKey, kontentaiBannerItem} from './config';
const aa = require('search-insights');
buildThaiDictionary();

aa('init', {
  appId: appId,
  apiKey: apiKey,
  useCookie: true,  // since v2, this is false by default
  cookieDuration: (cookieExpireInDays*24*60*60*1000) // one hour, in milliseconds (default: 15552000000)
});



function superEncodeURI(url) {

    var encodedStr = '', encodeChars = ["(", ")"];
    url = encodeURI(url);
  
    for(var i = 0, len = url.length; i < len; i++) {
      if (encodeChars.indexOf(url[i]) >= 0) {
          var hex = parseInt(url.charCodeAt(i)).toString(16);
          encodedStr += '%' + hex;
      }
      else {
          encodedStr += url[i];
      }
    }
  
    return encodedStr;
  }

  function getQueries(query, secondaryLanguage, primaryLanguage, currentLanguage, languages, queries, defaultSearhFilter, type = null, page = 0, defaultContentType = null) {    
    var filters = '(language:'+secondaryLanguage+'<score=2> OR language:'+primaryLanguage+'<score=1>)';
    languages.forEach(function (language) {

      if($('body').hasClass(language.class)){
        currentLanguage = language.id;
      }
    });

    if(currentLanguage != languages.find(x=>x.id==secondaryLanguage).id){
      filters = '(language:'+currentLanguage+'<score=2> OR language:'+secondaryLanguage+'<score=1>)';
    }
    query = cutWord(query);
    queries.forEach(function (item) {
      item.query = query;
      item.params.filters = filters + getCustomFilter(item, defaultSearhFilter);
      item.params.page = page;
      item.params.clickAnalytics = ((defaultContentType != null && defaultContentType?.length > 0)?(defaultContentType.indexOf(item.indexName.toLowerCase()) > -1):true);
    });
    
    if(type != null) {
      return queries.filter(x=>x.indexName===type);
    }else{
      return queries;
    }
    
  }

  function getCustomFilter(item, defaultSearhFilter) {
    const indexObject = advanceFilters.find(x=>x.indexName == item.indexName);
    let resultString = '';
    if(indexObject != null){
      
      if(defaultSearhFilter.hasOwnProperty(indexObject.id)) {
        const filters = defaultSearhFilter[indexObject.id];
        Object.keys(filters).forEach(filter => {
          if(filters[filter] != null && filters[filter].length>0 && filters[filter].length != indexObject.filters.length){
            if(filters[filter][0] !== undefined && filters[filter][0] != null && filters[filter][0] != ''){
              switch(filter) {
                case "gender" :
                    resultString += ' AND gender:'+filters[filter][0];
                  break;
                case "doctor_type" :
                  resultString += ' AND '+filters[filter][0]+':Y';
                  break;
              }
            }
           
          }
          
        });

      }
    }

    return resultString;
  }

  function cutWord(query) {
    if(checkIsThaiWord(query)){
      return  tokenize(query).join(' ');
    }
    return query;
  }
  function checkIsThaiWord(paragraph){
    const regex = /[\u0E00-\u0E7F]/;
    const found = paragraph.match(regex);
    return found !== null
  }
  function replaceHighLight(hit, name) {
    if(hit._highlightResult.hasOwnProperty('name_nlp'))
    {
      hit._highlightResult?.name_nlp?.matchedWords.forEach(function(word){
        function upperToHyphenLower(match, offset, string) {
          return  "<em>"+match+"</em>";
        }
        name = name.replace("<em>"+word+"</em>/g",word);
        name = name.replace(new RegExp(word,'ig'),upperToHyphenLower);
      });
    }else{
      hit._highlightResult?.name?.matchedWords.forEach(function(word){
        function upperToHyphenLower(match, offset, string) {
          return  "<em>"+match+"</em>";
        }
        name = name.replace("<em>"+word+"</em>/g",word);
        name = name.replace(new RegExp(word,'ig'),upperToHyphenLower);
      });
    }
    
    return name;
    
  }

  function replaceHighLightSpecialty(hit,key, name) {
    hit._highlightResult?.specialties_nlp[key]?.matchedWords?.forEach(function(word){
      function upperToHyphenLower(match, offset, string) {
        return  "<em>"+match+"</em>";
      }
      name = name.replace("<em>"+word+"</em>/g",word);
      name = name.replace(new RegExp(word,'ig'),upperToHyphenLower);
    });
    
    return name;
    
  }

  function getItemName(hit, limitNameCharactorLength = 9999) {
    let name = '';
    var textis="";
    textis=hit.name.substring(0, limitNameCharactorLength);
    if(hit.name.length > limitNameCharactorLength){
      textis = textis + ' ...';
    }
    name = replaceHighLight(hit, textis);
    
    return name;
  }

  function getArrayFilter(defaultSearhFilter){
    return Object.keys(defaultSearhFilter).map(key =>  Object.keys(defaultSearhFilter[key]).map(key2=> (defaultSearhFilter[key][key2].length>0)? key+ '['+key2+ ']' + '=' + defaultSearhFilter[key][key2]:null).join('&')) 
  }
  function replaceSwitchLanguageUrl(query, defaultContentType, defaultSearhFilter, $languageElementForReplace, isReplaceUrl = false){

    const filterArray = getArrayFilter(defaultSearhFilter);

    const url = '?q='+encodeURIComponent(query)+(defaultContentType != ''?'&t='+encodeURIComponent(defaultContentType):'')+ (defaultSearhFilter != undefined ?'&f='+encodeURIComponent(filterArray.filter(x=>Object.keys(x).length>0).join('&')):'');
    if(isReplaceUrl) {
      window.history.pushState('','',url);
    }
    
    if(typeof $languageElementForReplace != undefined) {
      $.each( $languageElementForReplace, function(key, value){
        const href = $(value).attr('href');
        const qPosition = href?.indexOf('?');
        if(qPosition>=0){
          $(value).attr('href', href.substring(0,qPosition)+url);
        }else{
          $(value).attr('href', href+url);
        }
      });
    }
  
  }

  function addRecent(searchText, localStorageKey){
    searchText = encodeURIComponent(searchText);
    let maps = JSON.parse(localStorage.getItem(localStorageKey) || "[]");
    if(maps!= undefined){
      maps = maps.filter(function(item) {
        return item.id !== searchText && !searchText.includes(item.label)
      });
    }
    maps.splice(0, 0, {id:searchText.toLowerCase(),label:searchText});
    maps = maps.slice(0, 2);
    localStorage.setItem(localStorageKey, JSON.stringify(maps));
  }

  function addSuggestion(indexSuggestion,$aaSearchDivRowContent , $aaSearchDivContentSectionDoctor, themeColor, themeSecondaryColor, $aaSearchTypo = null) {
  
    const aaSectionNoResultUl = {
      class: 'ul-list ',
    };

    const noResultSection = {
      class: 'pt-3 col-12 aaSection-Suggestion',
    };
    const $header = $('<header style="color:'+themeColor+'"><h3>People also search</h3></header>');
    const $noResultSection = $('<section>',noResultSection);
    const $noResultSectionUl = $('<ul>',aaSectionNoResultUl);
    $noResultSection.append($('<hr/>'));
    $noResultSection.append($header);
    
    $noResultSection.append($noResultSectionUl);

    indexSuggestion.search('',{
      hitsPerPage:5,
    })
    .then(({ hits }) => {
      $aaSearchDivContentSectionDoctor.removeClass("d-none");

      $aaSearchDivRowContent.append($aaSearchDivContentSectionDoctor);
      hits.forEach(function (hit) {
        const $li = $('<li class="li-aaSuggestion">');
        let $a;
        if($aaSearchTypo == null){
          $a = $('<a class="btn" href="'+getResultPath(hit.query)+'" style="color:'+themeSecondaryColor+';border-color:'+themeColor+'"><span>'+hit.query+'</span></a>');
          $a.bind('click',function(){
            addRecent(hit.query, localStorageKey);
          });
        }else{
          $a = $('<a class="btn"  style="color:'+themeSecondaryColor+';border-color:'+themeColor+'"><span>'+hit.query+'</span></a>');
          $a.bind('click',function(){
            $aaSearchTypo.val(hit.query);
            $aaSearchTypo.trigger('keyup');
            addRecent(hit.query, localStorageKey);
          });
        }
        
        
        $noResultSectionUl.append($li.append($a));
      });
      
      $aaSearchDivContentSectionDoctor.append($noResultSection);
    });
  }

  function gotoResultPage(query, indexName = null){
    addRecent(query, localStorageKey);
    window.location.href = getResultPath(query, indexName);
  }

  function getResultPath(query, indexName = null) {
    
    return seeAllUri+'?q='+encodeURIComponent(query)+ (indexName != null ? '&t='+encodeURIComponent(categories.find(x=>x.indexName==indexName).id):'')
    
  }

  function getFilterFromQueryString(defaultSearhFilters) {
    let defaultSearhFilter = {};
    const regexQueryString = /\[(\w+)]/;
    defaultSearhFilters.forEach(element => {
      if(element != ''){
        const items = element.split('&');
        items.forEach(item => {
          if(item ==''){
            return;
          }
          let fullParam = item.split('=');
          if(fullParam.length>0){
            let value = fullParam[1].split(',');
            let key = fullParam[0];
            
            const found = key.match(regexQueryString);
            if(found != null){
              let type = key.split('[')[0];
              if(defaultSearhFilter[type]===undefined){
                defaultSearhFilter[type] = {};
              }
              defaultSearhFilter[type][found[1]] = [];
              defaultSearhFilter[type][found[1]] = value;
            }
          }
        })
      }
      
    });

    return defaultSearhFilter;
  }
  function addFilter(eventName,$rootObject, indexName, defaultSearhFilter, themeSecondaryColor, typoKeyUp, $aaSearchTypo, suggestionStorageKey = '') {
    const randomChar = makeid(5);
    let currentLanguage;
    languages.forEach(function (language) {
      if($('body').hasClass(language.class)){
        currentLanguage = language.id;
      }
    });
    if(advanceFilters.find(x=>x.indexName == indexName) == null) {
      return;
    }
    const filters = advanceFilters.find(x=>x.indexName == indexName).filters;
    const categoriesContainer = {
      class: "aaCheckbox row",
      css:{
        color:themeSecondaryColor,
        paddingTop: '1rem'
      }
    };
    const $categoriesContainer = $("<div>", categoriesContainer);
   
    filters.forEach(function (filter) {
      const id = advanceFilters.find(x=>x.indexName == indexName).id;
      const categoryItemContainer = {
        class: "checkbox checkbox-inline col-auto"
      };
      const $categoryItemContainer = $('<div>', categoryItemContainer);
      $categoryItemContainer.append(`<h5>${currentLanguage === 'th-TH' ? filter.name_th : filter.name_en}</h5>`)
      filter.items.forEach(function (item) {
        const filterInput = {
          class: "styled",
          type: "checkbox",
          name: "aa"+filter.id,
          checked: (defaultSearhFilter[id] !== undefined && defaultSearhFilter[id][filter.id] !== undefined && defaultSearhFilter[id][filter.id].length>0 && defaultSearhFilter[id][filter.id].find(x=>x==item.id) != null),
          value: item.id,
          id: "aa-cb-"+item.id+"-"+randomChar
        };
        const $filterInput = $("<input>", filterInput);
        const filterlabel = {
          for: "aa-cb-"+item.id+"-"+randomChar
        };
        const $filterlabel = $("<label>", filterlabel);

        $filterInput.change(function(e) {
          if(!$(this).is(":checked")){
            defaultSearhFilter[id][filter.id] = defaultSearhFilter[id][filter.id].filter(x => (x != $(this).val()));
          }else{
            if(defaultSearhFilter[id] == undefined || !defaultSearhFilter[id].hasOwnProperty(filter.id)){
              if(defaultSearhFilter[id] == undefined){
                defaultSearhFilter[id] = {};
              }
              defaultSearhFilter[id][filter.id] = [];
            }
            if(defaultSearhFilter[id][filter.id].find(x => x == $(this).val()) === undefined  ){
              defaultSearhFilter[id][filter.id].push($(this).val());
            }
          }

          insightsFilterClick(eventName, id, defaultSearhFilter);


          if(suggestionStorageKey != '') {
            localStorage.setItem(suggestionStorageKey,JSON.stringify(defaultSearhFilter));
          }
          typoKeyUp($aaSearchTypo);
        });
       
        
        $filterlabel.html(currentLanguage === 'th-TH' ? item.name_th : item.name_en);
        $categoryItemContainer.append($filterInput);
        $categoryItemContainer.append($filterlabel);
        $categoryItemContainer.append("<br/>");
        $categoriesContainer.append($categoryItemContainer);
      });
     
    });
    $rootObject.append($categoriesContainer);
  }

  function insightsFilterClick(eventName, id, defaultSearhFilter){
    let object = {};
    const filterItem = advanceFilters.find(x=>x.id==id);
    object[filterItem.id] = defaultSearhFilter[id];
    let filter = getCustomFilter(filterItem,object);
    if(filter != '') {
      
      const filters = filter.split(" AND ");
      if(filters.length > 0){
        const userToken = getB2cId(jwtTokenName);
        aa('clickedFilters', {
          userToken: userToken,
          index: filterItem.indexName,
          eventName: getEventName(filterItem.indexName, eventName),
          filters: filters.filter(x=> x != '' && x != null)
        });
      }
      
    }
    
  }
  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function parseJwt (token) { 
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }catch{
      return null;
    }
  };

  function getB2cId(localStorageKey){
    try {
      let token = localStorage.getItem(localStorageKey);
      if(token != undefined){
        var jsonPayload = parseJwt(token);
        if(jsonPayload != null){
          return jsonPayload.sub;
        }
      }
      return getCookie('_ALGOLIA');
    }catch{
      return getCookie('_ALGOLIA');
    }
  }
  function getIndexElement(obj, rootSelector){
    let indexElement;
    if(obj.parent().parent().parent(rootSelector).length>0){
      indexElement = obj.parent().index();
    }else  if(obj.parent().parent().parent().parent(rootSelector).length>0){
      indexElement = obj.parent().parent().index();
    }else if(obj.parent().parent().parent().parent().parent().parent().parent(rootSelector).length>0){
      indexElement = obj.parent().parent().parent().index();
    }
    return indexElement;
  }
  function bindClickObjectIDAfterSearch(rootSelector, selectorItem, eventName){
    $(selectorItem).bind("click",function() {
      var obj = $(this);
      const queryID = obj.data('query-id');
      const objectID = obj.data('object-id');
      const trackingID = obj.data('tracking-id');
      const indexName = obj.data('index-name');
    
      let indexElement =  getIndexElement(obj, rootSelector);
      let userToken = getB2cId(jwtTokenName);

      aa('clickedObjectIDsAfterSearch', {
        userToken: userToken,
        index: indexName,
        eventName: getEventName(indexName, eventName),
        queryID: queryID,
        objectIDs: [objectID],
        positions: [indexElement +1]
      });
      if(indexName && objectID && queryID && userToken){
        setCookieInsights(indexName, getEventName(indexName, eventName), queryID, objectID, userToken, trackingID);
      }
      
      
    });
   
  }

  function getEventName(indexName, eventName){
    return eventName.replace('{indexName}',indexName);
  }

  function getExpires(exdays){
    var expires = getCookie(insightsExpiresCookieKey);
    
    if(expires != ""){
      return "expires="+ expires;
    }else{
      let dateExpires = new Date();
      dateExpires.setTime(dateExpires.getTime() + (exdays*24*60*60*1000));
      expires = "expires="+ dateExpires.toUTCString();
      setCookie(insightsExpiresCookieKey,dateExpires.toUTCString(),expires);
      return expires;
    }
    
  }

  function setCookieInsights(indexName, eventName, queryID, objectID, userToken, trackingID){
    var json = addObjectID(indexName, eventName, queryID, objectID, userToken, trackingID);
    var expires = getExpires(cookieExpireInDays);
    setCookie(insightsCookieKey, json, expires);

  }

  function setCookie(cname, cvalue, expires) {
    document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/; Secure;SameSite=Strict";
  }

  function getCookie(cname) {   
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  function addObjectID(indexName, eventName, queryID, objectID, userToken, trackingID){

    var cookie = getCookie(insightsCookieKey);
    let maps = JSON.parse(cookie || "[]");
    const newObject = {i:indexName, e:eventName, q:queryID, o:objectID, u:userToken, t:trackingID?trackingID:""};
    if(maps!= undefined){
     
      maps = maps.filter(function(item) {
        return item.indexName !== indexName || item.eventName !== eventName || item.queryID !== queryID || item.objectID !== objectID || item.userToken !== userToken
      });
      
    }else{
      maps = [];
    }
    maps.push(newObject);
    return JSON.stringify(maps.slice(Math.max(maps.length - 20, 0)));
  }

  async function getBanner(language){
    const response = await fetch(`${kontentaiBannerItem}&limit=1&language=${language}`)
    .then((response) => {
      if(response.ok){
        return response.json();
      }else{
        throw new Error('Something went wrong');
      }
    })
    .catch(()=> {
      return null;
    });
    return response;
  }

  export {superEncodeURI, getQueries, getItemName, replaceSwitchLanguageUrl, addRecent, addSuggestion, gotoResultPage, getFilterFromQueryString, addFilter, getArrayFilter, getB2cId, bindClickObjectIDAfterSearch, replaceHighLightSpecialty, getBanner};